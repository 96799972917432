/* Add this CSS to your existing CSS file or create a new file for styles */

/* Toast Message Styles */
.NewuserToast-message {
    position: fixed;
    bottom:42em;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    opacity: 0.9;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 200px;
    /* Adjust the width as needed */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s ease-in-out;
    font-size: 0.5em;
    /* text-align: center; */
    font-weight: 600;
}

/* Optional: Add styles for animation */
@keyframes fadeInOut {

    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

.NewuserToast-message-enter {
    animation: fadeInOut 0.3s ease-in-out;
}

.NewuserToast-message-exit {
    animation: fadeInOut 0.3s ease-in-out reverse;
}

/* .................................................... */

.newUser-loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 9999;
}

.newUser-spinner {
    border: 4px solid rgba(0, 0, 0, 0.3);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
    /* Apply the spin animation */
}

.newUser-main-container {
    display: flex;
    flex-direction: column;
    max-height: 100vh;
}

.newUser-content {
    /* margin-top: 10px; */
    border-top: 1px solid black;


    /* padding-top: 5px; */
    /* margin-bottom: 1em; */
}
.NewUserPhoneTemplate{
    max-height: 42em;
    /* opacity: 0.50; */
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 3.5em;
}
@media  screen and (max-width: 1366px) {
    /* Add this CSS to your existing CSS file or create a new file for styles */

/* Toast Message Styles */
.NewuserToast-message {
    position: fixed;
    bottom:42em;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    opacity: 0.9;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 200px;
    /* Adjust the width as needed */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s ease-in-out;
    font-size: 0.5em;
    /* text-align: center; */
    font-weight: 600;
}

/* Optional: Add styles for animation */
@keyframes fadeInOut {

    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

.NewuserToast-message-enter {
    animation: fadeInOut 0.3s ease-in-out;
}

.NewuserToast-message-exit {
    animation: fadeInOut 0.3s ease-in-out reverse;
}

/* .................................................... */

.newUser-loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 9999;
}

.newUser-spinner {
    border: 4px solid rgba(0, 0, 0, 0.3);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
    /* Apply the spin animation */
}

.newUser-main-container {
    display: flex;
    flex-direction: column;
    max-height: 100vh;
}

.newUser-content {
    /* margin-top: 10px; */
    border-top: 1px solid black;


    /* padding-top: 5px; */
    /* margin-bottom: 1em; */

}
.NewUserPhoneTemplate{
    max-height: 34em;
    /* opacity: 0.50; */
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 3em;
}

}
@media only screen
  and (min-device-width: 320px)
  and (max-device-width: 480px)
    and (orientation: portrait) {
        /* Add this CSS to your existing CSS file or create a new file for styles */

/* Toast Message Styles */
.NewuserToast-message {
    position: fixed;
    bottom:32em;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    opacity: 0.9;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 200px;
    /* Adjust the width as needed */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s ease-in-out;
    font-size: 0.5em;
    text-align: center;
}

/* Optional: Add styles for animation */
@keyframes fadeInOut {

    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

.NewuserToast-message-enter {
    animation: fadeInOut 0.3s ease-in-out;
}

.NewuserToast-message-exit {
    animation: fadeInOut 0.3s ease-in-out reverse;
}

/* .................................................... */
        .newUser-loading-spinner {
            display: flex;
            justify-content: center;
            align-items: center;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.7);
            z-index: 9999;
        }

        .newUser-spinner {
            border: 4px solid rgba(0, 0, 0, 0.3);
            border-top: 4px solid #3498db;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            animation: spin 2s linear infinite;
            /* Apply the spin animation */
        }

        .newUser-main-container {
            display: flex;
            flex-direction: column;
            max-height: 100vh;
        }

        .newUser-content {
            /* margin-top: 10px; */
            border-top: 1px solid black;


            /* padding-top: 5px; */
            /* margin-bottom: 1em; */
        }
        .NewUserPhoneTemplate{
            max-height: 42em;
            /* opacity: 0.50; */
            background-size: cover;
            background-repeat: no-repeat;
            margin-top: 3.5em;
        }
    }
/* For mobile devices (portrait and landscape) */
@media only screen and (max-width: 767px) {
    /* Your CSS styles for mobile devices */
}

/* For tablets and larger mobile devices (landscape) */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    /* Your CSS styles for tablets and larger mobile devices */
}


/* ----------- iPhone 4 and 4S ----------- */

/* Portrait and Landscape */
@media only screen
  and (min-device-width: 320px)
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2) {
    /* Add this CSS to your existing CSS file or create a new file for styles */

/* Toast Message Styles */
.NewuserToast-message {
    position: fixed;
    bottom:32em;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    opacity: 0.9;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 200px;
    /* Adjust the width as needed */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s ease-in-out;
    font-size: 0.5rem;
    text-align: center;
}

/* Optional: Add styles for animation */
@keyframes fadeInOut {

    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

.NewuserToast-message-enter {
    animation: fadeInOut 0.3s ease-in-out;
}

.NewuserToast-message-exit {
    animation: fadeInOut 0.3s ease-in-out reverse;
}

/* .................................................... */

    .newUser-loading-spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.7);
        z-index: 9999;
    }

    .newUser-spinner {
        border: 4px solid rgba(0, 0, 0, 0.3);
        border-top: 4px solid #3498db;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        animation: spin 2s linear infinite;
        /* Apply the spin animation */
    }

    .newUser-main-container {
        display: flex;
        flex-direction: column;
        max-height: 100vh;
    }

    .newUser-content {
        /* margin-top: 10px; */
        border-top: 1px solid black;


        /* padding-top: 5px; */
        /* margin-bottom: 1em; */
    }
    .NewUserPhoneTemplate{
        max-height: 42em;
        /* opacity: 0.50; */
        background-size: cover;
        background-repeat: no-repeat;
        margin-top: 3.5em;
    }

}

/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen
  and (min-device-width: 320px)
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2) {
    /* Add this CSS to your existing CSS file or create a new file for styles */

/* Toast Message Styles */
.NewuserToast-message {
    position: fixed;
    bottom:32em;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    opacity: 0.9;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 200px;
    /* Adjust the width as needed */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s ease-in-out;
    font-size: 0.5rem;
    text-align: center;
}

/* Optional: Add styles for animation */
@keyframes fadeInOut {

    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

.NewuserToast-message-enter {
    animation: fadeInOut 0.3s ease-in-out;
}

.NewuserToast-message-exit {
    animation: fadeInOut 0.3s ease-in-out reverse;
}

/* .................................................... */
    .newUser-loading-spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.7);
        z-index: 9999;
    }

    .newUser-spinner {
        border: 4px solid rgba(0, 0, 0, 0.3);
        border-top: 4px solid #3498db;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        animation: spin 2s linear infinite;
        /* Apply the spin animation */
    }

    .newUser-main-container {
        display: flex;
        flex-direction: column;
        max-height: 100vh;
    }

    .newUser-content {
        /* margin-top: 10px; */
        border-top: 1px solid black;


        /* padding-top: 5px; */
        /* margin-bottom: 1em; */
    }
    .NewUserPhoneTemplate{
        max-height: 42em;
        /* opacity: 0.50; */
        background-size: cover;
        background-repeat: no-repeat;
        margin-top: 3.5em;
    }

}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media only screen
  and (min-device-width: 375px)
  and (max-device-width: 667px)
  and (-webkit-min-device-pixel-ratio: 2) {
    /* Add this CSS to your existing CSS file or create a new file for styles */

/* Toast Message Styles */
.NewuserToast-message {
    position: fixed;
    bottom:32em;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    opacity: 0.9;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 200px;
    /* Adjust the width as needed */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s ease-in-out;
    font-size: 0.5rem;
    text-align: center;
}

/* Optional: Add styles for animation */
@keyframes fadeInOut {

    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

.NewuserToast-message-enter {
    animation: fadeInOut 0.3s ease-in-out;
}

.NewuserToast-message-exit {
    animation: fadeInOut 0.3s ease-in-out reverse;
}

/* .................................................... */

    .newUser-loading-spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.7);
        z-index: 9999;
    }

    .newUser-spinner {
        border: 4px solid rgba(0, 0, 0, 0.3);
        border-top: 4px solid #3498db;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        animation: spin 2s linear infinite;
        /* Apply the spin animation */
    }

    .newUser-main-container {
        display: flex;
        flex-direction: column;
        max-height: 100vh;
    }

    .newUser-content {
        /* margin-top: 10px; */
        border-top: 1px solid black;


        /* padding-top: 5px; */
        /* margin-bottom: 1em; */
    }
    .NewUserPhoneTemplate{
        max-height: 42em;
        /* opacity: 0.50; */
        background-size: cover;
        background-repeat: no-repeat;
        margin-top: 3.5em;
    }

}


/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait and Landscape */
@media only screen
  and (min-device-width: 414px)
  and (max-device-width: 736px)
  and (-webkit-min-device-pixel-ratio: 3) {

    /* Add this CSS to your existing CSS file or create a new file for styles */

/* Toast Message Styles */
.NewuserToast-message {
    position: fixed;
    bottom:32em;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    opacity: 0.9;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 200px;
    /* Adjust the width as needed */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s ease-in-out;
    font-size: 0.5rem;
    text-align: center;
}

/* Optional: Add styles for animation */
@keyframes fadeInOut {

    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

.NewuserToast-message-enter {
    animation: fadeInOut 0.3s ease-in-out;
}

.NewuserToast-message-exit {
    animation: fadeInOut 0.3s ease-in-out reverse;
}

/* .................................................... */

    .newUser-loading-spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.7);
        z-index: 9999;
    }

    .newUser-spinner {
        border: 4px solid rgba(0, 0, 0, 0.3);
        border-top: 4px solid #3498db;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        animation: spin 2s linear infinite;
        /* Apply the spin animation */
    }

    .newUser-main-container {
        display: flex;
        flex-direction: column;
        max-height: 100vh;
    }

    .newUser-content {
        /* margin-top: 10px; */
        border-top: 1px solid black;


        /* padding-top: 5px; */
        /* margin-bottom: 1em; */
    }
    .NewUserPhoneTemplate{
        max-height: 42em;
        /* opacity: 0.50; */
        background-size: cover;
        background-repeat: no-repeat;
        margin-top: 3.5em;
    }

}


/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen
  and (min-device-width: 375px)
  and (max-device-width: 812px)
  and (-webkit-min-device-pixel-ratio: 3) {

    /* Add this CSS to your existing CSS file or create a new file for styles */

/* Toast Message Styles */
.NewuserToast-message {
    position: fixed;
    bottom:32em;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    opacity: 0.9;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 200px;
    /* Adjust the width as needed */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s ease-in-out;
    font-size: 0.5rem;
    text-align: center;
}
/* Optional: Add styles for animation */
@keyframes fadeInOut {

    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

.NewuserToast-message-enter {
    animation: fadeInOut 0.3s ease-in-out;
}

.NewuserToast-message-exit {
    animation: fadeInOut 0.3s ease-in-out reverse;
}

/* .................................................... */

    .newUser-loading-spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.7);
        z-index: 9999;
    }

    .newUser-spinner {
        border: 4px solid rgba(0, 0, 0, 0.3);
        border-top: 4px solid #3498db;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        animation: spin 2s linear infinite;
        /* Apply the spin animation */
    }

    .newUser-main-container {
        display: flex;
        flex-direction: column;
        max-height: 100vh;
    }

    .newUser-content {
        /* margin-top: 10px; */
        border-top: 1px solid black;


        /* padding-top: 5px; */
        /* margin-bottom: 1em; */
    }
    .NewUserPhoneTemplate{
        max-height: 42em;
        /* opacity: 0.50; */
        background-size: cover;
        background-repeat: no-repeat;
        margin-top: 3.5em;
    }

}

/* ----------- Galaxy S3 ----------- */

/* Portrait and Landscape */
@media screen
  and (device-width: 360px)
  and (device-height: 640px)
  and (-webkit-device-pixel-ratio: 2) {

    /* Add this CSS to your existing CSS file or create a new file for styles */

/* Toast Message Styles */
.NewuserToast-message {
    position: fixed;
    bottom:35em;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    opacity: 0.9;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 200px;
    /* Adjust the width as needed */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s ease-in-out;
    font-size: 0.5rem;
    text-align: center;
}

/* Optional: Add styles for animation */
@keyframes fadeInOut {

    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

.NewuserToast-message-enter {
    animation: fadeInOut 0.3s ease-in-out;
}

.NewuserToast-message-exit {
    animation: fadeInOut 0.3s ease-in-out reverse;
}

/* .................................................... */

    .newUser-loading-spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.7);
        z-index: 9999;
    }

    .newUser-spinner {
        border: 4px solid rgba(0, 0, 0, 0.3);
        border-top: 4px solid #3498db;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        animation: spin 2s linear infinite;
        /* Apply the spin animation */
    }

    .newUser-main-container {
        display: flex;
        flex-direction: column;
        max-height: 100vh;
    }

    .newUser-content {
        /* margin-top: 10px; */
        border-top: 1px solid black;


        /* padding-top: 5px; */
        /* margin-bottom: 1em; */
    }
    .NewUserPhoneTemplate{
        max-height: 42em;
        /* opacity: 0.50; */
        background-size: cover;
        background-repeat: no-repeat;
        margin-top: 3.5em;
    }

}

/* ----------- Galaxy S4, S5 and Note 3 ----------- */

/* Portrait and Landscape */
@media screen
  and (device-width: 320px)
  and (device-height: 640px)
  and (-webkit-device-pixel-ratio: 3) {

    /* Add this CSS to your existing CSS file or create a new file for styles */

/* Toast Message Styles */
.NewuserToast-message {
    position: fixed;
    bottom:32em;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    opacity: 0.9;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 200px;
    /* Adjust the width as needed */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s ease-in-out;
    font-size: 0.5rem;
    text-align: center;
}

/* Optional: Add styles for animation */
@keyframes fadeInOut {

    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

.NewuserToast-message-enter {
    animation: fadeInOut 0.3s ease-in-out;
}

.NewuserToast-message-exit {
    animation: fadeInOut 0.3s ease-in-out reverse;
}

/* .................................................... */

    .newUser-loading-spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.7);
        z-index: 9999;
    }

    .newUser-spinner {
        border: 4px solid rgba(0, 0, 0, 0.3);
        border-top: 4px solid #3498db;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        animation: spin 2s linear infinite;
        /* Apply the spin animation */
    }

    .newUser-main-container {
        display: flex;
        flex-direction: column;
        max-height: 100vh;
    }

    .newUser-content {
        /* margin-top: 10px; */
        border-top: 1px solid black;


        /* padding-top: 5px; */
        /* margin-bottom: 1em; */
    }
    .NewUserPhoneTemplate{
        max-height: 42em;
        /* opacity: 0.50; */
        background-size: cover;
        background-repeat: no-repeat;
        margin-top: 3.5em;
    }

}

/* ----------- Galaxy S6 ----------- */

/* Portrait and Landscape */
@media screen
  and (device-width: 360px)
  and (device-height: 640px)
  and (-webkit-device-pixel-ratio: 4) {

    /* Add this CSS to your existing CSS file or create a new file for styles */

/* Toast Message Styles */
.NewuserToast-message {
    position: fixed;
    bottom:32em;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    opacity: 0.9;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 200px;
    /* Adjust the width as needed */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s ease-in-out;
    font-size: 0.5rem;
    text-align: center;
}
/* Optional: Add styles for animation */
@keyframes fadeInOut {

    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

.NewuserToast-message-enter {
    animation: fadeInOut 0.3s ease-in-out;
}

.NewuserToast-message-exit {
    animation: fadeInOut 0.3s ease-in-out reverse;
}

/* .................................................... */

    .newUser-loading-spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.7);
        z-index: 9999;
    }

    .newUser-spinner {
        border: 4px solid rgba(0, 0, 0, 0.3);
        border-top: 4px solid #3498db;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        animation: spin 2s linear infinite;
        /* Apply the spin animation */
    }

    .newUser-main-container {
        display: flex;
        flex-direction: column;
        max-height: 100vh;
    }

    .newUser-content {
        /* margin-top: 10px; */
        border-top: 1px solid black;


        /* padding-top: 5px; */
        /* margin-bottom: 1em; */
    }
    .NewUserPhoneTemplate{
        max-height: 42em;
        /* opacity: 0.50; */
        background-size: cover;
        background-repeat: no-repeat;
        margin-top: 3.5em;
    }

}

/* ----------- HTC One ----------- */

/* Portrait and Landscape */
@media screen
  and (device-width: 360px)
  and (device-height: 640px)
  and (-webkit-device-pixel-ratio: 3) {
    /* Add this CSS to your existing CSS file or create a new file for styles */

/* Toast Message Styles */
.NewuserToast-message {
    position: fixed;
    bottom:32em;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    opacity: 0.9;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 200px;
    /* Adjust the width as needed */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s ease-in-out;
    font-size: 0.5rem;
    text-align: center;
}

/* Optional: Add styles for animation */
@keyframes fadeInOut {

    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

.NewuserToast-message-enter {
    animation: fadeInOut 0.3s ease-in-out;
}

.NewuserToast-message-exit {
    animation: fadeInOut 0.3s ease-in-out reverse;
}

/* .................................................... */

    .newUser-loading-spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.7);
        z-index: 9999;
    }

    .newUser-spinner {
        border: 4px solid rgba(0, 0, 0, 0.3);
        border-top: 4px solid #3498db;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        animation: spin 2s linear infinite;
        /* Apply the spin animation */
    }

    .newUser-main-container {
        display: flex;
        flex-direction: column;
        max-height: 100vh;
    }

    .newUser-content {
        /* margin-top: 10px; */
        border-top: 1px solid black;


        /* padding-top: 5px; */
        /* margin-bottom: 1em; */
    }
    .NewUserPhoneTemplate{
        max-height: 42em;
        /* opacity: 0.50; */
        background-size: cover;
        background-repeat: no-repeat;
        margin-top: 3.5em;
    }

}

/* ----------- Google Pixel ----------- */

/* Portrait and Landscape */
@media screen
  and (device-width: 360px)
  and (device-height: 640px)
  and (-webkit-device-pixel-ratio: 3) {
    /* Add this CSS to your existing CSS file or create a new file for styles */

/* Toast Message Styles */
.NewuserToast-message {
    position: fixed;
    bottom:32em;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    opacity: 0.9;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 200px;
    /* Adjust the width as needed */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s ease-in-out;
    font-size: 0.5rem;
    text-align: center;
}

/* Optional: Add styles for animation */
@keyframes fadeInOut {

    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

.NewuserToast-message-enter {
    animation: fadeInOut 0.3s ease-in-out;
}

.NewuserToast-message-exit {
    animation: fadeInOut 0.3s ease-in-out reverse;
}

/* .................................................... */

    .newUser-loading-spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.7);
        z-index: 9999;
    }

    .newUser-spinner {
        border: 4px solid rgba(0, 0, 0, 0.3);
        border-top: 4px solid #3498db;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        animation: spin 2s linear infinite;
        /* Apply the spin animation */
    }

    .newUser-main-container {
        display: flex;
        flex-direction: column;
        max-height: 100vh;
    }

    .newUser-content {
        /* margin-top: 10px; */
        border-top: 1px solid black;


        /* padding-top: 5px; */
        /* margin-bottom: 1em; */
    }
    .NewUserPhoneTemplate{
        max-height: 42em;
        /* opacity: 0.50; */
        background-size: cover;
        background-repeat: no-repeat;
        margin-top: 3.5em;
    }

}

/* ----------- Google Pixel XL ----------- */

/* Portrait and Landscape */
@media screen
  and (device-width: 360px)
  and (device-height: 640px)
  and (-webkit-device-pixel-ratio: 4) {
    /* Add this CSS to your existing CSS file or create a new file for styles */

/* Toast Message Styles */
.NewuserToast-message {
    position: fixed;
    bottom:32em;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    opacity: 0.9;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 200px;
    /* Adjust the width as needed */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s ease-in-out;
    font-size: 0.5rem;
    text-align: center;
}

/* Optional: Add styles for animation */
@keyframes fadeInOut {

    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

.NewuserToast-message-enter {
    animation: fadeInOut 0.3s ease-in-out;
}

.NewuserToast-message-exit {
    animation: fadeInOut 0.3s ease-in-out reverse;
}

/* .................................................... */


    .newUser-loading-spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.7);
        z-index: 9999;
    }

    .newUser-spinner {
        border: 4px solid rgba(0, 0, 0, 0.3);
        border-top: 4px solid #3498db;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        animation: spin 2s linear infinite;
        /* Apply the spin animation */
    }

    .newUser-main-container {
        display: flex;
        flex-direction: column;
        max-height: 100vh;
    }

    .newUser-content {
        /* margin-top: 10px; */
        border-top: 1px solid black;


        /* padding-top: 5px; */
        /* margin-bottom: 1em; */
    }
    .NewUserPhoneTemplate{
        max-height: 42em;
        /* opacity: 0.50; */
        background-size: cover;
        background-repeat: no-repeat;
        margin-top: 3.5em;
    }

}

/* ----------- Nexus 4 ----------- */

/* Portrait and Landscape */
@media screen
  and (device-width: 384px)
  and (device-height: 592px)
  and (-webkit-device-pixel-ratio: 2) {
    /* Add this CSS to your existing CSS file or create a new file for styles */

/* Toast Message Styles */
.NewuserToast-message {
    position: fixed;
    bottom:32em;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    opacity: 0.9;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 200px;
    /* Adjust the width as needed */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s ease-in-out;
    font-size: 0.5rem;
    text-align: center;
}
/* Optional: Add styles for animation */
@keyframes fadeInOut {

    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

.NewuserToast-message-enter {
    animation: fadeInOut 0.3s ease-in-out;
}

.NewuserToast-message-exit {
    animation: fadeInOut 0.3s ease-in-out reverse;
}

/* .................................................... */
    .newUser-loading-spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.7);
        z-index: 9999;
    }

    .newUser-spinner {
        border: 4px solid rgba(0, 0, 0, 0.3);
        border-top: 4px solid #3498db;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        animation: spin 2s linear infinite;
        /* Apply the spin animation */
    }

    .newUser-main-container {
        display: flex;
        flex-direction: column;
        max-height: 100vh;
    }

    .newUser-content {
        /* margin-top: 10px; */
        border-top: 1px solid black;


        /* padding-top: 5px; */
        /* margin-bottom: 1em; */
    }
    .NewUserPhoneTemplate{
        max-height: 42em;
        /* opacity: 0.50; */
        background-size: cover;
        background-repeat: no-repeat;
        margin-top: 3.5em;
    }

}

/* ----------- Nexus 5 ----------- */

/* Portrait and Landscape */
@media screen
  and (device-width: 360px)
  and (device-height: 592px)
  and (-webkit-device-pixel-ratio: 3) {
    /* Add this CSS to your existing CSS file or create a new file for styles */

/* Toast Message Styles */
.NewuserToast-message {
    position: fixed;
    bottom:32em;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    opacity: 0.9;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 200px;
    /* Adjust the width as needed */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s ease-in-out;
    font-size: 0.5rem;
    text-align: center;
}

/* Optional: Add styles for animation */
@keyframes fadeInOut {

    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

.NewuserToast-message-enter {
    animation: fadeInOut 0.3s ease-in-out;
}

.NewuserToast-message-exit {
    animation: fadeInOut 0.3s ease-in-out reverse;
}

/* .................................................... */

    .newUser-loading-spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.7);
        z-index: 9999;
    }

    .newUser-spinner {
        border: 4px solid rgba(0, 0, 0, 0.3);
        border-top: 4px solid #3498db;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        animation: spin 2s linear infinite;
        /* Apply the spin animation */
    }

    .newUser-main-container {
        display: flex;
        flex-direction: column;
        max-height: 100vh;
    }

    .newUser-content {
        /* margin-top: 10px; */
        border-top: 1px solid black;


        /* padding-top: 5px; */
        /* margin-bottom: 1em; */
    }
    .NewUserPhoneTemplate{
        max-height: 42em;
        /* opacity: 0.50; */
        background-size: cover;
        background-repeat: no-repeat;
        margin-top: 3.5em;
    }

}

/* ----------- Nexus 6 and 6P ----------- */

/* Portrait and Landscape */
@media screen
  and (device-width: 360px)
  and (device-height: 592px)
  and (-webkit-device-pixel-ratio: 4) {
    /* Add this CSS to your existing CSS file or create a new file for styles */

/* Toast Message Styles */
.NewuserToast-message {
    position: fixed;
    bottom:32em;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    opacity: 0.9;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 200px;
    /* Adjust the width as needed */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s ease-in-out;
    font-size: 0.5rem;
    text-align: center;
}

/* Optional: Add styles for animation */
@keyframes fadeInOut {

    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

.NewuserToast-message-enter {
    animation: fadeInOut 0.3s ease-in-out;
}

.NewuserToast-message-exit {
    animation: fadeInOut 0.3s ease-in-out reverse;
}

/* .................................................... */

    .newUser-loading-spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.7);
        z-index: 9999;
    }

    .newUser-spinner {
        border: 4px solid rgba(0, 0, 0, 0.3);
        border-top: 4px solid #3498db;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        animation: spin 2s linear infinite;
        /* Apply the spin animation */
    }

    .newUser-main-container {
        display: flex;
        flex-direction: column;
        max-height: 100vh;
    }

    .newUser-content {
        /* margin-top: 10px; */
        border-top: 1px solid black;


        /* padding-top: 5px; */
        /* margin-bottom: 1em; */
    }
    .NewUserPhoneTemplate{
        max-height: 42em;
        /* opacity: 0.50; */
        background-size: cover;
        background-repeat: no-repeat;
        margin-top: 3.5em;
    }

}

/* ----------- Windows Phone ----------- */

/* Portrait and Landscape */
@media screen
  and (device-width: 480px)
  and (device-height: 800px) {
    /* Add this CSS to your existing CSS file or create a new file for styles */

/* Toast Message Styles */
.NewuserToast-message {
    position: fixed;
    bottom:32em;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    opacity: 0.9;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 200px;
    /* Adjust the width as needed */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s ease-in-out;
    font-size: 0.5rem;
    text-align: center;
}

/* Optional: Add styles for animation */
@keyframes fadeInOut {

    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

.NewuserToast-message-enter {
    animation: fadeInOut 0.3s ease-in-out;
}

.NewuserToast-message-exit {
    animation: fadeInOut 0.3s ease-in-out reverse;
}

/* .................................................... */

    .newUser-loading-spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.7);
        z-index: 9999;
    }

    .newUser-spinner {
        border: 4px solid rgba(0, 0, 0, 0.3);
        border-top: 4px solid #3498db;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        animation: spin 2s linear infinite;
        /* Apply the spin animation */
    }

    .newUser-main-container {
        display: flex;
        flex-direction: column;
        max-height: 100vh;
    }

    .newUser-content {
        /* margin-top: 10px; */
        border-top: 1px solid black;


        /* padding-top: 5px; */
        /* margin-bottom: 1em; */
    }
    .NewUserPhoneTemplate{
        max-height: 42em;
        /* opacity: 0.50; */
        background-size: cover;
        background-repeat: no-repeat;
        margin-top: 3.5em;
    }

}
