.toaster{
    position: fixed;
    left: 45%;
    z-index: 1000;
    top: 10em;
    text-align: center;
}

.homePage-loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 9999;
}

.homePage-spinner {
    border: 4px solid rgba(0, 0, 0, 0.3);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
    /* Apply the spin animation */
}


.homePageContainer {
    width: 500px;
    height: 190vh;
    margin-bottom: 3.5em;
    background-color: #e5e5e6;
    border-radius: 7px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 8.6em;
    bottom: 1em;
    /* overflow-y: auto; */
    /* Enable vertical scrolling */
    /* max-height: 100vh; */
    /* Set maximum height to fill the available space */
    min-height: max-content;
    overflow-x: hidden;

}

.grid-container {

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* Two columns */
    grid-template-rows: repeat(3, 1fr);
    /* Three rows */
    gap: 10px;
    /* Optional gap between grid items */
}

/* Style for the grid items (you can customize this as needed) */
.grid-item {
    /* border: 1px solid #ccc;
    padding: 20px; */
    text-align: center;
}

.homePage-image-container,
.homePage-header-container,
.homePage-header-container1,
.homePage-details-container,
.homePage-range-container,
.homePage-range-container1,
.homePage-range-container2,
.homePage-title-contaniner {
    display: flex;
    align-items: center;
    justify-content: center;
}

.image1 {
    max-width: 33%;
    /* height: 10%; */
    margin-right: 50%;
    margin-left: 50%;
    margin-top: 10px;
}


.bloodPressureImage {
    border: #000;
    border-radius: 10px;
    max-width: 25%;
    height: 8%;
    margin-right: 50%;
    margin-left: 50%;
    margin-top: 17px;
}


.image {
    max-width: 29%;
    height: 10%;
    margin-right: 50%;
    margin-left: 50%;
    /* margin-top: 30px; */
}


.homePageHeader-title {
    /* display: flex; */
    margin: 0;
    font-size: 14px;
    text-align: center;
    margin-right: 25%;
    margin-left: 25%;
    margin-top: 8px;
}

.homePageHeader-titleoxy {
    /* display: flex; */
    margin: 0;
    font-size: 14px;
    text-align: center;
    margin-right: 25%;
    margin-left: 24%;
    margin-top: -7px;
}

.homePageHeader-titleBT {
    /* display: flex; */
    margin: 0;
    font-size: 14px;
    text-align: center;
    margin-right: 25%;
    margin-left: 24%;
    margin-top: 7px;
}

.homePageHeader-title1 {
    margin: 0;
    font-size: 14px;
    text-align: center;
    margin-right: 25%;
    margin-left: 25%;
    margin-top: 8px;
}

.bioheaderLeft {
    flex: auto;
    margin-left: 3.7em;
}

.bioheaderRight {
    flex: 1;
}


.homePage-details-container .value {
    background-color: #ff9c00;
    color: white;
    font-size: 15px;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    margin-left: 25%;
    margin-right: 25%;
    margin-top: 5px;
    width: 60%;
}


.homePage-title-contaniner .tilte {
    text-align: center;
    font-size: 12px;
    margin-top: 5px;
    margin-left: 25%;
    margin-right: 25%;
}


.homePage-title-contaniner .tilteT {
    text-align: center;
    font-size: 12px;
    margin-top: 5px;
    margin-left: 25%;
    margin-right: 25%;
}



.homePage-range-container .range {
    text-align: center;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 15%;
    margin-right: 15%;
    font-weight: bold;
}


.homePage-range-container1 .range1 {
    text-align: center;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 25%;
    margin-right: 25%;
    font-weight: bold;
}


.homePage-range-container2 .range2 {
    text-align: center;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 25%;
    margin-right: 25%;
    margin-bottom: 20px;
    font-weight: bold;


}


.homePage-range-container1 .range3 {
    text-align: center;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 25%;
    margin-right: 25%;
    margin-bottom: 25px;
    font-weight: bold;
}


.homePage-range-container1 .range4 {
    text-align: center;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 25%;
    margin-right: 25%;
    margin-bottom: 25px;
    font-weight: bold;


}


.homePage-range-container1 .range5 {
    text-align: center;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 25%;
    margin-right: 25%;
    margin-bottom: 25px;
    font-weight: bold;
}


.homePage-range-container1 .range6 {
    text-align: center;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 25%;
    margin-right: 25%;
    margin-bottom: 25px;
    font-weight: bold;


}


.homePageFooter {
    display: flex;
    justify-content: center;
    margin-top: 1em;
}


.homePageElctrocardiogram-button {


    height: 40px;
    font-size: 19px;
    background-color: navy;
    color: white;
    position: relative;
    margin-top: 0.5em;
    margin-left: 3.5em;
    margin-bottom: 0.5em;
    margin-right: 4em;
    border-radius: 10px;


}

.homePageButton-text {
    /* font-weight: semi-bold; */

    color: white;
    font-size: 20px;
}


.homePage-ecg-button {
    height: 40px;
    font-size: 19px;
    background-color: navy;
    color: white;
    margin-top: 0.8em;
    margin-left: 3.5em;
    margin-right: 4em;
    border-radius: 10px;
    /* margin-bottom: 3em; */
}


.ECGGraphContainer {
    display: flex;
    background-color: #efefef;
    transform-origin: center;
    position: absolute;
    top: 0;
    left: 0;
    transition: width 0.2s, height 0.2s;
    overflow: auto;
    width: 96%;
    transform: scale(var(--zoomLevel));
}


.homePageECGIntervelText {
    font-size: 18px;
    font-weight: bold;
    margin-top: 8%;
    /* margin-left: 5em; */
    /* margin-right: 3.4em; */
}

.HomepageECGContainer{
    background-color: #efefef;
    height: 22em;
    width: 100%;
    margin: auto;
    /* margin-right: 10px; */
    margin-left: 10px;
    overflow: auto;
}

.homePageContainer-image {
    margin-top: 10px;
    padding-top: 5px;
}


.homePageHeader-title2 {
    margin: 0;
    font-size: 14px;
    text-align: center;
    margin-right: 25%;
    margin-left: 25%;
    margin-top: 8px;
}


.zoomText {
    font-size: 20px;
    font-weight: bold;
    margin-top: 0.4em;
    margin-left: 3em;
    margin-right: 2.5em;
}


.zoomPlusButton {
    width: 100px;
    height: 40px;
    background-color: navy;
    color: white;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    margin-bottom: 10px;
    margin-left: 6em;
}


.zoomMinusButton {
    width: 100px;
    height: 40px;
    background-color: navy;
    color: white;
    border-radius: 10px;
    border: none;
    cursor: pointer;
}



.homePageOverlay {
    position: fixed;
    height: 200vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;

}


.homePageTakeVitalsModal {
    position: absolute;
    top: 10em;
    top: 26%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    width: 300px;
    /* height: 535px; */

}

.switch {
    position: absolute;
    /* margin-left: 2em; */
    width: 46px;
    height: 24px;
}


.slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 0px;
    bottom: 0px;
    background-color:  greenyellow;
    border: 2px solid black;;
    transition: .4s;
    border-radius: 50%;
}

input:checked+.slider {
    background-color: white;
    transition: .4s;
}

input:checked+.slider:before {
    transform: translateX(23px);
}

.homePageButton {
    background-color: #1976D2;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 15px;
    margin: 0 5px;
    cursor: pointer;


}

.homePagefooter {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2.6em;
    background-color: white;
    display: flex;
    justify-content: space-between;
    padding: 14px 10px;
    box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
    /* Optional: Add a shadow to the footer */
}

/* .logoutModal {
    display: block;
    position: fixed;
    top: 50%;
    left: 52.5%;
    transform: translate(-50%, -50%);
    background-color: navy;
    z-index: 1000;
    width: 20em;
    height: 8.5em;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    background-color:rgba(0, 0, 0, 0.5) ;
} */



.instructionButton {
    display: flex;
    position: static;
    font-weight: 800;
    height: 20px;
    width: 20px;
    color: #efefef;
    margin-top: -1.5em;
    margin-left: 13em;
    cursor: pointer;
    background-color: #000080;
    border: none;
    border-radius: 1em;
    /* background-image: url('aa.png'); */
}

.popup {
    border: black solid 10px;
    border-radius: 10px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    height: 16.5em;
    width: 15em;
    padding: 20px;
    border: 1px solid #000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 999;
}

.ECGIntervalPopup {
    border: black solid 10px;
    border-radius: 10px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    height: 15em;
    width: 15em;
    padding: 20px;
    border: 1px solid #000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 999;
}

.ECGIntervalPopup1 {
    border: black solid 10px;
    border-radius: 10px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    height: 17em;
    width: 15em;
    padding: 20px;
    border: 1px solid #000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 999;
}


.popupheader {
    font-size: 1200;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4em;
    background-color: navy;
    color: white;
    /* Text color for the title */
    text-align: center;
    /* Center the text horizontally */
    line-height: 4em;
    /* Vertically center the text within the pseudo-element */
    font-weight: bold;
    /* Make the title text bold */
}



.popup-content {
    text-align: justify;
}

.instructionCloseButton {
    position: relative;
    padding: 10px;
    border: black solid 10px;
    border-radius: 10px;
    background-color: navy;
    color: #fff;
    margin-top: 2em;
    border: none;
    cursor: pointer;
    width: 5em;
    margin-left: 7em;

}

.instructionContent {
    margin-top: 4em;
}

.ECGgrid-container {

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* Two columns */
    grid-template-rows: repeat(2, 1fr);
    /* Two rows */
    gap: 10px;
    /* Optional gap between grid items */
}

.ibutton {
    display: flex;
    justify-content: flex-end;
}

.modalStyle2 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1000;
    width: 20em;
    height: 13em;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3em, white 3em);
}

.modalStyle0 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 999;
    width: 20em;
    height: 11em;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3em, white 3em);
}

.openModalStyle {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1000;
    width: 20em;
    height: 7em;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3em, white 3em);
}


.logout {
    display: fixed;
    top: 50%;
    left: 50%;
    padding: 20px;
    border: 1px solid #ccc;
    position: fixed;
    transform: translate(-50%, -50%);
    background-color: navy;
    width: 20em;
    height: 8.5em;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    z-index: 999;
}


.modalStyle {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 998;
    width: 20em;
    height: 12em;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3em, white 3em);
}


.modalStyle1 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 999;
    width: 20em;
    height: 9em;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3em, white 3em);
}

.grayOverlayStyle {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    /* Gray background with 50% opacity */
    z-index: 999;
}

.grayOverlayStyle0 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    /* Gray background with 50% opacity */
    z-index: 999;
}

.saveoverlayStyle {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 122.5%;
    background-color: rgba(0, 0, 0, 0.2);
    /* Semi-transparent black background */
    z-index: 999;
}

.SaveMust {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: navy;
    z-index: 1000;
    width: 20em;
    height: 8.5em;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
}

.saveYes {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: navy;
    z-index: 1000;
    width: 20em;
    height: 8.5em;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
}

.logoutoverlayStyle {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 999;
}

.overlayStyle {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 999;

}

.grayUpdateOverlayStyle {

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 999;

}

.bioheadergrid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    /* gap: 20px; */

}

.bioheaderLeft-item,
.bioheaderRight-item {
    /* border: 1px solid #ddd; */
    padding: 1%;
    margin-left: 19%;
    margin-right: 19%;
    /* text-align: center; */

    /* border-radius: 8px; */
}

.historyBoldTextStyle {
    font-weight: bold;
}

.bioHeader {
    height: 4.4em;
    padding: 9px;
    display: flex;
    background-color: navy;
    border: 1px solid #ccc;
    border-radius: 5px;
    justify-content: space-between;
    align-items: center;
    color: white;
    margin-bottom: 3px;
    margin-top: 2px;
}

.labelFonts{
    margin-top: 5px;
    margin-bottom: 2px;
    text-align: left;
    font-size: 10px;
    font-weight: 600;
    display: flex;
}

.labelFonts1{
    margin-top: 10px;
    margin-bottom: 2px;
    text-align: left;
    font-size: 10px;
    font-weight: 600;
    display: flex;
}

.labelFonts2{
    margin-left: 3px;
    margin-top: 10px;
    margin-bottom: 2px;
    text-align: left;
    font-size: 10px;
    font-weight: 600;
    display: flex;
}

.ageFont{
    margin-left: 9px;
    margin-top: 10px;
    margin-bottom: 2px;
    text-align: left;
    font-size: 10px;
    font-weight: 600;
    display: flex;
}

.faeyebutton::before {
    content: '';
    position: absolute;
    top: 65%;
    right: 0.3em;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
}
.faeyebutton::after{
    content: '';
    position: absolute;
    top: 65%;
    right: 2.5em;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
}

@media  screen and (max-width: 1366px) {
    .toaster{
        position: fixed;
        left: 45%;
        z-index: 1000;
        top: 10em;
        text-align: center;
    }

    .homePage-loading-spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.7);
        z-index: 9999;
    }

    .homePage-spinner {
        border: 4px solid rgba(0, 0, 0, 0.3);
        border-top: 4px solid #3498db;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        animation: spin 2s linear infinite;
        /* Apply the spin animation */
    }


    .homePageContainer {
        width: 500px;
        height: 300vh;
        margin-bottom: 3.5em;
        background-color: #e5e5e6;
        border-radius: 7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8.6em;
        bottom: 1em;
        /* overflow-y: auto; */
        /* Enable vertical scrolling */
        /* max-height: 100vh; */
        /* Set maximum height to fill the available space */
        min-height: max-content;
        overflow-x: hidden;

    }

    .grid-container {

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        /* Two columns */
        grid-template-rows: repeat(3, 1fr);
        /* Three rows */
        gap: 10px;
        /* Optional gap between grid items */
    }

    /* Style for the grid items (you can customize this as needed) */
    .grid-item {
        /* border: 1px solid #ccc;
        padding: 20px; */
        text-align: center;
    }

    .homePage-image-container,
    .homePage-header-container,
    .homePage-header-container1,
    .homePage-details-container,
    .homePage-range-container,
    .homePage-range-container1,
    .homePage-range-container2,
    .homePage-title-contaniner {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .image1 {
        max-width: 33%;
        /* height: 10%; */
        margin-right: 50%;
        margin-left: 50%;
        margin-top: 10px;
    }


    .bloodPressureImage {
        border: #000;
        border-radius: 10px;
        max-width: 25%;
        height: 8%;
        margin-right: 50%;
        margin-left: 50%;
        margin-top: 17px;
    }


    .image {
        max-width: 29%;
        height: 10%;
        margin-right: 50%;
        margin-left: 50%;
        /* margin-top: 30px; */
    }


    .homePageHeader-title {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }

    .homePageHeader-titleoxy {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 24%;
        margin-top: -7px;
    }

    .homePageHeader-titleBT {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 24%;
        margin-top: 7px;
    }

    .homePageHeader-title1 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }

    .bioheaderLeft {
        flex: auto;
        margin-left: 3.7em;
    }

    .bioheaderRight {
        flex: 1;
    }


    .homePage-details-container .value {
        background-color: #ff9c00;
        color: white;
        font-size: 15px;
        text-align: center;
        padding: 5px;
        border-radius: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-top: 5px;
        width: 60%;
    }


    .homePage-title-contaniner .tilte {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
    }


    .homePage-title-contaniner .tilteT {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
    }



    .homePage-range-container .range {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 15%;
        margin-right: 15%;
        font-weight: bold;
    }


    .homePage-range-container1 .range1 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        font-weight: bold;
    }


    .homePage-range-container2 .range2 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 20px;
        font-weight: bold;


    }


    .homePage-range-container1 .range3 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 25px;
        font-weight: bold;
    }


    .homePage-range-container1 .range4 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 25px;
        font-weight: bold;


    }


    .homePage-range-container1 .range5 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 25px;
        font-weight: bold;
    }


    .homePage-range-container1 .range6 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 25px;
        font-weight: bold;


    }


    .homePageFooter {
        display: flex;
        justify-content: center;
        margin-top: 1em;
    }


    .homePageElctrocardiogram-button {


        height: 40px;
        font-size: 19px;
        background-color: navy;
        color: white;
        position: relative;
        margin-top: 0.5em;
        margin-left: 3.5em;
        margin-bottom: 0.5em;
        margin-right: 4em;
        border-radius: 10px;


    }

    .homePageButton-text {
        /* font-weight: semi-bold; */

        color: white;
        font-size: 20px;
    }


    .homePage-ecg-button {
        height: 40px;
        font-size: 19px;
        background-color: navy;
        color: white;
        margin-top: 0.8em;
        margin-left: 3.5em;
        margin-right: 4em;
        border-radius: 10px;
        /* margin-bottom: 3em; */
    }


    .ECGGraphContainer {
        display: flex;
        background-color: #efefef;
        transform-origin: center;
        position: absolute;
        top: 0;
        left: 0;
        transition: width 0.2s, height 0.2s;
        overflow: auto;
        width: 96%;
        transform: scale(var(--zoomLevel));
    }


    .homePageECGIntervelText {
        font-size: 18px;
        font-weight: bold;
        margin-top: 8%;
        /* margin-left: 5em; */
        /* margin-right: 3.4em; */
    }

    .HomepageECGContainer{
        background-color: #efefef;
        height: 22em;
        width: 100%;
        margin: auto;
        /* margin-right: 10px; */
        margin-left: 10px;
        overflow: auto;
    }

    .homePageContainer-image {
        margin-top: 10px;
        padding-top: 5px;
    }


    .homePageHeader-title2 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }


    .zoomText {
        font-size: 20px;
        font-weight: bold;
        margin-top: 0.4em;
        margin-left: 3em;
        margin-right: 2.5em;
    }


    .zoomPlusButton {
        width: 100px;
        height: 40px;
        background-color: navy;
        color: white;
        border-radius: 10px;
        border: none;
        cursor: pointer;
        margin-bottom: 10px;
        margin-left: 6em;
    }


    .zoomMinusButton {
        width: 100px;
        height: 40px;
        background-color: navy;
        color: white;
        border-radius: 10px;
        border: none;
        cursor: pointer;
    }



    .homePageOverlay {
        position: fixed;
        height: 200vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 999;

    }


    .homePageTakeVitalsModal {
        position: absolute;
        top: 10em;
        top: 26%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        width: 300px;
        /* height: 535px; */

    }

    .switch {
        position: absolute;
        /* margin-left: 2em; */
        width: 46px;
        height: 24px;
    }


    .slider {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: .4s;
        border-radius: 34px;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 0px;
        bottom: 0px;
        background-color:  greenyellow;
        border: 2px solid black;;
        transition: .4s;
        border-radius: 50%;
    }

    input:checked+.slider {
        background-color: white;
        transition: .4s;
    }

    input:checked+.slider:before {
        transform: translateX(23px);
    }

    .homePageButton {
        background-color: #1976D2;
        color: white;
        border: none;
        border-radius: 5px;
        padding: 5px 15px;
        margin: 0 5px;
        cursor: pointer;


    }

    .homePagefooter {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2.6em;
        background-color: white;
        display: flex;
        justify-content: space-between;
        padding: 14px 10px;
        box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
        /* Optional: Add a shadow to the footer */
    }

    /* .logoutModal {
        display: block;
        position: fixed;
        top: 50%;
        left: 52.5%;
        transform: translate(-50%, -50%);
        background-color: navy;
        z-index: 1000;
        width: 20em;
        height: 8.5em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
        background-color:rgba(0, 0, 0, 0.5) ;
    } */



    .instructionButton {
        display: flex;
        position: static;
        font-weight: 800;
        height: 20px;
        width: 20px;
        color: #efefef;
        margin-top: -1.5em;
        margin-left: 13em;
        cursor: pointer;
        background-color: #000080;
        border: none;
        border-radius: 1em;
        /* background-image: url('aa.png'); */
    }

    .popup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 16.5em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .ECGIntervalPopup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 15em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .ECGIntervalPopup1 {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 17em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }


    .popupheader {
        font-size: 1200;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 4em;
        background-color: navy;
        color: white;
        /* Text color for the title */
        text-align: center;
        /* Center the text horizontally */
        line-height: 4em;
        /* Vertically center the text within the pseudo-element */
        font-weight: bold;
        /* Make the title text bold */
    }



    .popup-content {
        text-align: justify;
    }

    .instructionCloseButton {
        position: relative;
        padding: 10px;
        border: black solid 10px;
        border-radius: 10px;
        background-color: navy;
        color: #fff;
        margin-top: 2em;
        border: none;
        cursor: pointer;
        width: 5em;
        margin-left: 7em;

    }

    .instructionContent {
        margin-top: 4em;
    }

    .ECGgrid-container {

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        /* Two columns */
        grid-template-rows: repeat(2, 1fr);
        /* Two rows */
        gap: 10px;
        /* Optional gap between grid items */
    }

    .ibutton {
        display: flex;
        justify-content: flex-end;
    }

    .modalStyle2 {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 1000;
        width: 20em;
        height: 13em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }

    .modalStyle0 {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
        width: 20em;
        height: 11em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }

    .openModalStyle {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 1000;
        width: 20em;
        height: 7em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }


    .logout {
        display: fixed;
        top: 50%;
        left: 50%;
        padding: 20px;
        border: 1px solid #ccc;
        position: fixed;
        transform: translate(-50%, -50%);
        background-color: navy;
        width: 20em;
        height: 8.5em;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
        z-index: 999;
    }


    .modalStyle {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 998;
        width: 20em;
        height: 12em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }


    .modalStyle1 {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
        width: 20em;
        height: 9em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }

    .grayOverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        /* Gray background with 50% opacity */
        z-index: 999;
    }

    .grayOverlayStyle0 {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        /* Gray background with 50% opacity */
        z-index: 999;
    }

    .saveoverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 122.5%;
        background-color: rgba(0, 0, 0, 0.2);
        /* Semi-transparent black background */
        z-index: 999;
    }

    .SaveMust {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: navy;
        z-index: 1000;
        width: 20em;
        height: 8.5em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    }

    .saveYes {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: navy;
        z-index: 1000;
        width: 20em;
        height: 8.5em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    }

    .logoutoverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .overlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;

    }

    .grayUpdateOverlayStyle {

        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;

    }

    .bioheadergrid {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        /* gap: 20px; */

    }

    .bioheaderLeft-item,
    .bioheaderRight-item {
        /* border: 1px solid #ddd; */
        padding: 1%;
        margin-left: 19%;
        margin-right: 19%;
        /* text-align: center; */

        /* border-radius: 8px; */
    }

    .historyBoldTextStyle {
        font-weight: bold;
    }

    .bioHeader {
        height: 4.4em;
        padding: 9px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-bottom: 3px;
        margin-top: 2px;
    }

    .labelFonts{
        margin-top: 5px;
        margin-bottom: 2px;
        text-align: left;
        font-size: 10px;
        font-weight: 600;
        display: flex;
    }

    .labelFonts1{
        margin-top: 10px;
        margin-bottom: 2px;
        text-align: left;
        font-size: 10px;
        font-weight: 600;
        display: flex;
    }

    .labelFonts2{
        margin-left: 3px;
        margin-top: 10px;
        margin-bottom: 2px;
        text-align: left;
        font-size: 10px;
        font-weight: 600;
        display: flex;
    }

    .ageFont{
        margin-left: 9px;
        margin-top: 10px;
        margin-bottom: 2px;
        text-align: left;
        font-size: 10px;
        font-weight: 600;
        display: flex;
    }

    /* insert styles here */
}

@media only screen and (min-device-width: 360px) and (max-device-width: 480px) and (orientation: portrait) {

    .bioHeader {
        height: 3.8em;
        padding: 9px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-bottom: 3px;
        margin-top: 2px;
    }

    .ibutton {
        display: flex;
        justify-content: flex-end;
    }

    .instructionButton {
        font-weight: 800;
        height: 20px;
        width: 20px;
        color: #efefef;
        margin-top: -1.5em;
        margin-left: 13em;
        cursor: pointer;
        background-color: #000080;
        border: none;
        border-radius: 1em;
        /* background-image: url('aa.png'); */
    }

     .popup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 17em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .ECGIntervalPopup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 15em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .ECGIntervalPopup1 {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 17em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }
    .popup-content {
        text-align: justify;
    }

    .popupheader {
        font-size: 1200;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 4em;
        background-color: navy;
        color: white;
        /* Text color for the title */
        text-align: center;
        /* Center the text horizontally */
        line-height: 4em;
        /* Vertically center the text within the pseudo-element */
        font-weight: bold;
        /* Make the title text bold */
    }

    .homePageContainer {
        width: 500px;
        height: 250vh;
        margin-bottom: 5.5em;
        background-color: #e5e5e6;
        border-radius: 7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8.6em;
        bottom: 1em;
        overflow-y: auto;
        /* Enable vertical scrolling */
        /* max-height: 100vh; */
        /* Set maximum height to fill the available space */
        min-height: 115vh;


    }

    .logoutModal {
        display: block;
        /* or 'none' based on your logic */
        position: fixed;
        top: 30%;
        left: 55%;
        transform: translate(-50%, -50%);
        background-color: navy;
        z-index: 1000;
        width: 20em;
        height: 8.5em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    }

    .grid-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        /* Two columns */
        grid-template-rows: repeat(3, 1fr);
        /* Three rows */
        gap: 0px;
        width: inherit;
        padding: 1.5em;
        margin-top: -2em;
        /* Optional gap between grid items */
    }

    /* Style for the grid items (you can customize this as needed) */
    .grid-item {
        /* border: 1px solid #ccc;
        padding: 20px; */
        text-align: center;
    }

    .homePage-image-container,
    .homePage-header-container,
    .homePage-header-container1,
    .homePage-details-container,
    .homePage-range-container,
    .homePage-range-container1,
    .homePage-range-container2,
    .homePage-title-contaniner {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .image1 {
        max-width: 33%;
        /* height: 10%; */
        margin-right: 50%;
        margin-left: 50%;
        margin-top: 10px;
    }

    .bloodPressureImage {
        border: #000;
        border-radius: 10px;
        max-width: 25%;
        height: 8%;
        margin-right: 50%;
        margin-left: 50%;
        margin-top: 17px;
    }

    .image {
        max-width: 29%;
        height: 10%;
        margin-right: 50%;
        margin-left: 50%;
        /* margin-top: 30px; */
    }


    .homePageHeader-title {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }

    .homePageHeader-titleoxy {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 24%;
        margin-top: -7px;
    }

    .homePageHeader-titleBT {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 24%;
        margin-top: 7px;
    }

    .homePageHeader-title1 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }


    .bioheaderLeft {
        flex: auto;
        margin-left: 3.7em;
    }

    .bioheaderRight {
        flex: 1;
    }

    .homePage-details-container .value {
        background-color: #ff9c00;
        color: white;
        font-size: 15px;
        text-align: center;
        padding: 5px;
        border-radius: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-top: 5px;
        width: 60%;
    }


    .homePage-title-contaniner .tilte {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
    }

    .homePage-title-contaniner .tilteT {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
    }



    .homePage-range-container .range {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 15%;
        margin-right: 15%;
        font-weight: bold;
    }


    .homePage-range-container1 .range1 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        font-weight: bold;
    }


    .homePage-range-container2 .range2 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 20px;
        font-weight: bold;


    }


    .homePage-range-container1 .range3 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 77px;
        margin-bottom: 25px;
        font-weight: bold;
    }


    .homePage-range-container1 .range4 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 55px;
        margin-bottom: 25px;
        font-weight: bold;


    }


    .homePage-range-container1 .range5 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 25px;
        font-weight: bold;
    }


    .homePage-range-container1 .range6 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 25px;
        font-weight: bold;
    }

    .homePageFooter {
        display: flex;
        justify-content: center;
        margin-top: -1.5em
    }

    .homePageElctrocardiogram-button {
        height: 40px;
        font-size: 19px;
        background-color: navy;
        color: white;
        position: relative;
        margin-top: 0.5em;
        margin-left: 15%;
        margin-bottom: 0.8%;
        margin-right: 7.5%;
        border-radius: 10px;


    }

    .homePageButton-text {
        /* font-weight: semi-bold; */

        color: white;
        font-size: 20px;
    }


    .homePage-ecg-button {
        height: 40px;
        font-size: 19px;
        background-color: navy;
        color: white;
        margin-top: 0.8em;
        margin-left: 15%;
        margin-right: 7%;
        border-radius: 10px;
        /* margin-bottom: 3em; */
    }


    .ECGGraphContainer {
        display: flex;
        background-color: #efefef;
        transform-origin: center;
        position: absolute;
        top: 0;
        left: 0;
        transition: width 0.2s, height 0.2s;
        overflow: auto;
        width: 96%;
        transform: scale(var(--zoomLevel));
    }


    .homePageECGIntervelText {
        font-size: 18px;
        font-weight: bold;
        margin-top: 8%;
        margin-left: 3em;
        /* margin-right: 3.4em; */
    }
    .HomepageECGContainer{
        background-color: #efefef;
        height: 22em;
        width: 93%;
        margin: auto;
        /* margin-right: 10px; */
        margin-left: 2.5em;
        overflow: auto;
    }

    .homePageContainer-image {
        margin-top: 10px;
        padding-top: 5px;
    }


    .homePageHeader-title2 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }


    .zoomText {
        font-size: 20px;
        font-weight: bold;
        margin-top: 0.4em;
        margin-left: 3em;
        margin-right: 2.5em;
    }


    .zoomPlusButton {
        width: 100px;
        height: 40px;
        background-color: navy;
        color: white;
        border-radius: 10px;
        border: none;
        cursor: pointer;
        margin-bottom: 10px;
        margin-left: 6em;
    }


    .zoomMinusButton {
        width: 100px;
        height: 40px;
        background-color: navy;
        color: white;
        border-radius: 10px;
        border: none;
        cursor: pointer;
    }



    .homePageOverlay {
        position: fixed;
        height: 250vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .homePageTakeVitalsModal {
        position: absolute;
        height: 36em;
        top: 25%;
        /* left: 50%; */
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        width: 300px;
        /* height: 535px; */
        /* Adjust the width as needed */
    }

    .switch {
        position: absolute;
        margin-left: 2em;
        width: 46px;
        height: 25px;
    }


    .slider {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: .4s;
        border-radius: 34px;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 0px;
        bottom: 0px;
        background-color:  greenyellow;
        border: 2px solid black;;
        transition: .4s;
        border-radius: 50%;
    }

    input:checked+.slider {
        background-color: white;
        transition: .4s;
    }

    input:checked+.slider:before {
        transform: translateX(23px);
    }

    .homePageButton {
        background-color: #1976D2;
        color: white;
        border: none;
        border-radius: 5px;
        padding: 5px 15px;
        margin: 0 5px;
        cursor: pointer;


    }

    .homePagefooter {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2.6em;
        background-color: white;
        display: flex;
        justify-content: space-between;
        padding: 14px 10px;
        box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
        /* Optional: Add a shadow to the footer */
    }

    .ECGgrid-container {

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        /* Two columns */
        grid-template-rows: repeat(2, 1fr);
        /* Two rows */
        gap: 4px;
        /* Optional gap between grid items */
        margin-left: 4em;
    }

    .modalStyle2 {
        position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1000;
    width: 20em;
    height: 13em;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3em, white 3em);
    }
    .modalStyle0 {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
        width: 20em;
        height: 11em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }


    .openModalStyle {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 1000;
        width: 20em;
        height: 7em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }


    .logout {
        display: fixed;
    top: 50%;
    left: 50%;
    padding: 20px;
    border: 1px solid #ccc;
    position: fixed;
    transform: translate(-50%, -50%);
    background-color: navy;
    width: 20em;
    height: 8.5em;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    z-index: 999;
    }


    .modalStyle {
        position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 998;
    width: 20em;
    height: 12em;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3em, white 3em);
    }


    .modalStyle1 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 999;
    width: 20em;
    height: 11em;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3em, white 3em);
    }

    .grayOverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        /* Gray background with 50% opacity */
        z-index: 999;
    }

    .grayOverlayStyle0 {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        /* Gray background with 50% opacity */
        z-index: 999;
    }


    .saveoverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 122.5%;
        background-color: rgba(0, 0, 0, 0.2);
        /* Semi-transparent black background */
        z-index: 999;
    }

    .notsaveoverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 122.5%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .SaveMust {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: navy;
        z-index: 1000;
        width: 20em;
        height: 8.5em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    }

    .saveYes {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: navy;
        z-index: 1000;
        width: 20em;
        height: 8.5em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    }

    .logoutoverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .overlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 122.5%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;

    }

    .grayUpdateOverlayStyle {

        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;

    }

    .bioheadergrid {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        /* gap: 20px; */
        margin-left: 1.5em;
    }

    .bioheaderLeft-item,
    .bioheaderRight-item {
        /* border: 1px solid #ddd; */
        padding: 1%;
        margin-left: 19%;
        margin-right: 19%;
        /* text-align: center; */

        /* border-radius: 8px; */
    }

    .historyBoldTextStyle {
        font-weight: bold;
    }



}

/* ----------- iPhone 4 and 4S ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    .bioHeader {
        height: 3.8em;
        padding: 9px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-bottom: 3px;
        margin-top: 2px;
    }

    .ibutton {
        display: flex;
        justify-content: flex-end;
    }

    .instructionButton {
        font-weight: 800;
        height: 20px;
        width: 20px;
        color: #efefef;
        margin-top: -1.5em;
        margin-left: 13em;
        cursor: pointer;
        background-color: #000080;
        border: none;
        border-radius: 1em;
        /* background-image: url('aa.png'); */
    }

     .popup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 17em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .ECGIntervalPopup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 15em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .ECGIntervalPopup1 {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 17em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }
    .popup-content {
        text-align: justify;
    }

    .popupheader {
        font-size: 1200;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 4em;
        background-color: navy;
        color: white;
        /* Text color for the title */
        text-align: center;
        /* Center the text horizontally */
        line-height: 4em;
        /* Vertically center the text within the pseudo-element */
        font-weight: bold;
        /* Make the title text bold */
    }

    .homePageContainer {
        width: 500px;
        height: 250vh;
        margin-bottom: 5.5em;
        background-color: #e5e5e6;
        border-radius: 7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8.6em;
        bottom: 1em;
        overflow-y: auto;
        /* Enable vertical scrolling */
        /* max-height: 100vh; */
        /* Set maximum height to fill the available space */
        min-height: 115vh;


    }

    .logoutModal {
        display: block;
        /* or 'none' based on your logic */
        position: fixed;
        top: 30%;
        left: 55%;
        transform: translate(-50%, -50%);
        background-color: navy;
        z-index: 1000;
        width: 20em;
        height: 8.5em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    }

    .grid-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        /* Two columns */
        grid-template-rows: repeat(3, 1fr);
        /* Three rows */
        gap: 0px;
        width: inherit;
        padding: 1.5em;
        /* Optional gap between grid items */
        margin-top: -2em;
    }

    /* Style for the grid items (you can customize this as needed) */
    .grid-item {
        /* border: 1px solid #ccc;
        padding: 20px; */
        text-align: center;
    }

    .homePage-image-container,
    .homePage-header-container,
    .homePage-header-container1,
    .homePage-details-container,
    .homePage-range-container,
    .homePage-range-container1,
    .homePage-range-container2,
    .homePage-title-contaniner {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .image1 {
        max-width: 33%;
        /* height: 10%; */
        margin-right: 50%;
        margin-left: 50%;
        margin-top: 10px;
    }

    .bloodPressureImage {
        border: #000;
        border-radius: 10px;
        max-width: 25%;
        height: 8%;
        margin-right: 50%;
        margin-left: 50%;
        margin-top: 17px;
    }

    .image {
        max-width: 29%;
        height: 10%;
        margin-right: 50%;
        margin-left: 50%;
        /* margin-top: 30px; */
    }


    .homePageHeader-title {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }

    .homePageHeader-titleoxy {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 24%;
        margin-top: -7px;
    }

    .homePageHeader-titleBT {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 24%;
        margin-top: 7px;
    }

    .homePageHeader-title1 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }

    .bioheaderLeft {
        flex: auto;
        margin-left: 3.7em;
    }

    .bioheaderRight {
        flex: 1;
    }

    .homePage-details-container .value {
        background-color: #ff9c00;
        color: white;
        font-size: 15px;
        text-align: center;
        padding: 5px;
        border-radius: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-top: 5px;
        width: 60%;
    }


    .homePage-title-contaniner .tilte {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
    }


    .homePage-title-contaniner .tilteT {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
    }



    .homePage-range-container .range {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 15%;
        margin-right: 15%;
        font-weight: bold;
    }


    .homePage-range-container1 .range1 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        font-weight: bold;
    }


    .homePage-range-container2 .range2 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 20px;
        font-weight: bold;


    }


    .homePage-range-container1 .range3 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 77px;
        margin-bottom: 25px;
        font-weight: bold;
    }


    .homePage-range-container1 .range4 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 55px;
        margin-bottom: 25px;
        font-weight: bold;


    }


    .homePage-range-container1 .range5 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 25px;
        font-weight: bold;
    }


    .homePage-range-container1 .range6 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 25px;
        font-weight: bold;
    }

    .homePageFooter {
        display: flex;
        justify-content: center;
        margin-top: -1.5em
    }

    .homePageElctrocardiogram-button {
        height: 40px;
        font-size: 19px;
        background-color: navy;
        color: white;
        position: relative;
        margin-top: 0.5em;
        margin-left: 15%;
        margin-bottom: 0.8%;
        margin-right: 7.5%;
        border-radius: 10px;


    }

    .homePageButton-text {
        /* font-weight: semi-bold; */

        color: white;
        font-size: 20px;
    }


    .homePage-ecg-button {
        height: 40px;
        font-size: 19px;
        background-color: navy;
        color: white;
        margin-top: 0.8em;
        margin-left: 15%;
        margin-right: 7%;
        border-radius: 10px;
        /* margin-bottom: 3em; */
    }


    .ECGGraphContainer {
        display: flex;
        background-color: #efefef;
        transform-origin: center;
        position: absolute;
        top: 0;
        left: 0;
        transition: width 0.2s, height 0.2s;
        overflow: auto;
        width: 96%;
        transform: scale(var(--zoomLevel));
    }


    .homePageECGIntervelText {
        font-size: 18px;
        font-weight: bold;
        margin-top: 8%;
        margin-left: 3em;
        /* margin-right: 3.4em; */
    }
    .HomepageECGContainer{
        background-color: #efefef;
        height: 22em;
        width: 93%;
        margin: auto;
        /* margin-right: 10px; */
        margin-left: 2.5em;
        overflow: auto;
    }


    .homePageContainer-image {
        margin-top: 10px;
        padding-top: 5px;
    }


    .homePageHeader-title2 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }


    .zoomText {
        font-size: 20px;
        font-weight: bold;
        margin-top: 0.4em;
        margin-left: 3em;
        margin-right: 2.5em;
    }


    .zoomPlusButton {
        width: 100px;
        height: 40px;
        background-color: navy;
        color: white;
        border-radius: 10px;
        border: none;
        cursor: pointer;
        margin-bottom: 10px;
        margin-left: 6em;
    }


    .zoomMinusButton {
        width: 100px;
        height: 40px;
        background-color: navy;
        color: white;
        border-radius: 10px;
        border: none;
        cursor: pointer;
    }



    .homePageOverlay {
        position: fixed;
        height: 250vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .homePageTakeVitalsModal {
        position: absolute;
        height: 36em;
        top: 25%;
        /* left: 50%; */
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        width: 300px;
        /* height: 535px; */
        /* Adjust the width as needed */
    }

    .switch {
        position: absolute;
        margin-left: 2em;
        width: 46px;
        height: 25px;
    }


    .slider {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: .4s;
        border-radius: 34px;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 0px;
        bottom: 0px;
        background-color:  greenyellow;
        border: 2px solid black;;
        transition: .4s;
        border-radius: 50%;
    }

    input:checked+.slider {
        background-color: white;
        transition: .4s;
    }

    input:checked+.slider:before {
        transform: translateX(23px);
    }


    .homePageButton {
        background-color: #1976D2;
        color: white;
        border: none;
        border-radius: 5px;
        padding: 5px 15px;
        margin: 0 5px;
        cursor: pointer;


    }

    .homePagefooter {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2.6em;
        background-color: white;
        display: flex;
        justify-content: space-between;
        padding: 14px 10px;
        box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
        /* Optional: Add a shadow to the footer */
    }

    .ECGgrid-container {

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        /* Two columns */
        grid-template-rows: repeat(2, 1fr);
        /* Two rows */
        gap: 4px;
        /* Optional gap between grid items */
        margin-left: 4em;
    }

    .modalStyle2 {
        position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1000;
    width: 20em;
    height: 13em;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3em, white 3em);
    }

    .modalStyle0 {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
        width: 20em;
        height: 11em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }

    .openModalStyle {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 1000;
        width: 20em;
        height: 7em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }


    .logout {
        display: fixed;
    top: 50%;
    left: 50%;
    padding: 20px;
    border: 1px solid #ccc;
    position: fixed;
    transform: translate(-50%, -50%);
    background-color: navy;
    width: 20em;
    height: 8.5em;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    z-index: 999;
    }


    .modalStyle {
        position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 998;
    width: 20em;
    height: 11em;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3em, white 3em);
    }


    .modalStyle1 {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
        width: 20em;
        height: 11em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }

    .grayOverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        /* Gray background with 50% opacity */
        z-index: 999;
    }

    .grayOverlayStyle0 {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        /* Gray background with 50% opacity */
        z-index: 999;
    }

    .saveoverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 122.5%;
        background-color: rgba(0, 0, 0, 0.2);
        /* Semi-transparent black background */
        z-index: 999;
    }

    .notsaveoverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 122.5%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .SaveMust {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: navy;
        z-index: 1000;
        width: 20em;
        height: 8.5em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    }

    .saveYes {
        position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: navy;
    z-index: 1000;
    width: 20em;
    height: 8.5em;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    }

    .logoutoverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .overlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 122.5%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;

    }

    .grayUpdateOverlayStyle {

        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;

    }

    .bioheadergrid {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        /* gap: 20px; */
        margin-left: 1.5em;
    }

    .bioheaderLeft-item,
    .bioheaderRight-item {
        /* border: 1px solid #ddd; */
        padding: 1%;
        margin-left: 19%;
        margin-right: 19%;
        /* text-align: center; */

        /* border-radius: 8px; */
    }

    .historyBoldTextStyle {
        font-weight: bold;
    }

}

/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 3) {

    .bioHeader {
        height: 3.8em;
        padding: 9px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-bottom: 3px;
        margin-top: 2px;
    }

    .ibutton {
        display: flex;
        justify-content: flex-end;
    }

    .instructionButton {
        font-weight: 800;
        height: 20px;
        width: 20px;
        color: #efefef;
        margin-top: -1.5em;
        margin-left: 13em;
        cursor: pointer;
        background-color: #000080;
        border: none;
        border-radius: 1em;
        /* background-image: url('aa.png'); */
    }

    .popup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 17em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .ECGIntervalPopup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 15em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .ECGIntervalPopup1 {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 17em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }
    .popup-content {
        text-align: justify;
    }

    .popupheader {
        font-size: 1200;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 4em;
        background-color: navy;
        color: white;
        /* Text color for the title */
        text-align: center;
        /* Center the text horizontally */
        line-height: 4em;
        /* Vertically center the text within the pseudo-element */
        font-weight: bold;
        /* Make the title text bold */
    }

    .homePageContainer {
        width: 500px;
        height: 260vh;
        margin-bottom: 5.5em;
        background-color: #e5e5e6;
        border-radius: 7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8.6em;
        bottom: 1em;
        overflow-y: auto;
        /* Enable vertical scrolling */
        /* max-height: 100vh; */
        /* Set maximum height to fill the available space */
        min-height: 110vh;


    }

    .logoutModal {
        display: block;
        /* or 'none' based on your logic */
        position: absolute;
        top: 30%;
        left: 65%;
        transform: translate(-50%, -50%);
        background-color: navy;
        z-index: 1000;
        width: 20em;
        height: 8.5em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    }

    .grid-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        /* Two columns */
        grid-template-rows: repeat(3, 1fr);
        /* Three rows */
        gap: 0px;
        width: inherit;
        padding: 2em;
        /* Optional gap between grid items */
        margin-top: -2em;
    }

    /* Style for the grid items (you can customize this as needed) */
    .grid-item {
        /* border: 1px solid #ccc;
    padding: 20px; */
        text-align: center;
    }

    .homePage-image-container,
    .homePage-header-container,
    .homePage-header-container1,
    .homePage-details-container,
    .homePage-range-container,
    .homePage-range-container1,
    .homePage-range-container2,
    .homePage-title-contaniner {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .image1 {
        max-width: 33%;
        /* height: 10%; */
        margin-right: 50%;
        margin-left: 50%;
        margin-top: 10px;
    }

    .bloodPressureImage {
        border: #000;
        border-radius: 10px;
        max-width: 25%;
        height: 8%;
        margin-right: 50%;
        margin-left: 50%;
        margin-top: 17px;
    }

    .image {
        max-width: 29%;
        height: 10%;
        margin-right: 50%;
        margin-left: 50%;
        /* margin-top: 30px; */
    }


    .homePageHeader-title {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }

    .homePageHeader-titleoxy {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 24%;
        margin-top: -7px;
    }

    .homePageHeader-titleBT {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 24%;
        margin-top: 7px;
    }

    .homePageHeader-title1 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }


    .bioheaderLeft {
        flex: auto;
        margin-left: 4.7em;
    }

    .bioheaderRight {
        flex: 1;
    }

    .homePage-details-container .value {
        background-color: #ff9c00;
        color: white;
        font-size: 15px;
        text-align: center;
        padding: 5px;
        border-radius: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-top: 5px;
        width: 60%;
    }


    .homePage-title-contaniner .tilte {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
    }


    .homePage-title-contaniner .tilteT {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
    }


    .homePage-range-container .range {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 15%;
        margin-right: 15%;
        font-weight: bold;
    }


    .homePage-range-container1 .range1 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        font-weight: bold;
    }


    .homePage-range-container2 .range2 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 20px;
        font-weight: bold;


    }


    .homePage-range-container1 .range3 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 77px;
        margin-bottom: 25px;
        font-weight: bold;
    }


    .homePage-range-container1 .range4 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 55px;
        margin-bottom: 25px;
        font-weight: bold;


    }


    .homePage-range-container1 .range5 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 25px;
        font-weight: bold;
    }


    .homePage-range-container1 .range6 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 25px;
        font-weight: bold;
    }

    .homePageFooter {
        display: flex;
        justify-content: center;
        margin-top: -1.5em;
        margin-left: 1.5em;
    }

    .homePageElctrocardiogram-button {
        height: 40px;
        font-size: 19px;
        background-color: navy;
        color: white;
        position: relative;
        margin-top: 0.5em;
        margin-left: 15%;
        margin-bottom: 0.8%;
        margin-right: 7.5%;
        border-radius: 10px;


    }

    .homePageButton-text {
        /* font-weight: semi-bold; */

        color: white;
        font-size: 20px;
    }


    .homePage-ecg-button {
        height: 40px;
        font-size: 19px;
        background-color: navy;
        color: white;
        margin-top: 0.8em;
        margin-left: 15%;
        margin-right: 7%;
        border-radius: 10px;
        /* margin-bottom: 3em; */
    }


    .ECGGraphContainer {
        display: flex;
        background-color: #efefef;
        transform-origin: center;
        position: absolute;
        top: 0;
        left: 3%;
        transition: width 0.2s, height 0.2s;
        overflow: auto;
        width: 96%;
        transform: scale(var(--zoomLevel));
    }


    .homePageECGIntervelText {
        font-size: 18px;
        font-weight: bold;
        margin-top: 8%;
        margin-left: 3em;
        /* margin-right: 3.4em; */
    }

    .HomepageECGContainer{
        background-color: #efefef;
        height: 22em;
        width: 93%;
        margin: auto;
        /* margin-right: 10px; */
        margin-left: 2.5em;
        overflow: auto;
    }

    .homePageContainer-image {
        margin-top: 10px;
        padding-top: 5px;
    }


    .homePageHeader-title2 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }


    .zoomText {
        font-size: 20px;
        font-weight: bold;
        margin-top: 0.4em;
        margin-left: 3em;
        margin-right: 2.5em;
    }


    .zoomPlusButton {
        width: 100px;
        height: 40px;
        background-color: navy;
        color: white;
        border-radius: 10px;
        border: none;
        cursor: pointer;
        margin-bottom: 10px;
        margin-left: 6em;
    }


    .zoomMinusButton {
        width: 100px;
        height: 40px;
        background-color: navy;
        color: white;
        border-radius: 10px;
        border: none;
        cursor: pointer;
    }



    .homePageOverlay {
        position: fixed;
        height: 250vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .homePageTakeVitalsModal {
        position: absolute;
        height: 35em;
        top: 25%;
        /* left: 50%; */
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        width: 300px;
        /* height: 535px; */
        /* Adjust the width as needed */
    }

    .switch {
        position: absolute;
        margin-left: 2em;
        width: 46px;
        height: 25px;
    }


    .slider {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: .4s;
        border-radius: 34px;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 0px;
        bottom: 0px;
        background-color:  greenyellow;
        border: 2px solid black;;
        transition: .4s;
        border-radius: 50%;
    }

    input:checked+.slider {
        background-color: white;
        transition: .4s;
    }

    input:checked+.slider:before {
        transform: translateX(23px);
    }


    .homePageButton {
        background-color: #1976D2;
        color: white;
        border: none;
        border-radius: 5px;
        padding: 5px 15px;
        margin: 0 5px;
        cursor: pointer;


    }

    .homePagefooter {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2.6em;
        background-color: white;
        display: flex;
        justify-content: space-between;
        padding: 14px 10px;
        box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
        /* Optional: Add a shadow to the footer */
    }

    .ECGgrid-container {

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        /* Two columns */
        grid-template-rows: repeat(2, 1fr);
        /* Two rows */
        gap: 4px;
        /* Optional gap between grid items */
        margin-left: 4em;
    }

    .modalStyle2 {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 1000;
        width: 20em;
        height: 13em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }
    .modalStyle0 {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
        width: 20em;
        height: 11em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }

    .openModalStyle {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 1000;
        width: 20em;
        height: 7em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }


    .logout {
        display: fixed;
    top: 50%;
    left: 50%;
    padding: 20px;
    border: 1px solid #ccc;
    position: fixed;
    transform: translate(-50%, -50%);
    background-color: navy;
    width: 20em;
    height: 8.5em;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    z-index: 999;
    }


    .modalStyle {
        position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 998;
    width: 20em;
    height: 11em;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3em, white 3em);
    }


    .modalStyle1 {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
        width: 20em;
        height: 11em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }

    .grayOverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        /* Gray background with 50% opacity */
        z-index: 999;
    }

    .grayOverlayStyle0 {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        /* Gray background with 50% opacity */
        z-index: 999;
    }

    .saveoverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 122.5%;
        background-color: rgba(0, 0, 0, 0.2);
        /* Semi-transparent black background */
        z-index: 999;
    }

    .notsaveoverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 122.5%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .SaveMust {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: navy;
        z-index: 1000;
        width: 20em;
        height: 8.5em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    }

    .saveYes {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: navy;
        z-index: 1000;
        width: 20em;
        height: 8.5em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    }

    .logoutoverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .overlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 122.5%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;

    }

    .grayUpdateOverlayStyle {

        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;

    }

    .bioheadergrid {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        margin-left: 2em;
        /* gap: 20px; */
    }

    .bioheaderLeft-item,
    .bioheaderRight-item {
        /* border: 1px solid #ddd; */
        padding: 1%;
        margin-left: 25%;
        margin-right: 10%;
        /* text-align: center; */

        /* border-radius: 8px; */
    }

    .historyBoldTextStyle {
        font-weight: bold;
    }

}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {

    .bioHeader {
        height: 3.8em;
        padding: 9px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-bottom: 3px;
        margin-top: 2px;
    }

    .ibutton {
        display: flex;
        justify-content: flex-end;
    }

    .instructionButton {
        font-weight: 800;
        height: 20px;
        width: 20px;
        color: #efefef;
        margin-top: -1.5em;
        margin-left: 13em;
        cursor: pointer;
        background-color: #000080;
        border: none;
        border-radius: 1em;
        /* background-image: url('aa.png'); */
    }

    .popup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 16.5em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .ECGIntervalPopup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 15em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .ECGIntervalPopup1 {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 17.5em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }
    .popup-content {
        text-align: justify;
    }

    .popupheader {
        font-size: 1200;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 4em;
        background-color: navy;
        color: white;
        /* Text color for the title */
        text-align: center;
        /* Center the text horizontally */
        line-height: 4em;
        /* Vertically center the text within the pseudo-element */
        font-weight: bold;
        /* Make the title text bold */
    }

    .homePageContainer {
        width: 500px;
        height: 250vh;
        margin-bottom: 5.5em;
        background-color: #e5e5e6;
        border-radius: 7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8.6em;
        bottom: 5em;
        overflow-y: auto;
        /* Enable vertical scrolling */
        /* max-height: 100vh; */
        /* Set maximum height to fill the available space */
        min-height: 120vh;


    }

    .logoutModal {
        display: block;
        /* or 'none' based on your logic */
        position: fixed;
        top: 30%;
        left: 55%;
        transform: translate(-50%, -50%);
        background-color: navy;
        z-index: 1000;
        width: 20em;
        height: 8.5em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    }

    .grid-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        /* Two columns */
        grid-template-rows: repeat(3, 1fr);
        /* Three rows */
        gap: 0px;
        width: inherit;
        padding: 1.5em;
        /* Optional gap between grid items */
        margin-top: -2em;
    }

    /* Style for the grid items (you can customize this as needed) */
    .grid-item {
        /* border: 1px solid #ccc;
    padding: 20px; */
        text-align: center;
    }

    .homePage-image-container,
    .homePage-header-container,
    .homePage-header-container1,
    .homePage-details-container,
    .homePage-range-container,
    .homePage-range-container1,
    .homePage-range-container2,
    .homePage-title-contaniner {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .image1 {
        max-width: 33%;
        /* height: 10%; */
        margin-right: 50%;
        margin-left: 50%;
        margin-top: 10px;
    }

    .bloodPressureImage {
        border: #000;
        border-radius: 10px;
        max-width: 25%;
        height: 8%;
        margin-right: 50%;
        margin-left: 50%;
        margin-top: 17px;
    }

    .image {
        max-width: 29%;
        height: 10%;
        margin-right: 50%;
        margin-left: 50%;
        /* margin-top: 30px; */
    }


    .homePageHeader-title {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }

    .homePageHeader-titleoxy {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 24%;
        margin-top: -7px;
    }

    .homePageHeader-titleBT {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 24%;
        margin-top: 7px;
    }

    .homePageHeader-title1 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }


    .bioheaderLeft {
        flex: auto;
        margin-left: 3.7em;
    }

    .bioheaderRight {
        flex: 1;
    }

    .homePage-details-container .value {
        background-color: #ff9c00;
        color: white;
        font-size: 15px;
        text-align: center;
        padding: 5px;
        border-radius: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-top: 5px;
        width: 60%;
    }


    .homePage-title-contaniner .tilte {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
    }



    .homePage-title-contaniner .tilteT {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
    }



    .homePage-range-container .range {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 15%;
        margin-right: 15%;
        font-weight: bold;
    }


    .homePage-range-container1 .range1 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        font-weight: bold;
    }


    .homePage-range-container2 .range2 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 20px;
        font-weight: bold;


    }


    .homePage-range-container1 .range3 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 77px;
        margin-bottom: 25px;
        font-weight: bold;
    }


    .homePage-range-container1 .range4 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 55px;
        margin-bottom: 25px;
        font-weight: bold;


    }


    .homePage-range-container1 .range5 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 25px;
        font-weight: bold;
    }


    .homePage-range-container1 .range6 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 25px;
        font-weight: bold;
    }

    .homePageFooter {
        display: flex;
        justify-content: center;
        margin-top: -1.5em;
    }

    .homePageElctrocardiogram-button {
        height: 40px;
        font-size: 19px;
        background-color: navy;
        color: white;
        position: relative;
        margin-top: 0.5em;
        margin-left: 20%;
        margin-bottom: 0.8%;
        margin-right: 10%;
        border-radius: 10px;


    }

    .homePageButton-text {
        /* font-weight: semi-bold; */

        color: white;
        font-size: 20px;
    }


    .homePage-ecg-button {
        height: 40px;
        font-size: 19px;
        background-color: navy;
        color: white;
        margin-top: 0.8em;
        margin-left: 20%;
        margin-right: 10%;
        border-radius: 10px;
        /* margin-bottom: 3em; */
    }


    .ECGGraphContainer {
        display: flex;
        background-color: #efefef;
        transform-origin: center;
        position: absolute;
        top: 0;
        left: 5%;
        transition: width 0.2s, height 0.2s;
        overflow: auto;
        width: 96%;
        transform: scale(var(--zoomLevel));
        margin-right: 1.5em;
    }


    .homePageECGIntervelText {
        font-size: 18px;
        font-weight: bold;
        margin-top: 8%;
        margin-left: 3em;
        /* margin-right: 3.4em; */
    }

    .HomepageECGContainer{
        background-color: #efefef;
        height: 22em;
        width: 95%;
        margin: auto;
        /* margin-right: 10px; */
        margin-left: 3em;
        overflow: auto;
        overflow-x: hidden;
    }

    .homePageContainer-image {
        margin-top: 10px;
        padding-top: 5px;
    }


    .homePageHeader-title2 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }


    .zoomText {
        font-size: 20px;
        font-weight: bold;
        margin-top: 0.4em;
        margin-left: 3em;
        margin-right: 2.5em;
    }


    .zoomPlusButton {
        width: 100px;
        height: 40px;
        background-color: navy;
        color: white;
        border-radius: 10px;
        border: none;
        cursor: pointer;
        margin-bottom: 10px;
        margin-left: 6em;
    }


    .zoomMinusButton {
        width: 100px;
        height: 40px;
        background-color: navy;
        color: white;
        border-radius: 10px;
        border: none;
        cursor: pointer;
    }



    .homePageOverlay {
        position: fixed;
        height: 250vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
    }



    .homePageTakeVitalsModal {
        position: absolute;
        height: 36em;
        top: 25%;
        /* left: 50%; */
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        width: 300px;
        /* height: 535px; */
        /* Adjust the width as needed */
    }

    .switch {
        position: absolute;
        margin-left: 2em;
        width: 46px;
        height: 25px;
    }


    .slider {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: .4s;
        border-radius: 34px;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 0px;
        bottom: 0px;
        background-color:  greenyellow;
        border: 2px solid black;;
        transition: .4s;
        border-radius: 50%;
    }

    input:checked+.slider {
        background-color: white;
        transition: .4s;
    }

    input:checked+.slider:before {
        transform: translateX(23px);
    }

    .homePageButton {
        background-color: #1976D2;
        color: white;
        border: none;
        border-radius: 5px;
        padding: 5px 15px;
        margin: 0 5px;
        cursor: pointer;


    }

    .homePagefooter {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2.6em;
        background-color: white;
        display: flex;
        justify-content: space-between;
        padding: 14px 10px;
        box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
        /* Optional: Add a shadow to the footer */
    }

    .ECGgrid-container {

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        /* Two columns */
        grid-template-rows: repeat(2, 1fr);
        /* Two rows */
        gap: 4px;
        /* Optional gap between grid items */
        margin-left: 4em;
    }

    .modalStyle2 {
        position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1000;
    width: 20em;
    height: 13em;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3em, white 3em);
    }
    .modalStyle0 {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
        width: 20em;
        height: 11em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }


    .openModalStyle {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 1000;
        width: 20em;
        height: 7em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }


    .logout {
        display: fixed;
    top: 50%;
    left: 50%;
    padding: 20px;
    border: 1px solid #ccc;
    position: fixed;
    transform: translate(-50%, -50%);
    background-color: navy;
    width: 20em;
    height: 8.5em;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    z-index: 999;
    }


    .modalStyle {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 998;
        width: 20em;
        height: 11em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }


    .modalStyle1 {
        position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 999;
    width: 20em;
    height: 11em;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3em, white 3em);
    }

    .grayOverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        /* Gray background with 50% opacity */
        z-index: 999;
    }
    .grayOverlayStyle0 {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        /* Gray background with 50% opacity */
        z-index: 999;
    }


    .saveoverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 122.5%;
        background-color: rgba(0, 0, 0, 0.2);
        /* Semi-transparent black background */
        z-index: 999;
    }

    .notsaveoverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 122.5%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .SaveMust {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: navy;
        z-index: 1000;
        width: 20em;
        height: 8.5em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    }

    .saveYes {
        position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: navy;
    z-index: 1000;
    width: 20em;
    height: 8.5em;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    }

    .logoutoverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .overlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 122.5%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;

    }

    .grayUpdateOverlayStyle {

        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;

    }

    .bioheadergrid {
        /* width:100%; */
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        margin-left: 2em;
        /* gap: 20px; */
    }

    .bioheaderLeft-item {
        /* border: 1px solid #ddd; */
        padding: 1%;
        margin-left: 25%;
        margin-right: 10%;
        /* text-align: center; */

        /* border-radius: 8px; */
    }

    .bioheaderRight-item {
        /* border: 1px solid #ddd; */
        padding: 2%;
        margin-left: 25%;
        margin-right: 10%;
        /* text-align: center; */

        /* border-radius: 8px; */
    }

    .historyBoldTextStyle {
        font-weight: bold;
    }

}


/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {

    .bioHeader {
        height: 3.8em;
        padding: 9px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-bottom: 3px;
        margin-top: 2px;
    }

    .ibutton {
        display: flex;
        justify-content: flex-end;
    }

    .instructionButton {
        font-weight: 800;
        height: 20px;
        width: 20px;
        color: #efefef;
        margin-top: -1.5em;
        margin-left: 13em;
        cursor: pointer;
        background-color: #000080;
        border: none;
        border-radius: 1em;
        /* background-image: url('aa.png'); */
    }

     .popup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 17em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .ECGIntervalPopup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 15em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .ECGIntervalPopup1 {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 17em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }
    .popup-content {
        text-align: justify;
    }

    .popupheader {
        font-size: 1200;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 4em;
        background-color: navy;
        color: white;
        /* Text color for the title */
        text-align: center;
        /* Center the text horizontally */
        line-height: 4em;
        /* Vertically center the text within the pseudo-element */
        font-weight: bold;
        /* Make the title text bold */
    }

    .homePageContainer {
        width: 500px;
        height: 250vh;
        margin-bottom: 5.5em;
        background-color: #e5e5e6;
        border-radius: 7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8.6em;
        bottom: 1em;
        overflow-y: auto;
        /* Enable vertical scrolling */
        /* max-height: 100vh; */
        /* Set maximum height to fill the available space */
        min-height: 115vh;


    }

    .logoutModal {
        display: block;
        /* or 'none' based on your logic */
        position: fixed;
        top: 30%;
        left: 55%;
        transform: translate(-50%, -50%);
        background-color: navy;
        z-index: 1000;
        width: 20em;
        height: 8.5em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    }

    .grid-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        /* Two columns */
        grid-template-rows: repeat(3, 1fr);
        /* Three rows */
        gap: 0px;
        width: inherit;
        padding: 1.5em;
        /* Optional gap between grid items */
        margin-top: -2em;
    }

    /* Style for the grid items (you can customize this as needed) */
    .grid-item {
        /* border: 1px solid #ccc;
        padding: 20px; */
        text-align: center;
    }

    .homePage-image-container,
    .homePage-header-container,
    .homePage-header-container1,
    .homePage-details-container,
    .homePage-range-container,
    .homePage-range-container1,
    .homePage-range-container2,
    .homePage-title-contaniner {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .image1 {
        max-width: 33%;
        /* height: 10%; */
        margin-right: 50%;
        margin-left: 50%;
        margin-top: 10px;
    }

    .bloodPressureImage {
        border: #000;
        border-radius: 10px;
        max-width: 25%;
        height: 8%;
        margin-right: 50%;
        margin-left: 50%;
        margin-top: 17px;
    }

    .image {
        max-width: 29%;
        height: 10%;
        margin-right: 50%;
        margin-left: 50%;
        /* margin-top: 30px; */
    }


    .homePageHeader-title {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }

    .homePageHeader-titleoxy {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 24%;
        margin-top: -7px;
    }

    .homePageHeader-titleBT {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 24%;
        margin-top: 7px;
    }

    .homePageHeader-title1 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }


    .bioheaderLeft {
        flex: auto;
        margin-left: 3.7em;
    }

    .bioheaderRight {
        flex: 1;
    }

    .homePage-details-container .value {
        background-color: #ff9c00;
        color: white;
        font-size: 15px;
        text-align: center;
        padding: 5px;
        border-radius: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-top: 5px;
        width: 60%;
    }


    .homePage-title-contaniner .tilte {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
    }


    .homePage-title-contaniner .tilteT {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
    }



    .homePage-range-container .range {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 15%;
        margin-right: 15%;
        font-weight: bold;
    }


    .homePage-range-container1 .range1 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        font-weight: bold;
    }


    .homePage-range-container2 .range2 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 20px;
        font-weight: bold;


    }


    .homePage-range-container1 .range3 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 77px;
        margin-bottom: 25px;
        font-weight: bold;
    }


    .homePage-range-container1 .range4 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 55px;
        margin-bottom: 25px;
        font-weight: bold;


    }


    .homePage-range-container1 .range5 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 25px;
        font-weight: bold;
    }


    .homePage-range-container1 .range6 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 25px;
        font-weight: bold;
    }

    .homePageFooter {
        display: flex;
        justify-content: center;
        margin-top: -1.5em;
    }

    .homePageElctrocardiogram-button {
        height: 40px;
        font-size: 19px;
        background-color: navy;
        color: white;
        position: relative;
        margin-top: 0.5em;
        margin-left: 15%;
        margin-bottom: 0.8%;
        margin-right: 7.5%;
        border-radius: 10px;


    }

    .homePageButton-text {
        /* font-weight: semi-bold; */

        color: white;
        font-size: 20px;
    }


    .homePage-ecg-button {
        height: 40px;
        font-size: 19px;
        background-color: navy;
        color: white;
        margin-top: 0.8em;
        margin-left: 15%;
        margin-right: 7%;
        border-radius: 10px;
        /* margin-bottom: 3em; */
    }


    .ECGGraphContainer {
        display: flex;
        background-color: #efefef;
        transform-origin: center;
        position: absolute;
        top: 0;
        left: 0;
        transition: width 0.2s, height 0.2s;
        overflow: auto;
        width: 96%;
        transform: scale(var(--zoomLevel));
    }


    .homePageECGIntervelText {
        font-size: 18px;
        font-weight: bold;
        margin-top: 8%;
        margin-left: 3em;
        /* margin-right: 3.4em; */
    }
    .HomepageECGContainer{
        background-color: #efefef;
        height: 22em;
        width: 93%;
        margin: auto;
        /* margin-right: 10px; */
        margin-left: 3em;
        overflow: auto;
    }


    .homePageContainer-image {
        margin-top: 10px;
        padding-top: 5px;
    }


    .homePageHeader-title2 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }


    .zoomText {
        font-size: 20px;
        font-weight: bold;
        margin-top: 0.4em;
        margin-left: 3em;
        margin-right: 2.5em;
    }


    .zoomPlusButton {
        width: 100px;
        height: 40px;
        background-color: navy;
        color: white;
        border-radius: 10px;
        border: none;
        cursor: pointer;
        margin-bottom: 10px;
        margin-left: 6em;
    }


    .zoomMinusButton {
        width: 100px;
        height: 40px;
        background-color: navy;
        color: white;
        border-radius: 10px;
        border: none;
        cursor: pointer;
    }



    .homePageOverlay {
        position: fixed;
        height: 250vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .homePageTakeVitalsModal {
        position: absolute;
        height: 36em;
        top: 25%;
        /* left: 50%; */
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        width: 300px;
        /* height: 535px; */
        /* Adjust the width as needed */
    }

    .switch {
        position: absolute;
        margin-left: 2em;
        width: 46px;
        height: 25px;
    }


    .slider {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: .4s;
        border-radius: 34px;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 0px;
        bottom: 0px;
        background-color:  greenyellow;
        border: 2px solid black;;
        transition: .4s;
        border-radius: 50%;
    }

    input:checked+.slider {
        background-color: white;
        transition: .4s;
    }

    input:checked+.slider:before {
        transform: translateX(23px);
    }

    .homePageButton {
        background-color: #1976D2;
        color: white;
        border: none;
        border-radius: 5px;
        padding: 5px 15px;
        margin: 0 5px;
        cursor: pointer;


    }

    .homePagefooter {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2.6em;
        background-color: white;
        display: flex;
        justify-content: space-between;
        padding: 14px 10px;
        box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
        /* Optional: Add a shadow to the footer */
    }

    .ECGgrid-container {

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        /* Two columns */
        grid-template-rows: repeat(2, 1fr);
        /* Two rows */
        gap: 4px;
        /* Optional gap between grid items */
        margin-left: 4em;
    }

    .modalStyle2 {
        position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1000;
    width: 20em;
    height: 13em;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3em, white 3em);
    }

    .modalStyle0 {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
        width: 20em;
        height: 11em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }

    .openModalStyle {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 1000;
        width: 20em;
        height: 7em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }


    .logout {
        display: fixed;
    top: 50%;
    left: 50%;
    padding: 20px;
    border: 1px solid #ccc;
    position: fixed;
    transform: translate(-50%, -50%);
    background-color: navy;
    width: 20em;
    height: 8.5em;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    z-index: 999;
    }


    .modalStyle {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 998;
        width: 20em;
        height: 11em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }


    .modalStyle1 {
        position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 999;
    width: 20em;
    height: 11em;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3em, white 3em);
    }

    .grayOverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        /* Gray background with 50% opacity */
        z-index: 999;
    }

    .grayOverlayStyle0 {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        /* Gray background with 50% opacity */
        z-index: 999;
    }

    .saveoverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 122.5%;
        background-color: rgba(0, 0, 0, 0.2);
        /* Semi-transparent black background */
        z-index: 999;
    }

    .notsaveoverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 122.5%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .SaveMust {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: navy;
        z-index: 1000;
        width: 20em;
        height: 8.5em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    }

    .saveYes {
        position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: navy;
    z-index: 1000;
    width: 20em;
    height: 8.5em;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    }

    .logoutoverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .overlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 122.5%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;

    }

    .grayUpdateOverlayStyle {

        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;

    }

    .bioheadergrid {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        /* gap: 20px; */
        margin-left: 1.5em;
    }

    .bioheaderLeft-item,
    .bioheaderRight-item {
        /* border: 1px solid #ddd; */
        padding: 1%;
        margin-left: 19%;
        margin-right: 19%;
        /* text-align: center; */

        /* border-radius: 8px; */
    }

    .historyBoldTextStyle {
        font-weight: bold;
    }

}


/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {

    .bioHeader {
        height: 3.8em;
        padding: 9px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-bottom: 3px;
        margin-top: 2px;
    }

    .ibutton {
        display: flex;
        justify-content: flex-end;
    }

    .instructionButton {
        font-weight: 800;
        height: 20px;
        width: 20px;
        color: #efefef;
        margin-top: -1.5em;
        margin-left: 13em;
        cursor: pointer;
        background-color: #000080;
        border: none;
        border-radius: 1em;
        /* background-image: url('aa.png'); */
    }

     .popup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 17em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .ECGIntervalPopup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 15em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .ECGIntervalPopup1 {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 17em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }
    .popup-content {
        text-align: justify;
    }

    .popupheader {
        font-size: 1200;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 4em;
        background-color: navy;
        color: white;
        /* Text color for the title */
        text-align: center;
        /* Center the text horizontally */
        line-height: 4em;
        /* Vertically center the text within the pseudo-element */
        font-weight: bold;
        /* Make the title text bold */
    }

    .homePageContainer {
        width: 500px;
        height: 250vh;
        margin-bottom: 5.5em;
        background-color: #e5e5e6;
        border-radius: 7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8.6em;
        bottom: 1em;
        overflow-y: auto;
        /* Enable vertical scrolling */
        /* max-height: 100vh; */
        /* Set maximum height to fill the available space */
        min-height: 115vh;


    }

    .logoutModal {
        display: block;
        /* or 'none' based on your logic */
        position: fixed;
        top: 30%;
        left: 55%;
        transform: translate(-50%, -50%);
        background-color: navy;
        z-index: 1000;
        width: 20em;
        height: 8.5em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    }

    .grid-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        /* Two columns */
        grid-template-rows: repeat(3, 1fr);
        /* Three rows */
        gap: 0px;
        width: inherit;
        padding: 0.5em;
        /* Optional gap between grid items */
        margin-top: -1em;
    }

    /* Style for the grid items (you can customize this as needed) */
    .grid-item {
        /* border: 1px solid #ccc;
        padding: 20px; */
        text-align: center;
    }

    .homePage-image-container,
    .homePage-header-container,
    .homePage-header-container1,
    .homePage-details-container,
    .homePage-range-container,
    .homePage-range-container1,
    .homePage-range-container2,
    .homePage-title-contaniner {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .image1 {
        max-width: 33%;
        /* height: 10%; */
        margin-right: 50%;
        margin-left: 50%;
        margin-top: 10px;
    }

    .bloodPressureImage {
        border: #000;
        border-radius: 10px;
        max-width: 25%;
        height: 8%;
        margin-right: 50%;
        margin-left: 50%;
        margin-top: 17px;
    }

    .image {
        max-width: 29%;
        height: 10%;
        margin-right: 50%;
        margin-left: 50%;
        /* margin-top: 30px; */
    }


    .homePageHeader-title {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }

    .homePageHeader-titleoxy {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 24%;
        margin-top: -7px;
    }

    .homePageHeader-titleBT {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 24%;
        margin-top: 7px;
    }

    .homePageHeader-title1 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }


    .bioheaderLeft {
        flex: auto;
        margin-left: 3.7em;
    }

    .bioheaderRight {
        flex: 1;
    }

    .homePage-details-container .value {
        background-color: #ff9c00;
        color: white;
        font-size: 15px;
        text-align: center;
        padding: 5px;
        border-radius: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-top: 5px;
        width: 60%;
    }


    .homePage-title-contaniner .tilte {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
    }


    .homePage-title-contaniner .tilteT {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
    }



    .homePage-range-container .range {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 15%;
        margin-right: 15%;
        font-weight: bold;
    }


    .homePage-range-container1 .range1 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        font-weight: bold;
    }


    .homePage-range-container2 .range2 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 20px;
        font-weight: bold;


    }


    .homePage-range-container1 .range3 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 77px;
        margin-bottom: 25px;
        font-weight: bold;
    }


    .homePage-range-container1 .range4 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 55px;
        margin-bottom: 25px;
        font-weight: bold;


    }


    .homePage-range-container1 .range5 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 25px;
        font-weight: bold;
    }


    .homePage-range-container1 .range6 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 25px;
        font-weight: bold;
    }

    .homePageFooter {
        display: flex;
        justify-content: center;
    margin-top: -1.5em;
    margin-right: 1em;
    margin-left: -1em;
    }

    .homePageElctrocardiogram-button {
        height: 40px;
        font-size: 19px;
        background-color: navy;
        color: white;
        position: relative;
        margin-top: 0.5em;
        margin-left: 15%;
        margin-bottom: 0.8%;
        margin-right: 7.5%;
        border-radius: 10px;


    }

    .homePageButton-text {
        /* font-weight: semi-bold; */

        color: white;
        font-size: 20px;
    }


    .homePage-ecg-button {
        height: 40px;
        font-size: 19px;
        background-color: navy;
        color: white;
        margin-top: 0.8em;
        margin-left: 15%;
        margin-right: 7%;
        border-radius: 10px;
        /* margin-bottom: 3em; */
    }


    .ECGGraphContainer {
        display: flex;
        background-color: #efefef;
        transform-origin: center;
        position: absolute;
        top: 0;
        left: 0;
        transition: width 0.2s, height 0.2s;
        overflow: auto;
        width: 96%;
        transform: scale(var(--zoomLevel));
    }


    .homePageECGIntervelText {
        font-size: 18px;
        font-weight: bold;
        margin-top: 8%;
        margin-left: 3em;
        /* margin-right: 3.4em; */
    }
    .HomepageECGContainer{
        background-color: #efefef;
        height: 22em;
        width: 100%;
        margin: auto;
        /* margin-right: 10px; */
        margin-left: 10px;
        overflow: auto;
    }


    .homePageContainer-image {
        margin-top: 10px;
        padding-top: 5px;
    }


    .homePageHeader-title2 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }


    .zoomText {
        font-size: 20px;
        font-weight: bold;
        margin-top: 0.4em;
        margin-left: 3em;
        margin-right: 2.5em;
    }


    .zoomPlusButton {
        width: 100px;
        height: 40px;
        background-color: navy;
        color: white;
        border-radius: 10px;
        border: none;
        cursor: pointer;
        margin-bottom: 10px;
        margin-left: 6em;
    }


    .zoomMinusButton {
        width: 100px;
        height: 40px;
        background-color: navy;
        color: white;
        border-radius: 10px;
        border: none;
        cursor: pointer;
    }



    .homePageOverlay {
        position: fixed;
        height: 250vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .homePageTakeVitalsModal {
        position: absolute;
        height: 36em;
        top: 25%;
        /* left: 50%; */
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        width: 300px;
        /* height: 535px; */
        /* Adjust the width as needed */
    }

    .switch {
        position: absolute;
        margin-left: 2em;
        width: 46px;
        height: 25px;
    }


    .slider {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: .4s;
        border-radius: 34px;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 0px;
        bottom: 0px;
        background-color:  greenyellow;
        border: 2px solid black;;
        transition: .4s;
        border-radius: 50%;
    }

    input:checked+.slider {
        background-color: white;
        transition: .4s;
    }

    input:checked+.slider:before {
        transform: translateX(23px);
    }

    .homePageButton {
        background-color: #1976D2;
        color: white;
        border: none;
        border-radius: 5px;
        padding: 5px 15px;
        margin: 0 5px;
        cursor: pointer;


    }

    .homePagefooter {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2.6em;
        background-color: white;
        display: flex;
        justify-content: space-between;
        padding: 14px 10px;
        box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
        /* Optional: Add a shadow to the footer */
    }

    .ECGgrid-container {

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        /* Two columns */
        grid-template-rows: repeat(2, 1fr);
        /* Two rows */
        gap: 4px;
        /* Optional gap between grid items */
        margin-left: 4em;
    }


    .modalStyle2 {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 1000;
        width: 20em;
        height: 13em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }
    .modalStyle0 {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
        width: 20em;
        height: 11em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }


    .openModalStyle {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 1000;
        width: 20em;
        height: 7em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }


    .logout {
        display: fixed;
    top: 50%;
    left: 50%;
    padding: 20px;
    border: 1px solid #ccc;
    position: fixed;
    transform: translate(-50%, -50%);
    background-color: navy;
    width: 20em;
    height: 8.5em;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    z-index: 999;
    }


    .modalStyle {
        position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 998;
    width: 20em;
    height: 11em;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3em, white 3em);
    }


    .modalStyle1 {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
        width: 20em;
        height: 11em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }

    .grayOverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        /* Gray background with 50% opacity */
        z-index: 999;
    }

    .grayOverlayStyle0 {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        /* Gray background with 50% opacity */
        z-index: 999;
    }

    .saveoverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 122.5%;
        background-color: rgba(0, 0, 0, 0.2);
        /* Semi-transparent black background */
        z-index: 999;
    }

    .notsaveoverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 122.5%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .SaveMust {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: navy;
        z-index: 1000;
        width: 20em;
        height: 8.5em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    }

    .saveYes {
        position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: navy;
    z-index: 1000;
    width: 20em;
    height: 8.5em;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    }

    .logoutoverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .overlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 122.5%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;

    }

    .grayUpdateOverlayStyle {

        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;

    }

    .bioheadergrid {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        /* gap: 20px; */
        margin-left: 1em;
    }

    .bioheaderLeft-item,
    .bioheaderRight-item {
        /* border: 1px solid #ddd; */
        padding: 1%;
        margin-left: 19%;
        margin-right: 19%;
        /* text-align: center; */

        /* border-radius: 8px; */
    }

    .historyBoldTextStyle {
        font-weight: bold;
    }

}

/* ----------- Galaxy S3 ----------- */

/* Portrait and Landscape */
@media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 2) {

    .bioHeader {
        height: 3.8em;
        padding: 9px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-bottom: 3px;
        margin-top: 2px;
    }


    .ibutton {
        display: flex;
        justify-content: flex-end;
    }

    .instructionButton {
        font-weight: 800;
        height: 20px;
        width: 20px;
        color: #efefef;
        margin-top: -1.5em;
        margin-left: 13em;
        cursor: pointer;
        background-color: #000080;
        border: none;
        border-radius: 1em;
        /* background-image: url('aa.png'); */
    }

     .popup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 17em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .ECGIntervalPopup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 15em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .ECGIntervalPopup1 {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 17em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }
    .popup-content {
        text-align: justify;
    }

    .popupheader {
        font-size: 1200;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 4em;
        background-color: navy;
        color: white;
        /* Text color for the title */
        text-align: center;
        /* Center the text horizontally */
        line-height: 4em;
        /* Vertically center the text within the pseudo-element */
        font-weight: bold;
        /* Make the title text bold */
    }


    .homePageContainer {
        width: 500px;
        height: 250vh;
        margin-bottom: 5.5em;
        background-color: #e5e5e6;
        border-radius: 7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8.6em;
        bottom: 1em;
        overflow-y: auto;
        /* Enable vertical scrolling */
        /* max-height: 100vh; */
        /* Set maximum height to fill the available space */
        min-height: 115vh;


    }

    .logoutModal {
        display: block;
        /* or 'none' based on your logic */
        position: fixed;
        top: 30%;
        left: 55%;
        transform: translate(-50%, -50%);
        background-color: navy;
        z-index: 1000;
        width: 20em;
        height: 8.5em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    }

    .grid-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        /* Two columns */
        grid-template-rows: repeat(3, 1fr);
        /* Three rows */
        gap: 0px;
        width: inherit;
        padding: 1.5em;
        /* Optional gap between grid items */
        margin-top: -2em;
    }

    /* Style for the grid items (you can customize this as needed) */
    .grid-item {
        /* border: 1px solid #ccc;
        padding: 20px; */
        text-align: center;
    }

    .homePage-image-container,
    .homePage-header-container,
    .homePage-header-container1,
    .homePage-details-container,
    .homePage-range-container,
    .homePage-range-container1,
    .homePage-range-container2,
    .homePage-title-contaniner {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .image1 {
        max-width: 33%;
        /* height: 10%; */
        margin-right: 50%;
        margin-left: 50%;
        margin-top: 10px;
    }

    .bloodPressureImage {
        border: #000;
        border-radius: 10px;
        max-width: 25%;
        height: 8%;
        margin-right: 50%;
        margin-left: 50%;
        margin-top: 17px;
    }

    .image {
        max-width: 29%;
        height: 10%;
        margin-right: 50%;
        margin-left: 50%;
        /* margin-top: 30px; */
    }


    .homePageHeader-title {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }

    .homePageHeader-titleoxy {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 24%;
        margin-top: -7px;
    }

    .homePageHeader-titleBT {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 24%;
        margin-top: 7px;
    }

    .homePageHeader-title1 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }

    .bioheaderLeft {
        flex: auto;
        margin-left: 3.7em;
    }

    .bioheaderRight {
        flex: 1;
    }


    .homePage-details-container .value {
        background-color: #ff9c00;
        color: white;
        font-size: 15px;
        text-align: center;
        padding: 5px;
        border-radius: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-top: 5px;
        width: 60%;
    }


    .homePage-title-contaniner .tilte {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
    }



    .homePage-title-contaniner .tilteT {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
    }



    .homePage-range-container .range {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 15%;
        margin-right: 15%;
        font-weight: bold;
    }


    .homePage-range-container1 .range1 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        font-weight: bold;
    }


    .homePage-range-container2 .range2 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 20px;
        font-weight: bold;


    }


    .homePage-range-container1 .range3 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 77px;
        margin-bottom: 25px;
        font-weight: bold;
    }


    .homePage-range-container1 .range4 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 55px;
        margin-bottom: 25px;
        font-weight: bold;


    }


    .homePage-range-container1 .range5 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 25px;
        font-weight: bold;
    }


    .homePage-range-container1 .range6 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 25px;
        font-weight: bold;
    }

    .homePageFooter {
        display: flex;
        justify-content: center;
        margin-top: -1.5em;
    }

    .homePageElctrocardiogram-button {
        height: 40px;
        font-size: 19px;
        background-color: navy;
        color: white;
        position: relative;
        margin-top: 0.5em;
        margin-left: 15%;
        margin-bottom: 0.8%;
        margin-right: 7.5%;
        border-radius: 10px;


    }

    .homePageButton-text {
        /* font-weight: semi-bold; */

        color: white;
        font-size: 20px;
    }


    .homePage-ecg-button {
        height: 40px;
        font-size: 19px;
        background-color: navy;
        color: white;
        margin-top: 0.8em;
        margin-left: 15%;
        margin-right: 7%;
        border-radius: 10px;
        /* margin-bottom: 3em; */
    }


    .ECGGraphContainer {
        display: flex;
        background-color: #efefef;
        transform-origin: center;
        position: absolute;
        top: 0;
        left: 0;
        transition: width 0.2s, height 0.2s;
        overflow: auto;
        width: 96%;
        transform: scale(var(--zoomLevel));
    }


    .homePageECGIntervelText {
        font-size: 18px;
        font-weight: bold;
        margin-top: 8%;
        margin-left: 3em;
        /* margin-right: 3.4em; */
    }
    .HomepageECGContainer{
        background-color: #efefef;
        height: 22em;
        width: 100%;
        margin: auto;
        /* margin-right: 10px; */
        margin-left: 10px;
        overflow: auto;
    }


    .homePageContainer-image {
        margin-top: 10px;
        padding-top: 5px;
    }


    .homePageHeader-title2 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }


    .zoomText {
        font-size: 20px;
        font-weight: bold;
        margin-top: 0.4em;
        margin-left: 3em;
        margin-right: 2.5em;
    }


    .zoomPlusButton {
        width: 100px;
        height: 40px;
        background-color: navy;
        color: white;
        border-radius: 10px;
        border: none;
        cursor: pointer;
        margin-bottom: 10px;
        margin-left: 6em;
    }


    .zoomMinusButton {
        width: 100px;
        height: 40px;
        background-color: navy;
        color: white;
        border-radius: 10px;
        border: none;
        cursor: pointer;
    }



    .homePageOverlay {
        position: fixed;
        height: 250vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .homePageTakeVitalsModal {
        position: absolute;
        height: 36em;
        top: 25%;
        /* left: 50%; */
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        width: 300px;
        /* height: 535px; */
        /* Adjust the width as needed */
    }

    .switch {
        position: absolute;
        margin-left: 2em;
        width: 46px;
        height: 25px;
    }


    .slider {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: .4s;
        border-radius: 34px;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 0px;
        bottom: 0px;
        background-color:  greenyellow;
        border: 2px solid black;;
        transition: .4s;
        border-radius: 50%;
    }

    input:checked+.slider {
        background-color: white;
        transition: .4s;
    }

    input:checked+.slider:before {
        transform: translateX(23px);
    }


    .homePageButton {
        background-color: #1976D2;
        color: white;
        border: none;
        border-radius: 5px;
        padding: 5px 15px;
        margin: 0 5px;
        cursor: pointer;


    }

    .homePagefooter {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2.6em;
        background-color: white;
        display: flex;
        justify-content: space-between;
        padding: 14px 10px;
        box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
        /* Optional: Add a shadow to the footer */
    }

    .ECGgrid-container {

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        /* Two columns */
        grid-template-rows: repeat(2, 1fr);
        /* Two rows */
        gap: 4px;
        /* Optional gap between grid items */
        margin-left: 4em;
    }

    .modalStyle2 {
        position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1000;
    width: 20em;
    height: 13em;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3em, white 3em);
    }

    .modalStyle0 {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
        width: 20em;
        height: 11em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }

    .openModalStyle {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 1000;
        width: 20em;
        height: 7em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }


    .logout {
        display: fixed;
    top: 50%;
    left: 50%;
    padding: 20px;
    border: 1px solid #ccc;
    position: fixed;
    transform: translate(-50%, -50%);
    background-color: navy;
    width: 20em;
    height: 8.5em;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    z-index: 999;
    }


    .modalStyle {
        position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 998;
    width: 20em;
    height: 11em;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3em, white 3em);
    }


    .modalStyle1 {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
        width: 20em;
        height: 11em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }

    .grayOverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        /* Gray background with 50% opacity */
        z-index: 999;
    }

    .grayOverlayStyle0 {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        /* Gray background with 50% opacity */
        z-index: 999;
    }


    .saveoverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 122.5%;
        background-color: rgba(0, 0, 0, 0.2);
        /* Semi-transparent black background */
        z-index: 999;
    }

    .notsaveoverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 122.5%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .SaveMust {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: navy;
        z-index: 1000;
        width: 20em;
        height: 8.5em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    }

    .saveYes {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: navy;
        z-index: 1000;
        width: 20em;
        height: 8.5em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    }

    .logoutoverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .overlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 122.5%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;

    }

    .grayUpdateOverlayStyle {

        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;

    }

    .bioheadergrid {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        /* gap: 20px; */
        margin-left: 1.5em;
    }

    .bioheaderLeft-item,
    .bioheaderRight-item {
        /* border: 1px solid #ddd; */
        padding: 1%;
        margin-left: 19%;
        margin-right: 19%;
        /* text-align: center; */

        /* border-radius: 8px; */
    }

    .historyBoldTextStyle {
        font-weight: bold;
    }
}

/* ----------- Galaxy S4, S5 and Note 3 ----------- */

/* Portrait and Landscape */
@media screen and (device-width: 320px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3) {

    .bioHeader {
        height: 3.8em;
        padding: 9px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-bottom: 3px;
        margin-top: 2px;
    }

    .ibutton {
        display: flex;
        justify-content: flex-end;
    }

    .instructionButton {
        font-weight: 800;
        height: 20px;
        width: 20px;
        color: #efefef;
        margin-top: -1.5em;
        margin-left: 13em;
        cursor: pointer;
        background-color: #000080;
        border: none;
        border-radius: 1em;
        /* background-image: url('aa.png'); */
    }

     .popup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 17em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .ECGIntervalPopup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 15em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .ECGIntervalPopup1 {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 17em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .popup-content {
        text-align: justify;
    }

    .popupheader {
        font-size: 1200;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 4em;
        background-color: navy;
        color: white;
        /* Text color for the title */
        text-align: center;
        /* Center the text horizontally */
        line-height: 4em;
        /* Vertically center the text within the pseudo-element */
        font-weight: bold;
        /* Make the title text bold */
    }

    .homePageContainer {
        width: 500px;
        height: 250vh;
        margin-bottom: 5.5em;
        background-color: #e5e5e6;
        border-radius: 7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8.6em;
        bottom: 1em;
        overflow-y: auto;
        /* Enable vertical scrolling */
        /* max-height: 100vh; */
        /* Set maximum height to fill the available space */
        min-height: 115vh;


    }

    .logoutModal {
        display: block;
        /* or 'none' based on your logic */
        position: fixed;
        top: 30%;
        left: 55%;
        transform: translate(-50%, -50%);
        background-color: navy;
        z-index: 1000;
        width: 20em;
        height: 8.5em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    }

    .grid-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        /* Two columns */
        grid-template-rows: repeat(3, 1fr);
        /* Three rows */
        gap: 0px;
        width: inherit;
        padding: 1.5em;
        /* Optional gap between grid items */
        margin-top: -2em;
    }

    /* Style for the grid items (you can customize this as needed) */
    .grid-item {
        /* border: 1px solid #ccc;
        padding: 20px; */
        text-align: center;
    }

    .homePage-image-container,
    .homePage-header-container,
    .homePage-header-container1,
    .homePage-details-container,
    .homePage-range-container,
    .homePage-range-container1,
    .homePage-range-container2,
    .homePage-title-contaniner {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .image1 {
        max-width: 33%;
        /* height: 10%; */
        margin-right: 50%;
        margin-left: 50%;
        margin-top: 10px;
    }

    .bloodPressureImage {
        border: #000;
        border-radius: 10px;
        max-width: 25%;
        height: 8%;
        margin-right: 50%;
        margin-left: 50%;
        margin-top: 17px;
    }

    .image {
        max-width: 29%;
        height: 10%;
        margin-right: 50%;
        margin-left: 50%;
        /* margin-top: 30px; */
    }


    .homePageHeader-title {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }

    .homePageHeader-titleoxy {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 24%;
        margin-top: -7px;
    }

    .homePageHeader-titleBT {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 24%;
        margin-top: 7px;
    }

    .homePageHeader-title1 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }


    .bioheaderLeft {
        flex: auto;
        margin-left: 3.7em;
    }

    .bioheaderRight {
        flex: 1;
    }

    .homePage-details-container .value {
        background-color: #ff9c00;
        color: white;
        font-size: 15px;
        text-align: center;
        padding: 5px;
        border-radius: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-top: 5px;
        width: 60%;
    }


    .homePage-title-contaniner .tilte {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
    }

    .homePage-title-contaniner .tilteT {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
    }



    .homePage-range-container .range {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 15%;
        margin-right: 15%;
        font-weight: bold;
    }


    .homePage-range-container1 .range1 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        font-weight: bold;
    }


    .homePage-range-container2 .range2 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 20px;
        font-weight: bold;


    }


    .homePage-range-container1 .range3 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 77px;
        margin-bottom: 25px;
        font-weight: bold;
    }


    .homePage-range-container1 .range4 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 55px;
        margin-bottom: 25px;
        font-weight: bold;


    }


    .homePage-range-container1 .range5 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 25px;
        font-weight: bold;
    }


    .homePage-range-container1 .range6 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 25px;
        font-weight: bold;
    }

    .homePageFooter {
        display: flex;
        justify-content: center;
        margin-top: -1.5em;
    }

    .homePageElctrocardiogram-button {
        height: 40px;
        font-size: 19px;
        background-color: navy;
        color: white;
        position: relative;
        margin-top: 0.5em;
        margin-left: 15%;
        margin-bottom: 0.8%;
        margin-right: 7.5%;
        border-radius: 10px;


    }

    .homePageButton-text {
        /* font-weight: semi-bold; */

        color: white;
        font-size: 20px;
    }


    .homePage-ecg-button {
        height: 40px;
        font-size: 19px;
        background-color: navy;
        color: white;
        margin-top: 0.8em;
        margin-left: 15%;
        margin-right: 7%;
        border-radius: 10px;
        /* margin-bottom: 3em; */
    }


    .ECGGraphContainer {
        display: flex;
        background-color: #efefef;
        transform-origin: center;
        position: absolute;
        top: 0;
        left: 0;
        transition: width 0.2s, height 0.2s;
        overflow: auto;
        width: 96%;
        transform: scale(var(--zoomLevel));
    }


    .homePageECGIntervelText {
        font-size: 18px;
        font-weight: bold;
        margin-top: 8%;
        margin-left: 3em;
        /* margin-right: 3.4em; */
    }
    .HomepageECGContainer{
        background-color: #efefef;
        height: 22em;
        width: 100%;
        margin: auto;
        /* margin-right: 10px; */
        margin-left: 10px;
        overflow: auto;
    }


    .homePageContainer-image {
        margin-top: 10px;
        padding-top: 5px;
    }


    .homePageHeader-title2 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }


    .zoomText {
        font-size: 20px;
        font-weight: bold;
        margin-top: 0.4em;
        margin-left: 3em;
        margin-right: 2.5em;
    }


    .zoomPlusButton {
        width: 100px;
        height: 40px;
        background-color: navy;
        color: white;
        border-radius: 10px;
        border: none;
        cursor: pointer;
        margin-bottom: 10px;
        margin-left: 6em;
    }


    .zoomMinusButton {
        width: 100px;
        height: 40px;
        background-color: navy;
        color: white;
        border-radius: 10px;
        border: none;
        cursor: pointer;
    }



    .homePageOverlay {
        position: fixed;
        height: 250vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .homePageTakeVitalsModal {
        position: absolute;
        height: 36em;
        top: 25%;
        /* left: 50%; */
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        width: 300px;
        /* height: 535px; */
        /* Adjust the width as needed */
    }

    .switch {
        position: absolute;
        margin-left: 2em;
        width: 46px;
        height: 25px;
    }


    .slider {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: .4s;
        border-radius: 34px;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 0px;
        bottom: 0px;
        background-color:  greenyellow;
        border: 2px solid black;;
        transition: .4s;
        border-radius: 50%;
    }

    input:checked+.slider {
        background-color: white;
        transition: .4s;
    }

    input:checked+.slider:before {
        transform: translateX(23px);
    }


    .homePageButton {
        background-color: #1976D2;
        color: white;
        border: none;
        border-radius: 5px;
        padding: 5px 15px;
        margin: 0 5px;
        cursor: pointer;


    }

    .homePagefooter {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2.6em;
        background-color: white;
        display: flex;
        justify-content: space-between;
        padding: 14px 10px;
        box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
        /* Optional: Add a shadow to the footer */
    }

    .ECGgrid-container {

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        /* Two columns */
        grid-template-rows: repeat(2, 1fr);
        /* Two rows */
        gap: 4px;
        /* Optional gap between grid items */
        margin-left: 4em;
    }

    .modalStyle2 {
        position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1000;
    width: 20em;
    height: 13em;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3em, white 3em);
    }
    .modalStyle0 {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
        width: 20em;
        height: 11em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }


    .openModalStyle {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 1000;
        width: 20em;
        height: 7em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }


    .logout {
        display: fixed;
    top: 50%;
    left: 50%;
    padding: 20px;
    border: 1px solid #ccc;
    position: fixed;
    transform: translate(-50%, -50%);
    background-color: navy;
    width: 20em;
    height: 8.5em;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    z-index: 999;
    }


    .modalStyle {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 998;
        width: 20em;
        height: 11em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }


    .modalStyle1 {
        position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 999;
    width: 20em;
    height: 11em;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3em, white 3em);
    }

    .grayOverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        /* Gray background with 50% opacity */
        z-index: 999;
    }

    .grayOverlayStyle0 {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        /* Gray background with 50% opacity */
        z-index: 999;
    }

    .saveoverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 122.5%;
        background-color: rgba(0, 0, 0, 0.2);
        /* Semi-transparent black background */
        z-index: 999;
    }

    .notsaveoverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 122.5%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .SaveMust {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: navy;
        z-index: 1000;
        width: 20em;
        height: 8.5em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    }

    .saveYes {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: navy;
        z-index: 1000;
        width: 20em;
        height: 8.5em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    }

    .logoutoverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .overlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 122.5%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;

    }

    .grayUpdateOverlayStyle {

        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;

    }

    .bioheadergrid {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        /* gap: 20px; */
        margin-left: 1.5em;
    }

    .bioheaderLeft-item,
    .bioheaderRight-item {
        /* border: 1px solid #ddd; */
        padding: 1%;
        margin-left: 19%;
        margin-right: 19%;
        /* text-align: center; */

        /* border-radius: 8px; */
    }

    .historyBoldTextStyle {
        font-weight: bold;
    }

}

/* ----------- Galaxy S6 ----------- */

/* Portrait and Landscape */
@media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 4) {

    .bioHeader {
        height: 3.8em;
        padding: 9px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-bottom: 3px;
        margin-top: 2px;
    }


    .ibutton {
        display: flex;
        justify-content: flex-end;
    }

    .instructionButton {
        font-weight: 800;
        height: 20px;
        width: 20px;
        color: #efefef;
        margin-top: -1.5em;
        margin-left: 13em;
        cursor: pointer;
        background-color: #000080;
        border: none;
        border-radius: 1em;
        /* background-image: url('aa.png'); */
    }

     .popup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 17em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .ECGIntervalPopup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 15em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .ECGIntervalPopup1 {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 17em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }
    .popup-content {
        text-align: justify;
    }

    .popupheader {
        font-size: 1200;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 4em;
        background-color: navy;
        color: white;
        /* Text color for the title */
        text-align: center;
        /* Center the text horizontally */
        line-height: 4em;
        /* Vertically center the text within the pseudo-element */
        font-weight: bold;
        /* Make the title text bold */
    }

    .homePageContainer {
        width: 500px;
        height: 250vh;
        margin-bottom: 5.5em;
        background-color: #e5e5e6;
        border-radius: 7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8.6em;
        bottom: 1em;
        overflow-y: auto;
        /* Enable vertical scrolling */
        /* max-height: 100vh; */
        /* Set maximum height to fill the available space */
        min-height: 115vh;


    }

    .logoutModal {
        display: block;
        /* or 'none' based on your logic */
        position: fixed;
        top: 30%;
        left: 55%;
        transform: translate(-50%, -50%);
        background-color: navy;
        z-index: 1000;
        width: 20em;
        height: 8.5em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    }

    .grid-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        /* Two columns */
        grid-template-rows: repeat(3, 1fr);
        /* Three rows */
        gap: 0px;
        width: inherit;
        padding: 1.5em;
        /* Optional gap between grid items */
        margin-top: -2em;
    }

    /* Style for the grid items (you can customize this as needed) */
    .grid-item {
        /* border: 1px solid #ccc;
        padding: 20px; */
        text-align: center;
    }


    .homePage-image-container,
    .homePage-header-container,
    .homePage-header-container1,
    .homePage-details-container,
    .homePage-range-container,
    .homePage-range-container1,
    .homePage-range-container2,
    .homePage-title-contaniner {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .image1 {
        max-width: 33%;
        /* height: 10%; */
        margin-right: 50%;
        margin-left: 50%;
        margin-top: 10px;
    }

    .bloodPressureImage {
        border: #000;
        border-radius: 10px;
        max-width: 25%;
        height: 8%;
        margin-right: 50%;
        margin-left: 50%;
        margin-top: 17px;
    }

    .image {
        max-width: 29%;
        height: 10%;
        margin-right: 50%;
        margin-left: 50%;
        /* margin-top: 30px; */
    }


    .homePageHeader-title {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }

    .homePageHeader-titleoxy {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 24%;
        margin-top: -7px;
    }

    .homePageHeader-titleBT {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 24%;
        margin-top: 7px;
    }

    .homePageHeader-title1 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }


    .bioheaderLeft {
        flex: auto;
        margin-left: 3.7em;
    }

    .bioheaderRight {
        flex: 1;
    }

    .homePage-details-container .value {
        background-color: #ff9c00;
        color: white;
        font-size: 15px;
        text-align: center;
        padding: 5px;
        border-radius: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-top: 5px;
        width: 60%;
    }


    .homePage-title-contaniner .tilte {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
    }



    .homePage-title-contaniner .tilteT {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
    }



    .homePage-range-container .range {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 15%;
        margin-right: 15%;
        font-weight: bold;
    }


    .homePage-range-container1 .range1 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        font-weight: bold;
    }


    .homePage-range-container2 .range2 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 20px;
        font-weight: bold;


    }

    .homePage-range-container1 .range3 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 77px;
        margin-bottom: 25px;
        font-weight: bold;
    }


    .homePage-range-container1 .range4 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 55px;
        margin-bottom: 25px;
        font-weight: bold;


    }


    .homePage-range-container1 .range5 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 25px;
        font-weight: bold;
    }


    .homePage-range-container1 .range6 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 25px;
        font-weight: bold;
    }

    .homePageFooter {
        display: flex;
        justify-content: center;
        margin-top: -1.5em;
    }

    .homePageElctrocardiogram-button {
        height: 40px;
        font-size: 19px;
        background-color: navy;
        color: white;
        position: relative;
        margin-top: 0.5em;
        margin-left: 15%;
        margin-bottom: 0.8%;
        margin-right: 7.5%;
        border-radius: 10px;


    }

    .homePageButton-text {
        /* font-weight: semi-bold; */

        color: white;
        font-size: 20px;
    }


    .homePage-ecg-button {
        height: 40px;
        font-size: 19px;
        background-color: navy;
        color: white;
        margin-top: 0.8em;
        margin-left: 15%;
        margin-right: 7%;
        border-radius: 10px;
        /* margin-bottom: 3em; */
    }


    .ECGGraphContainer {
        display: flex;
        background-color: #efefef;
        transform-origin: center;
        position: absolute;
        top: 0;
        left: 0;
        transition: width 0.2s, height 0.2s;
        overflow: auto;
        width: 96%;
        transform: scale(var(--zoomLevel));
    }


    .homePageECGIntervelText {
        font-size: 18px;
        font-weight: bold;
        margin-top: 8%;
        margin-left: 3em;
        /* margin-right: 3.4em; */
    }
    .HomepageECGContainer{
        background-color: #efefef;
        height: 22em;
        width: 100%;
        margin: auto;
        /* margin-right: 10px; */
        margin-left: 10px;
        overflow: auto;
    }


    .homePageContainer-image {
        margin-top: 10px;
        padding-top: 5px;
    }


    .homePageHeader-title2 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }


    .zoomText {
        font-size: 20px;
        font-weight: bold;
        margin-top: 0.4em;
        margin-left: 3em;
        margin-right: 2.5em;
    }


    .zoomPlusButton {
        width: 100px;
        height: 40px;
        background-color: navy;
        color: white;
        border-radius: 10px;
        border: none;
        cursor: pointer;
        margin-bottom: 10px;
        margin-left: 6em;
    }


    .zoomMinusButton {
        width: 100px;
        height: 40px;
        background-color: navy;
        color: white;
        border-radius: 10px;
        border: none;
        cursor: pointer;
    }



    .homePageOverlay {
        position: fixed;
        height: 250vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .homePageTakeVitalsModal {
        position: absolute;
        height: 36em;
        top: 25%;
        /* left: 50%; */
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        width: 300px;
        /* height: 535px; */
        /* Adjust the width as needed */
    }

    .switch {
        position: absolute;
        margin-left: 2em;
        width: 46px;
        height: 25px;
    }


    .slider {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: .4s;
        border-radius: 34px;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 0px;
        bottom: 0px;
        background-color:  greenyellow;
        border: 2px solid black;;
        transition: .4s;
        border-radius: 50%;
    }

    input:checked+.slider {
        background-color: white;
        transition: .4s;
    }

    input:checked+.slider:before {
        transform: translateX(23px);
    }


    .homePageButton {
        background-color: #1976D2;
        color: white;
        border: none;
        border-radius: 5px;
        padding: 5px 15px;
        margin: 0 5px;
        cursor: pointer;


    }

    .homePagefooter {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2.6em;
        background-color: white;
        display: flex;
        justify-content: space-between;
        padding: 14px 10px;
        box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
        /* Optional: Add a shadow to the footer */
    }

    .ECGgrid-container {

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        /* Two columns */
        grid-template-rows: repeat(2, 1fr);
        /* Two rows */
        gap: 4px;
        /* Optional gap between grid items */
        margin-left: 4em;
    }

    .modalStyle2 {
        position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1000;
    width: 20em;
    height: 13em;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3em, white 3em);
    }

    .modalStyle0 {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
        width: 20em;
        height: 11em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }

    .openModalStyle {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 1000;
        width: 20em;
        height: 7em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }


    .logout {
        display: fixed;
    top: 50%;
    left: 50%;
    padding: 20px;
    border: 1px solid #ccc;
    position: fixed;
    transform: translate(-50%, -50%);
    background-color: navy;
    width: 20em;
    height: 8.5em;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    z-index: 999;
    }


    .modalStyle {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 998;
        width: 20em;
        height: 11em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }


    .modalStyle1 {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
        width: 20em;
        height: 11em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }

    .grayOverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        /* Gray background with 50% opacity */
        z-index: 999;
    }


    .grayOverlayStyle0 {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        /* Gray background with 50% opacity */
        z-index: 999;
    }

    .saveoverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 122.5%;
        background-color: rgba(0, 0, 0, 0.2);
        /* Semi-transparent black background */
        z-index: 999;
    }

    .notsaveoverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 122.5%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .SaveMust {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: navy;
        z-index: 1000;
        width: 20em;
        height: 8.5em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    }

    .saveYes {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: navy;
        z-index: 1000;
        width: 20em;
        height: 8.5em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    }

    .logoutoverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .overlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 122.5%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;

    }

    .grayUpdateOverlayStyle {

        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;

    }

    .bioheadergrid {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        /* gap: 20px; */
        margin-left: 1.5em;
    }

    .bioheaderLeft-item,
    .bioheaderRight-item {
        /* border: 1px solid #ddd; */
        padding: 1%;
        margin-left: 19%;
        margin-right: 19%;
        /* text-align: center; */

        /* border-radius: 8px; */
    }

    .historyBoldTextStyle {
        font-weight: bold;
    }
}

/* ----------- HTC One ----------- */

/* Portrait and Landscape */
@media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3) {

    .bioHeader {
        height: 3.8em;
        padding: 9px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-bottom: 3px;
        margin-top: 2px;
    }


    .ibutton {
        display: flex;
        justify-content: flex-end;
    }

    .instructionButton {
        font-weight: 800;
        height: 20px;
        width: 20px;
        color: #efefef;
        margin-top: -1.5em;
        margin-left: 13em;
        cursor: pointer;
        background-color: #000080;
        border: none;
        border-radius: 1em;
        /* background-image: url('aa.png'); */
    }

     .popup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 17em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .ECGIntervalPopup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 15em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .ECGIntervalPopup1 {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 17em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .popup-content {
        text-align: justify;
    }

    .popupheader {
        font-size: 1200;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 4em;
        background-color: navy;
        color: white;
        /* Text color for the title */
        text-align: center;
        /* Center the text horizontally */
        line-height: 4em;
        /* Vertically center the text within the pseudo-element */
        font-weight: bold;
        /* Make the title text bold */
    }

    .homePageContainer {
        width: 500px;
        height: 250vh;
        margin-bottom: 5.5em;
        background-color: #e5e5e6;
        border-radius: 7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8.6em;
        bottom: 1em;
        overflow-y: auto;
        /* Enable vertical scrolling */
        /* max-height: 100vh; */
        /* Set maximum height to fill the available space */
        min-height: 115vh;


    }

    .logoutModal {
        display: block;
        /* or 'none' based on your logic */
        position: fixed;
        top: 30%;
        left: 55%;
        transform: translate(-50%, -50%);
        background-color: navy;
        z-index: 1000;
        width: 20em;
        height: 8.5em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    }

    .grid-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        /* Two columns */
        grid-template-rows: repeat(3, 1fr);
        /* Three rows */
        gap: 0px;
        width: inherit;
        padding: 1.5em;
        /* Optional gap between grid items */
        margin-top: -2em;
    }

    /* Style for the grid items (you can customize this as needed) */
    .grid-item {
        /* border: 1px solid #ccc;
        padding: 20px; */
        text-align: center;
    }


    .homePage-image-container,
    .homePage-header-container,
    .homePage-header-container1,
    .homePage-details-container,
    .homePage-range-container,
    .homePage-range-container1,
    .homePage-range-container2,
    .homePage-title-contaniner {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .image1 {
        max-width: 33%;
        /* height: 10%; */
        margin-right: 50%;
        margin-left: 50%;
        margin-top: 10px;
    }

    .bloodPressureImage {
        border: #000;
        border-radius: 10px;
        max-width: 25%;
        height: 8%;
        margin-right: 50%;
        margin-left: 50%;
        margin-top: 17px;
    }

    .image {
        max-width: 29%;
        height: 10%;
        margin-right: 50%;
        margin-left: 50%;
        /* margin-top: 30px; */
    }


    .homePageHeader-title {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }

    .homePageHeader-titleoxy {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 24%;
        margin-top: -7px;
    }

    .homePageHeader-titleBT {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 24%;
        margin-top: 7px;
    }

    .homePageHeader-title1 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }


    .bioheaderLeft {
        flex: auto;
        margin-left: 3.7em;
    }

    .bioheaderRight {
        flex: 1;
    }

    .homePage-details-container .value {
        background-color: #ff9c00;
        color: white;
        font-size: 15px;
        text-align: center;
        padding: 5px;
        border-radius: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-top: 5px;
        width: 60%;
    }


    .homePage-title-contaniner .tilte {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
    }


    .homePage-title-contaniner .tilteT {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
    }



    .homePage-range-container .range {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 15%;
        margin-right: 15%;
        font-weight: bold;
    }


    .homePage-range-container1 .range1 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        font-weight: bold;
    }


    .homePage-range-container2 .range2 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 20px;
        font-weight: bold;


    }


    .homePage-range-container1 .range3 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 77px;
        margin-bottom: 25px;
        font-weight: bold;
    }


    .homePage-range-container1 .range4 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 55px;
        margin-bottom: 25px;
        font-weight: bold;


    }


    .homePage-range-container1 .range5 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 25px;
        font-weight: bold;
    }


    .homePage-range-container1 .range6 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 25px;
        font-weight: bold;
    }

    .homePageFooter {
        display: flex;
        justify-content: center;
        margin-top: -1.5em;
    }

    .homePageElctrocardiogram-button {
        height: 40px;
        font-size: 19px;
        background-color: navy;
        color: white;
        position: relative;
        margin-top: 0.5em;
        margin-left: 15%;
        margin-bottom: 0.8%;
        margin-right: 7.5%;
        border-radius: 10px;


    }

    .homePageButton-text {
        /* font-weight: semi-bold; */

        color: white;
        font-size: 20px;
    }


    .homePage-ecg-button {
        height: 40px;
        font-size: 19px;
        background-color: navy;
        color: white;
        margin-top: 0.8em;
        margin-left: 15%;
        margin-right: 7%;
        border-radius: 10px;
        /* margin-bottom: 3em; */
    }


    .ECGGraphContainer {
        display: flex;
        background-color: #efefef;
        transform-origin: center;
        position: absolute;
        top: 0;
        left: 0;
        transition: width 0.2s, height 0.2s;
        overflow: auto;
        width: 96%;
        transform: scale(var(--zoomLevel));
    }


    .homePageECGIntervelText {
        font-size: 18px;
        font-weight: bold;
        margin-top: 8%;
        margin-left: 3em;
        /* margin-right: 3.4em; */
    }
    .HomepageECGContainer{
        background-color: #efefef;
        height: 22em;
        width: 100%;
        margin: auto;
        /* margin-right: 10px; */
        margin-left: 10px;
        overflow: auto;
    }


    .homePageContainer-image {
        margin-top: 10px;
        padding-top: 5px;
    }


    .homePageHeader-title2 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }


    .zoomText {
        font-size: 20px;
        font-weight: bold;
        margin-top: 0.4em;
        margin-left: 3em;
        margin-right: 2.5em;
    }


    .zoomPlusButton {
        width: 100px;
        height: 40px;
        background-color: navy;
        color: white;
        border-radius: 10px;
        border: none;
        cursor: pointer;
        margin-bottom: 10px;
        margin-left: 6em;
    }


    .zoomMinusButton {
        width: 100px;
        height: 40px;
        background-color: navy;
        color: white;
        border-radius: 10px;
        border: none;
        cursor: pointer;
    }



    .homePageOverlay {
        position: fixed;
        height: 250vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .homePageTakeVitalsModal {
        position: absolute;
        height: 36em;
        top: 25%;
        /* left: 50%; */
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        width: 300px;
        /* height: 535px; */
        /* Adjust the width as needed */
    }

    .switch {
        position: absolute;
        margin-left: 2em;
        width: 46px;
        height: 25px;
    }


    .slider {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: .4s;
        border-radius: 34px;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 0px;
        bottom: 0px;
        background-color:  greenyellow;
        border: 2px solid black;;
        transition: .4s;
        border-radius: 50%;
    }

    input:checked+.slider {
        background-color: white;
        transition: .4s;
    }

    input:checked+.slider:before {
        transform: translateX(23px);
    }

    .homePageButton {
        background-color: #1976D2;
        color: white;
        border: none;
        border-radius: 5px;
        padding: 5px 15px;
        margin: 0 5px;
        cursor: pointer;


    }

    .homePagefooter {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2.6em;
        background-color: white;
        display: flex;
        justify-content: space-between;
        padding: 14px 10px;
        box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
        /* Optional: Add a shadow to the footer */
    }

    .ECGgrid-container {

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        /* Two columns */
        grid-template-rows: repeat(2, 1fr);
        /* Two rows */
        gap: 4px;
        /* Optional gap between grid items */
        margin-left: 4em;
    }

    .modalStyle2 {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 1000;
        width: 20em;
        height: 13em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }
    .modalStyle0 {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
        width: 20em;
        height: 11em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }


    .openModalStyle {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 1000;
        width: 20em;
        height: 7em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }


    .logout {
        display: fixed;
    top: 50%;
    left: 50%;
    padding: 20px;
    border: 1px solid #ccc;
    position: fixed;
    transform: translate(-50%, -50%);
    background-color: navy;
    width: 20em;
    height: 8.5em;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    z-index: 999;
    }


    .modalStyle {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 998;
        width: 20em;
        height: 11em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }


    .modalStyle1 {
        position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 999;
    width: 20em;
    height: 11em;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3em, white 3em);
    }

    .grayOverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        /* Gray background with 50% opacity */
        z-index: 999;
    }

    .grayOverlayStyle0 {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        /* Gray background with 50% opacity */
        z-index: 999;
    }

    .saveoverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 122.5%;
        background-color: rgba(0, 0, 0, 0.2);
        /* Semi-transparent black background */
        z-index: 999;
    }

    .notsaveoverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 122.5%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .SaveMust {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: navy;
        z-index: 1000;
        width: 20em;
        height: 8.5em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    }

    .saveYes {
        position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: navy;
    z-index: 1000;
    width: 20em;
    height: 8.5em;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    }

    .logoutoverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .overlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 122.5%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;

    }

    .grayUpdateOverlayStyle {

        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;

    }

    .bioheadergrid {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        /* gap: 20px; */
        margin-left: 1.5em;
    }

    .bioheaderLeft-item,
    .bioheaderRight-item {
        /* border: 1px solid #ddd; */
        padding: 1%;
        margin-left: 19%;
        margin-right: 19%;
        /* text-align: center; */

        /* border-radius: 8px; */
    }

    .historyBoldTextStyle {
        font-weight: bold;
    }
}

/* ----------- Google Pixel ----------- */

/* Portrait and Landscape */
@media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3) {

    .bioHeader {
        height: 3.8em;
        padding: 9px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-bottom: 3px;
        margin-top: 2px;
    }


    .ibutton {
        display: flex;
        justify-content: flex-end;
    }

    .instructionButton {
        font-weight: 800;
        height: 20px;
        width: 20px;
        color: #efefef;
        margin-top: -1.5em;
        margin-left: 13em;
        cursor: pointer;
        background-color: #000080;
        border: none;
        border-radius: 1em;
        /* background-image: url('aa.png'); */
    }

     .popup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 17em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .ECGIntervalPopup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 15em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .ECGIntervalPopup1 {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 17em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }
    .popup-content {
        text-align: justify;
    }

    .popupheader {
        font-size: 1200;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 4em;
        background-color: navy;
        color: white;
        /* Text color for the title */
        text-align: center;
        /* Center the text horizontally */
        line-height: 4em;
        /* Vertically center the text within the pseudo-element */
        font-weight: bold;
        /* Make the title text bold */
    }

    .homePageContainer {
        width: 500px;
        height: 250vh;
        margin-bottom: 5.5em;
        background-color: #e5e5e6;
        border-radius: 7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8.6em;
        bottom: 1em;
        overflow-y: auto;
        /* Enable vertical scrolling */
        /* max-height: 100vh; */
        /* Set maximum height to fill the available space */
        min-height: 115vh;


    }

    .logoutModal {
        display: block;
        /* or 'none' based on your logic */
        position: fixed;
        top: 30%;
        left: 55%;
        transform: translate(-50%, -50%);
        background-color: navy;
        z-index: 1000;
        width: 20em;
        height: 8.5em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    }

    .grid-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        /* Two columns */
        grid-template-rows: repeat(3, 1fr);
        /* Three rows */
        gap: 0px;
        width: inherit;
        padding: 1.5em;
        /* Optional gap between grid items */
        margin-top: -2em;
    }

    /* Style for the grid items (you can customize this as needed) */
    .grid-item {
        /* border: 1px solid #ccc;
        padding: 20px; */
        text-align: center;
    }

    .homePage-image-container,
    .homePage-header-container,
    .homePage-header-container1,
    .homePage-details-container,
    .homePage-range-container,
    .homePage-range-container1,
    .homePage-range-container2,
    .homePage-title-contaniner {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .image1 {
        max-width: 33%;
        /* height: 10%; */
        margin-right: 50%;
        margin-left: 50%;
        margin-top: 10px;
    }

    .bloodPressureImage {
        border: #000;
        border-radius: 10px;
        max-width: 25%;
        height: 8%;
        margin-right: 50%;
        margin-left: 50%;
        margin-top: 17px;
    }

    .image {
        max-width: 29%;
        height: 10%;
        margin-right: 50%;
        margin-left: 50%;
        /* margin-top: 30px; */
    }


    .homePageHeader-title {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }

    .homePageHeader-titleoxy {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 24%;
        margin-top: -7px;
    }

    .homePageHeader-titleBT {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 24%;
        margin-top: 7px;
    }

    .homePageHeader-title1 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }


    .bioheaderLeft {
        flex: auto;
        margin-left: 3.7em;
    }

    .bioheaderRight {
        flex: 1;
    }

    .homePage-details-container .value {
        background-color: #ff9c00;
        color: white;
        font-size: 15px;
        text-align: center;
        padding: 5px;
        border-radius: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-top: 5px;
        width: 60%;
    }


    .homePage-title-contaniner .tilte {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
    }


    .homePage-title-contaniner .tilteT {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
    }



    .homePage-range-container .range {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 15%;
        margin-right: 15%;
        font-weight: bold;
    }


    .homePage-range-container1 .range1 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        font-weight: bold;
    }


    .homePage-range-container2 .range2 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 20px;
        font-weight: bold;


    }


    .homePage-range-container1 .range3 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 77px;
        margin-bottom: 25px;
        font-weight: bold;
    }


    .homePage-range-container1 .range4 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 55px;
        margin-bottom: 25px;
        font-weight: bold;


    }


    .homePage-range-container1 .range5 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 25px;
        font-weight: bold;
    }


    .homePage-range-container1 .range6 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 25px;
        font-weight: bold;
    }

    .homePageFooter {
        display: flex;
        justify-content: center;
        margin-top: -1.5em;
    }

    .homePageElctrocardiogram-button {
        height: 40px;
        font-size: 19px;
        background-color: navy;
        color: white;
        position: relative;
        margin-top: 0.5em;
        margin-left: 15%;
        margin-bottom: 0.8%;
        margin-right: 7.5%;
        border-radius: 10px;


    }

    .homePageButton-text {
        /* font-weight: semi-bold; */

        color: white;
        font-size: 20px;
    }


    .homePage-ecg-button {
        height: 40px;
        font-size: 19px;
        background-color: navy;
        color: white;
        margin-top: 0.8em;
        margin-left: 15%;
        margin-right: 7%;
        border-radius: 10px;
        /* margin-bottom: 3em; */
    }


    .ECGGraphContainer {
        display: flex;
        background-color: #efefef;
        transform-origin: center;
        position: absolute;
        top: 0;
        left: 0;
        transition: width 0.2s, height 0.2s;
        overflow: auto;
        width: 96%;
        transform: scale(var(--zoomLevel));
    }


    .homePageECGIntervelText {
        font-size: 18px;
        font-weight: bold;
        margin-top: 8%;
        margin-left: 3em;
        /* margin-right: 3.4em; */
    }
    .HomepageECGContainer{
        background-color: #efefef;
        height: 22em;
        width: 100%;
        margin: auto;
        /* margin-right: 10px; */
        margin-left: 10px;
        overflow: auto;
    }


    .homePageContainer-image {
        margin-top: 10px;
        padding-top: 5px;
    }


    .homePageHeader-title2 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }


    .zoomText {
        font-size: 20px;
        font-weight: bold;
        margin-top: 0.4em;
        margin-left: 3em;
        margin-right: 2.5em;
    }


    .zoomPlusButton {
        width: 100px;
        height: 40px;
        background-color: navy;
        color: white;
        border-radius: 10px;
        border: none;
        cursor: pointer;
        margin-bottom: 10px;
        margin-left: 6em;
    }


    .zoomMinusButton {
        width: 100px;
        height: 40px;
        background-color: navy;
        color: white;
        border-radius: 10px;
        border: none;
        cursor: pointer;
    }



    .homePageOverlay {
        position: fixed;
        height: 250vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .homePageTakeVitalsModal {
        position: absolute;
        height: 36em;
        top: 25%;
        /* left: 50%; */
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        width: 300px;
        /* height: 535px; */
        /* Adjust the width as needed */
    }

    .switch {
        position: absolute;
        margin-left: 2em;
        width: 46px;
        height: 25px;
    }


    .slider {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: .4s;
        border-radius: 34px;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 0px;
        bottom: 0px;
        background-color:  greenyellow;
        border: 2px solid black;;
        transition: .4s;
        border-radius: 50%;
    }

    input:checked+.slider {
        background-color: white;
        transition: .4s;
    }

    input:checked+.slider:before {
        transform: translateX(23px);
    }


    .homePageButton {
        background-color: #1976D2;
        color: white;
        border: none;
        border-radius: 5px;
        padding: 5px 15px;
        margin: 0 5px;
        cursor: pointer;


    }

    .homePagefooter {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2.6em;
        background-color: white;
        display: flex;
        justify-content: space-between;
        padding: 14px 10px;
        box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
        /* Optional: Add a shadow to the footer */
    }

    .ECGgrid-container {

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        /* Two columns */
        grid-template-rows: repeat(2, 1fr);
        /* Two rows */
        gap: 4px;
        /* Optional gap between grid items */
        margin-left: 4em;
    }

    .modalStyle2 {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 1000;
        width: 20em;
        height: 13em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }

    .modalStyle0 {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
        width: 20em;
        height: 11em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }

    .openModalStyle {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 1000;
        width: 20em;
        height: 7em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }


    .logout {
        display: fixed;
    top: 50%;
    left: 50%;
    padding: 20px;
    border: 1px solid #ccc;
    position: fixed;
    transform: translate(-50%, -50%);
    background-color: navy;
    width: 20em;
    height: 8.5em;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    z-index: 999;
    }


    .modalStyle {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 998;
        width: 20em;
        height: 11em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }


    .modalStyle1 {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
        width: 20em;
        height: 11em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }

    .grayOverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        /* Gray background with 50% opacity */
        z-index: 999;
    }

    .grayOverlayStyle0 {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        /* Gray background with 50% opacity */
        z-index: 999;
    }

    .saveoverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 122.5%;
        background-color: rgba(0, 0, 0, 0.2);
        /* Semi-transparent black background */
        z-index: 999;
    }

    .notsaveoverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 122.5%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .SaveMust {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: navy;
        z-index: 1000;
        width: 20em;
        height: 8.5em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    }

    .saveYes {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: navy;
        z-index: 1000;
        width: 20em;
        height: 8.5em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    }

    .logoutoverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .overlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 122.5%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;

    }

    .grayUpdateOverlayStyle {

        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;

    }

    .bioheadergrid {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        /* gap: 20px; */
        margin-left: 1.5em;
    }

    .bioheaderLeft-item,
    .bioheaderRight-item {
        /* border: 1px solid #ddd; */
        padding: 1%;
        margin-left: 19%;
        margin-right: 19%;
        /* text-align: center; */

        /* border-radius: 8px; */
    }

    .historyBoldTextStyle {
        font-weight: bold;
    }
}

/* ----------- Google Pixel XL ----------- */

/* Portrait and Landscape */
@media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 4) {

    .bioHeader {
        height: 3.8em;
        padding: 9px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-bottom: 3px;
        margin-top: 2px;
    }


    .ibutton {
        display: flex;
        justify-content: flex-end;
    }

    .instructionButton {
        font-weight: 800;
        height: 20px;
        width: 20px;
        color: #efefef;
        margin-top: -1.5em;
        margin-left: 13em;
        cursor: pointer;
        background-color: #000080;
        border: none;
        border-radius: 1em;
        /* background-image: url('aa.png'); */
    }

     .popup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 17em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .ECGIntervalPopup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 15em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .ECGIntervalPopup1 {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 17em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .popup-content {
        text-align: justify;
    }

    .popupheader {
        font-size: 1200;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 4em;
        background-color: navy;
        color: white;
        /* Text color for the title */
        text-align: center;
        /* Center the text horizontally */
        line-height: 4em;
        /* Vertically center the text within the pseudo-element */
        font-weight: bold;
        /* Make the title text bold */
    }

    .homePageContainer {
        width: 500px;
        height: 250vh;
        margin-bottom: 5.5em;
        background-color: #e5e5e6;
        border-radius: 7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8.6em;
        bottom: 1em;
        overflow-y: auto;
        /* Enable vertical scrolling */
        /* max-height: 100vh; */
        /* Set maximum height to fill the available space */
        min-height: 115vh;


    }

    .logoutModal {
        display: block;
        /* or 'none' based on your logic */
        position: fixed;
        top: 30%;
        left: 55%;
        transform: translate(-50%, -50%);
        background-color: navy;
        z-index: 1000;
        width: 20em;
        height: 8.5em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    }

    .grid-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        /* Two columns */
        grid-template-rows: repeat(3, 1fr);
        /* Three rows */
        gap: 0px;
        width: inherit;
        padding: 1.5em;
        /* Optional gap between grid items */
        margin-top: -2em;
    }

    /* Style for the grid items (you can customize this as needed) */
    .grid-item {
        /* border: 1px solid #ccc;
        padding: 20px; */
        text-align: center;
    }

    .homePage-image-container,
    .homePage-header-container,
    .homePage-header-container1,
    .homePage-details-container,
    .homePage-range-container,
    .homePage-range-container1,
    .homePage-range-container2,
    .homePage-title-contaniner {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .image1 {
        max-width: 33%;
        /* height: 10%; */
        margin-right: 50%;
        margin-left: 50%;
        margin-top: 10px;
    }

    .bloodPressureImage {
        border: #000;
        border-radius: 10px;
        max-width: 25%;
        height: 8%;
        margin-right: 50%;
        margin-left: 50%;
        margin-top: 17px;
    }


    .image {
        max-width: 29%;
        height: 10%;
        margin-right: 50%;
        margin-left: 50%;
        /* margin-top: 30px; */
    }


    .homePageHeader-title {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }

    .homePageHeader-titleoxy {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 24%;
        margin-top: -7px;
    }

    .homePageHeader-titleBT {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 24%;
        margin-top: 7px;
    }

    .homePageHeader-title1 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }

    .bioheaderLeft {
        flex: auto;
        margin-left: 3.7em;
    }

    .bioheaderRight {
        flex: 1;
    }


    .homePage-details-container .value {
        background-color: #ff9c00;
        color: white;
        font-size: 15px;
        text-align: center;
        padding: 5px;
        border-radius: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-top: 5px;
        width: 60%;
    }


    .homePage-title-contaniner .tilte {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
    }


    .homePage-title-contaniner .tilteT {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
    }



    .homePage-range-container .range {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 15%;
        margin-right: 15%;
        font-weight: bold;
    }


    .homePage-range-container1 .range1 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        font-weight: bold;
    }


    .homePage-range-container2 .range2 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 20px;
        font-weight: bold;


    }


    .homePage-range-container1 .range3 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 77px;
        margin-bottom: 25px;
        font-weight: bold;
    }


    .homePage-range-container1 .range4 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 55px;
        margin-bottom: 25px;
        font-weight: bold;


    }


    .homePage-range-container1 .range5 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 25px;
        font-weight: bold;
    }


    .homePage-range-container1 .range6 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 25px;
        font-weight: bold;
    }

    .homePageFooter {
        display: flex;
        justify-content: center;
        margin-top: -1.5em;
    }

    .homePageElctrocardiogram-button {
        height: 40px;
        font-size: 19px;
        background-color: navy;
        color: white;
        position: relative;
        margin-top: 0.5em;
        margin-left: 15%;
        margin-bottom: 0.8%;
        margin-right: 7.5%;
        border-radius: 10px;


    }

    .homePageButton-text {
        /* font-weight: semi-bold; */

        color: white;
        font-size: 20px;
    }


    .homePage-ecg-button {
        height: 40px;
        font-size: 19px;
        background-color: navy;
        color: white;
        margin-top: 0.8em;
        margin-left: 15%;
        margin-right: 7%;
        border-radius: 10px;
        /* margin-bottom: 3em; */
    }


    .ECGGraphContainer {
        display: flex;
        background-color: #efefef;
        transform-origin: center;
        position: absolute;
        top: 0;
        left: 0;
        transition: width 0.2s, height 0.2s;
        overflow: auto;
        width: 96%;
        transform: scale(var(--zoomLevel));
    }


    .homePageECGIntervelText {
        font-size: 18px;
        font-weight: bold;
        margin-top: 8%;
        margin-left: 3em;
        /* margin-right: 3.4em; */
    }
    .HomepageECGContainer{
        background-color: #efefef;
        height: 22em;
        width: 100%;
        margin: auto;
        /* margin-right: 10px; */
        margin-left: 10px;
        overflow: auto;
    }


    .homePageContainer-image {
        margin-top: 10px;
        padding-top: 5px;
    }


    .homePageHeader-title2 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }


    .zoomText {
        font-size: 20px;
        font-weight: bold;
        margin-top: 0.4em;
        margin-left: 3em;
        margin-right: 2.5em;
    }


    .zoomPlusButton {
        width: 100px;
        height: 40px;
        background-color: navy;
        color: white;
        border-radius: 10px;
        border: none;
        cursor: pointer;
        margin-bottom: 10px;
        margin-left: 6em;
    }


    .zoomMinusButton {
        width: 100px;
        height: 40px;
        background-color: navy;
        color: white;
        border-radius: 10px;
        border: none;
        cursor: pointer;
    }



    .homePageOverlay {
        position: fixed;
        height: 250vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .homePageTakeVitalsModal {
        position: absolute;
        height: 36em;
        top: 25%;
        /* left: 50%; */
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        width: 300px;
        /* height: 535px; */
        /* Adjust the width as needed */
    }

    .switch {
        position: absolute;
        margin-left: 2em;
        width: 46px;
        height: 25px;
    }


    .slider {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: .4s;
        border-radius: 34px;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 0px;
        bottom: 0px;
        background-color:  greenyellow;
        border: 2px solid black;;
        transition: .4s;
        border-radius: 50%;
    }

    input:checked+.slider {
        background-color: white;
        transition: .4s;
    }

    input:checked+.slider:before {
        transform: translateX(23px);
    }

    .homePageButton {
        background-color: #1976D2;
        color: white;
        border: none;
        border-radius: 5px;
        padding: 5px 15px;
        margin: 0 5px;
        cursor: pointer;


    }

    .homePagefooter {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2.6em;
        background-color: white;
        display: flex;
        justify-content: space-between;
        padding: 14px 10px;
        box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
        /* Optional: Add a shadow to the footer */
    }

    .ECGgrid-container {

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        /* Two columns */
        grid-template-rows: repeat(2, 1fr);
        /* Two rows */
        gap: 4px;
        /* Optional gap between grid items */
        margin-left: 4em;
    }

    .modalStyle2 {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 1000;
        width: 20em;
        height: 13em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }

    .modalStyle0 {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
        width: 20em;
        height: 11em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }

    .openModalStyle {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 1000;
        width: 20em;
        height: 7em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }


    .logout {
        display: fixed;
    top: 50%;
    left: 50%;
    padding: 20px;
    border: 1px solid #ccc;
    position: fixed;
    transform: translate(-50%, -50%);
    background-color: navy;
    width: 20em;
    height: 8.5em;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    z-index: 999;
    }


    .modalStyle {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 998;
        width: 20em;
        height: 11em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }


    .modalStyle1 {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
        width: 20em;
        height: 11em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }

    .grayOverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        /* Gray background with 50% opacity */
        z-index: 999;
    }

    .grayOverlayStyle0 {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        /* Gray background with 50% opacity */
        z-index: 999;
    }

    .saveoverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 122.5%;
        background-color: rgba(0, 0, 0, 0.2);
        /* Semi-transparent black background */
        z-index: 999;
    }

    .notsaveoverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 122.5%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .SaveMust {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: navy;
        z-index: 1000;
        width: 20em;
        height: 8.5em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    }

    .saveYes {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: navy;
        z-index: 1000;
        width: 20em;
        height: 8.5em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    }

    .logoutoverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .overlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 122.5%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;

    }

    .grayUpdateOverlayStyle {

        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;

    }

    .bioheadergrid {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        /* gap: 20px; */
        margin-left: 1.5em;
    }

    .bioheaderLeft-item,
    .bioheaderRight-item {
        /* border: 1px solid #ddd; */
        padding: 1%;
        margin-left: 19%;
        margin-right: 19%;
        /* text-align: center; */

        /* border-radius: 8px; */
    }

    .historyBoldTextStyle {
        font-weight: bold;
    }
}

/* ----------- Nexus 4 ----------- */

/* Portrait and Landscape */
@media screen and (device-width: 384px) and (device-height: 592px) and (-webkit-device-pixel-ratio: 2) {

    .bioHeader {
        height: 3.8em;
        padding: 9px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-bottom: 3px;
        margin-top: 2px;
    }

    .ibutton {
        display: flex;
        justify-content: flex-end;
    }

    .instructionButton {
        font-weight: 800;
        height: 20px;
        width: 20px;
        color: #efefef;
        margin-top: -1.5em;
        margin-left: 13em;
        cursor: pointer;
        background-color: #000080;
        border: none;
        border-radius: 1em;
        /* background-image: url('aa.png'); */
    }

     .popup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 17em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .ECGIntervalPopup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 15em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .ECGIntervalPopup1 {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 17em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }
    .popup-content {
        text-align: justify;
    }

    .popupheader {
        font-size: 1200;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 4em;
        background-color: navy;
        color: white;
        /* Text color for the title */
        text-align: center;
        /* Center the text horizontally */
        line-height: 4em;
        /* Vertically center the text within the pseudo-element */
        font-weight: bold;
        /* Make the title text bold */
    }

    .homePageContainer {
        width: 500px;
        height: 250vh;
        margin-bottom: 5.5em;
        background-color: #e5e5e6;
        border-radius: 7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8.6em;
        bottom: 1em;
        overflow-y: auto;
        /* Enable vertical scrolling */
        /* max-height: 100vh; */
        /* Set maximum height to fill the available space */
        min-height: 115vh;


    }

    .logoutModal {
        display: block;
        /* or 'none' based on your logic */
        position: fixed;
        top: 30%;
        left: 55%;
        transform: translate(-50%, -50%);
        background-color: navy;
        z-index: 1000;
        width: 20em;
        height: 8.5em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    }

    .grid-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        /* Two columns */
        grid-template-rows: repeat(3, 1fr);
        /* Three rows */
        gap: 0px;
        width: inherit;
        padding: 1.5em;
        /* Optional gap between grid items */
        margin-top: -2em;
    }

    /* Style for the grid items (you can customize this as needed) */
    .grid-item {
        /* border: 1px solid #ccc;
        padding: 20px; */
        text-align: center;
    }

    .homePage-image-container,
    .homePage-header-container,
    .homePage-header-container1,
    .homePage-details-container,
    .homePage-range-container,
    .homePage-range-container1,
    .homePage-range-container2,
    .homePage-title-contaniner {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .image1 {
        max-width: 33%;
        /* height: 10%; */
        margin-right: 50%;
        margin-left: 50%;
        margin-top: 10px;
    }

    .bloodPressureImage {
        border: #000;
        border-radius: 10px;
        max-width: 25%;
        height: 8%;
        margin-right: 50%;
        margin-left: 50%;
        margin-top: 17px;
    }

    .image {
        max-width: 29%;
        height: 10%;
        margin-right: 50%;
        margin-left: 50%;
        /* margin-top: 30px; */
    }


    .homePageHeader-title {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }

    .homePageHeader-titleoxy {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 24%;
        margin-top: -7px;
    }

    .homePageHeader-titleBT {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 24%;
        margin-top: 7px;
    }

    .homePageHeader-title1 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }

    .bioheaderLeft {
        flex: auto;
        margin-left: 3.7em;
    }

    .bioheaderRight {
        flex: 1;
    }

    .homePage-details-container .value {
        background-color: #ff9c00;
        color: white;
        font-size: 15px;
        text-align: center;
        padding: 5px;
        border-radius: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-top: 5px;
        width: 60%;
    }


    .homePage-title-contaniner .tilte {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
    }



    .homePage-title-contaniner .tilteT {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
    }



    .homePage-range-container .range {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 15%;
        margin-right: 15%;
        font-weight: bold;
    }


    .homePage-range-container1 .range1 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        font-weight: bold;
    }


    .homePage-range-container2 .range2 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 20px;
        font-weight: bold;


    }


    .homePage-range-container1 .range3 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 77px;
        margin-bottom: 25px;
        font-weight: bold;
    }


    .homePage-range-container1 .range4 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 55px;
        margin-bottom: 25px;
        font-weight: bold;


    }


    .homePage-range-container1 .range5 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 25px;
        font-weight: bold;
    }


    .homePage-range-container1 .range6 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 25px;
        font-weight: bold;
    }

    .homePageFooter {
        display: flex;
        justify-content: center;
        margin-top: -1.5em;
    }

    .homePageElctrocardiogram-button {
        height: 40px;
        font-size: 19px;
        background-color: navy;
        color: white;
        position: relative;
        margin-top: 0.5em;
        margin-left: 15%;
        margin-bottom: 0.8%;
        margin-right: 7.5%;
        border-radius: 10px;


    }

    .homePageButton-text {
        /* font-weight: semi-bold; */

        color: white;
        font-size: 20px;
    }


    .homePage-ecg-button {
        height: 40px;
        font-size: 19px;
        background-color: navy;
        color: white;
        margin-top: 0.8em;
        margin-left: 15%;
        margin-right: 7%;
        border-radius: 10px;
        /* margin-bottom: 3em; */
    }


    .ECGGraphContainer {
        display: flex;
        background-color: #efefef;
        transform-origin: center;
        position: absolute;
        top: 0;
        left: 0;
        transition: width 0.2s, height 0.2s;
        overflow: auto;
        width: 96%;
        transform: scale(var(--zoomLevel));
    }


    .homePageECGIntervelText {
        font-size: 18px;
        font-weight: bold;
        margin-top: 8%;
        margin-left: 3em;
        /* margin-right: 3.4em; */
    }
    .HomepageECGContainer{
        background-color: #efefef;
        height: 22em;
        width: 100%;
        margin: auto;
        /* margin-right: 10px; */
        margin-left: 10px;
        overflow: auto;
    }


    .homePageContainer-image {
        margin-top: 10px;
        padding-top: 5px;
    }


    .homePageHeader-title2 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }


    .zoomText {
        font-size: 20px;
        font-weight: bold;
        margin-top: 0.4em;
        margin-left: 3em;
        margin-right: 2.5em;
    }


    .zoomPlusButton {
        width: 100px;
        height: 40px;
        background-color: navy;
        color: white;
        border-radius: 10px;
        border: none;
        cursor: pointer;
        margin-bottom: 10px;
        margin-left: 6em;
    }


    .zoomMinusButton {
        width: 100px;
        height: 40px;
        background-color: navy;
        color: white;
        border-radius: 10px;
        border: none;
        cursor: pointer;
    }



    .homePageOverlay {
        position: fixed;
        height: 250vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .homePageTakeVitalsModal {
        position: absolute;
        height: 36em;
        top: 25%;
        /* left: 50%; */
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        width: 300px;
        /* height: 535px; */
        /* Adjust the width as needed */
    }

    .switch {
        position: absolute;
        margin-left: 2em;
        width: 46px;
        height: 25px;
    }


    .slider {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: .4s;
        border-radius: 34px;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 0px;
        bottom: 0px;
        background-color:  greenyellow;
        border: 2px solid black;;
        transition: .4s;
        border-radius: 50%;
    }

    input:checked+.slider {
        background-color: white;
        transition: .4s;
    }

    input:checked+.slider:before {
        transform: translateX(23px);
    }


    .homePageButton {
        background-color: #1976D2;
        color: white;
        border: none;
        border-radius: 5px;
        padding: 5px 15px;
        margin: 0 5px;
        cursor: pointer;


    }

    .homePagefooter {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2.6em;
        background-color: white;
        display: flex;
        justify-content: space-between;
        padding: 14px 10px;
        box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
        /* Optional: Add a shadow to the footer */
    }

    .ECGgrid-container {

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        /* Two columns */
        grid-template-rows: repeat(2, 1fr);
        /* Two rows */
        gap: 4px;
        /* Optional gap between grid items */
        margin-left: 4em;
    }

    .modalStyle2 {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 1000;
        width: 20em;
        height: 13em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }

    .modalStyle0 {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
        width: 20em;
        height: 11em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }

    .openModalStyle {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 1000;
        width: 20em;
        height: 7em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }


    .logout {
        display: fixed;
    top: 50%;
    left: 50%;
    padding: 20px;
    border: 1px solid #ccc;
    position: fixed;
    transform: translate(-50%, -50%);
    background-color: navy;
    width: 20em;
    height: 8.5em;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    z-index: 999;
    }


    .modalStyle {
        position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 998;
    width: 20em;
    height: 11em;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3em, white 3em);
    }


    .modalStyle1 {
        position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 999;
    width: 20em;
    height: 11em;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3em, white 3em);
    }

    .grayOverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        /* Gray background with 50% opacity */
        z-index: 999;
    }

    .grayOverlayStyle0 {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        /* Gray background with 50% opacity */
        z-index: 999;
    }

    .saveoverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 122.5%;
        background-color: rgba(0, 0, 0, 0.2);
        /* Semi-transparent black background */
        z-index: 999;
    }

    .notsaveoverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 122.5%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .SaveMust {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: navy;
        z-index: 1000;
        width: 20em;
        height: 8.5em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    }

    .saveYes {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: navy;
        z-index: 1000;
        width: 20em;
        height: 8.5em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    }

    .logoutoverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .overlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 122.5%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;

    }

    .grayUpdateOverlayStyle {

        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;

    }

    .bioheadergrid {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        /* gap: 20px; */
        margin-left: 1.5em;
    }

    .bioheaderLeft-item,
    .bioheaderRight-item {
        /* border: 1px solid #ddd; */
        padding: 1%;
        margin-left: 19%;
        margin-right: 19%;
        /* text-align: center; */

        /* border-radius: 8px; */
    }

    .historyBoldTextStyle {
        font-weight: bold;
    }
}

/* ----------- Nexus 5 ----------- */

/* Portrait and Landscape */
@media screen and (device-width: 360px) and (device-height: 592px) and (-webkit-device-pixel-ratio: 3) {

    .bioHeader {
        height: 3.8em;
        padding: 9px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-bottom: 3px;
        margin-top: 2px;
    }


    .ibutton {
        display: flex;
        justify-content: flex-end;
    }

    .instructionButton {
        font-weight: 800;
        height: 20px;
        width: 20px;
        color: #efefef;
        margin-top: -1.5em;
        margin-left: 13em;
        cursor: pointer;
        background-color: #000080;
        border: none;
        border-radius: 1em;
        /* background-image: url('aa.png'); */
    }

     .popup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 17em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .ECGIntervalPopup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 15em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .ECGIntervalPopup1 {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 17em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .popup-content {
        text-align: justify;
    }

    .popupheader {
        font-size: 1200;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 4em;
        background-color: navy;
        color: white;
        /* Text color for the title */
        text-align: center;
        /* Center the text horizontally */
        line-height: 4em;
        /* Vertically center the text within the pseudo-element */
        font-weight: bold;
        /* Make the title text bold */
    }

    .homePageContainer {
        width: 500px;
        height: 250vh;
        margin-bottom: 5.5em;
        background-color: #e5e5e6;
        border-radius: 7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8.6em;
        bottom: 1em;
        overflow-y: auto;
        /* Enable vertical scrolling */
        /* max-height: 100vh; */
        /* Set maximum height to fill the available space */
        min-height: 115vh;


    }

    .logoutModal {
        display: block;
        /* or 'none' based on your logic */
        position: fixed;
        top: 30%;
        left: 55%;
        transform: translate(-50%, -50%);
        background-color: navy;
        z-index: 1000;
        width: 20em;
        height: 8.5em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    }

    .grid-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        /* Two columns */
        grid-template-rows: repeat(3, 1fr);
        /* Three rows */
        gap: 0px;
        width: inherit;
        padding: 1.5em;
        /* Optional gap between grid items */
        margin-top: -2em;
    }

    /* Style for the grid items (you can customize this as needed) */
    .grid-item {
        /* border: 1px solid #ccc;
        padding: 20px; */
        text-align: center;
    }

    .homePage-image-container,
    .homePage-header-container,
    .homePage-header-container1,
    .homePage-details-container,
    .homePage-range-container,
    .homePage-range-container1,
    .homePage-range-container2,
    .homePage-title-contaniner {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .image1 {
        max-width: 33%;
        /* height: 10%; */
        margin-right: 50%;
        margin-left: 50%;
        margin-top: 10px;
    }


    .bloodPressureImage {
        border: #000;
        border-radius: 10px;
        max-width: 25%;
        height: 8%;
        margin-right: 50%;
        margin-left: 50%;
        margin-top: 17px;
    }

    .image {
        max-width: 29%;
        height: 10%;
        margin-right: 50%;
        margin-left: 50%;
        /* margin-top: 30px; */
    }


    .homePageHeader-title {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }

    .homePageHeader-titleoxy {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 24%;
        margin-top: -7px;
    }

    .homePageHeader-titleBT {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 24%;
        margin-top: 7px;
    }

    .homePageHeader-title1 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }

    .bioheaderLeft {
        flex: auto;
        margin-left: 3.7em;
    }

    .bioheaderRight {
        flex: 1;
    }

    .homePage-details-container .value {
        background-color: #ff9c00;
        color: white;
        font-size: 15px;
        text-align: center;
        padding: 5px;
        border-radius: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-top: 5px;
        width: 60%;
    }


    .homePage-title-contaniner .tilte {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
    }

    .homePage-title-contaniner .tilteT {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
    }


    .homePage-range-container .range {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 15%;
        margin-right: 15%;
        font-weight: bold;
    }

    .homePage-range-container1 .range1 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        font-weight: bold;
    }


    .homePage-range-container2 .range2 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 20px;
        font-weight: bold;


    }


    .homePage-range-container1 .range3 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 77px;
        margin-bottom: 25px;
        font-weight: bold;
    }


    .homePage-range-container1 .range4 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 55px;
        margin-bottom: 25px;
        font-weight: bold;


    }


    .homePage-range-container1 .range5 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 25px;
        font-weight: bold;
    }


    .homePage-range-container1 .range6 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 25px;
        font-weight: bold;
    }

    .homePageFooter {
        display: flex;
        justify-content: center;
        margin-top: -1.5em;
    }

    .homePageElctrocardiogram-button {
        height: 40px;
        font-size: 19px;
        background-color: navy;
        color: white;
        position: relative;
        margin-top: 0.5em;
        margin-left: 15%;
        margin-bottom: 0.8%;
        margin-right: 7.5%;
        border-radius: 10px;


    }

    .homePageButton-text {
        /* font-weight: semi-bold; */

        color: white;
        font-size: 20px;
    }


    .homePage-ecg-button {
        height: 40px;
        font-size: 19px;
        background-color: navy;
        color: white;
        margin-top: 0.8em;
        margin-left: 15%;
        margin-right: 7%;
        border-radius: 10px;
        /* margin-bottom: 3em; */
    }


    .ECGGraphContainer {
        display: flex;
        background-color: #efefef;
        transform-origin: center;
        position: absolute;
        top: 0;
        left: 0;
        transition: width 0.2s, height 0.2s;
        overflow: auto;
        width: 96%;
        transform: scale(var(--zoomLevel));
    }


    .homePageECGIntervelText {
        font-size: 18px;
        font-weight: bold;
        margin-top: 8%;
        margin-left: 3em;
        /* margin-right: 3.4em; */
    }
    .HomepageECGContainer{
        background-color: #efefef;
        height: 22em;
        width: 100%;
        margin: auto;
        /* margin-right: 10px; */
        margin-left: 10px;
        overflow: auto;
    }


    .homePageContainer-image {
        margin-top: 10px;
        padding-top: 5px;
    }


    .homePageHeader-title2 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }


    .zoomText {
        font-size: 20px;
        font-weight: bold;
        margin-top: 0.4em;
        margin-left: 3em;
        margin-right: 2.5em;
    }


    .zoomPlusButton {
        width: 100px;
        height: 40px;
        background-color: navy;
        color: white;
        border-radius: 10px;
        border: none;
        cursor: pointer;
        margin-bottom: 10px;
        margin-left: 6em;
    }


    .zoomMinusButton {
        width: 100px;
        height: 40px;
        background-color: navy;
        color: white;
        border-radius: 10px;
        border: none;
        cursor: pointer;
    }



    .homePageOverlay {
        position: fixed;
        height: 250vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .homePageTakeVitalsModal {
        position: absolute;
        height: 36em;
        top: 25%;
        /* left: 50%; */
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        width: 300px;
        /* height: 535px; */
        /* Adjust the width as needed */
    }

    .switch {
        position: absolute;
        margin-left: 2em;
        width: 46px;
        height: 25px;
    }


    .slider {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: .4s;
        border-radius: 34px;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 0px;
        bottom: 0px;
        background-color:  greenyellow;
        border: 2px solid black;;
        transition: .4s;
        border-radius: 50%;
    }

    input:checked+.slider {
        background-color: white;
        transition: .4s;
    }

    input:checked+.slider:before {
        transform: translateX(23px);
    }


    .homePageButton {
        background-color: #1976D2;
        color: white;
        border: none;
        border-radius: 5px;
        padding: 5px 15px;
        margin: 0 5px;
        cursor: pointer;


    }

    .homePagefooter {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2.6em;
        background-color: white;
        display: flex;
        justify-content: space-between;
        padding: 14px 10px;
        box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
        /* Optional: Add a shadow to the footer */
    }

    .ECGgrid-container {

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        /* Two columns */
        grid-template-rows: repeat(2, 1fr);
        /* Two rows */
        gap: 4px;
        /* Optional gap between grid items */
        margin-left: 4em;
    }

    .modalStyle2 {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 1000;
        width: 20em;
        height: 13em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }
    .modalStyle0 {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
        width: 20em;
        height: 11em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }


    .openModalStyle {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 1000;
        width: 20em;
        height: 7em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }


    .logout {
        display: fixed;
    top: 50%;
    left: 50%;
    padding: 20px;
    border: 1px solid #ccc;
    position: fixed;
    transform: translate(-50%, -50%);
    background-color: navy;
    width: 20em;
    height: 8.5em;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    z-index: 999;
    }


    .modalStyle {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 998;
        width: 20em;
        height: 11em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }


    .modalStyle1 {
        position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 999;
    width: 20em;
    height: 11em;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3em, white 3em);
    }

    .grayOverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        /* Gray background with 50% opacity */
        z-index: 999;
    }

    .grayOverlayStyle0 {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        /* Gray background with 50% opacity */
        z-index: 999;
    }


    .saveoverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 122.5%;
        background-color: rgba(0, 0, 0, 0.2);
        /* Semi-transparent black background */
        z-index: 999;
    }

    .notsaveoverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 122.5%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .SaveMust {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: navy;
        z-index: 1000;
        width: 20em;
        height: 8.5em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    }

    .saveYes {
        position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: navy;
    z-index: 1000;
    width: 20em;
    height: 8.5em;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    }

    .logoutoverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .overlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 122.5%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;

    }

    .grayUpdateOverlayStyle {

        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;

    }

    .bioheadergrid {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        /* gap: 20px; */
        margin-left: 1.5em;
    }

    .bioheaderLeft-item,
    .bioheaderRight-item {
        /* border: 1px solid #ddd; */
        padding: 1%;
        margin-left: 19%;
        margin-right: 19%;
        /* text-align: center; */

        /* border-radius: 8px; */
    }

    .historyBoldTextStyle {
        font-weight: bold;
    }
}

/* ----------- Nexus 6 and 6P ----------- */

/* Portrait and Landscape */
@media screen and (device-width: 360px) and (device-height: 592px) and (-webkit-device-pixel-ratio: 4) {

    .bioHeader {
        height: 3.8em;
        padding: 9px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-bottom: 3px;
        margin-top: 2px;
    }


    .ibutton {
        display: flex;
        justify-content: flex-end;
    }

    .instructionButton {
        font-weight: 800;
        height: 20px;
        width: 20px;
        color: #efefef;
        margin-top: -1.5em;
        margin-left: 13em;
        cursor: pointer;
        background-color: #000080;
        border: none;
        border-radius: 1em;
        /* background-image: url('aa.png'); */
    }

     .popup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 17em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .ECGIntervalPopup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 15em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .ECGIntervalPopup1 {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 17em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .popup-content {
        text-align: justify;
    }

    .popupheader {
        font-size: 1200;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 4em;
        background-color: navy;
        color: white;
        /* Text color for the title */
        text-align: center;
        /* Center the text horizontally */
        line-height: 4em;
        /* Vertically center the text within the pseudo-element */
        font-weight: bold;
        /* Make the title text bold */
    }

    .homePageContainer {
        width: 500px;
        height: 250vh;
        margin-bottom: 5.5em;
        background-color: #e5e5e6;
        border-radius: 7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8.6em;
        bottom: 1em;
        overflow-y: auto;
        /* Enable vertical scrolling */
        /* max-height: 100vh; */
        /* Set maximum height to fill the available space */
        min-height: 115vh;


    }

    .logoutModal {
        display: block;
        /* or 'none' based on your logic */
        position: fixed;
        top: 30%;
        left: 55%;
        transform: translate(-50%, -50%);
        background-color: navy;
        z-index: 1000;
        width: 20em;
        height: 8.5em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    }

    .grid-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        /* Two columns */
        grid-template-rows: repeat(3, 1fr);
        /* Three rows */
        gap: 0px;
        width: inherit;
        padding: 1.5em;
        /* Optional gap between grid items */
        margin-top: -2em;
    }

    /* Style for the grid items (you can customize this as needed) */
    .grid-item {
        /* border: 1px solid #ccc;
        padding: 20px; */
        text-align: center;
    }

    .homePage-image-container,
    .homePage-header-container,
    .homePage-header-container1,
    .homePage-details-container,
    .homePage-range-container,
    .homePage-range-container1,
    .homePage-range-container2,
    .homePage-title-contaniner {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .image1 {
        max-width: 33%;
        /* height: 10%; */
        margin-right: 50%;
        margin-left: 50%;
        margin-top: 10px;
    }

    .bloodPressureImage {
        border: #000;
        border-radius: 10px;
        max-width: 25%;
        height: 8%;
        margin-right: 50%;
        margin-left: 50%;
        margin-top: 17px;
    }


    .image {
        max-width: 29%;
        height: 10%;
        margin-right: 50%;
        margin-left: 50%;
        /* margin-top: 30px; */
    }


    .homePageHeader-title {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }

    .homePageHeader-titleoxy {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 24%;
        margin-top: -7px;
    }

    .homePageHeader-titleBT {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 24%;
        margin-top: 7px;
    }

    .homePageHeader-title1 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }


    .bioheaderLeft {
        flex: auto;
        margin-left: 3.7em;
    }

    .bioheaderRight {
        flex: 1;
    }

    .homePage-details-container .value {
        background-color: #ff9c00;
        color: white;
        font-size: 15px;
        text-align: center;
        padding: 5px;
        border-radius: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-top: 5px;
        width: 60%;
    }


    .homePage-title-contaniner .tilte {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
    }


    .homePage-title-contaniner .tilteT {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
    }



    .homePage-range-container .range {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 15%;
        margin-right: 15%;
        font-weight: bold;
    }


    .homePage-range-container1 .range1 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        font-weight: bold;
    }


    .homePage-range-container2 .range2 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 20px;
        font-weight: bold;


    }

    .homePage-range-container1 .range3 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 77px;
        margin-bottom: 25px;
        font-weight: bold;
    }


    .homePage-range-container1 .range4 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 55px;
        margin-bottom: 25px;
        font-weight: bold;


    }


    .homePage-range-container1 .range5 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 25px;
        font-weight: bold;
    }


    .homePage-range-container1 .range6 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 25px;
        font-weight: bold;
    }

    .homePageFooter {
        display: flex;
        justify-content: center;
        margin-top: -1.5em;
    }

    .homePageElctrocardiogram-button {
        height: 40px;
        font-size: 19px;
        background-color: navy;
        color: white;
        position: relative;
        margin-top: 0.5em;
        margin-left: 15%;
        margin-bottom: 0.8%;
        margin-right: 7.5%;
        border-radius: 10px;


    }

    .homePageButton-text {
        /* font-weight: semi-bold; */

        color: white;
        font-size: 20px;
    }


    .homePage-ecg-button {
        height: 40px;
        font-size: 19px;
        background-color: navy;
        color: white;
        margin-top: 0.8em;
        margin-left: 15%;
        margin-right: 7%;
        border-radius: 10px;
        /* margin-bottom: 3em; */
    }


    .ECGGraphContainer {
        display: flex;
        background-color: #efefef;
        transform-origin: center;
        position: absolute;
        top: 0;
        left: 0;
        transition: width 0.2s, height 0.2s;
        overflow: auto;
        width: 96%;
        transform: scale(var(--zoomLevel));
    }


    .homePageECGIntervelText {
        font-size: 18px;
        font-weight: bold;
        margin-top: 8%;
        margin-left: 3em;
        /* margin-right: 3.4em; */
    }
    .HomepageECGContainer{
        background-color: #efefef;
        height: 22em;
        width: 100%;
        margin: auto;
        /* margin-right: 10px; */
        margin-left: 10px;
        overflow: auto;
    }


    .homePageContainer-image {
        margin-top: 10px;
        padding-top: 5px;
    }


    .homePageHeader-title2 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }


    .zoomText {
        font-size: 20px;
        font-weight: bold;
        margin-top: 0.4em;
        margin-left: 3em;
        margin-right: 2.5em;
    }


    .zoomPlusButton {
        width: 100px;
        height: 40px;
        background-color: navy;
        color: white;
        border-radius: 10px;
        border: none;
        cursor: pointer;
        margin-bottom: 10px;
        margin-left: 6em;
    }


    .zoomMinusButton {
        width: 100px;
        height: 40px;
        background-color: navy;
        color: white;
        border-radius: 10px;
        border: none;
        cursor: pointer;
    }



    .homePageOverlay {
        position: fixed;
        height: 250vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .homePageTakeVitalsModal {
        position: absolute;
        height: 36em;
        top: 25%;
        /* left: 50%; */
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        width: 300px;
        /* height: 535px; */
        /* Adjust the width as needed */
    }

    .switch {
        position: absolute;
        margin-left: 2em;
        width: 46px;
        height: 25px;
    }


    .slider {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: .4s;
        border-radius: 34px;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 0px;
        bottom: 0px;
        background-color:  greenyellow;
        border: 2px solid black;;
        transition: .4s;
        border-radius: 50%;
    }

    input:checked+.slider {
        background-color: white;
        transition: .4s;
    }

    input:checked+.slider:before {
        transform: translateX(23px);
    }


    .homePageButton {
        background-color: #1976D2;
        color: white;
        border: none;
        border-radius: 5px;
        padding: 5px 15px;
        margin: 0 5px;
        cursor: pointer;


    }

    .homePagefooter {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2.6em;
        background-color: white;
        display: flex;
        justify-content: space-between;
        padding: 14px 10px;
        box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
        /* Optional: Add a shadow to the footer */
    }

    .ECGgrid-container {

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        /* Two columns */
        grid-template-rows: repeat(2, 1fr);
        /* Two rows */
        gap: 4px;
        /* Optional gap between grid items */
        margin-left: 4em;
    }

    .modalStyle2 {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 1000;
        width: 20em;
        height: 13em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }
    .modalStyle0 {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
        width: 20em;
        height: 11em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }


    .openModalStyle {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 1000;
        width: 20em;
        height: 7em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }


    .logout {
        display: fixed;
    top: 50%;
    left: 50%;
    padding: 20px;
    border: 1px solid #ccc;
    position: fixed;
    transform: translate(-50%, -50%);
    background-color: navy;
    width: 20em;
    height: 8.5em;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    z-index: 999;
    }


    .modalStyle {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 998;
        width: 20em;
        height: 11em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }


    .modalStyle1 {
        position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 999;
    width: 20em;
    height: 11em;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3em, white 3em);
    }

    .grayOverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        /* Gray background with 50% opacity */
        z-index: 999;
    }

    .grayOverlayStyle0 {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        /* Gray background with 50% opacity */
        z-index: 999;
    }


    .saveoverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 122.5%;
        background-color: rgba(0, 0, 0, 0.2);
        /* Semi-transparent black background */
        z-index: 999;
    }

    .notsaveoverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 122.5%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .SaveMust {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: navy;
        z-index: 1000;
        width: 20em;
        height: 8.5em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    }

    .saveYes {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: navy;
        z-index: 1000;
        width: 20em;
        height: 8.5em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    }

    .logoutoverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .overlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 122.5%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;

    }

    .grayUpdateOverlayStyle {

        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;

    }

    .bioheadergrid {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        /* gap: 20px; */
        margin-left: 1.5em;
    }

    .bioheaderLeft-item,
    .bioheaderRight-item {
        /* border: 1px solid #ddd; */
        padding: 1%;
        margin-left: 19%;
        margin-right: 19%;
        /* text-align: center; */

        /* border-radius: 8px; */
    }

    .historyBoldTextStyle {
        font-weight: bold;
    }
}

/* ----------- Windows Phone ----------- */

/* Portrait and Landscape */
@media screen and (device-width: 480px) and (device-height: 800px) {

    .bioHeader {
        height: 3.8em;
        padding: 9px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-bottom: 3px;
        margin-top: 2px;
    }

    .ibutton {
        display: flex;
        justify-content: flex-end;
    }

    .instructionButton {
        font-weight: 800;
        height: 20px;
        width: 20px;
        color: #efefef;
        margin-top: -1.5em;
        margin-left: 13em;
        cursor: pointer;
        background-color: #000080;
        border: none;
        border-radius: 1em;
        /* background-image: url('aa.png'); */
    }

     .popup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 17em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }
    .ECGIntervalPopup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 15em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .ECGIntervalPopup1 {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 17em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }
    .popup-content {
        text-align: justify;
    }

    .popupheader {
        font-size: 1200;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 4em;
        background-color: navy;
        color: white;
        /* Text color for the title */
        text-align: center;
        /* Center the text horizontally */
        line-height: 4em;
        /* Vertically center the text within the pseudo-element */
        font-weight: bold;
        /* Make the title text bold */
    }

    .homePageContainer {
        width: 500px;
        height: 250vh;
        margin-bottom: 5.5em;
        background-color: #e5e5e6;
        border-radius: 7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8.6em;
        bottom: 1em;
        overflow-y: auto;
        /* Enable vertical scrolling */
        /* max-height: 100vh; */
        /* Set maximum height to fill the available space */
        min-height: 115vh;


    }

    .logoutModal {
        display: block;
        /* or 'none' based on your logic */
        position: fixed;
        top: 30%;
        left: 55%;
        transform: translate(-50%, -50%);
        background-color: navy;
        z-index: 1000;
        width: 20em;
        height: 8.5em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    }

    .grid-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        /* Two columns */
        grid-template-rows: repeat(3, 1fr);
        /* Three rows */
        gap: 0px;
        width: inherit;
        padding: 1.5em;
        margin-top: -2em;
    }

    /* Style for the grid items (you can customize this as needed) */
    .grid-item {
        /* border: 1px solid #ccc;
        padding: 20px; */
        text-align: center;
    }

    .homePage-image-container,
    .homePage-header-container,
    .homePage-header-container1,
    .homePage-details-container,
    .homePage-range-container,
    .homePage-range-container1,
    .homePage-range-container2,
    .homePage-title-contaniner {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .image1 {
        max-width: 33%;
        /* height: 10%; */
        margin-right: 50%;
        margin-left: 50%;
        margin-top: 10px;
    }

    .bloodPressureImage {
        border: #000;
        border-radius: 10px;
        max-width: 25%;
        height: 8%;
        margin-right: 50%;
        margin-left: 50%;
        margin-top: 17px;
    }


    .image {
        max-width: 29%;
        height: 10%;
        margin-right: 50%;
        margin-left: 50%;
        /* margin-top: 30px; */
    }


    .homePageHeader-title {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }

    .homePageHeader-titleoxy {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 24%;
        margin-top: -7px;
    }

    .homePageHeader-titleBT {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 24%;
        margin-top: 7px;
    }

    .homePageHeader-title1 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }

    .bioheaderLeft {
        flex: auto;
        margin-left: 3.7em;
    }

    .bioheaderRight {
        flex: 1;
    }

    .homePage-details-container .value {
        background-color: #ff9c00;
        color: white;
        font-size: 15px;
        text-align: center;
        padding: 5px;
        border-radius: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-top: 5px;
        width: 60%;
    }


    .homePage-title-contaniner .tilte {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
    }



    .homePage-title-contaniner .tilteT {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
    }



    .homePage-range-container .range {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 15%;
        margin-right: 15%;
        font-weight: bold;
    }


    .homePage-range-container1 .range1 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        font-weight: bold;
    }


    .homePage-range-container2 .range2 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 20px;
        font-weight: bold;


    }

    .homePage-range-container1 .range3 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 77px;
        margin-bottom: 25px;
        font-weight: bold;
    }


    .homePage-range-container1 .range4 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 55px;
        margin-bottom: 25px;
        font-weight: bold;


    }


    .homePage-range-container1 .range5 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 25px;
        font-weight: bold;
    }


    .homePage-range-container1 .range6 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 25%;
        margin-right: 25%;
        margin-bottom: 25px;
        font-weight: bold;
    }

    .homePageFooter {
        display: flex;
        justify-content: center;
        margin-top: -1.5em;
    }

    .homePageElctrocardiogram-button {
        height: 40px;
        font-size: 19px;
        background-color: navy;
        color: white;
        position: relative;
        margin-top: 0.5em;
        margin-left: 15%;
        margin-bottom: 0.8%;
        margin-right: 7.5%;
        border-radius: 10px;


    }

    .homePageButton-text {
        /* font-weight: semi-bold; */

        color: white;
        font-size: 20px;
    }


    .homePage-ecg-button {
        height: 40px;
        font-size: 19px;
        background-color: navy;
        color: white;
        margin-top: 0.8em;
        margin-left: 15%;
        margin-right: 7%;
        border-radius: 10px;
        /* margin-bottom: 3em; */
    }


    .ECGGraphContainer {
        display: flex;
        background-color: #efefef;
        transform-origin: center;
        position: absolute;
        top: 0;
        left: 0;
        transition: width 0.2s, height 0.2s;
        overflow: auto;
        width: 96%;
        transform: scale(var(--zoomLevel));
    }


    .homePageECGIntervelText {
        font-size: 18px;
        font-weight: bold;
        margin-top: 8%;
        margin-left: 3em;
        /* margin-right: 3.4em; */
    }
    .HomepageECGContainer{
        background-color: #efefef;
        height: 22em;
        width: 100%;
        margin: auto;
        /* margin-right: 10px; */
        margin-left: 10px;
        overflow: auto;
    }


    .homePageContainer-image {
        margin-top: 10px;
        padding-top: 5px;
    }


    .homePageHeader-title2 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }


    .zoomText {
        font-size: 20px;
        font-weight: bold;
        margin-top: 0.4em;
        margin-left: 3em;
        margin-right: 2.5em;
    }


    .zoomPlusButton {
        width: 100px;
        height: 40px;
        background-color: navy;
        color: white;
        border-radius: 10px;
        border: none;
        cursor: pointer;
        margin-bottom: 10px;
        margin-left: 6em;
    }


    .zoomMinusButton {
        width: 100px;
        height: 40px;
        background-color: navy;
        color: white;
        border-radius: 10px;
        border: none;
        cursor: pointer;
    }



    .homePageOverlay {
        position: fixed;
        height: 250vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        overflow-y: hidden;
    }


    .homePageTakeVitalsModal {
        position: absolute;
        height: 36em;
        top: 25%;
        /* left: 50%; */
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        width: 300px;
        /* height: 535px; */
        /* Adjust the width as needed */
    }

    .switch {
        position: absolute;
        margin-left: 2em;
        width: 46px;
        height: 25px;
    }


    .slider {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: .4s;
        border-radius: 34px;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 0px;
        bottom: 0px;
        background-color:  greenyellow;
        border: 2px solid black;;
        transition: .4s;
        border-radius: 50%;
    }

    input:checked+.slider {
        background-color: white;
        transition: .4s;
    }

    input:checked+.slider:before {
        transform: translateX(23px);
    }

    .homePageButton {
        background-color: #1976D2;
        color: white;
        border: none;
        border-radius: 5px;
        padding: 5px 15px;
        margin: 0 5px;
        cursor: pointer;


    }

    .homePagefooter {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2.6em;
        background-color: white;
        display: flex;
        justify-content: space-between;
        padding: 14px 10px;
        box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
        /* Optional: Add a shadow to the footer */
    }

    .ECGgrid-container {

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        /* Two columns */
        grid-template-rows: repeat(2, 1fr);
        /* Two rows */
        gap: 4px;
        /* Optional gap between grid items */
        margin-left: 4em;
    }


    .modalStyle2 {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 1000;
        width: 20em;
        height: 13em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }

    .modalStyle0 {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
        width: 20em;
        height: 11em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }

    .openModalStyle {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 1000;
        width: 20em;
        height: 7em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }


    .logout {
        display: fixed;
    top: 50%;
    left: 50%;
    padding: 20px;
    border: 1px solid #ccc;
    position: fixed;
    transform: translate(-50%, -50%);
    background-color: navy;
    width: 20em;
    height: 8.5em;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    z-index: 999;
    }


    .modalStyle {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 998;
        width: 20em;
        height: 11em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3em, white 3em);
    }


    .modalStyle1 {
        position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 999;
    width: 20em;
    height: 11em;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
    background: linear-gradient(to bottom, navy 3em, white 3em);
    }

    .grayOverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        /* Gray background with 50% opacity */
        z-index: 999;
    }

    .grayOverlayStyle0 {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        /* Gray background with 50% opacity */
        z-index: 999;
    }


    .saveoverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 122.5%;
        background-color: rgba(0, 0, 0, 0.2);
        /* Semi-transparent black background */
        z-index: 999;
    }

    .notsaveoverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 122.5%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
    }


    .SaveMust {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: navy;
        z-index: 1000;
        width: 20em;
        height: 8.5em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    }

    .saveYes {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: navy;
        z-index: 1000;
        width: 20em;
        height: 8.5em;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px;
        text-align: center;
        background: linear-gradient(to bottom, navy 3.3em, white 3.3em);
    }

    .logoutoverlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .overlayStyle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 122.5%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;

    }

    .grayUpdateOverlayStyle {

        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 999;

    }

    .bioheadergrid {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        /* gap: 20px; */
        margin-left: 1.5em;
    }

    .bioheaderLeft-item,
    .bioheaderRight-item {
        /* border: 1px solid #ddd; */
        padding: 1%;
        margin-left: 19%;
        margin-right: 19%;
        /* text-align: center; */

        /* border-radius: 8px; */
    }

    .historyBoldTextStyle {
        font-weight: bold;
    }

}