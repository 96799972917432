/* / Toggle.css / */

.toggle-container {
    width: 46px;
    height: 24px;
    border-radius: 15px;
    margin-left: 35%;
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 2px solid black;
}

.toggle-button {
    width: 20px;
    height: 20px;
    background-color: greenyellow;
    border: 2px solid black;
    border-radius: 50%;
    transition: .4s;
}

.toggle-container.on {
    background-color: #ffffff;
}

.toggle-container.off {
    background-color: #e2e2e2;
}

.toggle-button.on {
    transform: translateX(23px);
}

.toggle-button.off {
    transform: translateX(0);
}

.separator-line {
    width: 100%;
    height: 2px;
    background-color: black;
    margin-top: 5px;
}






.HistoryContainer {
    width: 500px;
    /* height: 46.5em; */
    margin-bottom: 3.5em;
    background-color: #e5e5e6;
    border-radius: 7px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 8.6em;
    bottom: 1em;
    /* overflow-y: auto; */
    overflow-x: hidden;

    /* Enable vertical scrolling */
    max-height: calc(100vh);
    /* Set maximum height to fill the available space */
    min-height: 11em;
}

.history-loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 9999;
}

.history-spinner {
    border: 4px solid rgba(0, 0, 0, 0.3);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
    /* Apply the spin animation */
}

.historyHeader {
    padding: 5px;
    display: flex;
    background-color: navy;
    border: 1px solid #ccc;
    border-radius: 5px;
    justify-content: space-between;
    align-items: center;
    color: white;
    margin-top: 5px;
    font-size: 25px;
    /* width: 100%; */
    height: 1.2em;
}

.historyBackBtn {

    background-color: #000080;
    color: white;
    border: none;
    padding: 3px;
    cursor: pointer;
    border-radius: 10px;
    font-weight: bold;
    display: flex;
    align-items: center;

}

.ImgHistoryBackBtn {
    height: 30px;
    width: 30px;
}

.HistoryHeaderTxt {
    margin-right: 7em;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
}

.profileDetailHeader {
    height: 2.8em;
    /* width: 80%; */
    padding: 9px;
    display: flex;
    background-color: navy;
    border: 1px solid #ccc;
    border-radius: 5px;
    justify-content: space-between;
    align-items: center;
    color: white;
    margin-bottom: 3px;
    margin-top: 2px;
}


.profileDetailHeaderText {
    flex: auto;
    margin-left: 1em;
}


.profileDetailText {
    font-weight: bold;
}



.FilterHeaderTitle {
    width: 90%;
    /* height: 5%;  */
    background-color: white;
    text-align: center;
    padding: 10px;
    font-size: 16px;
    margin-left: 1.6em;
    margin-top: 0.5em;
}


.filterModalBox{
    position: absolute;
    top: 27em;
    left: 50%;
    height: 27em;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    width: 23em;
}

.FilterDropdown {
    text-align: center;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
}

.FilterDropdown .select {
    width: 90%;
    text-align: center;
    padding: 10px;
    font-size: 16px;
}

.FilterDropdown .select .paragraphText {
    margin-top: 10px;
    font-weight: bold;
    font-size: 16px;
    color: #333;
}



.FilterDropdown-headerTitle {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    justify-content: center;
    margin-right: 2.3em;
    margin-left: 3.8em;
}

.FilterDropdown-headerTitle1 {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin-right: 0em;
    margin-left: 1em;
    padding-left: 4.7em;
}

.history-rectangle {
    width: 90%;
    height: 225px;
    background-color: white;
    margin-left: 1.7em;
    margin-top: 0.5em;
    display: flex;
    flex-direction: column;
}

.history-top-content {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.FilterDropdown-header {
    display: flex;
}

.Historypopup {
    border: black solid 10px;
    border-radius: 10px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    height: 16em;
    width: 15em;
    padding: 20px;
    border: 1px solid #000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 999;
}

.HistoryECGIntervalPopup {
    border: black solid 10px;
    border-radius: 10px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    height: 15em;
    width: 15em;
    padding: 20px;
    border: 1px solid #000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 999;
}

.HistoryECGIntervalPopup1 {
    border: black solid 10px;
    border-radius: 10px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    height: 17em;
    width: 15em;
    padding: 20px;
    border: 1px solid #000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 999;
}

/* .FilterDropdown-headerTitle {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    justify-content: center;
    margin-right: -2.5em;
    margin-left: 3.7em;
}
.history-filter-top-content1
.FilterDropdown-headerTitle1 {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin-right: 1em;
    margin-left: 5em;
} */

.history-filter-rectangle {
    width: 94%;
    /* width: 460px; */
    height: 45px;
    padding-left: 2px;
    background-color: white;
    margin-left: 0.85em;
    margin-bottom: 0.5em;
    display: flex;
    flex-direction: column;
}

.linkimage{
    display: flex;
    justify-content: flex-end;
}
.history-filter-top-content {
    display: flex;
    margin-right: -4em;
    margin-left: 1.7em;
}


.history-filter-top-content1 {
    display: flex;
    margin-right: -2em;
    margin-left: -0.5em;
}


.FilterDropdown-headerTitle-1 {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin-right: 0em;
    margin-left: 15%;

}

.FilterDropdown-headerTitle-2 {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin-right: 0em;
    margin-left: 21%;

}

.FilterDropdown-headerTitle-3 {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin-right: 0em;
    margin-left: 19%;

}

.historyFilterTextStyle {
    font-size: 16px;
    margin-left: -1em;
}

.historyPopUpModal {
    position: absolute;
    top: 55%;
    left: 50%;
    height: 40em;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    /* width: 400px; */
    /* width: 302px; */
    /* width: 28.5%; */
}


.historyModel {
    display: block;
    /* You can adjust this based on the value of 'modalIsOpen' */
    width: 100%;
}


.vitalDetailsBorder {
    width: 100%;
    height: 2em;
    background: navy;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-top: 0.5px; */
    margin-bottom: 0.5em;
}

.ECGContainer {
    margin-top: "4em";
    margin-left: "-15px";
    display: block;
    background-color: #efefef;
    height: 35em;
    /* width: 105%; */
    margin: auto;
    margin-right: -15px;
    /* margin-top: -15px; */
    /* margin-top: 4em; */
    /* margin-bottom: em; */
    /* This appears to be incomplete */
    margin-left: -15px;
    overflow: auto;
    scroll-margin-left: 0;
    scroll-margin-top: 0;
}


.HistoryRecord-title {
    display: flex;
    font-size: 14px;
    text-align: center;
    margin-right: 0em;
    margin-left: 6.5em;
    margin-top: 0em;
}





.HistoryImage-container,
.HistoryHeader-container,
.HistoryHeader-container1,
.HistoryDetails-container,
.HistoryRange-container,
.HistoryRange-container1,
.HistoryRange-container2,
.HistoryTilte-contaniner {
    display: flex;
    align-items: center;
    justify-content: center;
}

.HistoryImage1 {
    max-width: 33%;
    /* height: 10%; */
    margin-right:50%;
    margin-left: 50%;
    margin-top: 11px;
}

.HistorybloodPressureImage {
    border: #000;
    border-radius: 3px;
    max-width: 25%;
    height: 8%;
    margin-right: 50%;
    margin-left: 50%;
    margin-top: 17px;
}


.HistoryImage {
    max-width: 29%;
    height: 10%;
    margin-right: 50%;
    margin-left: 50%;
    /* margin-top: 30px; */
}








.HistoryHeader-title {
    /* display: flex; */
    margin: 0;
    font-size: 14px;
    text-align: center;
    /* margin-right: 25%; */
    /* margin-left: 25%; */
    margin-top: 8px;
}

.HistoryHeader-titleoxy {
    /* display: flex; */
    margin: 0;
    font-size: 14px;
    text-align: center;
    /* margin-right: 25%; */
    /* margin-left: 24%; */
    margin-top: 3px;
}

.HistoryHeader-titleBT {
    /* display: flex; */
    margin: 0;
    font-size: 14px;
    text-align: center;
    /* margin-right: 25%; */
    /* margin-left: 24%; */
    margin-top: 7px;
}



.HistoryHeader-title1 {
    margin: 0;
    font-size: 14px;
    text-align: center;
    margin-right: 25%;
    margin-left: 25%;
    margin-top: 8px;
}




.HistoryHeader-title2 {
    margin: 0;
    font-size: 14px;
    text-align: center;
    margin-right: 20%;
    margin-left: 20%;
    margin-top: 8px;
}


.HistoryDetails-container .HistoryValue {
    background-color: #ffb33a;
    color: white;
    font-size: 15px;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 5px;
    width: 95%;

}




.HistoryTilte-contaniner .HistoryTilte {
    text-align: center;
    font-size: 12px;
    margin-top: 5px;
    /* margin-left: 20%; */
    /* margin-right: 20%; */
}




.HistoryTilte-contaniner .HistoryTilteT {
    text-align: center;
    font-size: 12px;
    margin-top: 5px;
    margin-left: 20%;
    margin-right: 20%;
}




.HistoryContainer-image {
    padding: 15px 10px;
    margin-top: 10px;
    padding-top: 5px;
}




.HistoryRange-container .HistoryRange {
    text-align: center;
    font-size: 13px;
    margin-top: 5px;
    /* margin-left: 25%; */
    /* margin-right: 25%; */
    font-weight: bold;
}




.HistoryRange-container1 .HistoryRange1 {
    text-align: center;
    font-size: 13px;
    margin-top: 5px;
    /* margin-left: 25%; */
    /* margin-right: 25%; */
    font-weight: bold;
}




.HistoryRange-container2 .HistoryRange2 {
    text-align: center;
    font-size: 13px;
    margin-top: 5px;
    /* margin-left: 25%; */
    /* margin-right: 25%; */
    margin-bottom: 20px;
    font-weight: bold;

}








.HistoryRange-container1 .HistoryRange3 {
    text-align: center;
    font-size: 13px;
    margin-top: 5px;
    margin-left: 25%;
    margin-right: 25%;
    margin-bottom: 25px;
    font-weight: bold;
}




.HistoryRange-container1 .HistoryRange4 {
    text-align: center;
    font-size: 13px;
    margin-top: 5px;
    margin-left: 25%;
    margin-right: 25%;
    margin-bottom: 25px;
    font-weight: bold;




}


.HistoryRange-container1 .HistoryRange5 {
    text-align: center;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 15%;
    margin-right: 15%;
    margin-bottom: 25px;
    font-weight: bold;
}




.HistoryRange-container1 .HistoryRange6 {
    text-align: center;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 15%;
    margin-right: 15%;
    margin-bottom: 25px;
    font-weight: bold;
}


.ibuttonHistory {
    display: flex;
    justify-content: flex-end;
}

.instructionButtonHistory {
    display: flex;
    position: static;
    font-weight: 800;
    height: 20px;
    width: 20px;
    color: #efefef;
    margin-top: -1.5em;
    margin-left: 9em;
    cursor: pointer;
    background-color: #000080;
    border: none;
    border-radius: 1em;
    /* background-image: url('aa.png'); */
}

.HistoryECGIntervelText {
    font-size: 18px;
    font-weight: bold;
    margin-top: 1em;
    margin-bottom: -2em;
    margin-left: 1em;
}


.HistoryECGIntervelText1 {
    font-size: 18px;
    font-weight: bold;
    margin-top: -2em;
    margin-left: 1em;
}






.historyFooter {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2.6em;
    background-color: white;
    display: flex;
    justify-content: space-between;
    padding: 14px 10px;
    box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
    /* Optional: Add a shadow to the footer */
}

.historyOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}


.vitalDetailsModal {
    /* margin: 0; */
    color: white;
    font-size: 13px;
    font-weight: bold;
    display: flex;
    margin-left: 8em;
    /* align-items: center; */
    /* justify-content: center; */
}


.historyModelX {
    background: navy;
    color: white;
    border-color: white;
    font-weight: bold;
    padding: 3px 7px;
    border-radius: 14px;
    font-size: 14px;
    display: flex;
    margin-left: 8em;
    margin-right: -2em;
}

.filterModelx{
    background: navy;
    color: white;
    border-color: white;
    font-weight: bold;
    /* padding: 1px 6px; */
    border-radius: 15px;
    font-size: 14px;
    display: flex;
    margin-left: 23em;
    display: flex;
    position: absolute;
    margin-top: -1.4em;
}

.historyDateTime {
    height: 1.5em;
    padding: 7px;
    display: flex;
    background-color: navy;
    border: 1px solid #ccc;
    border-radius: 5px;
    justify-content: space-between;
    align-items: center;
    color: white;
    margin-bottom: 6px;
    margin-top: 1px;
}

.detail-icon{
    display:flex;
    position:fixed;
    height:1.5em;
    margin-left:8.5em;
}



.share-icon{
    display:flex;
    position:absolute;
    height:1.5em;
    margin-left:8.5em;
    margin-top: 2em;
}

.Historygrid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns */
    grid-template-rows: repeat(3, 1fr);    /* Three rows */
    gap: 15px;
    width: inherit;
    align-items: center;
  }
  /* Style for the grid items (you can customize this as needed) */
  .Historygrid-item {
    /* border: 1px solid #ccc;
    padding: 20px; */
    text-align: center;
  }

  .HistoryECGgrid-container{
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns */
    grid-template-rows: repeat(2, 1fr);    /* Two rows */
    gap: 10px;


  }

  .Historybioheadergrid {
    width:100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    /* gap: 20px; */
  }

  .HistorybioheaderLeft-item,
  .HistorybioheaderRight-item {
    /* border: 1px solid #ddd; */
    padding:1%;
    margin-left: 18%;
    margin-right: 18%;
    /* text-align: center; */

    /* border-radius: 8px; */
  }

  .historyBoldTextStyle {
    font-weight: bold;
  }

  .history-filter-grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    /* Three equal columns */
    gap: 8px;
    /* Adjust the gap as needed */
}

.history-filter-grid-item {
    text-align: center;
    flex: 2;
    /* Additional styling for each grid item if necessary */
}


.history-filter-grid-item-head {
    text-align: center;
    flex: 1;
    font-weight: bolder;
    margin-left: -1em;
    /* Additional styling for each grid item if necessary */
}

@media  screen and (max-width: 1366px){
    /* / Toggle.css / */
.HistoryECGContainer{
    display: inline;
    margin-left: 1em;
}
.toggle-container {
    width: 46px;
    height: 24px;
    border-radius: 15px;
    margin-left: 35%;
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 2px solid black;
}

.toggle-button {
    width: 20px;
    height: 20px;
    background-color: greenyellow;
    border: 2px solid black;
    border-radius: 50%;
    transition: .4s;
}

.toggle-container.on {
    background-color: #ffffff;
}

.toggle-container.off {
    background-color: #e2e2e2;
}

.toggle-button.on {
    transform: translateX(23px);
}

.toggle-button.off {
    transform: translateX(0);
}

.separator-line {
    width: 100%;
    height: 2px;
    background-color: black;
    margin-top: 5px;
}






.HistoryContainer {
    width: 500px;
    /* height: 46.5em; */
    margin-bottom: 3.5em;
    background-color: #e5e5e6;
    border-radius: 7px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 8.6em;
    bottom: 1em;
    /* overflow-y: auto; */
    overflow-x: hidden;

    /* Enable vertical scrolling */
    max-height: calc(100vh);
    /* Set maximum height to fill the available space */
    min-height: 11em;
}

.history-loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 9999;
}

.history-spinner {
    border: 4px solid rgba(0, 0, 0, 0.3);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
    /* Apply the spin animation */
}

.historyHeader {
    padding: 5px;
    display: flex;
    background-color: navy;
    border: 1px solid #ccc;
    border-radius: 5px;
    justify-content: space-between;
    align-items: center;
    color: white;
    margin-top: 5px;
    font-size: 25px;
    /* width: 100%; */
    height: 1.2em;
}

.historyBackBtn {

    background-color: #000080;
    color: white;
    border: none;
    padding: 3px;
    cursor: pointer;
    border-radius: 10px;
    font-weight: bold;
    display: flex;
    align-items: center;

}

.ImgHistoryBackBtn {
    height: 30px;
    width: 30px;
}

.HistoryHeaderTxt {
    margin-right: 7em;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
}

.profileDetailHeader {
    height: 2.8em;
    /* width: 80%; */
    padding: 9px;
    display: flex;
    background-color: navy;
    border: 1px solid #ccc;
    border-radius: 5px;
    justify-content: space-between;
    align-items: center;
    color: white;
    margin-bottom: 3px;
    margin-top: 2px;
}


.profileDetailHeaderText {
    flex: auto;
    margin-left: 1em;
}


.profileDetailText {
    font-weight: bold;
}



.FilterHeaderTitle {
    width: 90%;
    /* height: 5%;  */
    background-color: white;
    text-align: center;
    padding: 10px;
    font-size: 16px;
    margin-left: 1.6em;
    margin-top: 0.5em;
}


.filterModalBox{
    position: absolute;
    top: 27em;
    left: 50%;
    height: 27em;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    width: 23em;
}

.FilterDropdown {
    text-align: center;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
}

.FilterDropdown .select {
    width: 90%;
    text-align: center;
    padding: 10px;
    font-size: 16px;
}

.FilterDropdown .select .paragraphText {
    margin-top: 10px;
    font-weight: bold;
    font-size: 16px;
    color: #333;
}



.FilterDropdown-headerTitle {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    justify-content: center;
    margin-right: 2.3em;
    margin-left: 3.8em;
}

.FilterDropdown-headerTitle1 {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin-right: 0em;
    margin-left: 1em;
    padding-left: 4.7em;
}

.history-rectangle {
    width: 90%;
    height: 225px;
    background-color: white;
    margin-left: 1.7em;
    margin-top: 0.5em;
    display: flex;
    flex-direction: column;
}

.history-top-content {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.FilterDropdown-header {
    display: flex;
}

.Historypopup {
    border: black solid 10px;
    border-radius: 10px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    height: 16em;
    width: 15em;
    padding: 20px;
    border: 1px solid #000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 999;
}

.HistoryECGIntervalPopup {
    border: black solid 10px;
    border-radius: 10px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    height: 15em;
    width: 15em;
    padding: 20px;
    border: 1px solid #000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 999;
}

.HistoryECGIntervalPopup1 {
    border: black solid 10px;
    border-radius: 10px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    height: 17em;
    width: 15em;
    padding: 20px;
    border: 1px solid #000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 999;
}

/* .FilterDropdown-headerTitle {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    justify-content: center;
    margin-right: -2.5em;
    margin-left: 3.7em;
}
.history-filter-top-content1
.FilterDropdown-headerTitle1 {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin-right: 1em;
    margin-left: 5em;
} */

.history-filter-rectangle {
    width: 94%;
    /* width: 460px; */
    height: 45px;
    padding-left: 2px;
    background-color: white;
    margin-left: 0.85em;
    margin-bottom: 0.5em;
    display: flex;
    flex-direction: column;
}

.linkimage{
    display: flex;
    justify-content: flex-end;
}
.history-filter-top-content {
    display: flex;
    margin-right: -4em;
    margin-left: 1.7em;
}


.history-filter-top-content1 {
    display: flex;
    margin-right: -2em;
    margin-left: -0.5em;
}


.FilterDropdown-headerTitle-1 {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin-right: 0em;
    margin-left: 15%;

}

.FilterDropdown-headerTitle-2 {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin-right: 0em;
    margin-left: 21%;

}

.FilterDropdown-headerTitle-3 {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin-right: 0em;
    margin-left: 19%;

}

.historyFilterTextStyle {
    font-size: 16px;
    margin-left: -1em;
}

.historyPopUpModal {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 35em;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    /* width: 400px; */
    /* width: 302px; */
    /* width: 28.5%; */
}


.historyModel {
    display: block;
    /* You can adjust this based on the value of 'modalIsOpen' */
    width: 100%;
}


.vitalDetailsBorder {
    width: 100%;
    height: 2em;
    background: navy;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-top: 0.5px; */
    margin-bottom: 0.5em;
}

.ECGContainer {
    margin-top: "4em";
    margin-left: "-15px";
    display: block;
    background-color: #efefef;
    height: 30em;
    /* width: 105%; */
    margin: auto;
    margin-right: -15px;
    /* margin-top: -15px; */
    /* margin-top: 4em; */
    /* margin-bottom: em; */
    /* This appears to be incomplete */
    margin-left: -15px;
    overflow: auto;
    scroll-margin-left: 0;
    scroll-margin-top: 0;
}


.HistoryRecord-title {
    display: flex;
    font-size: 14px;
    text-align: center;
    margin-right: 0em;
    margin-left: 6.5em;
    margin-top: 0em;
}





.HistoryImage-container,
.HistoryHeader-container,
.HistoryHeader-container1,
.HistoryDetails-container,
.HistoryRange-container,
.HistoryRange-container1,
.HistoryRange-container2,
.HistoryTilte-contaniner {
    display: flex;
    align-items: center;
    justify-content: center;
}

.HistoryImage1 {
    max-width: 33%;
    /* height: 10%; */
    margin-right:50%;
    margin-left: 50%;
    margin-top: 11px;
}

.HistorybloodPressureImage {
    border: #000;
    border-radius: 3px;
    max-width: 25%;
    height: 8%;
    margin-right: 50%;
    margin-left: 50%;
    margin-top: 17px;
}


.HistoryImage {
    max-width: 29%;
    height: 10%;
    margin-right: 50%;
    margin-left: 50%;
    /* margin-top: 30px; */
}








.HistoryHeader-title {
    /* display: flex; */
    margin: 0;
    font-size: 14px;
    text-align: center;
    /* margin-right: 25%; */
    /* margin-left: 25%; */
    margin-top: 8px;
}

.HistoryHeader-titleoxy {
    /* display: flex; */
    margin: 0;
    font-size: 14px;
    text-align: center;
    /* margin-right: 25%; */
    /* margin-left: 24%; */
    margin-top: 3px;
}

.HistoryHeader-titleBT {
    /* display: flex; */
    margin: 0;
    font-size: 14px;
    text-align: center;
    /* margin-right: 25%; */
    /* margin-left: 24%; */
    margin-top: 7px;
}



.HistoryHeader-title1 {
    margin: 0;
    font-size: 14px;
    text-align: center;
    margin-right: 25%;
    margin-left: 25%;
    margin-top: 8px;
}




.HistoryHeader-title2 {
    margin: 0;
    font-size: 14px;
    text-align: center;
    margin-right: 20%;
    margin-left: 20%;
    margin-top: 8px;
}


.HistoryDetails-container .HistoryValue {
    background-color: #ffb33a;
    color: white;
    font-size: 15px;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 5px;
    width: 95%;

}




.HistoryTilte-contaniner .HistoryTilte {
    text-align: center;
    font-size: 12px;
    margin-top: 5px;
    /* margin-left: 20%; */
    /* margin-right: 20%; */
}




.HistoryTilte-contaniner .HistoryTilteT {
    text-align: center;
    font-size: 12px;
    margin-top: 5px;
    margin-left: 20%;
    margin-right: 20%;
}




.HistoryContainer-image {
    padding: 15px 10px;
    margin-top: 10px;
    padding-top: 5px;
}




.HistoryRange-container .HistoryRange {
    text-align: center;
    font-size: 13px;
    margin-top: 5px;
    /* margin-left: 25%; */
    /* margin-right: 25%; */
    font-weight: bold;
}




.HistoryRange-container1 .HistoryRange1 {
    text-align: center;
    font-size: 13px;
    margin-top: 5px;
    /* margin-left: 25%; */
    /* margin-right: 25%; */
    font-weight: bold;
}




.HistoryRange-container2 .HistoryRange2 {
    text-align: center;
    font-size: 13px;
    margin-top: 5px;
    /* margin-left: 25%; */
    /* margin-right: 25%; */
    margin-bottom: 20px;
    font-weight: bold;

}








.HistoryRange-container1 .HistoryRange3 {
    text-align: center;
    font-size: 13px;
    margin-top: 5px;
    margin-left: 25%;
    margin-right: 25%;
    margin-bottom: 25px;
    font-weight: bold;
}




.HistoryRange-container1 .HistoryRange4 {
    text-align: center;
    font-size: 13px;
    margin-top: 5px;
    margin-left: 25%;
    margin-right: 25%;
    margin-bottom: 25px;
    font-weight: bold;




}


.HistoryRange-container1 .HistoryRange5 {
    text-align: center;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 15%;
    margin-right: 15%;
    margin-bottom: 25px;
    font-weight: bold;
}




.HistoryRange-container1 .HistoryRange6 {
    text-align: center;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 15%;
    margin-right: 15%;
    margin-bottom: 25px;
    font-weight: bold;
}


.ibuttonHistory {
    display: flex;
    justify-content: flex-end;
}

.instructionButtonHistory {
    display: flex;
    position: static;
    font-weight: 800;
    height: 20px;
    width: 20px;
    color: #efefef;
    margin-top: -1.5em;
    margin-left: 9em;
    cursor: pointer;
    background-color: #000080;
    border: none;
    border-radius: 1em;
    /* background-image: url('aa.png'); */
}

.HistoryECGIntervelText {
    font-size: 18px;
    font-weight: bold;
    margin-top: 1em;
    margin-bottom: -2em;
    margin-left: 1em;
}


.HistoryECGIntervelText1 {
    font-size: 18px;
    font-weight: bold;
    margin-top: -2em;
    margin-left: 1em;
}






.historyFooter {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2.6em;
    background-color: white;
    display: flex;
    justify-content: space-between;
    padding: 14px 10px;
    box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
    /* Optional: Add a shadow to the footer */
}

.historyOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}


.vitalDetailsModal {
    /* margin: 0; */
    color: white;
    font-size: 13px;
    font-weight: bold;
    display: flex;
    margin-left: 8em;
    /* align-items: center; */
    /* justify-content: center; */
}


.historyModelX {
    background: navy;
    color: white;
    border-color: white;
    font-weight: bold;
    padding: 3px 7px;
    border-radius: 14px;
    font-size: 14px;
    display: flex;
    margin-left: 8em;
    margin-right: -2em;
}

.filterModelx{
    background: navy;
    color: white;
    border-color: white;
    font-weight: bold;
    /* padding: 1px 6px; */
    border-radius: 15px;
    font-size: 14px;
    display: flex;
    margin-left: 23em;
    display: flex;
    position: absolute;
    margin-top: -1.4em;
}

.historyDateTime {
    height: 1.5em;
    padding: 7px;
    display: flex;
    background-color: navy;
    border: 1px solid #ccc;
    border-radius: 5px;
    justify-content: space-between;
    align-items: center;
    color: white;
    margin-bottom: 6px;
    margin-top: 1px;
}

.detail-icon{
    display:flex;
    position:fixed;
    height:1.5em;
    margin-left:8.5em;
}



.share-icon{
    display:flex;
    position:absolute;
    height:1.5em;
    margin-left:8.5em;
    margin-top: 2em;
}

.Historygrid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns */
    grid-template-rows: repeat(3, 1fr);    /* Three rows */
    gap: 15px;
    width: inherit;
    align-items: center;
  }
  /* Style for the grid items (you can customize this as needed) */
  .Historygrid-item {
    /* border: 1px solid #ccc;
    padding: 20px; */
    text-align: center;
  }

  .HistoryECGgrid-container{
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns */
    grid-template-rows: repeat(2, 1fr);    /* Two rows */
    gap: 10px;


  }

  .Historybioheadergrid {
    width:100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    /* gap: 20px; */
  }

  .HistorybioheaderLeft-item,
  .HistorybioheaderRight-item {
    /* border: 1px solid #ddd; */
    padding:1%;
    margin-left: 18%;
    margin-right: 18%;
    /* text-align: center; */

    /* border-radius: 8px; */
  }

  .historyBoldTextStyle {
    font-weight: bold;
  }

  .history-filter-grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    /* Three equal columns */
    gap: 8px;
    /* Adjust the gap as needed */
}

.history-filter-grid-item {
    text-align: center;
    flex: 2;
    /* Additional styling for each grid item if necessary */
}


.history-filter-grid-item-head {
    text-align: center;
    flex: 1;
    font-weight: bolder;
    margin-left: -1em;
    /* Additional styling for each grid item if necessary */
}

}
@media screen and (max-width: 768px) {

    .HistoryContainer {
        width: 100%;
        /* height: 46.5em; */
        margin-bottom: 3.5em;
        background-color: #e5e5e6;
        border-radius: 7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8.6em;
        bottom: 1em;
        overflow-y: auto;
        /* Enable vertical scrolling */
        max-height: calc(100vh);
        /* Set maximum height to fill the available space */
        min-height: 11em;
        overflow-x: hidden;
    }




    .profileDetailHeader {
        height: 2.6em;
        width: 100%;
        padding: 9px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-bottom: 3px;
        margin-top: 2px;
    }


    .profileDetailHeaderText {
        flex: auto;
        /* margin-left: 1.5em; */
        margin-left: 0em;
    }


    .historyHeader {
        padding: 5px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-top: 5px;
        font-size: 25px;
        width: 97%;
        height: 1.4em;
    }


    .history-rectangle {
        margin-left: 5.5%;
        /* margin-right: -15%; */
        width: 89.5%;
        height: 39%;
        background-color: white;
        /* margin-left: 0em; */
        /* margin-bottom: 2em; */
        margin-top: 0.5em;
        display: flex;
        flex-direction: column;
    }




    .history-filter-rectangle {
        width: 420px;
        height: 45px;
        padding: 2px;
        background-color: white;
        margin-left: 1.4em;
        margin-bottom: 0.5em;
        display: flex;
        flex-direction: column;
    }

    .FilterHeaderTitle {
        width: 90%;
        height: 5%;
        background-color: white;
        text-align: center;
        padding: 10px;
        font-size: 16px;
        margin-left: 1.5em;
        margin-top: 0.5em;
    }


    .filterModalBox{
        position: absolute;
        top: 27em;
        left: 50%;
        height: 27em;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 10px;
        border-radius: 8px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        width: 23em;
    }

    .FilterDropdown {
        text-align: center;
        margin-bottom: 0.5em;
        margin-top: 0.5em;
        /* margin-left: 0em; */
        width: 100%;
    }


    .FilterDropdown .select {
        width: 90%;
        text-align: center;
        padding: 10px;
        font-size: 16px;
    }


    .FilterDropdown .select .paragraphText {
        margin-top: 10px;
        font-weight: bold;
        font-size: 16px;
        color: #333;
    }


    .historyBackBtn {
        /* margin-left: 1.5em; */
        margin-left: 0em;
        background-color: #000080;
        color: white;
        border: none;
        padding: 3px;
        cursor: pointer;
        border-radius: 10px;
        font-weight: bold;
        display: flex;
        align-items: center;
    }






    .history-filter-top-content {
        display: flex;
        margin-right: -5.8em;
        margin-left: 1em;
    }




    .history-filter-top-content1 {
        display: flex;
        margin-right: -4em;
        margin-left: -1.6em;




    }




    .historyPopUpModal {
        position: absolute;
        top: 54%;
        left: 50%;
        height: 32em;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        width: 300px;
    }

    .HistoryECGContainer{
        margin-left: -0.2em;
    }
    .HistoryHeaderTxt {
        margin-right: 5em;
        text-align: center;
        font-size: 20px;
    }




    .vitalDetailsBorder {
        width: 100%;
        height: 2.3em;
        background: navy;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1px;
        margin-bottom: 1em;
    }




    .vitalDetailsModal {
        /* margin: 0; */
        color: white;
        font-size: 13px;
        font-weight: bold;
        display: flex;
        margin-left: 8em;
        /* align-items: center; */
        /* justify-content: center; */
    }


    .historyDateTime {
        height: 2em;
        padding: 7px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-bottom: 10px;
        margin-top: 1px;
    }






    .HistoryRecord-title {
        display: flex;
        font-size: 14px;
        text-align: center;
        margin-right: 25px;
        margin-left: 62px;
        margin-top: 3px;
    }


    .historyModelX {
        background: navy;
        color: white;
        border-color: white;
        font-weight: bold;
        padding: 3px 7px;
        border-radius: 14px;
        font-size: 14px;
        display: flex;
        margin-left: 6em;
    }


    .ECGContainer {
        display: block;
        /* You can adjust this based on the value of 'isModalOpen' */
        background-color: #efefef;
        height: 25.8em;
        /* width: 105%; */
        margin: auto;
        margin-right: -15px;
        /* margin-top: -15px; */
        /* margin-top: 4em; */
        /* margin-bottom: em; */
        /* This appears to be incomplete */
        margin-left: -15px;
        overflow: auto;
        scroll-margin-left: 0;
        scroll-margin-top: 0;
    }








    .HistoryImage-container,
    .HistoryHeader-container,
    .HistoryHeader-container1,
    .HistoryDetails-container,
    .HistoryRange-container,
    .HistoryRange-container1,
    .HistoryRange-container2,
    .HistoryTilte-contaniner {
        display: flex;
        align-items: center;
        justify-content: center;
    }






    .HistoryImage1 {
        max-width: 33%;
        /* height: 10%; */
        margin-right:50%;
        margin-left: 50%;
        margin-top: 11px;
    }

    .HistorybloodPressureImage {
        border: #000;
        border-radius: 3px;
        max-width: 25%;
        height: 8%;
        margin-right: 50%;
        margin-left: 50%;
        margin-top: 17px;
    }


    .HistoryImage {
        max-width: 29%;
    height: 10%;
    margin-right: 50%;
    margin-left: 50%;
    }








    .HistoryHeader-title {
   /* display: flex; */
   margin: 0;
   font-size: 14px;
   text-align: center;
   /* margin-right: 25%; */
   /* margin-left: 25%; */
   margin-top: 8px;
    }

    .HistoryHeader-titleoxy {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        /* margin-right: 25%; */
        /* margin-left: 24%; */
        margin-top: 3px;
    }

    .HistoryHeader-titleBT {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        /* margin-right: 25%; */
        /* margin-left: 24%; */
        margin-top: 7px;
    }


    .HistoryHeader-title1 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }




    .HistoryHeader-title2 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 0%;
        margin-left: 0%;
        margin-top: 8px;
    }




    .HistoryDetails-container .HistoryValue {
        background-color: #ffb33a;
        color: white;
        font-size: 14px;
        text-align: center;
        padding: 5px;
        border-radius: 5px;
        margin-left: 0%;
        margin-right: 0%;
        margin-top: 5px;
        width: 95%;

    }




    .HistoryTilte-contaniner .HistoryTilte {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 20%;
        margin-right: 20%;
    }




    .HistoryTilte-contaniner .HistoryTilteT {
        text-align: center;
    font-size: 12px;
    margin-top: 5px;
    margin-left: 0%;
    margin-right: 0%;
    }




    .HistoryContainer-image {
        padding: 15px 10px;
        margin-top: 10px;
        padding-top: 5px;
    }




    .HistoryRange-container .HistoryRange {
        text-align: center;
        font-size: 13px;
        margin-top: 5px;
        margin-left: 0%;
        margin-right: 0%;
        font-weight: bold;
    }




    .HistoryRange-container1 .HistoryRange1 {
        text-align: center;
        font-size: 13px;
        margin-top: 5px;
        /* margin-left: 25%; */
        /* margin-right: 25%; */
        font-weight: bold;
    }




    .HistoryRange-container2 .HistoryRange2 {
        text-align: center;
        font-size: 13px;
        margin-top: 5px;
        /* margin-left: 25%; */
        /* margin-right: 25%; */
        margin-bottom: 20px;
        font-weight: bold;

    }










    .HistoryRange-container1 .HistoryRange3 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 77px;
        margin-bottom: 25px;
        font-weight: bold;
    }




    .HistoryRange-container1 .HistoryRange4 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 55px;
        margin-bottom: 25px;
        font-weight: bold;




    }




    .HistoryRange-container1 .HistoryRange5 {
         text-align: center;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 0%;
    margin-right: 0%;
    margin-bottom: 25px;
    font-weight: bold;
    }




    .HistoryRange-container1 .HistoryRange6 {
         text-align: center;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 0%;
    margin-right: 0%;
    margin-bottom: 25px;
    font-weight: bold;
    }

    .ibuttonHistory {
        display: flex;
        justify-content: flex-end;
    }

    .instructionButtonHistory {
        display: flex;
        position: static;
        font-weight: 800;
        height: 20px;
        width: 20px;
        color: #efefef;
        margin-top: -1.5em;
        margin-left: 9em;
        cursor: pointer;
        background-color: #000080;
        border: none;
        border-radius: 1em;
        /* background-image: url('aa.png'); */
    }


    .HistoryECGIntervelText {
        font-size: 18px;
        font-weight: bold;
        margin-top: 1em;
        margin-bottom: -2em;
        margin-left: 1em;
    }


    .HistoryECGIntervelText1 {
        font-size: 18px;
        font-weight: bold;
        margin-top: 0em;
        margin-left: 1em;
    }
    .detail-icon
    {
        display:flex;
        position:absolute;
        height:1.5em;
        margin-left:8.5em
    }
    .share-icon{
        display:flex;
        position:absolute;
        height:1.5em;
        margin-left:8.5em;
        margin-top: 2em;
    }

    .HistoryECGgrid-container{
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Two columns */
        grid-template-rows: repeat(2, 1fr);    /* Two rows */
        gap: 10px;

      }
      .Historybioheadergrid {
        width:100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        /* gap: 20px; */
      }

      .HistorybioheaderLeft-item,
      .HistorybioheaderRight-item {
        /* border: 1px solid #ddd; */
        padding:1%;
        margin-left: 10%;
        margin-right: 10%;
        /* text-align: center; */

        /* border-radius: 8px; */
      }

      .historyBoldTextStyle {
        font-weight: bold;
      }
      .history-filter-grid-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        /* Three equal columns */
        gap: 8px;
        /* Adjust the gap as needed */
    }

    .history-filter-grid-item {
        text-align: center;
        flex: 2;
        /* Additional styling for each grid item if necessary */
    }


    .history-filter-grid-item-head {
        text-align: center;
        flex: 1;
        font-weight: bolder;
        margin-left: -1em;
        /* Additional styling for each grid item if necessary */
    }
    .Historypopup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 16em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .HistoryECGIntervalPopup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 15em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .HistoryECGIntervalPopup1 {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 17em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

}


/* For mobile devices (portrait and landscape) */
@media only screen and (max-width: 767px) {
    /* Your CSS styles for mobile devices */
}


/* For tablets and larger mobile devices (landscape) */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    /* Your CSS styles for tablets and larger mobile devices */
}


/* ----------- iPhone 4 and 4S ----------- */

/* Portrait and Landscape */
@media only screen
  and (min-device-width: 320px)
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .HistoryContainer {
        width: 100%;
        /* height: 46.5em; */
        margin-bottom: 3.5em;
        background-color: #e5e5e6;
        border-radius: 7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8.6em;
        bottom: 1em;
        overflow-y: auto;
        /* Enable vertical scrolling */
        max-height: calc(100vh);
        /* Set maximum height to fill the available space */
        min-height: 11em;
        overflow-x: hidden;
    }




    .profileDetailHeader {
        height: 2.6em;
        width: 100%;
        padding: 9px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-bottom: 3px;
        margin-top: 2px;
    }


    .profileDetailHeaderText {
        flex: auto;
        /* margin-left: 1.5em; */
        margin-left: 0em;
    }


    .historyHeader {
        padding: 5px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-top: 5px;
        font-size: 25px;
        width: 97%;
        height: 1.4em;
    }


    .history-rectangle {
        margin-left: 5.5%;
        /* margin-right: -15%; */
        width: 89.5%;
        height: 32%;
        background-color: white;
        /* margin-left: 0em; */
        /* margin-bottom: 2em; */
        margin-top: 0.5em;
        display: flex;
        flex-direction: column;
    }




    .history-filter-rectangle {
        width: 420px;
        height: 45px;
        padding: 2px;
        background-color: white;
        margin-left: 1.4em;
        margin-bottom: 0.5em;
        display: flex;
        flex-direction: column;
    }

    .FilterHeaderTitle {
        width: 90%;
        height: 5%;
        background-color: white;
        text-align: center;
        padding: 10px;
        font-size: 16px;
        margin-left: 1.5em;
        margin-top: 0.5em;
    }


    .filterModalBox{
        position: absolute;
        top: 27em;
        left: 50%;
        height: 27em;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 10px;
        border-radius: 8px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        width: 23em;
    }
    .FilterDropdown {
        text-align: center;
        margin-bottom: 0.5em;
        margin-top: 0.5em;
        /* margin-left: 0em; */
        width: 100%;
    }


    .FilterDropdown .select {
        width: 90%;
        text-align: center;
        padding: 10px;
        font-size: 16px;
    }


    .FilterDropdown .select .paragraphText {
        margin-top: 10px;
        font-weight: bold;
        font-size: 16px;
        color: #333;
    }


    .historyBackBtn {
        /* margin-left: 1.5em; */
        margin-left: 0em;
        background-color: #000080;
        color: white;
        border: none;
        padding: 3px;
        cursor: pointer;
        border-radius: 10px;
        font-weight: bold;
        display: flex;
        align-items: center;
    }






    .history-filter-top-content {
        display: flex;
        margin-right: -5.8em;
        margin-left: 1em;
    }




    .history-filter-top-content1 {
        display: flex;
        margin-right: -4em;
        margin-left: -1.6em;




    }




    .historyPopUpModal {
        position: absolute;
        top: 54%;
        left: 50%;
        height: 32em;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        width: 300px;
    }
    .HistoryECGContainer{
        margin-left: -0.2em;
    }

    .HistoryHeaderTxt {
        margin-right: 5em;
        text-align: center;
        font-size: 20px;
    }




    .vitalDetailsBorder {
        width: 100%;
        height: 2.3em;
        background: navy;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1px;
        margin-bottom: 1em;
    }




    .vitalDetailsModal {
        /* margin: 0; */
        color: white;
        font-size: 13px;
        font-weight: bold;
        display: flex;
        margin-left: 8em;
        /* align-items: center; */
        /* justify-content: center; */
    }


    .historyDateTime {
        height: 2em;
        padding: 7px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-bottom: 10px;
        margin-top: 1px;
    }






    .HistoryRecord-title {
        display: flex;
        font-size: 14px;
        text-align: center;
        margin-right: 25px;
        margin-left: 62px;
        margin-top: 3px;
    }


    .historyModelX {
        background: navy;
        color: white;
        border-color: white;
        font-weight: bold;
        padding: 3px 7px;
        border-radius: 14px;
        font-size: 14px;
        display: flex;
        margin-left: 6em;
    }


    .ECGContainer {
        display: block;
        /* You can adjust this based on the value of 'isModalOpen' */
        background-color: #efefef;
        height: 25.8em;
        /* width: 105%; */
        margin: auto;
        margin-right: -15px;
        /* margin-top: -15px; */
        /* margin-top: 4em; */
        /* margin-bottom: em; */
        /* This appears to be incomplete */
        margin-left: -15px;
        overflow: auto;
        scroll-margin-left: 0;
        scroll-margin-top: 0;
    }








    .HistoryImage-container,
    .HistoryHeader-container,
    .HistoryHeader-container1,
    .HistoryDetails-container,
    .HistoryRange-container,
    .HistoryRange-container1,
    .HistoryRange-container2,
    .HistoryTilte-contaniner {
        display: flex;
        align-items: center;
        justify-content: center;    }






    .HistoryImage1 {
        max-width: 33%;
    /* height: 10%; */
    margin-right:50%;
    margin-left: 50%;
    margin-top: 11px;
    }


    .HistorybloodPressureImage {
        border: #000;
        border-radius: 3px;
        max-width: 25%;
        height: 8%;
        margin-right: 50%;
        margin-left: 50%;
        margin-top: 17px;
    }

    .HistoryImage {
        max-width: 29%;
        height: 10%;
        margin-right: 50%;
        margin-left: 50%;
    }








    .HistoryHeader-title {
        /* display: flex; */
    margin: 0;
    font-size: 14px;
    text-align: center;
    /* margin-right: 25%; */
    /* margin-left: 25%; */
    margin-top: 8px;
    }

    .HistoryHeader-titleoxy {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        /* margin-right: 25%; */
        /* margin-left: 24%; */
        margin-top: 3px;
    }

    .HistoryHeader-titleBT {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        /* margin-right: 25%; */
        /* margin-left: 24%; */
        margin-top: 7px;
    }


    .HistoryHeader-title1 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }




    .HistoryHeader-title2 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 0%;
        margin-left: 0%;
        margin-top: 8px;
    }




    .HistoryDetails-container .HistoryValue {
        background-color: #ffb33a;
        color: white;
        font-size: 14px;
        text-align: center;
        padding: 5px;
        border-radius: 5px;
        margin-left: 0%;
        margin-right: 0%;
        margin-top: 5px;
        width: 95%;
    }




    .HistoryTilte-contaniner .HistoryTilte {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 20%;
        margin-right: 20%;
    }




    .HistoryTilte-contaniner .HistoryTilteT {
        text-align: center;
    font-size: 12px;
    margin-top: 5px;
    margin-left: 0%;
    margin-right: 0%;
    }




    .HistoryContainer-image {
        padding: 15px 10px;
        margin-top: 10px;
        padding-top: 5px;
    }




    .HistoryRange-container .HistoryRange {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 0%;
        margin-right: 0%;
        font-weight: bold;
    }




    .HistoryRange-container1 .HistoryRange1 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 70px;
        margin-right: 25px;
        font-weight: bold;
    }




    .HistoryRange-container2 .HistoryRange2 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 70px;
        margin-right: 36px;
        margin-bottom: 20px;
        font-weight: bold;

    }








    .HistoryRange-container1 .HistoryRange3 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 77px;
        margin-bottom: 25px;
        font-weight: bold;
    }




    .HistoryRange-container1 .HistoryRange4 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 55px;
        margin-bottom: 25px;
        font-weight: bold;




    }




    .HistoryRange-container1 .HistoryRange5 {
         text-align: center;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 0%;
    margin-right: 0%;
    margin-bottom: 25px;
    font-weight: bold;
    }




    .HistoryRange-container1 .HistoryRange6 {
         text-align: center;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 0%;
    margin-right: 0%;
    margin-bottom: 25px;
    font-weight: bold;
    }

    .ibuttonHistory {
        display: flex;
        justify-content: flex-end;
    }

    .instructionButtonHistory {
        display: flex;
        position: static;
        font-weight: 800;
        height: 20px;
        width: 20px;
        color: #efefef;
        margin-top: -1.5em;
        margin-left: 9em;
        cursor: pointer;
        background-color: #000080;
        border: none;
        border-radius: 1em;
        /* background-image: url('aa.png'); */
    }


    .HistoryECGIntervelText {
        font-size: 18px;
        font-weight: bold;
        margin-top: 1em;
        margin-bottom: -2em;
        margin-left: 1em;
    }


    .HistoryECGIntervelText1 {
        font-size: 18px;
        font-weight: bold;
        margin-top: 0em;
        margin-left: 1em;
    }

    .detail-icon
    {
        display:flex;
        position:absolute;
        height:1.5em;
        margin-left:8.5em
    }
    .share-icon{
        display:flex;
        position:absolute;
        height:1.5em;
        margin-left:8.5em;
        margin-top: 2em;
    }
    .HistoryECGgrid-container{
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Two columns */
        grid-template-rows: repeat(2, 1fr);    /* Two rows */
        gap: 10px;

      }
      .Historybioheadergrid {
        width:100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        /* gap: 20px; */
      }

      .HistorybioheaderLeft-item,
      .HistorybioheaderRight-item {
        /* border: 1px solid #ddd; */
        padding:1%;
        margin-left: 10%;
        margin-right: 10%;
        /* text-align: center; */

        /* border-radius: 8px; */
      }

      .historyBoldTextStyle {
        font-weight: bold;
      }

      .history-filter-grid-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        /* Three equal columns */
        gap: 8px;
        /* Adjust the gap as needed */
    }

    .history-filter-grid-item {
        text-align: center;
        flex: 2;
        /* Additional styling for each grid item if necessary */
    }


    .history-filter-grid-item-head {
        text-align: center;
        flex: 1;
        font-weight: bolder;
        margin-left: -1em;
        /* Additional styling for each grid item if necessary */
    }
    .Historypopup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 16em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .HistoryECGIntervalPopup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 15em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .HistoryECGIntervalPopup1 {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 17em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }
}

/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (max-device-height: 896px)and (-webkit-min-device-pixel-ratio: 2) {
    .HistoryContainer {
        width: 100%;
        /* height: 46.5em; */
        margin-bottom: 3.5em;
        background-color: #e5e5e6;
        border-radius: 7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8.6em;
        bottom: 1em;
        overflow-y: auto;
        /* Enable vertical scrolling */
        max-height: 100vh;
        /* Set maximum height to fill the available space */
        min-height: 11em;
        overflow-x: hidden;
    }




    .profileDetailHeader {
        height: 2.6em;
        width: 100%;
        padding: 9px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-bottom: 3px;
        margin-top: 2px;

    }


    .profileDetailHeaderText {
        flex: auto;
        /* margin-left: 1.5em; */
        /* margin-left: em; */
    }
    .profileDetailHeaderText1{
        flex:1;
        margin-right: 0.5em;
        font-size: 15px;
        margin-left: -2em;
    }

    .historyHeader {
        padding: 5px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-top: 5px;
        font-size: 25px;
        width: 97%;
        height: 1.4em;
    }


    .history-rectangle {
        margin-left: 5.5%;
        /* margin-right: -15%; */
        width: 89.5%;
        height: 36%;
        background-color: white;
        /* margin-left: 0em; */
        /* margin-bottom: 2em; */
        margin-top: 0.5em;
        display: flex;
        flex-direction: column;
    }

    .history-filter-rectangle {
        width: 91%;
        height: 45px;
        padding: 2px;
        background-color: white;
        margin-left: 1em;
        margin-bottom: 0.5em;
        display: flex;
        flex-direction: column;
    }
    .FilterHeaderTitle {
        width: 90%;
        height: 6%;
        background-color: white;
        text-align: center;
        padding: 6px;
        font-size: 14px;
        margin-left: 1.5em;
        margin-top: 0.5em;
    }


    .filterModalBox{
        position: absolute;
        top: 16em;
        left: 50%;
        height: 29em;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 10px;
        border-radius: 8px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        width: 75%;
    }

    .FilterDropdown {
        text-align: center;
        margin-bottom: 0.5em;
        margin-top: 0.5em;
        /* margin-left: 0em; */
        width: 100%;
    }


    .FilterDropdown .select {
        width: 90%;
        text-align: center;
        padding: 10px;
        font-size: 16px;
    }


    .FilterDropdown .select .paragraphText {
        margin-top: 10px;
        font-weight: bold;
        font-size: 16px;
        color: #333;
    }


    .historyBackBtn {
        /* margin-left: 1.5em; */
        margin-left: 0em;
        background-color: #000080;
        color: white;
        border: none;
        padding: 3px;
        cursor: pointer;
        border-radius: 10px;
        font-weight: bold;
        display: flex;
        align-items: center;
    }



    .history-top-content {
        display: flex;
        justify-content: space-between;
        padding: 15px;
        font-size: small;
    }


    .history-filter-top-content {
        display: flex;
        margin-right: -5.8em;
        margin-left: 1em;
    }




    .history-filter-top-content1 {
        display: flex;
        margin-right: -4em;
        margin-left: -1.6em;

    }


    .filterModelx{
        background: navy;
        color: white;
        border-color: white;
        font-weight: bold;
        /* padding: 1px 6px; */
        border-radius: 15px;
        font-size: 14px;
        display: flex;
        margin-left: 16em;
        display: flex;
        position: absolute;
        margin-top: -1.4em;
    }


    .historyPopUpModal {
        position: absolute;
        top: 50%;
        left: 50%;
        height: 63%;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        /* width: 300px; */
        width: 75%;

    }

    .HistoryECGContainer{
        margin-left: -0.2em;
    }

    .HistoryHeaderTxt {
        margin-right: 4.5em;
        text-align: center;
        font-size: 20px;
    }




    .vitalDetailsBorder {
        width: 100%;
        height: 2.3em;
        background: navy;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1px;
        margin-bottom: 1em;
    }




    .vitalDetailsModal {
        /* margin: 0; */
        color: white;
        font-size: 13px;
        font-weight: bold;
        display: flex;
        margin-left: 6em;
        /* align-items: center; */
        /* justify-content: center; */
    }


    .historyDateTime {
        height: 2em;
        padding: 7px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-bottom: 10px;
        margin-top: 1px;
    }


    .FilterDropdown-headerTitle-1 {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        margin-right: 0em;
        margin-left: 15%;

    }

    .FilterDropdown-headerTitle-2 {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        margin-right: 0em;
        margin-left: 21%;

    }

    .FilterDropdown-headerTitle-3 {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        margin-right: 0em;
        margin-left: 7%;

    }





    .HistoryRecord-title {
        display: flex;
        font-size: 14px;
        text-align: center;
        margin-right: 10px;
        margin-left: 80px;
        margin-top: 3px;
    }


    .historyModelX {
        background: navy;
        color: white;
        border-color: white;
        font-weight: bold;
        padding: 3px 7px;
        border-radius: 14px;
        font-size: 14px;
        display: flex;
        margin-left: 6em;
    }


    .ECGContainer {
        display: block;
        /* You can adjust this based on the value of 'isModalOpen' */
        background-color: #efefef;
        height: 24em;
        /* width: 105%; */
        margin: auto;
        margin-right: -15px;
        /* margin-top: -15px; */
        /* margin-top: 4em; */
        /* margin-bottom: em; */
        /* This appears to be incomplete */
        margin-left: -15px;
        overflow: auto;
        scroll-margin-left: 0;
        scroll-margin-top: 0;
    }








    .HistoryImage-container,
    .HistoryHeader-container,
    .HistoryHeader-container1,
    .HistoryDetails-container,
    .HistoryRange-container,
    .HistoryRange-container1,
    .HistoryRange-container2,
    .HistoryTilte-contaniner {
        display: flex;
        align-items: center;
        justify-content: center;    }






    .HistoryImage1 {
        max-width: 33%;
    /* height: 10%; */
    margin-right:50%;
    margin-left: 50%;
    margin-top: 11px;
    }

    .HistorybloodPressureImage {
        border: #000;
        border-radius: 3px;
        max-width: 25%;
        height: 8%;
        margin-right: 50%;
        margin-left: 50%;
        margin-top: 17px;
    }


    .HistoryImage {
        max-width: 29%;
        height: 10%;
        margin-right: 50%;
        margin-left: 50%;
    }








    .HistoryHeader-title {
     /* display: flex; */
     margin: 0;
     font-size: 14px;
     text-align: center;
     /* margin-right: 25%; */
     /* margin-left: 25%; */
     margin-top: 8px;
    }

    .HistoryHeader-titleoxy {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        /* margin-right: 25%; */
        /* margin-left: 24%; */
        margin-top: 3px;
    }

    .HistoryHeader-titleBT {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        /* margin-right: 25%; */
        /* margin-left: 24%; */
        margin-top: 7px;
    }


    .HistoryHeader-title1 {
        margin: 0;
    font-size: 14px;
    text-align: center;
    margin-right: 25%;
    margin-left: 25%;
    margin-top: 8px;
    }


    .HistoryHeader-title2 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 0%;
        margin-left: 0%;
        margin-top: 8px;
    }




    .HistoryDetails-container .HistoryValue {
        background-color: #ffb33a;
        color: white;
        font-size: 14px;
        text-align: center;
        padding: 5px;
        border-radius: 5px;
        margin-left: 0%;
        margin-right: 0%;
        margin-top: 5px;
        width: 95%;

    }




    .HistoryTilte-contaniner .HistoryTilte {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 20%;
        margin-right: 20%;
    }




    .HistoryTilte-contaniner .HistoryTilteT {
         text-align: center;
    font-size: 12px;
    margin-top: 5px;
    margin-left: 0%;
    margin-right: 0%;
    }




    .HistoryContainer-image {
        padding: 15px 10px;
        margin-top: 10px;
        padding-top: 5px;
    }




    .HistoryRange-container .HistoryRange {
        text-align: center;
        font-size: 13px;
        margin-top: 5px;
        margin-left: 0%;
        margin-right: 0%;
        font-weight: bold;
    }




    .HistoryRange-container1 .HistoryRange1 {
        text-align: center;
        font-size: 13px;
        margin-top: 5px;
        /* margin-left: 25%; */
        /* margin-right: 25%; */
        font-weight: bold;
    }




    .HistoryRange-container2 .HistoryRange2 {
        text-align: center;
        font-size: 13px;
        margin-top: 5px;
        /* margin-left: 25%; */
        /* margin-right: 25%; */
        margin-bottom: 20px;
        font-weight: bold;

    }


    .HistoryRange-container1 .HistoryRange3 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 77px;
        margin-bottom: 25px;
        font-weight: bold;
    }




    .HistoryRange-container1 .HistoryRange4 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 55px;
        margin-bottom: 25px;
        font-weight: bold;




    }




    .HistoryRange-container1 .HistoryRange5 {
         text-align: center;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 0%;
    margin-right: 0%;
    margin-bottom: 25px;
    font-weight: bold;
    }




    .HistoryRange-container1 .HistoryRange6 {
         text-align: center;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 0%;
    margin-right: 0%;
    margin-bottom: 25px;
    font-weight: bold;
    }

    .ibuttonHistory {
        display: flex;
        justify-content: flex-end;
    }

    .instructionButtonHistory {
        display: flex;
        position: static;
        font-weight: 800;
        height: 20px;
        width: 20px;
        color: #efefef;
        margin-top: -1.5em;
        margin-left: 9em;
        cursor: pointer;
        background-color: #000080;
        border: none;
        border-radius: 1em;
        /* background-image: url('aa.png'); */
    }


    .HistoryECGIntervelText {
        font-size: 18px;
        font-weight: bold;
        margin-top: 1em;
        margin-bottom: -2em;
        margin-left: 1em;
    }


    .HistoryECGIntervelText1 {
        font-size: 18px;
        font-weight: bold;
        margin-top: 0em;
        margin-left: 1em;
    }

    .detail-icon
    {
        display:flex;
        position:absolute;
        height:1.5em;
        margin-left:8.5em
    }
    .share-icon{
        display:flex;
        position:absolute;
        height:1.5em;
        margin-left:8.5em;
        margin-top: 2em;
    }

    .HistoryECGgrid-container{
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Two columns */
        grid-template-rows: repeat(2, 1fr);    /* Two rows */
        gap: 10px;

      }
      .Historybioheadergrid {
        width:100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        /* gap: 20px; */
      }

      .HistorybioheaderLeft-item,
      .HistorybioheaderRight-item {
        /* border: 1px solid #ddd; */
        padding:1%;
        margin-left: 10%;
        margin-right: 10%;
        /* text-align: center; */

        /* border-radius: 8px; */
      }

      .historyBoldTextStyle {
        font-weight: bold;
      }

      .history-filter-grid-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        /* Three equal columns */
        gap: 8px;
        /* Adjust the gap as needed */
    }

    .history-filter-grid-item {
        text-align: center;
        flex: 2;
        /* Additional styling for each grid item if necessary */
    }


    .history-filter-grid-item-head {
        text-align: center;
        flex: 1;
        font-weight: bolder;
        margin-left: -1em;
        /* Additional styling for each grid item if necessary */
    }
    .Historypopup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 16em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .HistoryECGIntervalPopup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 15em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .HistoryECGIntervalPopup1 {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 17em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

}

/*----------------- Samsung galaxy 8+  -----------*/

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (max-device-height: 740px)and (-webkit-min-device-pixel-ratio: 2) {
    .HistoryContainer {
        width: 100%;
        /* height: 46.5em; */
        margin-bottom: 3.5em;
        background-color: #e5e5e6;
        border-radius: 7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8.6em;
        bottom: 1em;
        overflow-y: auto;
        /* Enable vertical scrolling */
        max-height: 100vh;
        /* Set maximum height to fill the available space */
        min-height: 11em;
        overflow-x: hidden;
    }




    .profileDetailHeader {
        height: 2.6em;
        width: 100%;
        padding: 9px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-bottom: 3px;
        margin-top: 2px;

    }


    .profileDetailHeaderText {
        flex: auto;
        /* margin-left: 1.5em; */
        /* margin-left: em; */
    }
    .profileDetailHeaderText1{
        flex:1;
        margin-right: 0.5em;
        font-size: 15px;
        margin-left: -2em;
    }

    .historyHeader {
        padding: 5px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-top: 5px;
        font-size: 25px;
        width: 97%;
        height: 1.4em;
    }


    .history-rectangle {
        margin-left: 5.5%;
        /* margin-right: -15%; */
        width: 89.5%;
        height: 36%;
        background-color: white;
        /* margin-left: 0em; */
        /* margin-bottom: 2em; */
        margin-top: 0.5em;
        display: flex;
        flex-direction: column;
    }

    .history-filter-rectangle {
        width: 91%;
        height: 45px;
        padding: 2px;
        background-color: white;
        margin-left: 1em;
        margin-bottom: 0.5em;
        display: flex;
        flex-direction: column;
    }
    .FilterHeaderTitle {
        width: 90%;
        height: 6%;
        background-color: white;
        text-align: center;
        padding: 6px;
        font-size: 14px;
        margin-left: 1.5em;
        margin-top: 0.5em;
    }


    .filterModalBox{
        position: absolute;
        top: 16em;
        left: 50%;
        height: 29em;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 10px;
        border-radius: 8px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        width: 75%;
    }

    .FilterDropdown {
        text-align: center;
        margin-bottom: 0.5em;
        margin-top: 0.5em;
        /* margin-left: 0em; */
        width: 100%;
    }


    .FilterDropdown .select {
        width: 90%;
        text-align: center;
        padding: 10px;
        font-size: 16px;
    }


    .FilterDropdown .select .paragraphText {
        margin-top: 10px;
        font-weight: bold;
        font-size: 16px;
        color: #333;
    }


    .historyBackBtn {
        /* margin-left: 1.5em; */
        margin-left: 0em;
        background-color: #000080;
        color: white;
        border: none;
        padding: 3px;
        cursor: pointer;
        border-radius: 10px;
        font-weight: bold;
        display: flex;
        align-items: center;
    }



    .history-top-content {
        display: flex;
        justify-content: space-between;
        padding: 15px;
        font-size: small;
    }


    .history-filter-top-content {
        display: flex;
        margin-right: -5.8em;
        margin-left: 1em;
    }




    .history-filter-top-content1 {
        display: flex;
        margin-right: -4em;
        margin-left: -1.6em;

    }


    .filterModelx{
        background: navy;
        color: white;
        border-color: white;
        font-weight: bold;
        /* padding: 1px 6px; */
        border-radius: 15px;
        font-size: 14px;
        display: flex;
        margin-left: 16em;
        display: flex;
        position: absolute;
        margin-top: -1.4em;
    }


    .historyPopUpModal {
        position: absolute;
        top: 56%;
        left: 50%;
        height: 67%;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        /* width: 300px; */
        width: 75%;

    }

    .HistoryECGContainer{
        margin-left: -0.2em;
    }
    .HistoryHeaderTxt {
        margin-right: 4.5em;
        text-align: center;
        font-size: 20px;
    }




    .vitalDetailsBorder {
        width: 100%;
        height: 2.3em;
        background: navy;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1px;
        margin-bottom: 1em;
    }




    .vitalDetailsModal {
        /* margin: 0; */
        color: white;
        font-size: 13px;
        font-weight: bold;
        display: flex;
        margin-left: 6em;
        /* align-items: center; */
        /* justify-content: center; */
    }


    .historyDateTime {
        height: 2em;
        padding: 7px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-bottom: 10px;
        margin-top: 1px;
    }


    .FilterDropdown-headerTitle-1 {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        margin-right: 0em;
        margin-left: 15%;

    }

    .FilterDropdown-headerTitle-2 {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        margin-right: 0em;
        margin-left: 21%;

    }

    .FilterDropdown-headerTitle-3 {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        margin-right: 0em;
        margin-left: 7%;

    }





    .HistoryRecord-title {
        display: flex;
        font-size: 14px;
        text-align: center;
        margin-right: 10px;
        margin-left: 80px;
        margin-top: 3px;
    }


    .historyModelX {
        background: navy;
        color: white;
        border-color: white;
        font-weight: bold;
        padding: 3px 7px;
        border-radius: 14px;
        font-size: 14px;
        display: flex;
        margin-left: 6em;
    }


    .ECGContainer {
        display: block;
        /* You can adjust this based on the value of 'isModalOpen' */
        background-color: #efefef;
        height: 25em;
        /* width: 105%; */
        margin: auto;
        margin-right: -15px;
        /* margin-top: -15px; */
        /* margin-top: 4em; */
        /* margin-bottom: em; */
        /* This appears to be incomplete */
        margin-left: -15px;
        overflow: auto;
        scroll-margin-left: 0;
        scroll-margin-top: 0;
    }








    .HistoryImage-container,
    .HistoryHeader-container,
    .HistoryHeader-container1,
    .HistoryDetails-container,
    .HistoryRange-container,
    .HistoryRange-container1,
    .HistoryRange-container2,
    .HistoryTilte-contaniner {
        display: flex;
        align-items: center;
        justify-content: center;    }






    .HistoryImage1 {
        max-width: 33%;
    /* height: 10%; */
    margin-right:50%;
    margin-left: 50%;
    margin-top: 11px;
    }

    .HistorybloodPressureImage {
        border: #000;
        border-radius: 3px;
        max-width: 25%;
        height: 8%;
        margin-right: 50%;
        margin-left: 50%;
        margin-top: 17px;
    }


    .HistoryImage {
        max-width: 29%;
        height: 10%;
        margin-right: 50%;
        margin-left: 50%;
    }








    .HistoryHeader-title {
     /* display: flex; */
     margin: 0;
     font-size: 14px;
     text-align: center;
     /* margin-right: 25%; */
     /* margin-left: 25%; */
     margin-top: 8px;
    }

    .HistoryHeader-titleoxy {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        /* margin-right: 25%; */
        /* margin-left: 24%; */
        margin-top: 3px;
    }

    .HistoryHeader-titleBT {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        /* margin-right: 25%; */
        /* margin-left: 24%; */
        margin-top: 7px;
    }


    .HistoryHeader-title1 {
        margin: 0;
    font-size: 14px;
    text-align: center;
    margin-right: 25%;
    margin-left: 25%;
    margin-top: 8px;
    }


    .HistoryHeader-title2 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 0%;
        margin-left: 0%;
        margin-top: 8px;
    }




    .HistoryDetails-container .HistoryValue {
        background-color: #ffb33a;
        color: white;
        font-size: 14px;
        text-align: center;
        padding: 5px;
        border-radius: 5px;
        margin-left: 0%;
        margin-right: 0%;
        margin-top: 5px;
        width: 95%;

    }




    .HistoryTilte-contaniner .HistoryTilte {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 20%;
        margin-right: 20%;
    }




    .HistoryTilte-contaniner .HistoryTilteT {
         text-align: center;
    font-size: 12px;
    margin-top: 5px;
    margin-left: 0%;
    margin-right: 0%;
    }




    .HistoryContainer-image {
        padding: 15px 10px;
        margin-top: 10px;
        padding-top: 5px;
    }




    .HistoryRange-container .HistoryRange {
        text-align: center;
        font-size: 13px;
        margin-top: 5px;
        margin-left: 0%;
        margin-right: 0%;
        font-weight: bold;
    }




    .HistoryRange-container1 .HistoryRange1 {
        text-align: center;
        font-size: 13px;
        margin-top: 5px;
        /* margin-left: 25%; */
        /* margin-right: 25%; */
        font-weight: bold;
    }




    .HistoryRange-container2 .HistoryRange2 {
        text-align: center;
        font-size: 13px;
        margin-top: 5px;
        /* margin-left: 25%; */
        /* margin-right: 25%; */
        margin-bottom: 20px;
        font-weight: bold;

    }


    .HistoryRange-container1 .HistoryRange3 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 77px;
        margin-bottom: 25px;
        font-weight: bold;
    }




    .HistoryRange-container1 .HistoryRange4 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 55px;
        margin-bottom: 25px;
        font-weight: bold;




    }




    .HistoryRange-container1 .HistoryRange5 {
         text-align: center;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 0%;
    margin-right: 0%;
    margin-bottom: 25px;
    font-weight: bold;
    }




    .HistoryRange-container1 .HistoryRange6 {
         text-align: center;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 0%;
    margin-right: 0%;
    margin-bottom: 25px;
    font-weight: bold;
    }

    .ibuttonHistory {
        display: flex;
        justify-content: flex-end;
    }

    .instructionButtonHistory {
        display: flex;
        position: static;
        font-weight: 800;
        height: 20px;
        width: 20px;
        color: #efefef;
        margin-top: -1.5em;
        margin-left: 9em;
        cursor: pointer;
        background-color: #000080;
        border: none;
        border-radius: 1em;
        /* background-image: url('aa.png'); */
    }


    .HistoryECGIntervelText {
        font-size: 18px;
        font-weight: bold;
        margin-top: 1em;
        margin-bottom: -2em;
        margin-left: 1em;
    }


    .HistoryECGIntervelText1 {
        font-size: 18px;
        font-weight: bold;
        margin-top: 0em;
        margin-left: 1em;
    }

    .detail-icon
    {
        display:flex;
        position:absolute;
        height:1.5em;
        margin-left:8.5em
    }
    .share-icon{
        display:flex;
        position:absolute;
        height:1.5em;
        margin-left:8.5em;
        margin-top: 2em;
    }

    .HistoryECGgrid-container{
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Two columns */
        grid-template-rows: repeat(2, 1fr);    /* Two rows */
        gap: 10px;

      }
      .Historybioheadergrid {
        width:100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        /* gap: 20px; */
      }

      .HistorybioheaderLeft-item,
      .HistorybioheaderRight-item {
        /* border: 1px solid #ddd; */
        padding:1%;
        margin-left: 10%;
        margin-right: 10%;
        /* text-align: center; */

        /* border-radius: 8px; */
      }

      .historyBoldTextStyle {
        font-weight: bold;
      }

      .history-filter-grid-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        /* Three equal columns */
        gap: 8px;
        /* Adjust the gap as needed */
    }

    .history-filter-grid-item {
        text-align: center;
        flex: 2;
        /* Additional styling for each grid item if necessary */
    }


    .history-filter-grid-item-head {
        text-align: center;
        flex: 1;
        font-weight: bolder;
        margin-left: -1em;
        /* Additional styling for each grid item if necessary */
    }
    .Historypopup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 16em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .HistoryECGIntervalPopup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 15em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .HistoryECGIntervalPopup1 {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 17em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

}


/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (max-device-height: 667px)and (-webkit-min-device-pixel-ratio: 2) {
    .HistoryContainer {
        width: 100%;
        /* height: 46.5em; */
        margin-bottom: 3.5em;
        background-color: #e5e5e6;
        border-radius: 7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8.6em;
        bottom: 1em;
        overflow-y: auto;
        /* Enable vertical scrolling */
        max-height: calc(100vh);
        /* Set maximum height to fill the available space */
        min-height: 11em;
        overflow-x: hidden;
    }


    .filterModelx{
        background: navy;
        color: white;
        border-color: white;
        font-weight: bold;
        /* padding: 1px 6px; */
        border-radius: 15px;
        font-size: 14px;
        display: flex;
        margin-left: 23em;
        display: flex;
        position: absolute;
        margin-top: -1.4em;
    }

    .profileDetailHeader {
        height: 2.6em;
        width: 100%;
        padding: 9px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-bottom: 3px;
        margin-top: 2px;
    }


    .profileDetailHeaderText {
        flex: auto;
        /* margin-left: 1.5em; */
        margin-left: 0em;
    }


    .historyHeader {
        padding: 5px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-top: 5px;
        font-size: 25px;
        width: 97%;
        height: 1.4em;
    }


    .history-rectangle {
        margin-left: 5.5%;
        /* margin-right: -15%; */
        width: 89.5%;
        height: 40%;
        background-color: white;
        /* margin-left: 0em; */
        /* margin-bottom: 2em; */
        margin-top: 0.5em;
        display: flex;
        flex-direction: column;
    }




    .history-filter-rectangle {
        width: 90%;
        height: 45px;
        padding: 2px;
        background-color: white;
        margin-left: 1.2em;
        margin-bottom: 0.5em;
        display: flex;
        flex-direction: column;
    }

    .FilterHeaderTitle {
        width: 90%;
        height: 5%;
        background-color: white;
        text-align: center;
        padding: 0px;
        font-size: 16px;
        margin-left: 1.5em;
        margin-top: 0.5em;
    }


    .filterModalBox{
        position: absolute;
        top: 27em;
        left: 50%;
        height: 29.5em;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 10px;
        border-radius: 8px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        width: 23em;
    }

    .FilterDropdown {
        text-align: center;
        margin-bottom: 0.5em;
        margin-top: 0.5em;
        /* margin-left: 0em; */
        width: 100%;
    }


    .FilterDropdown .select {
        width: 90%;
        text-align: center;
        padding: 10px;
        font-size: 16px;
    }


    .FilterDropdown .select .paragraphText {
        margin-top: 10px;
        font-weight: bold;
        font-size: 16px;
        color: #333;
    }

    .FilterDropdown-headerTitle {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        justify-content: center;
        margin-right: 1.3em;
        margin-left: 3.8em;
    }

    .FilterDropdown-headerTitle1 {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        margin-right: 0em;
        margin-left: 1em;
        padding-left: 4.7em;
    }
    .historyBackBtn {
        /* margin-left: 1.5em; */
        margin-left: 0em;
        background-color: #000080;
        color: white;
        border: none;
        padding: 3px;
        cursor: pointer;
        border-radius: 10px;
        font-weight: bold;
        display: flex;
        align-items: center;
    }






    .history-filter-top-content {
        display: flex;
        margin-right: -5.8em;
        margin-left: 1em;
    }




    .history-filter-top-content1 {
        display: flex;
        margin-right: -4em;
        margin-left: -1.6em;




    }




    .historyPopUpModal {
        position: absolute;
        top: 59%;
        left: 50%;
        height: 32em;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        width: 300px;
    }
    .HistoryECGContainer{
        margin-left: -0.2em;
    }

    .HistoryHeaderTxt {
        margin-right: 5em;
        text-align: center;
        font-size: 20px;
    }




    .vitalDetailsBorder {
        width: 100%;
        height: 2.3em;
        background: navy;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1px;
        margin-bottom: 1em;
    }




    .vitalDetailsModal {
        /* margin: 0; */
        color: white;
        font-size: 13px;
        font-weight: bold;
        display: flex;
        margin-left: 8em;
        /* align-items: center; */
        /* justify-content: center; */
    }


    .historyDateTime {
        height: 2em;
        padding: 7px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-bottom: 10px;
        margin-top: 1px;
    }






    .HistoryRecord-title {
        display: flex;
        font-size: 14px;
        text-align: center;
        margin-right: 25px;
        margin-left: 62px;
        margin-top: 3px;
    }


    .historyModelX {
        background: navy;
        color: white;
        border-color: white;
        font-weight: bold;
        padding: 3px 7px;
        border-radius: 14px;
        font-size: 14px;
        display: flex;
        margin-left: 6em;
    }


    .ECGContainer {
        display: block;
        /* You can adjust this based on the value of 'isModalOpen' */
        background-color: #efefef;
        height: 25.8em;
        /* width: 105%; */
        margin: auto;
        margin-right: -15px;
        /* margin-top: -15px; */
        /* margin-top: 4em; */
        /* margin-bottom: em; */
        /* This appears to be incomplete */
        margin-left: -15px;
        overflow: auto;
        scroll-margin-left: 0;
        scroll-margin-top: 0;
    }








    .HistoryImage-container,
    .HistoryHeader-container,
    .HistoryHeader-container1,
    .HistoryDetails-container,
    .HistoryRange-container,
    .HistoryRange-container1,
    .HistoryRange-container2,
    .HistoryTilte-contaniner {
        display: flex;
        align-items: center;
        justify-content: center;    }






    .HistoryImage1 {
        max-width: 33%;
        /* height: 10%; */
        margin-right:50%;
        margin-left: 50%;
        margin-top: 11px;
    }


    .HistorybloodPressureImage {
        border: #000;
        border-radius: 3px;
        max-width: 25%;
        height: 8%;
        margin-right: 50%;
        margin-left: 50%;
        margin-top: 17px;
    }

    .HistoryImage {
        max-width: 29%;
        height: 10%;
        margin-right: 50%;
        margin-left: 50%;
    }








    .HistoryHeader-title {
      /* display: flex; */
    margin: 0;
    font-size: 14px;
    text-align: center;
    /* margin-right: 25%; */
    /* margin-left: 25%; */
    margin-top: 8px;
    }


    .HistoryHeader-titleoxy {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        /* margin-right: 25%; */
        /* margin-left: 24%; */
        margin-top: 3px;
    }

    .HistoryHeader-titleBT {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        /* margin-right: 25%; */
        /* margin-left: 24%; */
        margin-top: 7px;
    }

    .HistoryHeader-title1 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }




    .HistoryHeader-title2 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 0%;
        margin-left: 0%;
        margin-top: 8px;
    }




    .HistoryDetails-container .HistoryValue {
        background-color: #ffb33a;
        color: white;
        font-size: 14px;
        text-align: center;
        padding: 5px;
        border-radius: 5px;
        margin-left: 0%;
        margin-right: 0%;
        margin-top: 5px;
        width: 95%;

    }




    .HistoryTilte-contaniner .HistoryTilte {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 20%;
        margin-right: 20%;
    }




    .HistoryTilte-contaniner .HistoryTilteT {
         text-align: center;
    font-size: 12px;
    margin-top: 5px;
    margin-left: 0%;
    margin-right: 0%;
    }




    .HistoryContainer-image {
        padding: 15px 10px;
        margin-top: 10px;
        padding-top: 5px;
    }




    .HistoryRange-container .HistoryRange {
        text-align: center;
        font-size: 13px;
        margin-top: 5px;
        margin-left: 0%;
        margin-right: 0%;
        font-weight: bold;
    }




    .HistoryRange-container1 .HistoryRange1 {
        text-align: center;
        font-size: 13px;
        margin-top: 5px;
        /* margin-left: 25%; */
        /* margin-right: 25%; */
        font-weight: bold;
    }




    .HistoryRange-container2 .HistoryRange2 {
        text-align: center;
        font-size: 13px;
        margin-top: 5px;
        /* margin-left: 25%; */
        /* margin-right: 25%; */
        margin-bottom: 20px;
        font-weight: bold;

    }










    .HistoryRange-container1 .HistoryRange3 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 77px;
        margin-bottom: 25px;
        font-weight: bold;
    }




    .HistoryRange-container1 .HistoryRange4 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 55px;
        margin-bottom: 25px;
        font-weight: bold;




    }




    .HistoryRange-container1 .HistoryRange5 {
         text-align: center;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 0%;
    margin-right: 0%;
    margin-bottom: 25px;
    font-weight: bold;
    }




    .HistoryRange-container1 .HistoryRange6 {
         text-align: center;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 0%;
    margin-right: 0%;
    margin-bottom: 25px;
    font-weight: bold;
    }

    .ibuttonHistory {
        display: flex;
        justify-content: flex-end;
    }

    .instructionButtonHistory {
        display: flex;
        position: static;
        font-weight: 800;
        height: 20px;
        width: 20px;
        color: #efefef;
        margin-top: -1.5em;
        margin-left: 9em;
        cursor: pointer;
        background-color: #000080;
        border: none;
        border-radius: 1em;
        /* background-image: url('aa.png'); */
    }



    .HistoryECGIntervelText {
        font-size: 18px;
        font-weight: bold;
        margin-top: 1em;
        margin-bottom: -2em;
        margin-left: 1em;
    }


    .HistoryECGIntervelText1 {
        font-size: 18px;
        font-weight: bold;
        margin-top: 0em;
        margin-left: 1em;
    }

    .detail-icon
    {
        display:flex;
        position:absolute;
        height:1.5em;
        margin-left:8.5em
    }

    .share-icon{
        display:flex;
        position:absolute;
        height:1.5em;
        margin-left:8.5em;
        margin-top: 2em;
    }

    .HistoryECGgrid-container{
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Two columns */
        grid-template-rows: repeat(2, 1fr);    /* Two rows */
        gap: 10px;

      }
      .Historybioheadergrid {
        width:100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        /* gap: 20px; */
      }

      .HistorybioheaderLeft-item,
      .HistorybioheaderRight-item {
        /* border: 1px solid #ddd; */
        padding:1%;
        margin-left: 10%;
        margin-right: 10%;
        /* text-align: center; */

        /* border-radius: 8px; */
      }

      .historyBoldTextStyle {
        font-weight: bold;
      }

      .history-filter-grid-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        /* Three equal columns */
        gap: 8px;
        /* Adjust the gap as needed */
    }

    .history-filter-grid-item {
        text-align: center;
        flex: 2;
        /* Additional styling for each grid item if necessary */
    }


    .history-filter-grid-item-head {
        text-align: center;
        flex: 1;
        font-weight: bolder;
        margin-left: -1em;
        /* Additional styling for each grid item if necessary */
    }
    .Historypopup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 16em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .HistoryECGIntervalPopup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 15em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .HistoryECGIntervalPopup1 {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 17em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }
}


/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait and Landscape */
@media only screen
  and (min-device-width: 414px)
  and (max-device-width: 736px)
  and (-webkit-min-device-pixel-ratio: 3) {
    .HistoryContainer {
        width: 100%;
        /* height: 46.5em; */
        margin-bottom: 3.5em;
        background-color: #e5e5e6;
        border-radius: 7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8.6em;
        bottom: 1em;
        overflow-y: auto;
        /* Enable vertical scrolling */
        max-height: calc(100vh);
        /* Set maximum height to fill the available space */
        min-height: 11em;
        overflow-x: hidden;
    }




    .profileDetailHeader {
        height: 2.6em;
        width: 100%;
        padding: 9px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-bottom: 3px;
        margin-top: 2px;
    }


    .profileDetailHeaderText {
        flex: auto;
        /* margin-left: 1.5em; */
        margin-left: 0em;
    }


    .historyHeader {
        padding: 5px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-top: 5px;
        font-size: 25px;
        width: 97%;
        height: 1.4em;
    }


    .history-rectangle {
        margin-left: 5.5%;
        /* margin-right: -15%; */
        width: 89.5%;
        height: 39%;
        background-color: white;
        /* margin-left: 0em; */
        /* margin-bottom: 2em; */
        margin-top: 0.5em;
        display: flex;
        flex-direction: column;
    }




    .history-filter-rectangle {
        width: 420px;
        height: 45px;
        padding: 2px;
        background-color: white;
        margin-left: 1.4em;
        margin-bottom: 0.5em;
        display: flex;
        flex-direction: column;
    }

.FilterHeaderTitle {
    width: 90%;
    height: 5%;
    background-color: white;
    text-align: center;
    padding: 10px;
    font-size: 16px;
    margin-left: 1.5em;
    margin-top: 0.5em;
}


.filterModalBox{
    position: absolute;
    top: 27em;
    left: 50%;
    height: 27em;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    width: 23em;
}

    .FilterDropdown {
        text-align: center;
        margin-bottom: 0.5em;
        margin-top: 0.5em;
        /* margin-left: 0em; */
        width: 100%;
    }


    .FilterDropdown .select {
        width: 90%;
        text-align: center;
        padding: 10px;
        font-size: 16px;
    }


    .FilterDropdown .select .paragraphText {
        margin-top: 10px;
        font-weight: bold;
        font-size: 16px;
        color: #333;
    }


    .historyBackBtn {
        /* margin-left: 1.5em; */
        margin-left: 0em;
        background-color: #000080;
        color: white;
        border: none;
        padding: 3px;
        cursor: pointer;
        border-radius: 10px;
        font-weight: bold;
        display: flex;
        align-items: center;
    }






    .history-filter-top-content {
        display: flex;
        margin-right: -5.8em;
        margin-left: 1em;
    }




    .history-filter-top-content1 {
        display: flex;
        margin-right: -4em;
        margin-left: -1.6em;




    }




    .historyPopUpModal {
        position: absolute;
        top: 54%;
        left: 50%;
        height: 32em;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        width: 300px;
    }
    .HistoryECGContainer{
        margin-left: -0.2em;
    }

    .HistoryHeaderTxt {
        margin-right: 5em;
        text-align: center;
        font-size: 20px;
    }




    .vitalDetailsBorder {
        width: 100%;
        height: 2.3em;
        background: navy;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1px;
        margin-bottom: 1em;
    }




    .vitalDetailsModal {
        /* margin: 0; */
        color: white;
        font-size: 13px;
        font-weight: bold;
        display: flex;
        margin-left: 8em;
        /* align-items: center; */
        /* justify-content: center; */
    }


    .historyDateTime {
        height: 2em;
        padding: 7px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-bottom: 10px;
        margin-top: 1px;
    }






    .HistoryRecord-title {
        display: flex;
        font-size: 14px;
        text-align: center;
        margin-right: 25px;
        margin-left: 62px;
        margin-top: 3px;
    }


    .historyModelX {
        background: navy;
        color: white;
        border-color: white;
        font-weight: bold;
        padding: 3px 7px;
        border-radius: 14px;
        font-size: 14px;
        display: flex;
        margin-left: 6em;
    }


    .ECGContainer {
        display: block;
        /* You can adjust this based on the value of 'isModalOpen' */
        background-color: #efefef;
        height: 25.8em;
        /* width: 105%; */
        margin: auto;
        margin-right: -15px;
        /* margin-top: -15px; */
        /* margin-top: 4em; */
        /* margin-bottom: em; */
        /* This appears to be incomplete */
        margin-left: -15px;
        overflow: auto;
        scroll-margin-left: 0;
        scroll-margin-top: 0;
    }








    .HistoryImage-container,
    .HistoryHeader-container,
    .HistoryHeader-container1,
    .HistoryDetails-container,
    .HistoryRange-container,
    .HistoryRange-container1,
    .HistoryRange-container2,
    .HistoryTilte-contaniner {
        display: flex;
        align-items: center;
        justify-content: center;    }






    .HistoryImage1 {
        max-width: 33%;
        /* height: 10%; */
        margin-right:50%;
        margin-left: 50%;
        margin-top: 11px;
    }


    .HistorybloodPressureImage {
        border: #000;
        border-radius: 3px;
        max-width: 25%;
        height: 8%;
        margin-right: 50%;
        margin-left: 50%;
        margin-top: 17px;
    }

    .HistoryImage {
        max-width: 29%;
        height: 10%;
        margin-right: 50%;
        margin-left: 50%;
    }








    .HistoryHeader-title {
         /* display: flex; */
    margin: 0;
    font-size: 14px;
    text-align: center;
    /* margin-right: 25%; */
    /* margin-left: 25%; */
    margin-top: 8px;
    }

    .HistoryHeader-titleoxy {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        /* margin-right: 25%; */
        /* margin-left: 24%; */
        margin-top: 3px;
    }

    .HistoryHeader-titleBT {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        /* margin-right: 25%; */
        /* margin-left: 24%; */
        margin-top: 7px;
    }


    .HistoryHeader-title1 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }




    .HistoryHeader-title2 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 0%;
        margin-left: 0%;
        margin-top: 8px;
    }




    .HistoryDetails-container .HistoryValue {
        background-color: #ffb33a;
        color: white;
        font-size: 14px;
        text-align: center;
        padding: 5px;
        border-radius: 5px;
        margin-left: 0%;
        margin-right: 0%;
        margin-top: 5px;
        width: 95%;

    }




    .HistoryTilte-contaniner .HistoryTilte {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 20%;
        margin-right: 20%;
    }




    .HistoryTilte-contaniner .HistoryTilteT {
         text-align: center;
    font-size: 12px;
    margin-top: 5px;
    margin-left: 0%;
    margin-right: 0%;
    }




    .HistoryContainer-image {
        padding: 15px 10px;
        margin-top: 10px;
        padding-top: 5px;
    }




    .HistoryRange-container .HistoryRange {
        text-align: center;
        font-size: 13px;
        margin-top: 5px;
        margin-left: 0%;
        margin-right: 0%;
        font-weight: bold;
    }




    .HistoryRange-container1 .HistoryRange1 {
        text-align: center;
        font-size: 13px;
        margin-top: 5px;
        /* margin-left: 25%; */
        /* margin-right: 25%; */
        font-weight: bold;
    }




    .HistoryRange-container2 .HistoryRange2 {
        text-align: center;
        font-size: 13px;
        margin-top: 5px;
        /* margin-left: 25%; */
        /* margin-right: 25%; */
        margin-bottom: 20px;
        font-weight: bold;

    }







    .HistoryRange-container1 .HistoryRange3 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 77px;
        margin-bottom: 25px;
        font-weight: bold;
    }




    .HistoryRange-container1 .HistoryRange4 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 55px;
        margin-bottom: 25px;
        font-weight: bold;




    }




    .HistoryRange-container1 .HistoryRange5 {
         text-align: center;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 0%;
    margin-right: 0%;
    margin-bottom: 25px;
    font-weight: bold;
    }




    .HistoryRange-container1 .HistoryRange6 {
         text-align: center;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 0%;
    margin-right: 0%;
    margin-bottom: 25px;
    font-weight: bold;
    }

    .ibuttonHistory {
        display: flex;
        justify-content: flex-end;
    }

    .instructionButtonHistory {
        display: flex;
        position: static;
        font-weight: 800;
        height: 20px;
        width: 20px;
        color: #efefef;
        margin-top: -1.5em;
        margin-left: 9em;
        cursor: pointer;
        background-color: #000080;
        border: none;
        border-radius: 1em;
        /* background-image: url('aa.png'); */
    }



    .HistoryECGIntervelText {
        font-size: 18px;
        font-weight: bold;
        margin-top: 1em;
        margin-bottom: -2em;
        margin-left: 1em;
    }


    .HistoryECGIntervelText1 {
        font-size: 18px;
        font-weight: bold;
        margin-top: 0em;
        margin-left: 1em;
    }

    .detail-icon
    {
        display:flex;
        position:absolute;
        height:1.5em;
        margin-left:8.5em
    }

    .share-icon{
        display:flex;
        position:absolute;
        height:1.5em;
        margin-left:8.5em;
        margin-top: 2em;
    }
    .HistoryECGgrid-container{
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Two columns */
        grid-template-rows: repeat(2, 1fr);    /* Two rows */
        gap: 10px;

      }

      .Historybioheadergrid {
        width:100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        /* gap: 20px; */
      }

      .HistorybioheaderLeft-item,
      .HistorybioheaderRight-item {
        /* border: 1px solid #ddd; */
        padding:1%;
        margin-left: 10%;
        margin-right: 10%;
        /* text-align: center; */

        /* border-radius: 8px; */
      }

      .historyBoldTextStyle {
        font-weight: bold;
      }

      .history-filter-grid-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        /* Three equal columns */
        gap: 8px;
        /* Adjust the gap as needed */
    }

    .history-filter-grid-item {
        text-align: center;
        flex: 2;
        /* Additional styling for each grid item if necessary */
    }


    .history-filter-grid-item-head {
        text-align: center;
        flex: 1;
        font-weight: bolder;
        margin-left: -1em;
        /* Additional styling for each grid item if necessary */
    }
    .Historypopup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 16em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .HistoryECGIntervalPopup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 15em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .HistoryECGIntervalPopup1 {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 17em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }
}

/* ----------- iPhone XS MAX ----------- */

/* Portrait and Landscape */
@media only screen
  and (min-device-width: 375px)
  and (max-device-width: 812px)
  and (-webkit-min-device-pixel-ratio: 3) {
    .HistoryContainer {
        width: 100%;
        /* height: 46.5em; */
        margin-bottom: 3.5em;
        background-color: #e5e5e6;
        border-radius: 7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8.6em;
        bottom: 1em;
        overflow-y: auto;
        /* Enable vertical scrolling */
        max-height: calc(100vh);
        /* Set maximum height to fill the available space */
        min-height: 11em;
        overflow-x: hidden;
    }




    .profileDetailHeader {
        height: 2.6em;
        width: 100%;
        padding: 9px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-bottom: 3px;
        margin-top: 2px;
    }


    .profileDetailHeaderText {
        flex: auto;
        /* margin-left: 1.5em; */
        margin-left: 0em;
    }


    .historyHeader {
        padding: 5px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-top: 5px;
        font-size: 25px;
        width: 97%;
        height: 1.4em;
    }


    .history-rectangle {
        margin-left: 5.5%;
        /* margin-right: -15%; */
        width: 89.5%;
        height: 31%;
        background-color: white;
        /* margin-left: 0em; */
        /* margin-bottom: 2em; */
        margin-top: 0.5em;
        display: flex;
        flex-direction: column;
    }




    .history-filter-rectangle {
        width: 420px;
        height: 45px;
        padding: 2px;
        background-color: white;
        margin-left: 1.4em;
        margin-bottom: 0.5em;
        display: flex;
        flex-direction: column;
    }
    .FilterHeaderTitle {
        width: 90%;
        height: 6%;
        background-color: white;
        text-align: center;
        padding: 2.5px;
        font-size: 16px;
        margin-left: 1.5em;
        margin-top: 0.5em;
    }


    .filterModalBox{
        position: absolute;
        top: 27em;
        left: 50%;
        height: 29.5em;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 10px;
        border-radius: 8px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        width: 23em;
    }

    .FilterDropdown {
        text-align: center;
        margin-bottom: 0.5em;
        margin-top: 0.5em;
        /* margin-left: 0em; */
        width: 100%;
    }


    .FilterDropdown .select {
        width: 90%;
        text-align: center;
        padding: 10px;
        font-size: 16px;
    }


    .FilterDropdown .select .paragraphText {
        margin-top: 10px;
        font-weight: bold;
        font-size: 16px;
        color: #333;
    }


    .filterModelx{
        background: navy;
        color: WHITE;
        border-color: white;
        font-weight: bold;
        /* padding: 1px 6px; */
        border-radius: 15px;
        font-size: 14px;
        display: flex;
        margin-left: 23em;
        display: flex;
        position: absolute;
        margin-top: -1.4em;
    }



    .FilterDropdown-headerTitle-1 {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        margin-right: 0em;
        margin-left: 13%;

    }

    .FilterDropdown-headerTitle-2 {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        margin-right: 0em;
        margin-left: 25%;

    }

    .FilterDropdown-headerTitle-3 {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        margin-right: 0em;
        margin-left: 20%;

    }




    .historyBackBtn {
        /* margin-left: 1.5em; */
        margin-left: 0em;
        background-color: #000080;
        color: white;
        border: none;
        padding: 3px;
        cursor: pointer;
        border-radius: 10px;
        font-weight: bold;
        display: flex;
        align-items: center;
    }






    .history-filter-top-content {
        display: flex;
        margin-right: -5.8em;
        margin-left: 1em;
    }




    .history-filter-top-content1 {
        display: flex;
        margin-right: -4em;
        margin-left: -1.6em;




    }




    .historyPopUpModal {
        position: absolute;
        top:58%;
        left: 50%;
        height: 32em;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        width: 300px;
    }
    .HistoryECGContainer{
        margin-left: -0.2em;
    }

    .HistoryHeaderTxt {
        margin-right: 5em;
        text-align: center;
        font-size: 20px;
    }




    .vitalDetailsBorder {
        width: 100%;
        height: 2.3em;
        background: navy;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1px;
        margin-bottom: 1em;
    }




    .vitalDetailsModal {
        /* margin: 0; */
        color: white;
        font-size: 13px;
        font-weight: bold;
        display: flex;
        margin-left: 8em;
        /* align-items: center; */
        /* justify-content: center; */
    }


    .historyDateTime {
        height: 2em;
        padding: 7px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-bottom: 10px;
        margin-top: 1px;
    }






    .HistoryRecord-title {
        display: flex;
        font-size: 14px;
        text-align: center;
        margin-right: 25px;
        margin-left: 62px;
        margin-top: 3px;
    }


    .historyModelX {
        background: navy;
        color: white;
        border-color: white;
        font-weight: bold;
        padding: 3px 7px;
        border-radius: 14px;
        font-size: 14px;
        display: flex;
        margin-left: 6em;
    }


    .ECGContainer {
        display: block;
        /* You can adjust this based on the value of 'isModalOpen' */
        background-color: #efefef;
        height: 25.8em;
        /* width: 105%; */
        margin: auto;
        margin-right: -15px;
        /* margin-top: -15px; */
        /* margin-top: 4em; */
        /* margin-bottom: em; */
        /* This appears to be incomplete */
        margin-left: -15px;
        overflow: auto;
        scroll-margin-left: 0;
        scroll-margin-top: 0;
    }








    .HistoryImage-container,
    .HistoryHeader-container,
    .HistoryHeader-container1,
    .HistoryDetails-container,
    .HistoryRange-container,
    .HistoryRange-container1,
    .HistoryRange-container2,
    .HistoryTilte-contaniner {
        display: flex;
        align-items: center;
        justify-content: center;    }






    .HistoryImage1 {
        max-width: 33%;
        /* height: 10%; */
        margin-right:50%;
        margin-left: 50%;
        margin-top: 11px;
    }


    .HistorybloodPressureImage {
        border: #000;
        border-radius: 3px;
        max-width: 25%;
        height: 8%;
        margin-right: 50%;
        margin-left: 50%;
        margin-top: 17px;
    }

    .HistoryImage {
        max-width: 29%;
        height: 10%;
        margin-right: 50%;
        margin-left: 50%;
    }








    .HistoryHeader-title {
      /* display: flex; */
    margin: 0;
    font-size: 14px;
    text-align: center;
    /* margin-right: 25%; */
    /* margin-left: 25%; */
    margin-top: 8px;
    }

    .HistoryHeader-titleoxy {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        /* margin-right: 25%; */
        /* margin-left: 24%; */
        margin-top: 3px;
    }

    .HistoryHeader-titleBT {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        /* margin-right: 25%; */
        /* margin-left: 24%; */
        margin-top: 7px;
    }


    .HistoryHeader-title1 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }




    .HistoryHeader-title2 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 0%;
        margin-left: 0%;
        margin-top: 8px;
    }



    .HistoryDetails-container .HistoryValue {
        background-color: #ffb33a;
        color: white;
        font-size: 14px;
        text-align: center;
        padding: 5px;
        border-radius: 5px;
        margin-left: 0%;
        margin-right: 0%;
        margin-top: 5px;
        width: 95%;

    }




    .HistoryTilte-contaniner .HistoryTilte {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 20%;
        margin-right: 20%;
    }




    .HistoryTilte-contaniner .HistoryTilteT {
         text-align: center;
    font-size: 12px;
    margin-top: 5px;
    margin-left: 0%;
    margin-right: 0%;
    }




    .HistoryContainer-image {
        padding: 15px 10px;
        margin-top: 10px;
        padding-top: 5px;
    }




    .HistoryRange-container .HistoryRange {
        text-align: center;
        font-size: 13px;
        margin-top: 5px;
        margin-left: 0%;
        margin-right: 0%;
        font-weight: bold;
    }




    .HistoryRange-container1 .HistoryRange1 {
        text-align: center;
        font-size: 13px;
        margin-top: 5px;
        /* margin-left: 25%; */
        /* margin-right: 25%; */
        font-weight: bold;
    }




    .HistoryRange-container2 .HistoryRange2 {
        text-align: center;
        font-size: 13px;
        margin-top: 5px;
        /* margin-left: 25%; */
        /* margin-right: 25%; */
        margin-bottom: 20px;
        font-weight: bold;

    }







    .HistoryRange-container1 .HistoryRange3 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 77px;
        margin-bottom: 25px;
        font-weight: bold;
    }




    .HistoryRange-container1 .HistoryRange4 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 55px;
        margin-bottom: 25px;
        font-weight: bold;




    }




    .HistoryRange-container1 .HistoryRange5 {
         text-align: center;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 0%;
    margin-right: 0%;
    margin-bottom: 25px;
    font-weight: bold;
    }




    .HistoryRange-container1 .HistoryRange6 {
         text-align: center;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 0%;
    margin-right: 0%;
    margin-bottom: 25px;
    font-weight: bold;
    }

    .ibuttonHistory {
        display: flex;
        justify-content: flex-end;
    }

    .instructionButtonHistory {
        display: flex;
        position: static;
        font-weight: 800;
        height: 20px;
        width: 20px;
        color: #efefef;
        margin-top: -1.5em;
        margin-left: 9em;
        cursor: pointer;
        background-color: #000080;
        border: none;
        border-radius: 1em;
        /* background-image: url('aa.png'); */
    }


    .HistoryECGIntervelText {
        font-size: 18px;
        font-weight: bold;
        margin-top: 1em;
        margin-bottom: -2em;
        margin-left: 1em;
    }


    .HistoryECGIntervelText1 {
        font-size: 18px;
        font-weight: bold;
        margin-top: 0em;
        margin-left: 1em;
    }

    .detail-icon
    {
        display:flex;
        position:absolute;
        height:1.5em;
        margin-left:8.5em
    }


    .share-icon{
        display:flex;
        position:absolute;
        height:1.5em;
        margin-left: 8.5em;
        margin-top: 2em;
    }

    .HistoryECGgrid-container{
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Two columns */
        grid-template-rows: repeat(2, 1fr);    /* Two rows */
        gap: 10px;

      }

      .Historybioheadergrid {
        width:100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        /* gap: 20px; */
      }

      .HistorybioheaderLeft-item,
      .HistorybioheaderRight-item {
        /* border: 1px solid #ddd; */
        padding:1%;
        margin-left: 10%;
        margin-right: 10%;
        /* text-align: center; */

        /* border-radius: 8px; */
      }

      .historyBoldTextStyle {
        font-weight: bold;
      }

      .history-filter-grid-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        /* Three equal columns */
        gap: 8px;
        /* Adjust the gap as needed */
    }

    .history-filter-grid-item {
        text-align: center;
        flex: 2;
        /* Additional styling for each grid item if necessary */
    }


    .history-filter-grid-item-head {
        text-align: center;
        flex: 1;
        font-weight: bolder;
        margin-left: -1em;
        /* Additional styling for each grid item if necessary */
    }

    .Historypopup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 16em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .HistoryECGIntervalPopup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 15em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .HistoryECGIntervalPopup1 {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 17em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }
}

/* ----------- Galaxy S3 ----------- */

/* Portrait and Landscape */
@media screen
  and (device-width: 360px)
  and (device-height: 640px)
  and (-webkit-device-pixel-ratio: 2) {
    .HistoryContainer {
        width: 100%;
        /* height: 46.5em; */
        margin-bottom: 3.5em;
        background-color: #e5e5e6;
        border-radius: 7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8.6em;
        bottom: 1em;
        overflow-y: auto;
        /* Enable vertical scrolling */
        max-height: calc(100vh);
        /* Set maximum height to fill the available space */
        min-height: 11em;
        overflow-x: hidden;
    }




    .profileDetailHeader {
        height: 2.6em;
        width: 100%;
        padding: 9px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-bottom: 3px;
        margin-top: 2px;
    }


    .profileDetailHeaderText {
        flex: auto;
        /* margin-left: 1.5em; */
        margin-left: 0em;
    }


    .historyHeader {
        padding: 5px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-top: 5px;
        font-size: 25px;
        width: 97%;
        height: 1.4em;
    }


    .history-rectangle {
        margin-left: 5.5%;
        /* margin-right: -15%; */
        width: 89.5%;
        height: 39%;
        background-color: white;
        /* margin-left: 0em; */
        /* margin-bottom: 2em; */
        margin-top: 0.5em;
        display: flex;
        flex-direction: column;
    }




    .history-filter-rectangle {
        width: 420px;
        height: 45px;
        padding: 2px;
        background-color: white;
        margin-left: 1.4em;
        margin-bottom: 0.5em;
        display: flex;
        flex-direction: column;
    }
    .FilterHeaderTitle {
        width: 90%;
        height: 5%;
        background-color: white;
        text-align: center;
        padding: 10px;
        font-size: 16px;
        margin-left: 1.5em;
        margin-top: 0.5em;
    }


    .filterModalBox{
        position: absolute;
        top: 27em;
        left: 50%;
        height: 27em;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 10px;
        border-radius: 8px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        width: 23em;
    }

    .FilterDropdown {
        text-align: center;
        margin-bottom: 0.5em;
        margin-top: 0.5em;
        /* margin-left: 0em; */
        width: 100%;
    }


    .FilterDropdown .select {
        width: 90%;
        text-align: center;
        padding: 10px;
        font-size: 16px;
    }


    .FilterDropdown .select .paragraphText {
        margin-top: 10px;
        font-weight: bold;
        font-size: 16px;
        color: #333;
    }


    .historyBackBtn {
        /* margin-left: 1.5em; */
        margin-left: 0em;
        background-color: #000080;
        color: white;
        border: none;
        padding: 3px;
        cursor: pointer;
        border-radius: 10px;
        font-weight: bold;
        display: flex;
        align-items: center;
    }






    .history-filter-top-content {
        display: flex;
        margin-right: -5.8em;
        margin-left: 1em;
    }




    .history-filter-top-content1 {
        display: flex;
        margin-right: -4em;
        margin-left: -1.6em;




    }




    .historyPopUpModal {
        position: absolute;
        top: 54%;
        left: 50%;
        height: 32em;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        width: 300px;
    }
    .HistoryECGContainer{
        margin-left: -0.2em;
    }


    .HistoryHeaderTxt {
        margin-right: 5em;
        text-align: center;
        font-size: 20px;
    }




    .vitalDetailsBorder {
        width: 100%;
        height: 2.3em;
        background: navy;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1px;
        margin-bottom: 1em;
    }




    .vitalDetailsModal {
        /* margin: 0; */
        color: white;
        font-size: 13px;
        font-weight: bold;
        display: flex;
        margin-left: 8em;
        /* align-items: center; */
        /* justify-content: center; */
    }


    .historyDateTime {
        height: 2em;
        padding: 7px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-bottom: 10px;
        margin-top: 1px;
    }






    .HistoryRecord-title {
        display: flex;
        font-size: 14px;
        text-align: center;
        margin-right: 25px;
        margin-left: 62px;
        margin-top: 3px;
    }


    .historyModelX {
        background: navy;
        color: white;
        border-color: white;
        font-weight: bold;
        padding: 3px 7px;
        border-radius: 14px;
        font-size: 14px;
        display: flex;
        margin-left: 6em;
    }


    .ECGContainer {
        display: block;
        /* You can adjust this based on the value of 'isModalOpen' */
        background-color: #efefef;
        height: 25.8em;
        /* width: 105%; */
        margin: auto;
        margin-right: -15px;
        /* margin-top: -15px; */
        /* margin-top: 4em; */
        /* margin-bottom: em; */
        /* This appears to be incomplete */
        margin-left: -15px;
        overflow: auto;
        scroll-margin-left: 0;
        scroll-margin-top: 0;
    }








    .HistoryImage-container,
    .HistoryHeader-container,
    .HistoryHeader-container1,
    .HistoryDetails-container,
    .HistoryRange-container,
    .HistoryRange-container1,
    .HistoryRange-container2,
    .HistoryTilte-contaniner {
        display: flex;
        align-items: center;
        justify-content: center;    }






    .HistoryImage1 {
        max-width: 33%;
        /* height: 10%; */
        margin-right:50%;
        margin-left: 50%;
        margin-top: 11px;
    }

    .HistorybloodPressureImage {
        border: #000;
        border-radius: 3px;
        max-width: 25%;
        height: 8%;
        margin-right: 50%;
        margin-left: 50%;
        margin-top: 17px;
    }


    .HistoryImage {
        max-width: 29%;
        height: 10%;
        margin-right: 50%;
        margin-left: 50%;
    }








    .HistoryHeader-title {
      /* display: flex; */
    margin: 0;
    font-size: 14px;
    text-align: center;
    /* margin-right: 25%; */
    /* margin-left: 25%; */
    margin-top: 8px;
    }

    .HistoryHeader-titleoxy {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        /* margin-right: 25%; */
        /* margin-left: 24%; */
        margin-top: 3px;
    }

    .HistoryHeader-titleBT {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        /* margin-right: 25%; */
        /* margin-left: 24%; */
        margin-top: 7px;
    }


    .HistoryHeader-title1 {
        margin: 0;
    font-size: 14px;
    text-align: center;
    margin-right: 25%;
    margin-left: 25%;
    margin-top: 8px;
    }




    .HistoryHeader-title2 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 0%;
        margin-left: 0%;
        margin-top: 8px;
    }




    .HistoryDetails-container .HistoryValue {
        background-color: #ffb33a;
        color: white;
        font-size: 14px;
        text-align: center;
        padding: 5px;
        border-radius: 5px;
        margin-left: 0%;
        margin-right: 0%;
        margin-top: 5px;
        width: 95%;

    }




    .HistoryTilte-contaniner .HistoryTilte {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 20%;
        margin-right: 20%;
    }




    .HistoryTilte-contaniner .HistoryTilteT {
         text-align: center;
    font-size: 12px;
    margin-top: 5px;
    margin-left: 0%;
    margin-right: 0%;
    }




    .HistoryContainer-image {
        padding: 15px 10px;
        margin-top: 10px;
        padding-top: 5px;
    }




    .HistoryRange-container .HistoryRange {
        text-align: center;
        font-size: 13px;
        margin-top: 5px;
        margin-left: 0%;
        margin-right: 0%;
        font-weight: bold;
    }




    .HistoryRange-container1 .HistoryRange1 {
        text-align: center;
        font-size: 13px;
        margin-top: 5px;
        /* margin-left: 25%; */
        /* margin-right: 25%; */
        font-weight: bold;
    }




    .HistoryRange-container2 .HistoryRange2 {
        text-align: center;
        font-size: 13px;
        margin-top: 5px;
        /* margin-left: 25%; */
        /* margin-right: 25%; */
        margin-bottom: 20px;
        font-weight: bold;

    }







    .HistoryRange-container1 .HistoryRange3 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 77px;
        margin-bottom: 25px;
        font-weight: bold;
    }




    .HistoryRange-container1 .HistoryRange4 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 55px;
        margin-bottom: 25px;
        font-weight: bold;




    }




    .HistoryRange-container1 .HistoryRange5 {
         text-align: center;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 0%;
    margin-right: 0%;
    margin-bottom: 25px;
    font-weight: bold;
    }




    .HistoryRange-container1 .HistoryRange6 {
         text-align: center;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 0%;
    margin-right: 0%;
    margin-bottom: 25px;
    font-weight: bold;
    }

    .ibuttonHistory {
        display: flex;
        justify-content: flex-end;
    }

    .instructionButtonHistory {
        display: flex;
        position: static;
        font-weight: 800;
        height: 20px;
        width: 20px;
        color: #efefef;
        margin-top: -1.5em;
        margin-left: 9em;
        cursor: pointer;
        background-color: #000080;
        border: none;
        border-radius: 1em;
        /* background-image: url('aa.png'); */
    }



    .HistoryECGIntervelText {
        font-size: 18px;
        font-weight: bold;
        margin-top: 1em;
        margin-bottom: -2em;
        margin-left: 1em;
    }


    .HistoryECGIntervelText1 {
        font-size: 18px;
        font-weight: bold;
        margin-top: 0em;
        margin-left: 1em;
    }

    .detail-icon
    {
        display:flex;
        position:absolute;
        height:1.5em;
        margin-left:8.5em
    }

    .share-icon{
        display:flex;
        position:absolute;
        height:1.5em;
        margin-left:8.5em;
        margin-top: 2em;
    }

    .HistoryECGgrid-container{
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Two columns */
        grid-template-rows: repeat(2, 1fr);    /* Two rows */
        gap: 10px;

      }
      .Historybioheadergrid {
        width:100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        /* gap: 20px; */
      }

      .HistorybioheaderLeft-item,
      .HistorybioheaderRight-item {
        /* border: 1px solid #ddd; */
        padding:1%;
        margin-left: 10%;
        margin-right: 10%;
        /* text-align: center; */

        /* border-radius: 8px; */
      }

      .historyBoldTextStyle {
        font-weight: bold;
      }

      .history-filter-grid-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        /* Three equal columns */
        gap: 8px;
        /* Adjust the gap as needed */
    }

    .history-filter-grid-item {
        text-align: center;
        flex: 2;
        /* Additional styling for each grid item if necessary */
    }


    .history-filter-grid-item-head {
        text-align: center;
        flex: 1;
        font-weight: bolder;
        margin-left: -1em;
        /* Additional styling for each grid item if necessary */
    }

    .Historypopup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 16em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .HistoryECGIntervalPopup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 15em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .HistoryECGIntervalPopup1 {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 17em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }
}

/* ----------- Galaxy S4, S5 and Note 3 ----------- */

/* Portrait and Landscape */
@media screen
  and (device-width: 320px)
  and (device-height: 640px)
  and (-webkit-device-pixel-ratio: 3) {
    .HistoryContainer {
        width: 100%;
        /* height: 46.5em; */
        margin-bottom: 3.5em;
        background-color: #e5e5e6;
        border-radius: 7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8.6em;
        bottom: 1em;
        overflow-y: auto;
        /* Enable vertical scrolling */
        max-height: calc(100vh);
        /* Set maximum height to fill the available space */
        min-height: 11em;
        overflow-x: hidden;
    }




    .profileDetailHeader {
        height: 2.6em;
        width: 100%;
        padding: 9px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-bottom: 3px;
        margin-top: 2px;
    }


    .profileDetailHeaderText {
        flex: auto;
        /* margin-left: 1.5em; */
        margin-left: 0em;
    }


    .historyHeader {
        padding: 5px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-top: 5px;
        font-size: 25px;
        width: 97%;
        height: 1.4em;
    }


    .history-rectangle {
        margin-left: 5.5%;
        /* margin-right: -15%; */
        width: 89.5%;
        height: 39%;
        background-color: white;
        /* margin-left: 0em; */
        /* margin-bottom: 2em; */
        margin-top: 0.5em;
        display: flex;
        flex-direction: column;
    }




    .history-filter-rectangle {
        width: 420px;
        height: 45px;
        padding: 2px;
        background-color: white;
        margin-left: 1.4em;
        margin-bottom: 0.5em;
        display: flex;
        flex-direction: column;
    }
    .FilterHeaderTitle {
        width: 90%;
        height: 5%;
        background-color: white;
        text-align: center;
        padding: 10px;
        font-size: 16px;
        margin-left: 1.5em;
        margin-top: 0.5em;
    }


    .filterModalBox{
        position: absolute;
        top: 27em;
        left: 50%;
        height: 27em;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 10px;
        border-radius: 8px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        width: 23em;
    }

    .FilterDropdown {
        text-align: center;
        margin-bottom: 0.5em;
        margin-top: 0.5em;
        /* margin-left: 0em; */
        width: 100%;
    }


    .FilterDropdown .select {
        width: 90%;
        text-align: center;
        padding: 10px;
        font-size: 16px;
    }


    .FilterDropdown .select .paragraphText {
        margin-top: 10px;
        font-weight: bold;
        font-size: 16px;
        color: #333;
    }


    .historyBackBtn {
        /* margin-left: 1.5em; */
        margin-left: 0em;
        background-color: #000080;
        color: white;
        border: none;
        padding: 3px;
        cursor: pointer;
        border-radius: 10px;
        font-weight: bold;
        display: flex;
        align-items: center;
    }






    .history-filter-top-content {
        display: flex;
        margin-right: -5.8em;
        margin-left: 1em;
    }




    .history-filter-top-content1 {
        display: flex;
        margin-right: -4em;
        margin-left: -1.6em;




    }




    .historyPopUpModal {
        position: absolute;
        top: 54%;
        left: 50%;
        height: 32em;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        width: 300px;
    }
    .HistoryECGContainer{
        margin-left: -0.2em;
    }


    .HistoryHeaderTxt {
        margin-right: 5em;
        text-align: center;
        font-size: 20px;
    }




    .vitalDetailsBorder {
        width: 100%;
        height: 2.3em;
        background: navy;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1px;
        margin-bottom: 1em;
    }




    .vitalDetailsModal {
        /* margin: 0; */
        color: white;
        font-size: 13px;
        font-weight: bold;
        display: flex;
        margin-left: 8em;
        /* align-items: center; */
        /* justify-content: center; */
    }


    .historyDateTime {
        height: 2em;
        padding: 7px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-bottom: 10px;
        margin-top: 1px;
    }






    .HistoryRecord-title {
        display: flex;
        font-size: 14px;
        text-align: center;
        margin-right: 25px;
        margin-left: 62px;
        margin-top: 3px;
    }


    .historyModelX {
        background: navy;
        color: white;
        border-color: white;
        font-weight: bold;
        padding: 3px 7px;
        border-radius: 14px;
        font-size: 14px;
        display: flex;
        margin-left: 6em;
    }


    .ECGContainer {
        display: block;
        /* You can adjust this based on the value of 'isModalOpen' */
        background-color: #efefef;
        height: 25.8em;
        /* width: 105%; */
        margin: auto;
        margin-right: -15px;
        /* margin-top: -15px; */
        /* margin-top: 4em; */
        /* margin-bottom: em; */
        /* This appears to be incomplete */
        margin-left: -15px;
        overflow: auto;
        scroll-margin-left: 0;
        scroll-margin-top: 0;
    }








    .HistoryImage-container,
    .HistoryHeader-container,
    .HistoryHeader-container1,
    .HistoryDetails-container,
    .HistoryRange-container,
    .HistoryRange-container1,
    .HistoryRange-container2,
    .HistoryTilte-contaniner {
        display: flex;
        align-items: center;
        justify-content: center;    }






    .HistoryImage1 {
        max-width: 33%;
    /* height: 10%; */
    margin-right:50%;
    margin-left: 50%;
    margin-top: 11px;
    }

    .HistorybloodPressureImage {
        border: #000;
        border-radius: 3px;
        max-width: 25%;
        height: 8%;
        margin-right: 50%;
        margin-left: 50%;
        margin-top: 17px;
    }


    .HistoryImage {
        max-width: 29%;
        height: 10%;
        margin-right: 50%;
        margin-left: 50%;
    }








    .HistoryHeader-title {
    /* display: flex; */
    margin: 0;
    font-size: 14px;
    text-align: center;
    /* margin-right: 25%; */
    /* margin-left: 25%; */
    margin-top: 8px;
    }


    .HistoryHeader-titleoxy {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        /* margin-right: 25%; */
        /* margin-left: 24%; */
        margin-top: 3px;
    }

    .HistoryHeader-titleBT {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        /* margin-right: 25%; */
        /* margin-left: 24%; */
        margin-top: 7px;
    }

    .HistoryHeader-title1 {
        margin: 0;
    font-size: 14px;
    text-align: center;
    margin-right: 25%;
    margin-left: 25%;
    margin-top: 8px;
    }




    .HistoryHeader-title2 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 0%;
        margin-left: 0%;
        margin-top: 8px;
    }




    .HistoryDetails-container .HistoryValue {
        background-color: #ffb33a;
        color: white;
        font-size: 14px;
        text-align: center;
        padding: 5px;
        border-radius: 5px;
        margin-left: 0%;
        margin-right: 0%;
        margin-top: 5px;
        width: 95%;

    }




    .HistoryTilte-contaniner .HistoryTilte {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 20%;
        margin-right: 20%;
    }




    .HistoryTilte-contaniner .HistoryTilteT {
         text-align: center;
    font-size: 12px;
    margin-top: 5px;
    margin-left: 0%;
    margin-right: 0%;
    }




    .HistoryContainer-image {
        padding: 15px 10px;
        margin-top: 10px;
        padding-top: 5px;
    }




    .HistoryRange-container .HistoryRange {
        text-align: center;
        font-size: 13px;
        margin-top: 5px;
        margin-left: 0%;
        margin-right: 0%;
        font-weight: bold;
    }




    .HistoryRange-container1 .HistoryRange1 {
        text-align: center;
        font-size: 13px;
        margin-top: 5px;
        /* margin-left: 25%; */
        /* margin-right: 25%; */
        font-weight: bold;
    }




    .HistoryRange-container2 .HistoryRange2 {
        text-align: center;
        font-size: 13px;
        margin-top: 5px;
        /* margin-left: 25%; */
        /* margin-right: 25%; */
        margin-bottom: 20px;
        font-weight: bold;

    }








    .HistoryRange-container1 .HistoryRange3 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 77px;
        margin-bottom: 25px;
        font-weight: bold;
    }




    .HistoryRange-container1 .HistoryRange4 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 55px;
        margin-bottom: 25px;
        font-weight: bold;




    }




    .HistoryRange-container1 .HistoryRange5 {
         text-align: center;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 0%;
    margin-right: 0%;
    margin-bottom: 25px;
    font-weight: bold;
    }




    .HistoryRange-container1 .HistoryRange6 {
         text-align: center;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 0%;
    margin-right: 0%;
    margin-bottom: 25px;
    font-weight: bold;
    }

    .ibuttonHistory {
        display: flex;
        justify-content: flex-end;
    }

    .instructionButtonHistory {
        display: flex;
        position: static;
        font-weight: 800;
        height: 20px;
        width: 20px;
        color: #efefef;
        margin-top: -1.5em;
        margin-left: 9em;
        cursor: pointer;
        background-color: #000080;
        border: none;
        border-radius: 1em;
        /* background-image: url('aa.png'); */
    }


    .HistoryECGIntervelText {
        font-size: 18px;
        font-weight: bold;
        margin-top: 1em;
        margin-bottom: -2em;
        margin-left: 1em;
    }


    .HistoryECGIntervelText1 {
        font-size: 18px;
        font-weight: bold;
        margin-top: 0em;
        margin-left: 1em;
    }

    .detail-icon
    {
        display:flex;
        position:absolute;
        height:1.5em;
        margin-left:8.5em
    }

    .share-icon{
        display:flex;
        position:absolute;
        height:1.5em;
        margin-left:8.5em;
        margin-top: 2em;
    }

    .HistoryECGgrid-container{
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Two columns */
        grid-template-rows: repeat(2, 1fr);    /* Two rows */
        gap: 10px;

      }

      .Historybioheadergrid {
        width:100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        /* gap: 20px; */
      }

      .HistorybioheaderLeft-item,
      .HistorybioheaderRight-item {
        /* border: 1px solid #ddd; */
        padding:1%;
        margin-left: 10%;
        margin-right: 10%;
        /* text-align: center; */

        /* border-radius: 8px; */
      }

      .historyBoldTextStyle {
        font-weight: bold;
      }

      .history-filter-grid-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        /* Three equal columns */
        gap: 8px;
        /* Adjust the gap as needed */
    }

    .history-filter-grid-item {
        text-align: center;
        flex: 2;
        /* Additional styling for each grid item if necessary */
    }


    .history-filter-grid-item-head {
        text-align: center;
        flex: 1;
        font-weight: bolder;
        margin-left: -1em;
        /* Additional styling for each grid item if necessary */
    }

    .Historypopup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 16em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .HistoryECGIntervalPopup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 15em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .HistoryECGIntervalPopup1 {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 17em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }
}

/* ----------- Galaxy S6 ----------- */

/* Portrait and Landscape */
@media screen
  and (device-width: 360px)
  and (device-height: 640px)
  and (-webkit-device-pixel-ratio: 4) {
    .HistoryContainer {
        width: 100%;
        /* height: 46.5em; */
        margin-bottom: 3.5em;
        background-color: #e5e5e6;
        border-radius: 7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8.6em;
        bottom: 1em;
        overflow-y: auto;
        /* Enable vertical scrolling */
        max-height: calc(100vh);
        /* Set maximum height to fill the available space */
        min-height: 11em;
        overflow-x: hidden;
    }




    .profileDetailHeader {
        height: 2.6em;
        width: 100%;
        padding: 9px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-bottom: 3px;
        margin-top: 2px;
    }


    .profileDetailHeaderText {
        flex: auto;
        /* margin-left: 1.5em; */
        margin-left: 0em;
    }


    .historyHeader {
        padding: 5px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-top: 5px;
        font-size: 25px;
        width: 97%;
        height: 1.4em;
    }


    .history-rectangle {
        margin-left: 5.5%;
        /* margin-right: -15%; */
        width: 89.5%;
        height: 39%;
        background-color: white;
        /* margin-left: 0em; */
        /* margin-bottom: 2em; */
        margin-top: 0.5em;
        display: flex;
        flex-direction: column;
    }




    .history-filter-rectangle {
        width: 420px;
        height: 45px;
        padding: 2px;
        background-color: white;
        margin-left: 1.4em;
        margin-bottom: 0.5em;
        display: flex;
        flex-direction: column;
    }
    .FilterHeaderTitle {
        width: 90%;
        height: 5%;
        background-color: white;
        text-align: center;
        padding: 10px;
        font-size: 16px;
        margin-left: 1.5em;
        margin-top: 0.5em;
    }


    .filterModalBox{
        position: absolute;
        top: 27em;
        left: 50%;
        height: 27em;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 10px;
        border-radius: 8px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        width: 23em;
    }

    .FilterDropdown {
        text-align: center;
        margin-bottom: 0.5em;
        margin-top: 0.5em;
        /* margin-left: 0em; */
        width: 100%;
    }


    .FilterDropdown .select {
        width: 90%;
        text-align: center;
        padding: 10px;
        font-size: 16px;
    }


    .FilterDropdown .select .paragraphText {
        margin-top: 10px;
        font-weight: bold;
        font-size: 16px;
        color: #333;
    }


    .historyBackBtn {
        /* margin-left: 1.5em; */
        margin-left: 0em;
        background-color: #000080;
        color: white;
        border: none;
        padding: 3px;
        cursor: pointer;
        border-radius: 10px;
        font-weight: bold;
        display: flex;
        align-items: center;
    }






    .history-filter-top-content {
        display: flex;
        margin-right: -5.8em;
        margin-left: 1em;
    }




    .history-filter-top-content1 {
        display: flex;
        margin-right: -4em;
        margin-left: -1.6em;




    }




    .historyPopUpModal {
        position: absolute;
        top: 54%;
        left: 50%;
        height: 32em;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        width: 300px;
    }
    .HistoryECGContainer{
        margin-left: -0.2em;
    }

    .HistoryHeaderTxt {
        margin-right: 5em;
        text-align: center;
        font-size: 20px;
    }




    .vitalDetailsBorder {
        width: 100%;
        height: 2.3em;
        background: navy;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1px;
        margin-bottom: 1em;
    }




    .vitalDetailsModal {
        /* margin: 0; */
        color: white;
        font-size: 13px;
        font-weight: bold;
        display: flex;
        margin-left: 8em;
        /* align-items: center; */
        /* justify-content: center; */
    }


    .historyDateTime {
        height: 2em;
        padding: 7px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-bottom: 10px;
        margin-top: 1px;
    }






    .HistoryRecord-title {
        display: flex;
        font-size: 14px;
        text-align: center;
        margin-right: 25px;
        margin-left: 62px;
        margin-top: 3px;
    }


    .historyModelX {
        background: navy;
        color: white;
        border-color: white;
        font-weight: bold;
        padding: 3px 7px;
        border-radius: 14px;
        font-size: 14px;
        display: flex;
        margin-left: 6em;
    }


    .ECGContainer {
        display: block;
        /* You can adjust this based on the value of 'isModalOpen' */
        background-color: #efefef;
        height: 25.8em;
        /* width: 105%; */
        margin: auto;
        margin-right: -15px;
        /* margin-top: -15px; */
        /* margin-top: 4em; */
        /* margin-bottom: em; */
        /* This appears to be incomplete */
        margin-left: -15px;
        overflow: auto;
        scroll-margin-left: 0;
        scroll-margin-top: 0;
    }








    .HistoryImage-container,
    .HistoryHeader-container,
    .HistoryHeader-container1,
    .HistoryDetails-container,
    .HistoryRange-container,
    .HistoryRange-container1,
    .HistoryRange-container2,
    .HistoryTilte-contaniner {
        display: flex;
        align-items: center;
        justify-content: center;    }






    .HistoryImage1 {
        max-width: 33%;
    /* height: 10%; */
    margin-right:50%;
    margin-left: 50%;
    margin-top: 11px;
    }


    .HistorybloodPressureImage {
        border: #000;
        border-radius: 3px;
        max-width: 25%;
        height: 8%;
        margin-right: 50%;
        margin-left: 50%;
        margin-top: 17px;
    }

    .HistoryImage {
        max-width: 29%;
        height: 10%;
        margin-right: 50%;
        margin-left: 50%;
    }








    .HistoryHeader-title {
         /* display: flex; */
    margin: 0;
    font-size: 14px;
    text-align: center;
    /* margin-right: 25%; */
    /* margin-left: 25%; */
    margin-top: 8px;
    }

    .HistoryHeader-titleoxy {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        /* margin-right: 25%; */
        /* margin-left: 24%; */
        margin-top: 3px;
    }

    .HistoryHeader-titleBT {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        /* margin-right: 25%; */
        /* margin-left: 24%; */
        margin-top: 7px;
    }


    .HistoryHeader-title1 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }




    .HistoryHeader-title2 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 0%;
        margin-left: 0%;
        margin-top: 8px;
    }




    .HistoryDetails-container .HistoryValue {
        background-color: #ffb33a;
        color: white;
        font-size: 14px;
        text-align: center;
        padding: 5px;
        border-radius: 5px;
        margin-left: 0%;
        margin-right: 0%;
        margin-top: 5px;
        width: 95%;

    }




    .HistoryTilte-contaniner .HistoryTilte {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 20%;
        margin-right: 20%;
    }




    .HistoryTilte-contaniner .HistoryTilteT {
         text-align: center;
    font-size: 12px;
    margin-top: 5px;
    margin-left: 0%;
    margin-right: 0%;
    }




    .HistoryContainer-image {
        padding: 15px 10px;
        margin-top: 10px;
        padding-top: 5px;
    }




    .HistoryRange-container .HistoryRange {
        text-align: center;
        font-size: 13px;
        margin-top: 5px;
        margin-left: 0%;
        margin-right: 0%;
        font-weight: bold;
    }




    .HistoryRange-container1 .HistoryRange1 {
        text-align: center;
        font-size: 13px;
        margin-top: 5px;
        /* margin-left: 25%; */
        /* margin-right: 25%; */
        font-weight: bold;
    }




    .HistoryRange-container2 .HistoryRange2 {
        text-align: center;
        font-size: 13px;
        margin-top: 5px;
        /* margin-left: 25%; */
        /* margin-right: 25%; */
        margin-bottom: 20px;
        font-weight: bold;

    }







    .HistoryRange-container1 .HistoryRange3 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 77px;
        margin-bottom: 25px;
        font-weight: bold;
    }




    .HistoryRange-container1 .HistoryRange4 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 55px;
        margin-bottom: 25px;
        font-weight: bold;




    }




    .HistoryRange-container1 .HistoryRange5 {
         text-align: center;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 0%;
    margin-right: 0%;
    margin-bottom: 25px;
    font-weight: bold;
    }




    .HistoryRange-container1 .HistoryRange6 {
         text-align: center;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 0%;
    margin-right: 0%;
    margin-bottom: 25px;
    font-weight: bold;
    }


    .ibuttonHistory {
        display: flex;
        justify-content: flex-end;
    }

    .instructionButtonHistory {
        display: flex;
        position: static;
        font-weight: 800;
        height: 20px;
        width: 20px;
        color: #efefef;
        margin-top: -1.5em;
        margin-left: 9em;
        cursor: pointer;
        background-color: #000080;
        border: none;
        border-radius: 1em;
        /* background-image: url('aa.png'); */
    }


    .HistoryECGIntervelText {
        font-size: 18px;
        font-weight: bold;
        margin-top: 1em;
        margin-bottom: -2em;
        margin-left: 1em;
    }


    .HistoryECGIntervelText1 {
        font-size: 18px;
        font-weight: bold;
        margin-top: 0em;
        margin-left: 1em;
    }
    .detail-icon
    {
        display:flex;
        position:absolute;
        height:1.5em;
        margin-left:8.5em
    }
    .share-icon{
        display:flex;
        position:absolute;
        height:1.5em;
        margin-left:8.5em;
        margin-top: 2em;
    }
    .HistoryECGgrid-container{
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Two columns */
        grid-template-rows: repeat(2, 1fr);    /* Two rows */
        gap: 10px;

      }

      .Historybioheadergrid {
        width:100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        /* gap: 20px; */
      }

      .HistorybioheaderLeft-item,
      .HistorybioheaderRight-item {
        /* border: 1px solid #ddd; */
        padding:1%;
        margin-left: 10%;
        margin-right: 10%;
        /* text-align: center; */

        /* border-radius: 8px; */
      }

      .historyBoldTextStyle {
        font-weight: bold;
      }

      .history-filter-grid-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        /* Three equal columns */
        gap: 8px;
        /* Adjust the gap as needed */
    }

    .history-filter-grid-item {
        text-align: center;
        flex: 2;
        /* Additional styling for each grid item if necessary */
    }


    .history-filter-grid-item-head {
        text-align: center;
        flex: 1;
        font-weight: bolder;
        margin-left: -1em;
        /* Additional styling for each grid item if necessary */
    }

    .Historypopup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 16em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .HistoryECGIntervalPopup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 15em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .HistoryECGIntervalPopup1 {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 17em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }
}

/* ----------- HTC One ----------- */

/* Portrait and Landscape */
@media screen
  and (device-width: 360px)
  and (device-height: 640px)
  and (-webkit-device-pixel-ratio: 3) {
    .HistoryContainer {
        width: 100%;
        /* height: 46.5em; */
        margin-bottom: 3.5em;
        background-color: #e5e5e6;
        border-radius: 7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8.6em;
        bottom: 1em;
        overflow-y: auto;
        /* Enable vertical scrolling */
        max-height: calc(100vh);
        /* Set maximum height to fill the available space */
        min-height: 11em;
        overflow-x: hidden;
    }




    .profileDetailHeader {
        height: 2.6em;
        width: 100%;
        padding: 9px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-bottom: 3px;
        margin-top: 2px;
    }


    .profileDetailHeaderText {
        flex: auto;
        /* margin-left: 1.5em; */
        margin-left: 0em;
    }


    .historyHeader {
        padding: 5px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-top: 5px;
        font-size: 25px;
        width: 97%;
        height: 1.4em;
    }


    .history-rectangle {
        margin-left: 5.5%;
        /* margin-right: -15%; */
        width: 89.5%;
        height: 39%;
        background-color: white;
        /* margin-left: 0em; */
        /* margin-bottom: 2em; */
        margin-top: 0.5em;
        display: flex;
        flex-direction: column;
    }




    .history-filter-rectangle {
        width: 420px;
        height: 45px;
        padding: 2px;
        background-color: white;
        margin-left: 1.4em;
        margin-bottom: 0.5em;
        display: flex;
        flex-direction: column;
    }
    .FilterHeaderTitle {
        width: 90%;
        height: 5%;
        background-color: white;
        text-align: center;
        padding: 10px;
        font-size: 16px;
        margin-left: 1.5em;
        margin-top: 0.5em;
    }


    .filterModalBox{
        position: absolute;
        top: 27em;
        left: 50%;
        height: 27em;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 10px;
        border-radius: 8px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        width: 23em;
    }

    .FilterDropdown {
        text-align: center;
        margin-bottom: 0.5em;
        margin-top: 0.5em;
        /* margin-left: 0em; */
        width: 100%;
    }


    .FilterDropdown .select {
        width: 90%;
        text-align: center;
        padding: 10px;
        font-size: 16px;
    }


    .FilterDropdown .select .paragraphText {
        margin-top: 10px;
        font-weight: bold;
        font-size: 16px;
        color: #333;
    }


    .historyBackBtn {
        /* margin-left: 1.5em; */
        margin-left: 0em;
        background-color: #000080;
        color: white;
        border: none;
        padding: 3px;
        cursor: pointer;
        border-radius: 10px;
        font-weight: bold;
        display: flex;
        align-items: center;
    }






    .history-filter-top-content {
        display: flex;
        margin-right: -5.8em;
        margin-left: 1em;
    }




    .history-filter-top-content1 {
        display: flex;
        margin-right: -4em;
        margin-left: -1.6em;




    }




    .historyPopUpModal {
        position: absolute;
        top: 54%;
        left: 50%;
        height: 32em;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        width: 300px;
    }
    .HistoryECGContainer{
        margin-left: -0.2em;
    }

    .HistoryHeaderTxt {
        margin-right: 5em;
        text-align: center;
        font-size: 20px;
    }




    .vitalDetailsBorder {
        width: 100%;
        height: 2.3em;
        background: navy;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1px;
        margin-bottom: 1em;
    }




    .vitalDetailsModal {
        /* margin: 0; */
        color: white;
        font-size: 13px;
        font-weight: bold;
        display: flex;
        margin-left: 8em;
        /* align-items: center; */
        /* justify-content: center; */
    }


    .historyDateTime {
        height: 2em;
        padding: 7px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-bottom: 10px;
        margin-top: 1px;
    }






    .HistoryRecord-title {
        display: flex;
        font-size: 14px;
        text-align: center;
        margin-right: 25px;
        margin-left: 62px;
        margin-top: 3px;
    }


    .historyModelX {
        background: navy;
        color: white;
        border-color: white;
        font-weight: bold;
        padding: 3px 7px;
        border-radius: 14px;
        font-size: 14px;
        display: flex;
        margin-left: 6em;
    }


    .ECGContainer {
        display: block;
        /* You can adjust this based on the value of 'isModalOpen' */
        background-color: #efefef;
        height: 25.8em;
        /* width: 105%; */
        margin: auto;
        margin-right: -15px;
        /* margin-top: -15px; */
        /* margin-top: 4em; */
        /* margin-bottom: em; */
        /* This appears to be incomplete */
        margin-left: -15px;
        overflow: auto;
        scroll-margin-left: 0;
        scroll-margin-top: 0;
    }








    .HistoryImage-container,
    .HistoryHeader-container,
    .HistoryHeader-container1,
    .HistoryDetails-container,
    .HistoryRange-container,
    .HistoryRange-container1,
    .HistoryRange-container2,
    .HistoryTilte-contaniner {
        display: flex;
        align-items: center;
        justify-content: center;    }






    .HistoryImage1 {
        max-width: 33%;
    /* height: 10%; */
    margin-right:50%;
    margin-left: 50%;
    margin-top: 11px;
    }

    .HistorybloodPressureImage {
        border: #000;
        border-radius: 3px;
        max-width: 25%;
        height: 8%;
        margin-right: 50%;
        margin-left: 50%;
        margin-top: 17px;
    }


    .HistoryImage {
        max-width: 29%;
        height: 10%;
        margin-right: 50%;
        margin-left: 50%;
    }








    .HistoryHeader-title {
         /* display: flex; */
    margin: 0;
    font-size: 14px;
    text-align: center;
    /* margin-right: 25%; */
    /* margin-left: 25%; */
    margin-top: 8px;
    }


    .HistoryHeader-titleoxy {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        /* margin-right: 25%; */
        /* margin-left: 24%; */
        margin-top: 3px;
    }

    .HistoryHeader-titleBT {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        /* margin-right: 25%; */
        /* margin-left: 24%; */
        margin-top: 7px;
    }

    .HistoryHeader-title1 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }




    .HistoryHeader-title2 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 0%;
        margin-left: 0%;
        margin-top: 8px;
    }




    .HistoryDetails-container .HistoryValue {
        background-color: #ffb33a;
        color: white;
        font-size: 14px;
        text-align: center;
        padding: 5px;
        border-radius: 5px;
        margin-left: 0%;
        margin-right: 0%;
        margin-top: 5px;
        width: 95%;

    }




    .HistoryTilte-contaniner .HistoryTilte {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 20%;
        margin-right: 20%;
    }




    .HistoryTilte-contaniner .HistoryTilteT {
         text-align: center;
    font-size: 12px;
    margin-top: 5px;
    margin-left: 0%;
    margin-right: 0%;
    }




    .HistoryContainer-image {
        padding: 15px 10px;
        margin-top: 10px;
        padding-top: 5px;
    }




    .HistoryRange-container .HistoryRange {
        text-align: center;
        font-size: 13px;
        margin-top: 5px;
        margin-left: 0%;
        margin-right: 0%;
        font-weight: bold;
    }




    .HistoryRange-container1 .HistoryRange1 {
        text-align: center;
        font-size: 13px;
        margin-top: 5px;
        /* margin-left: 25%; */
        /* margin-right: 25%; */
        font-weight: bold;
    }




    .HistoryRange-container2 .HistoryRange2 {
        text-align: center;
        font-size: 13px;
        margin-top: 5px;
        /* margin-left: 25%; */
        /* margin-right: 25%; */
        margin-bottom: 20px;
        font-weight: bold;

    }








    .HistoryRange-container1 .HistoryRange3 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 77px;
        margin-bottom: 25px;
        font-weight: bold;
    }




    .HistoryRange-container1 .HistoryRange4 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 55px;
        margin-bottom: 25px;
        font-weight: bold;




    }




    .HistoryRange-container1 .HistoryRange5 {
         text-align: center;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 0%;
    margin-right: 0%;
    margin-bottom: 25px;
    font-weight: bold;
    }




    .HistoryRange-container1 .HistoryRange6 {
         text-align: center;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 0%;
    margin-right: 0%;
    margin-bottom: 25px;
    font-weight: bold;
    }

    .ibuttonHistory {
        display: flex;
        justify-content: flex-end;
    }

    .instructionButtonHistory {
        display: flex;
        position: static;
        font-weight: 800;
        height: 20px;
        width: 20px;
        color: #efefef;
        margin-top: -1.5em;
        margin-left: 9em;
        cursor: pointer;
        background-color: #000080;
        border: none;
        border-radius: 1em;
        /* background-image: url('aa.png'); */
    }


    .HistoryECGIntervelText {
        font-size: 18px;
        font-weight: bold;
        margin-top: 1em;
        margin-bottom: -2em;
        margin-left: 1em;
    }


    .HistoryECGIntervelText1 {
        font-size: 18px;
        font-weight: bold;
        margin-top: 0em;
        margin-left: 1em;
    }
    .detail-icon
    {
        display:flex;
        position:absolute;
        height:1.5em;
        margin-left:8.5em
    }

    .share-icon{
        display:flex;
        position:absolute;
        height:1.5em;
        margin-left:8.5em;
        margin-top: 2em;
    }
    .HistoryECGgrid-container{
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Two columns */
        grid-template-rows: repeat(2, 1fr);    /* Two rows */
        gap: 10px;

      }

      .Historybioheadergrid {
        width:100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        /* gap: 20px; */
      }

      .HistorybioheaderLeft-item,
      .HistorybioheaderRight-item {
        /* border: 1px solid #ddd; */
        padding:1%;
        margin-left: 10%;
        margin-right: 10%;
        /* text-align: center; */

        /* border-radius: 8px; */
      }

      .historyBoldTextStyle {
        font-weight: bold;
      }

      .history-filter-grid-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        /* Three equal columns */
        gap: 8px;
        /* Adjust the gap as needed */
    }

    .history-filter-grid-item {
        text-align: center;
        flex: 2;
        /* Additional styling for each grid item if necessary */
    }


    .history-filter-grid-item-head {
        text-align: center;
        flex: 1;
        font-weight: bolder;
        margin-left: -1em;
        /* Additional styling for each grid item if necessary */
    }

    .Historypopup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 16em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .HistoryECGIntervalPopup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 15em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .HistoryECGIntervalPopup1 {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 17em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }
}

/* ----------- Google Pixel ----------- */

/* Portrait and Landscape */
@media screen
  and (device-width: 360px)
  and (device-height: 640px)
  and (-webkit-device-pixel-ratio: 3) {
    .HistoryContainer {
        width: 100%;
        /* height: 46.5em; */
        margin-bottom: 3.5em;
        background-color: #e5e5e6;
        border-radius: 7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8.6em;
        bottom: 1em;
        overflow-y: auto;
        /* Enable vertical scrolling */
        max-height: calc(100vh);
        /* Set maximum height to fill the available space */
        min-height: 11em;
        overflow-x: hidden;
    }




    .profileDetailHeader {
        height: 2.6em;
        width: 100%;
        padding: 9px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-bottom: 3px;
        margin-top: 2px;
    }


    .profileDetailHeaderText {
        flex: auto;
        /* margin-left: 1.5em; */
        margin-left: 0em;
    }


    .historyHeader {
        padding: 5px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-top: 5px;
        font-size: 25px;
        width: 97%;
        height: 1.4em;
    }


    .history-rectangle {
        margin-left: 5.5%;
        /* margin-right: -15%; */
        width: 89.5%;
        height: 39%;
        background-color: white;
        /* margin-left: 0em; */
        /* margin-bottom: 2em; */
        margin-top: 0.5em;
        display: flex;
        flex-direction: column;
    }




    .history-filter-rectangle {
        width: 420px;
        height: 45px;
        padding: 2px;
        background-color: white;
        margin-left: 1.4em;
        margin-bottom: 0.5em;
        display: flex;
        flex-direction: column;
    }
    .FilterHeaderTitle {
        width: 90%;
        height: 5%;
        background-color: white;
        text-align: center;
        padding: 10px;
        font-size: 16px;
        margin-left: 1.5em;
        margin-top: 0.5em;
    }


    .filterModalBox{
        position: absolute;
        top: 27em;
        left: 50%;
        height: 27em;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 10px;
        border-radius: 8px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        width: 23em;
    }

    .FilterDropdown {
        text-align: center;
        margin-bottom: 0.5em;
        margin-top: 0.5em;
        /* margin-left: 0em; */
        width: 100%;
    }


    .FilterDropdown .select {
        width: 90%;
        text-align: center;
        padding: 10px;
        font-size: 16px;
    }


    .FilterDropdown .select .paragraphText {
        margin-top: 10px;
        font-weight: bold;
        font-size: 16px;
        color: #333;
    }


    .historyBackBtn {
        /* margin-left: 1.5em; */
        margin-left: 0em;
        background-color: #000080;
        color: white;
        border: none;
        padding: 3px;
        cursor: pointer;
        border-radius: 10px;
        font-weight: bold;
        display: flex;
        align-items: center;
    }






    .history-filter-top-content {
        display: flex;
        margin-right: -5.8em;
        margin-left: 1em;
    }




    .history-filter-top-content1 {
        display: flex;
        margin-right: -4em;
        margin-left: -1.6em;




    }




    .historyPopUpModal {
        position: absolute;
        top: 54%;
        left: 50%;
        height: 32em;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        width: 300px;
    }

    .HistoryECGContainer{
        margin-left: -0.2em;
    }


    .HistoryHeaderTxt {
        margin-right: 5em;
        text-align: center;
        font-size: 20px;
    }




    .vitalDetailsBorder {
        width: 100%;
        height: 2.3em;
        background: navy;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1px;
        margin-bottom: 1em;
    }




    .vitalDetailsModal {
        /* margin: 0; */
        color: white;
        font-size: 13px;
        font-weight: bold;
        display: flex;
        margin-left: 8em;
        /* align-items: center; */
        /* justify-content: center; */
    }


    .historyDateTime {
        height: 2em;
        padding: 7px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-bottom: 10px;
        margin-top: 1px;
    }






    .HistoryRecord-title {
        display: flex;
        font-size: 14px;
        text-align: center;
        margin-right: 25px;
        margin-left: 62px;
        margin-top: 3px;
    }


    .historyModelX {
        background: navy;
        color: white;
        border-color: white;
        font-weight: bold;
        padding: 3px 7px;
        border-radius: 14px;
        font-size: 14px;
        display: flex;
        margin-left: 6em;
    }


    .ECGContainer {
        display: block;
        /* You can adjust this based on the value of 'isModalOpen' */
        background-color: #efefef;
        height: 25.8em;
        /* width: 105%; */
        margin: auto;
        margin-right: -15px;
        /* margin-top: -15px; */
        /* margin-top: 4em; */
        /* margin-bottom: em; */
        /* This appears to be incomplete */
        margin-left: -15px;
        overflow: auto;
        scroll-margin-left: 0;
        scroll-margin-top: 0;
    }








    .HistoryImage-container,
    .HistoryHeader-container,
    .HistoryHeader-container1,
    .HistoryDetails-container,
    .HistoryRange-container,
    .HistoryRange-container1,
    .HistoryRange-container2,
    .HistoryTilte-contaniner {
        display: flex;
        align-items: center;
        justify-content: center;    }






    .HistoryImage1 {
        max-width: 33%;
        /* height: 10%; */
        margin-right:50%;
        margin-left: 50%;
        margin-top: 11px;
    }


    .HistorybloodPressureImage {
        border: #000;
        border-radius: 3px;
        max-width: 25%;
        height: 8%;
        margin-right: 50%;
        margin-left: 50%;
        margin-top: 17px;
    }

    .HistoryImage {
        max-width: 29%;
        height: 10%;
        margin-right: 50%;
        margin-left: 50%;
    }








    .HistoryHeader-title {
       /* display: flex; */
    margin: 0;
    font-size: 14px;
    text-align: center;
    /* margin-right: 25%; */
    /* margin-left: 25%; */
    margin-top: 8px;
    }

    .HistoryHeader-titleoxy {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        /* margin-right: 25%; */
        /* margin-left: 24%; */
        margin-top: 3px;
    }

    .HistoryHeader-titleBT {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        /* margin-right: 25%; */
        /* margin-left: 24%; */
        margin-top: 7px;
    }


    .HistoryHeader-title1 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }




    .HistoryHeader-title2 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 0%;
        margin-left: 0%;
        margin-top: 8px;
    }




    .HistoryDetails-container .HistoryValue {
        background-color: #ffb33a;
        color: white;
        font-size: 14px;
        text-align: center;
        padding: 5px;
        border-radius: 5px;
        margin-left: 0%;
        margin-right: 0%;
        margin-top: 5px;
        width: 95%;

    }




    .HistoryTilte-contaniner .HistoryTilte {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 20%;
        margin-right: 20%;
    }




    .HistoryTilte-contaniner .HistoryTilteT {
         text-align: center;
    font-size: 12px;
    margin-top: 5px;
    margin-left: 0%;
    margin-right: 0%;
    }




    .HistoryContainer-image {
        padding: 15px 10px;
        margin-top: 10px;
        padding-top: 5px;
    }




    .HistoryRange-container .HistoryRange {
        text-align: center;
        font-size: 13px;
        margin-top: 5px;
        margin-left: 0%;
        margin-right: 0%;
        font-weight: bold;
    }




    .HistoryRange-container1 .HistoryRange1 {
        text-align: center;
        font-size: 13px;
        margin-top: 5px;
        /* margin-left: 25%; */
        /* margin-right: 25%; */
        font-weight: bold;
    }




    .HistoryRange-container2 .HistoryRange2 {
        text-align: center;
        font-size: 13px;
        margin-top: 5px;
        /* margin-left: 25%; */
        /* margin-right: 25%; */
        margin-bottom: 20px;
        font-weight: bold;

    }








    .HistoryRange-container1 .HistoryRange3 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 77px;
        margin-bottom: 25px;
        font-weight: bold;
    }




    .HistoryRange-container1 .HistoryRange4 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 55px;
        margin-bottom: 25px;
        font-weight: bold;




    }




    .HistoryRange-container1 .HistoryRange5 {
         text-align: center;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 0%;
    margin-right: 0%;
    margin-bottom: 25px;
    font-weight: bold;
    }




    .HistoryRange-container1 .HistoryRange6 {
         text-align: center;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 0%;
    margin-right: 0%;
    margin-bottom: 25px;
    font-weight: bold;
    }

    .ibuttonHistory {
        display: flex;
        justify-content: flex-end;
    }

    .instructionButtonHistory {
        display: flex;
        position: static;
        font-weight: 800;
        height: 20px;
        width: 20px;
        color: #efefef;
        margin-top: -1.5em;
        margin-left: 9em;
        cursor: pointer;
        background-color: #000080;
        border: none;
        border-radius: 1em;
        /* background-image: url('aa.png'); */
    }


    .HistoryECGIntervelText {
        font-size: 18px;
        font-weight: bold;
        margin-top: 1em;
        margin-bottom: -2em;
        margin-left: 1em;
    }


    .HistoryECGIntervelText1 {
        font-size: 18px;
        font-weight: bold;
        margin-top: 0em;
        margin-left: 1em;
    }
    .detail-icon
    {
        display:flex;
        position:absolute;
        height:1.5em;
        margin-left:8.5em
    }

    .share-icon{
        display:flex;
        position:absolute;
        height:1.5em;
        margin-left:8.5em;
        margin-top: 2em;
    }
    .HistoryECGgrid-container{
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Two columns */
        grid-template-rows: repeat(2, 1fr);    /* Two rows */
        gap: 10px;

      }

      .Historybioheadergrid {
        width:100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        /* gap: 20px; */
      }

      .HistorybioheaderLeft-item,
      .HistorybioheaderRight-item {
        /* border: 1px solid #ddd; */
        padding:1%;
        margin-left: 10%;
        margin-right: 10%;
        /* text-align: center; */

        /* border-radius: 8px; */
      }

      .historyBoldTextStyle {
        font-weight: bold;
      }

      .history-filter-grid-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        /* Three equal columns */
        gap: 8px;
        /* Adjust the gap as needed */
    }

    .history-filter-grid-item {
        text-align: center;
        flex: 2;
        /* Additional styling for each grid item if necessary */
    }


    .history-filter-grid-item-head {
        text-align: center;
        flex: 1;
        font-weight: bolder;
        margin-left: -1em;
        /* Additional styling for each grid item if necessary */
    }

    .Historypopup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 16em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .HistoryECGIntervalPopup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 15em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .HistoryECGIntervalPopup1 {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 17em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

}

/* ----------- Google Pixel XL ----------- */

/* Portrait and Landscape */
@media screen
  and (device-width: 360px)
  and (device-height: 640px)
  and (-webkit-device-pixel-ratio: 4) {
    .HistoryContainer {
        width: 100%;
        /* height: 46.5em; */
        margin-bottom: 3.5em;
        background-color: #e5e5e6;
        border-radius: 7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8.6em;
        bottom: 1em;
        overflow-y: auto;
        /* Enable vertical scrolling */
        max-height: calc(100vh);
        /* Set maximum height to fill the available space */
        min-height: 11em;
        overflow-x: hidden;
    }




    .profileDetailHeader {
        height: 2.6em;
        width: 100%;
        padding: 9px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-bottom: 3px;
        margin-top: 2px;
    }


    .profileDetailHeaderText {
        flex: auto;
        /* margin-left: 1.5em; */
        margin-left: 0em;
    }


    .historyHeader {
        padding: 5px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-top: 5px;
        font-size: 25px;
        width: 97%;
        height: 1.4em;
    }


    .history-rectangle {
        margin-left: 5.5%;
        /* margin-right: -15%; */
        width: 89.5%;
        height: 39%;
        background-color: white;
        /* margin-left: 0em; */
        /* margin-bottom: 2em; */
        margin-top: 0.5em;
        display: flex;
        flex-direction: column;
    }




    .history-filter-rectangle {
        width: 420px;
        height: 45px;
        padding: 2px;
        background-color: white;
        margin-left: 1.4em;
        margin-bottom: 0.5em;
        display: flex;
        flex-direction: column;
    }
    .FilterHeaderTitle {
        width: 90%;
        height: 5%;
        background-color: white;
        text-align: center;
        padding: 10px;
        font-size: 16px;
        margin-left: 1.5em;
        margin-top: 0.5em;
    }


    .filterModalBox{
        position: absolute;
        top: 27em;
        left: 50%;
        height: 27em;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 10px;
        border-radius: 8px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        width: 23em;
    }

    .FilterDropdown {
        text-align: center;
        margin-bottom: 0.5em;
        margin-top: 0.5em;
        /* margin-left: 0em; */
        width: 100%;
    }


    .FilterDropdown .select {
        width: 90%;
        text-align: center;
        padding: 10px;
        font-size: 16px;
    }


    .FilterDropdown .select .paragraphText {
        margin-top: 10px;
        font-weight: bold;
        font-size: 16px;
        color: #333;
    }


    .historyBackBtn {
        /* margin-left: 1.5em; */
        margin-left: 0em;
        background-color: #000080;
        color: white;
        border: none;
        padding: 3px;
        cursor: pointer;
        border-radius: 10px;
        font-weight: bold;
        display: flex;
        align-items: center;
    }






    .history-filter-top-content {
        display: flex;
        margin-right: -5.8em;
        margin-left: 1em;
    }




    .history-filter-top-content1 {
        display: flex;
        margin-right: -4em;
        margin-left: -1.6em;




    }




    .historyPopUpModal {
        position: absolute;
        top: 54%;
        left: 50%;
        height: 32em;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        width: 300px;
    }

    .HistoryECGContainer{
        margin-left: -0.2em;
    }

    .HistoryHeaderTxt {
        margin-right: 5em;
        text-align: center;
        font-size: 20px;
    }




    .vitalDetailsBorder {
        width: 100%;
        height: 2.3em;
        background: navy;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1px;
        margin-bottom: 1em;
    }




    .vitalDetailsModal {
        /* margin: 0; */
        color: white;
        font-size: 13px;
        font-weight: bold;
        display: flex;
        margin-left: 8em;
        /* align-items: center; */
        /* justify-content: center; */
    }


    .historyDateTime {
        height: 2em;
        padding: 7px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-bottom: 10px;
        margin-top: 1px;
    }






    .HistoryRecord-title {
        display: flex;
        font-size: 14px;
        text-align: center;
        margin-right: 25px;
        margin-left: 62px;
        margin-top: 3px;
    }


    .historyModelX {
        background: navy;
        color: white;
        border-color: white;
        font-weight: bold;
        padding: 3px 7px;
        border-radius: 14px;
        font-size: 14px;
        display: flex;
        margin-left: 6em;
    }


    .ECGContainer {
        display: block;
        /* You can adjust this based on the value of 'isModalOpen' */
        background-color: #efefef;
        height: 25.8em;
        /* width: 105%; */
        margin: auto;
        margin-right: -15px;
        /* margin-top: -15px; */
        /* margin-top: 4em; */
        /* margin-bottom: em; */
        /* This appears to be incomplete */
        margin-left: -15px;
        overflow: auto;
        scroll-margin-left: 0;
        scroll-margin-top: 0;
    }








    .HistoryImage-container,
    .HistoryHeader-container,
    .HistoryHeader-container1,
    .HistoryDetails-container,
    .HistoryRange-container,
    .HistoryRange-container1,
    .HistoryRange-container2,
    .HistoryTilte-contaniner {
        display: flex;
        align-items: center;
        justify-content: center;    }






    .HistoryImage1 {
        max-width: 33%;
        /* height: 10%; */
        margin-right:50%;
        margin-left: 50%;
        margin-top: 11px;
    }


    .HistorybloodPressureImage {
        border: #000;
        border-radius: 3px;
        max-width: 25%;
        height: 8%;
        margin-right: 50%;
        margin-left: 50%;
        margin-top: 17px;
    }

    .HistoryImage {
        max-width: 29%;
        height: 10%;
        margin-right: 50%;
        margin-left: 50%;
    }








    .HistoryHeader-title {
       /* display: flex; */
    margin: 0;
    font-size: 14px;
    text-align: center;
    /* margin-right: 25%; */
    /* margin-left: 25%; */
    margin-top: 8px;
    }

    .HistoryHeader-titleoxy {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        /* margin-right: 25%; */
        /* margin-left: 24%; */
        margin-top: 3px;
    }

    .HistoryHeader-titleBT {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        /* margin-right: 25%; */
        /* margin-left: 24%; */
        margin-top: 7px;
    }


    .HistoryHeader-title1 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }




    .HistoryHeader-title2 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 0%;
        margin-left: 0%;
        margin-top: 8px;
    }




    .HistoryDetails-container .HistoryValue {
        background-color: #ffb33a;
        color: white;
        font-size: 14px;
        text-align: center;
        padding: 5px;
        border-radius: 5px;
        margin-left: 0%;
        margin-right: 0%;
        margin-top: 5px;
        width: 95%;

    }




    .HistoryTilte-contaniner .HistoryTilte {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 20%;
        margin-right: 20%;
    }




    .HistoryTilte-contaniner .HistoryTilteT {
         text-align: center;
    font-size: 12px;
    margin-top: 5px;
    margin-left: 0%;
    margin-right: 0%;
    }




    .HistoryContainer-image {
        padding: 15px 10px;
        margin-top: 10px;
        padding-top: 5px;
    }




    .HistoryRange-container .HistoryRange {
        text-align: center;
        font-size: 13px;
        margin-top: 5px;
        margin-left: 0%;
        margin-right: 0%;
        font-weight: bold;
    }




    .HistoryRange-container1 .HistoryRange1 {
        text-align: center;
        font-size: 13px;
        margin-top: 5px;
        /* margin-left: 25%; */
        /* margin-right: 25%; */
        font-weight: bold;
    }




    .HistoryRange-container2 .HistoryRange2 {
        text-align: center;
        font-size: 13px;
        margin-top: 5px;
        /* margin-left: 25%; */
        /* margin-right: 25%; */
        margin-bottom: 20px;
        font-weight: bold;

    }







    .HistoryRange-container1 .HistoryRange3 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 77px;
        margin-bottom: 25px;
        font-weight: bold;
    }




    .HistoryRange-container1 .HistoryRange4 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 55px;
        margin-bottom: 25px;
        font-weight: bold;




    }




    .HistoryRange-container1 .HistoryRange5 {
         text-align: center;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 0%;
    margin-right: 0%;
    margin-bottom: 25px;
    font-weight: bold;
    }




    .HistoryRange-container1 .HistoryRange6 {
         text-align: center;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 0%;
    margin-right: 0%;
    margin-bottom: 25px;
    font-weight: bold;
    }

    .ibuttonHistory {
        display: flex;
        justify-content: flex-end;
    }

    .instructionButtonHistory {
        display: flex;
        position: static;
        font-weight: 800;
        height: 20px;
        width: 20px;
        color: #efefef;
        margin-top: -1.5em;
        margin-left: 9em;
        cursor: pointer;
        background-color: #000080;
        border: none;
        border-radius: 1em;
        /* background-image: url('aa.png'); */
    }


    .HistoryECGIntervelText {
        font-size: 18px;
        font-weight: bold;
        margin-top: 1em;
        margin-bottom: -2em;
        margin-left: 1em;
    }


    .HistoryECGIntervelText1 {
        font-size: 18px;
        font-weight: bold;
        margin-top: 0em;
        margin-left: 1em;
    }
    .detail-icon
    {
        display:flex;
        position:absolute;
        height:1.5em;
        margin-left:8.5em
    }
    .share-icon{
        display:flex;
        position:absolute;
        height:1.5em;
        margin-left:8.5em;
        margin-top: 2em;
    }
    .HistoryECGgrid-container{
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Two columns */
        grid-template-rows: repeat(2, 1fr);    /* Two rows */
        gap: 10px;

      }

         .Historybioheadergrid {
        width:100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        /* gap: 20px; */
      }

      .HistorybioheaderLeft-item,
      .HistorybioheaderRight-item {
        /* border: 1px solid #ddd; */
        padding:1%;
        margin-left: 10%;
        margin-right: 10%;
        /* text-align: center; */

        /* border-radius: 8px; */
      }

      .historyBoldTextStyle {
        font-weight: bold;
      }

      .history-filter-grid-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        /* Three equal columns */
        gap: 8px;
        /* Adjust the gap as needed */
    }

    .history-filter-grid-item {
        text-align: center;
        flex: 2;
        /* Additional styling for each grid item if necessary */
    }


    .history-filter-grid-item-head {
        text-align: center;
        flex: 1;
        font-weight: bolder;
        margin-left: -1em;
        /* Additional styling for each grid item if necessary */
    }

    .Historypopup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 16em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .HistoryECGIntervalPopup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 15em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .HistoryECGIntervalPopup1 {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 17em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }
}

/* ----------- Nexus 4 ----------- */

/* Portrait and Landscape */
@media screen
  and (device-width: 384px)
  and (device-height: 592px)
  and (-webkit-device-pixel-ratio: 2) {
    .HistoryContainer {
        width: 100%;
        /* height: 46.5em; */
        margin-bottom: 3.5em;
        background-color: #e5e5e6;
        border-radius: 7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8.6em;
        bottom: 1em;
        overflow-y: auto;
        /* Enable vertical scrolling */
        max-height: calc(100vh);
        /* Set maximum height to fill the available space */
        min-height: 11em;
        overflow-x: hidden;
    }




    .profileDetailHeader {
        height: 2.6em;
        width: 100%;
        padding: 9px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-bottom: 3px;
        margin-top: 2px;
    }


    .profileDetailHeaderText {
        flex: auto;
        /* margin-left: 1.5em; */
        margin-left: 0em;
    }


    .historyHeader {
        padding: 5px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-top: 5px;
        font-size: 25px;
        width: 97%;
        height: 1.4em;
    }


    .history-rectangle {
        margin-left: 5.5%;
        /* margin-right: -15%; */
        width: 89.5%;
        height: 39%;
        background-color: white;
        /* margin-left: 0em; */
        /* margin-bottom: 2em; */
        margin-top: 0.5em;
        display: flex;
        flex-direction: column;
    }




    .history-filter-rectangle {
        width: 420px;
        height: 45px;
        padding: 2px;
        background-color: white;
        margin-left: 1.4em;
        margin-bottom: 0.5em;
        display: flex;
        flex-direction: column;
    }
    .FilterHeaderTitle {
        width: 90%;
        height: 5%;
        background-color: white;
        text-align: center;
        padding: 10px;
        font-size: 16px;
        margin-left: 1.5em;
        margin-top: 0.5em;
    }


    .filterModalBox{
        position: absolute;
        top: 27em;
        left: 50%;
        height: 27em;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 10px;
        border-radius: 8px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        width: 23em;
    }

    .FilterDropdown {
        text-align: center;
        margin-bottom: 0.5em;
        margin-top: 0.5em;
        /* margin-left: 0em; */
        width: 100%;
    }


    .FilterDropdown .select {
        width: 90%;
        text-align: center;
        padding: 10px;
        font-size: 16px;
    }


    .FilterDropdown .select .paragraphText {
        margin-top: 10px;
        font-weight: bold;
        font-size: 16px;
        color: #333;
    }


    .historyBackBtn {
        /* margin-left: 1.5em; */
        margin-left: 0em;
        background-color: #000080;
        color: white;
        border: none;
        padding: 3px;
        cursor: pointer;
        border-radius: 10px;
        font-weight: bold;
        display: flex;
        align-items: center;
    }






    .history-filter-top-content {
        display: flex;
        margin-right: -5.8em;
        margin-left: 1em;
    }




    .history-filter-top-content1 {
        display: flex;
        margin-right: -4em;
        margin-left: -1.6em;




    }




    .historyPopUpModal {
        position: absolute;
        top: 54%;
        left: 50%;
        height: 32em;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        width: 300px;
    }

    .HistoryECGContainer{
        margin-left: -0.2em;
    }


    .HistoryHeaderTxt {
        margin-right: 5em;
        text-align: center;
        font-size: 20px;
    }




    .vitalDetailsBorder {
        width: 100%;
        height: 2.3em;
        background: navy;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1px;
        margin-bottom: 1em;
    }




    .vitalDetailsModal {
        /* margin: 0; */
        color: white;
        font-size: 13px;
        font-weight: bold;
        display: flex;
        margin-left: 8em;
        /* align-items: center; */
        /* justify-content: center; */
    }


    .historyDateTime {
        height: 2em;
        padding: 7px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-bottom: 10px;
        margin-top: 1px;
    }






    .HistoryRecord-title {
        display: flex;
        font-size: 14px;
        text-align: center;
        margin-right: 25px;
        margin-left: 62px;
        margin-top: 3px;
    }


    .historyModelX {
        background: navy;
        color: white;
        border-color: white;
        font-weight: bold;
        padding: 3px 7px;
        border-radius: 14px;
        font-size: 14px;
        display: flex;
        margin-left: 6em;
    }


    .ECGContainer {
        display: block;
        /* You can adjust this based on the value of 'isModalOpen' */
        background-color: #efefef;
        height: 25.8em;
        /* width: 105%; */
        margin: auto;
        margin-right: -15px;
        /* margin-top: -15px; */
        /* margin-top: 4em; */
        /* margin-bottom: em; */
        /* This appears to be incomplete */
        margin-left: -15px;
        overflow: auto;
        scroll-margin-left: 0;
        scroll-margin-top: 0;
    }








    .HistoryImage-container,
    .HistoryHeader-container,
    .HistoryHeader-container1,
    .HistoryDetails-container,
    .HistoryRange-container,
    .HistoryRange-container1,
    .HistoryRange-container2,
    .HistoryTilte-contaniner {
        display: flex;
        align-items: center;
        justify-content: center;    }






    .HistoryImage1 {
        max-width: 33%;
    /* height: 10%; */
    margin-right:50%;
    margin-left: 50%;
    margin-top: 11px;
    }


    .HistorybloodPressureImage {
        border: #000;
        border-radius: 3px;
        max-width: 25%;
        height: 8%;
        margin-right: 50%;
        margin-left: 50%;
        margin-top: 17px;
    }

    .HistoryImage {
        max-width: 29%;
        height: 10%;
        margin-right: 50%;
        margin-left: 50%;
    }








    .HistoryHeader-title {
        /* display: flex; */
    margin: 0;
    font-size: 14px;
    text-align: center;
    /* margin-right: 25%; */
    /* margin-left: 25%; */
    margin-top: 8px;
    }

    .HistoryHeader-titleoxy {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        /* margin-right: 25%; */
        /* margin-left: 24%; */
        margin-top: 3px;
    }

    .HistoryHeader-titleBT {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        /* margin-right: 25%; */
        /* margin-left: 24%; */
        margin-top: 7px;
    }


    .HistoryHeader-title1 {
        margin: 0;
    font-size: 14px;
    text-align: center;
    margin-right: 25%;
    margin-left: 25%;
    margin-top: 8px;
    }




    .HistoryHeader-title2 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 0%;
        margin-left: 0%;
        margin-top: 8px;
    }




    .HistoryDetails-container .HistoryValue {
        background-color: #ffb33a;
        color: white;
        font-size: 14px;
        text-align: center;
        padding: 5px;
        border-radius: 5px;
        margin-left: 0%;
        margin-right: 0%;
        margin-top: 5px;
        width: 95%;

    }




    .HistoryTilte-contaniner .HistoryTilte {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 20%;
        margin-right: 20%;
    }




    .HistoryTilte-contaniner .HistoryTilteT {
         text-align: center;
    font-size: 12px;
    margin-top: 5px;
    margin-left: 0%;
    margin-right: 0%;
    }




    .HistoryContainer-image {
        padding: 15px 10px;
        margin-top: 10px;
        padding-top: 5px;
    }




    .HistoryRange-container .HistoryRange {
        text-align: center;
        font-size: 13px;
        margin-top: 5px;
        margin-left: 0%;
        margin-right: 0%;
        font-weight: bold;
    }




    .HistoryRange-container1 .HistoryRange1 {
        text-align: center;
        font-size: 13px;
        margin-top: 5px;
        /* margin-left: 25%; */
        /* margin-right: 25%; */
        font-weight: bold;
    }




    .HistoryRange-container2 .HistoryRange2 {
        text-align: center;
        font-size: 13px;
        margin-top: 5px;
        /* margin-left: 25%; */
        /* margin-right: 25%; */
        margin-bottom: 20px;
        font-weight: bold;

    }







    .HistoryRange-container1 .HistoryRange3 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 77px;
        margin-bottom: 25px;
        font-weight: bold;
    }




    .HistoryRange-container1 .HistoryRange4 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 55px;
        margin-bottom: 25px;
        font-weight: bold;




    }




    .HistoryRange-container1 .HistoryRange5 {
         text-align: center;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 0%;
    margin-right: 0%;
    margin-bottom: 25px;
    font-weight: bold;
    }




    .HistoryRange-container1 .HistoryRange6 {
         text-align: center;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 0%;
    margin-right: 0%;
    margin-bottom: 25px;
    font-weight: bold;
    }

    .ibuttonHistory {
        display: flex;
        justify-content: flex-end;
    }

    .instructionButtonHistory {
        display: flex;
        position: static;
        font-weight: 800;
        height: 20px;
        width: 20px;
        color: #efefef;
        margin-top: -1.5em;
        margin-left: 9em;
        cursor: pointer;
        background-color: #000080;
        border: none;
        border-radius: 1em;
        /* background-image: url('aa.png'); */
    }


    .HistoryECGIntervelText {
        font-size: 18px;
        font-weight: bold;
        margin-top: 1em;
        margin-bottom: -2em;
        margin-left: 1em;
    }


    .HistoryECGIntervelText1 {
        font-size: 18px;
        font-weight: bold;
        margin-top: 0em;
        margin-left: 1em;
    }
    .detail-icon
    {
        display:flex;
        position:absolute;
        height:1.5em;
        margin-left:8.5em
    }
    .share-icon{
        display:flex;
        position:absolute;
        height:1.5em;
        margin-left:8.5em;
        margin-top: 2em;
    }
    .HistoryECGgrid-container{
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Two columns */
        grid-template-rows: repeat(2, 1fr);    /* Two rows */
        gap: 10px;

      }
      .Historybioheadergrid {
        width:100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        /* gap: 20px; */
      }

      .HistorybioheaderLeft-item,
      .HistorybioheaderRight-item {
        /* border: 1px solid #ddd; */
        padding:1%;
        margin-left: 10%;
        margin-right: 10%;
        /* text-align: center; */

        /* border-radius: 8px; */
      }

      .historyBoldTextStyle {
        font-weight: bold;
      }

      .history-filter-grid-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        /* Three equal columns */
        gap: 8px;
        /* Adjust the gap as needed */
    }

    .history-filter-grid-item {
        text-align: center;
        flex: 2;
        /* Additional styling for each grid item if necessary */
    }


    .history-filter-grid-item-head {
        text-align: center;
        flex: 1;
        font-weight: bolder;
        margin-left: -1em;
        /* Additional styling for each grid item if necessary */
    }

    .Historypopup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 16em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .HistoryECGIntervalPopup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 15em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .HistoryECGIntervalPopup1 {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 17em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }
}

/* ----------- Nexus 5 ----------- */

/* Portrait and Landscape */
@media screen
  and (device-width: 360px)
  and (device-height: 592px)
  and (-webkit-device-pixel-ratio: 3) {
    .HistoryContainer {
        width: 100%;
        /* height: 46.5em; */
        margin-bottom: 3.5em;
        background-color: #e5e5e6;
        border-radius: 7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8.6em;
        bottom: 1em;
        overflow-y: auto;
        /* Enable vertical scrolling */
        max-height: calc(100vh);
        /* Set maximum height to fill the available space */
        min-height: 11em;
        overflow-x: hidden;
    }




    .profileDetailHeader {
        height: 2.6em;
        width: 100%;
        padding: 9px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-bottom: 3px;
        margin-top: 2px;
    }


    .profileDetailHeaderText {
        flex: auto;
        /* margin-left: 1.5em; */
        margin-left: 0em;
    }


    .historyHeader {
        padding: 5px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-top: 5px;
        font-size: 25px;
        width: 97%;
        height: 1.4em;
    }


    .history-rectangle {
        margin-left: 5.5%;
        /* margin-right: -15%; */
        width: 89.5%;
        height: 39%;
        background-color: white;
        /* margin-left: 0em; */
        /* margin-bottom: 2em; */
        margin-top: 0.5em;
        display: flex;
        flex-direction: column;
    }




    .history-filter-rectangle {
        width: 420px;
        height: 45px;
        padding: 2px;
        background-color: white;
        margin-left: 1.4em;
        margin-bottom: 0.5em;
        display: flex;
        flex-direction: column;
    }
    .FilterHeaderTitle {
        width: 90%;
        height: 5%;
        background-color: white;
        text-align: center;
        padding: 10px;
        font-size: 16px;
        margin-left: 1.5em;
        margin-top: 0.5em;
    }


    .filterModalBox{
        position: absolute;
        top: 27em;
        left: 50%;
        height: 27em;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 10px;
        border-radius: 8px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        width: 23em;
    }

    .FilterDropdown {
        text-align: center;
        margin-bottom: 0.5em;
        margin-top: 0.5em;
        /* margin-left: 0em; */
        width: 100%;
    }


    .FilterDropdown .select {
        width: 90%;
        text-align: center;
        padding: 10px;
        font-size: 16px;
    }


    .FilterDropdown .select .paragraphText {
        margin-top: 10px;
        font-weight: bold;
        font-size: 16px;
        color: #333;
    }


    .historyBackBtn {
        /* margin-left: 1.5em; */
        margin-left: 0em;
        background-color: #000080;
        color: white;
        border: none;
        padding: 3px;
        cursor: pointer;
        border-radius: 10px;
        font-weight: bold;
        display: flex;
        align-items: center;
    }






    .history-filter-top-content {
        display: flex;
        margin-right: -5.8em;
        margin-left: 1em;
    }




    .history-filter-top-content1 {
        display: flex;
        margin-right: -4em;
        margin-left: -1.6em;




    }




    .historyPopUpModal {
        position: absolute;
        top: 54%;
        left: 50%;
        height: 32em;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        width: 300px;
    }

    .HistoryECGContainer{
        margin-left: -0.2em;
    }

    .HistoryHeaderTxt {
        margin-right: 5em;
        text-align: center;
        font-size: 20px;
    }




    .vitalDetailsBorder {
        width: 100%;
        height: 2.3em;
        background: navy;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1px;
        margin-bottom: 1em;
    }




    .vitalDetailsModal {
        /* margin: 0; */
        color: white;
        font-size: 13px;
        font-weight: bold;
        display: flex;
        margin-left: 8em;
        /* align-items: center; */
        /* justify-content: center; */
    }


    .historyDateTime {
        height: 2em;
        padding: 7px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-bottom: 10px;
        margin-top: 1px;
    }






    .HistoryRecord-title {
        display: flex;
        font-size: 14px;
        text-align: center;
        margin-right: 25px;
        margin-left: 62px;
        margin-top: 3px;
    }


    .historyModelX {
        background: navy;
        color: white;
        border-color: white;
        font-weight: bold;
        padding: 3px 7px;
        border-radius: 14px;
        font-size: 14px;
        display: flex;
        margin-left: 6em;
    }


    .ECGContainer {
        display: block;
        /* You can adjust this based on the value of 'isModalOpen' */
        background-color: #efefef;
        height: 25.8em;
        /* width: 105%; */
        margin: auto;
        margin-right: -15px;
        /* margin-top: -15px; */
        /* margin-top: 4em; */
        /* margin-bottom: em; */
        /* This appears to be incomplete */
        margin-left: -15px;
        overflow: auto;
        scroll-margin-left: 0;
        scroll-margin-top: 0;
    }








    .HistoryImage-container,
    .HistoryHeader-container,
    .HistoryHeader-container1,
    .HistoryDetails-container,
    .HistoryRange-container,
    .HistoryRange-container1,
    .HistoryRange-container2,
    .HistoryTilte-contaniner {
        display: flex;
        align-items: center;
        justify-content: center;    }






    .HistoryImage1 {
        max-width: 33%;
        /* height: 10%; */
        margin-right:50%;
        margin-left: 50%;
        margin-top: 11px;
    }

    .HistorybloodPressureImage {
        border: #000;
        border-radius: 3px;
        max-width: 25%;
        height: 8%;
        margin-right: 50%;
        margin-left: 50%;
        margin-top: 17px;
    }


    .HistoryImage {
        max-width: 29%;
    height: 10%;
    margin-right: 50%;
    margin-left: 50%;
    }








    .HistoryHeader-title {
     /* display: flex; */
     margin: 0;
     font-size: 14px;
     text-align: center;
     /* margin-right: 25%; */
     /* margin-left: 25%; */
     margin-top: 8px;
    }

    .HistoryHeader-titleoxy {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        /* margin-right: 25%; */
        /* margin-left: 24%; */
        margin-top: 3px;
    }

    .HistoryHeader-titleBT {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        /* margin-right: 25%; */
        /* margin-left: 24%; */
        margin-top: 7px;
    }


    .HistoryHeader-title1 {
        margin: 0;
    font-size: 14px;
    text-align: center;
    margin-right: 25%;
    margin-left: 25%;
    margin-top: 8px;
    }




    .HistoryHeader-title2 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 0%;
        margin-left: 0%;
        margin-top: 8px;
    }




    .HistoryDetails-container .HistoryValue {
        background-color: #ffb33a;
        color: white;
        font-size: 14px;
        text-align: center;
        padding: 5px;
        border-radius: 5px;
        margin-left: 0%;
        margin-right: 0%;
        margin-top: 5px;
        width: 95%;

    }




    .HistoryTilte-contaniner .HistoryTilte {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 20%;
        margin-right: 20%;
    }




    .HistoryTilte-contaniner .HistoryTilteT {
         text-align: center;
    font-size: 12px;
    margin-top: 5px;
    margin-left: 0%;
    margin-right: 0%;
    }




    .HistoryContainer-image {
        padding: 15px 10px;
        margin-top: 10px;
        padding-top: 5px;
    }




    .HistoryRange-container .HistoryRange {
        text-align: center;
        font-size: 13px;
        margin-top: 5px;
        margin-left: 0%;
        margin-right: 0%;
        font-weight: bold;
    }




    .HistoryRange-container1 .HistoryRange1 {
        text-align: center;
        font-size: 13px;
        margin-top: 5px;
        /* margin-left: 25%; */
        /* margin-right: 25%; */
        font-weight: bold;
    }




    .HistoryRange-container2 .HistoryRange2 {
        text-align: center;
        font-size: 13px;
        margin-top: 5px;
        /* margin-left: 25%; */
        /* margin-right: 25%; */
        margin-bottom: 20px;
        font-weight: bold;

    }







    .HistoryRange-container1 .HistoryRange3 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 77px;
        margin-bottom: 25px;
        font-weight: bold;
    }




    .HistoryRange-container1 .HistoryRange4 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 55px;
        margin-bottom: 25px;
        font-weight: bold;




    }




    .HistoryRange-container1 .HistoryRange5 {
         text-align: center;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 0%;
    margin-right: 0%;
    margin-bottom: 25px;
    font-weight: bold;
    }




    .HistoryRange-container1 .HistoryRange6 {
         text-align: center;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 0%;
    margin-right: 0%;
    margin-bottom: 25px;
    font-weight: bold;
    }

    .ibuttonHistory {
        display: flex;
        justify-content: flex-end;
    }

    .instructionButtonHistory {
        display: flex;
        position: static;
        font-weight: 800;
        height: 20px;
        width: 20px;
        color: #efefef;
        margin-top: -1.5em;
        margin-left: 9em;
        cursor: pointer;
        background-color: #000080;
        border: none;
        border-radius: 1em;
        /* background-image: url('aa.png'); */
    }


    .HistoryECGIntervelText {
        font-size: 18px;
        font-weight: bold;
        margin-top: 1em;
        margin-bottom: -2em;
        margin-left: 1em;
    }


    .HistoryECGIntervelText1 {
        font-size: 18px;
        font-weight: bold;
        margin-top: 0em;
        margin-left: 1em;
    }
    .detail-icon
    {
        display:flex;
        position:absolute;
        height:1.5em;
        margin-left:8.5em
    }
    .share-icon{
        display:flex;
        position:absolute;
        height:1.5em;
        margin-left:8.5em;
        margin-top: 2em;
    }
    .HistoryECGgrid-container{
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Two columns */
        grid-template-rows: repeat(2, 1fr);    /* Two rows */
        gap: 10px;

      }
      .Historybioheadergrid {
        width:100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        /* gap: 20px; */
      }

      .HistorybioheaderLeft-item,
      .HistorybioheaderRight-item {
        /* border: 1px solid #ddd; */
        padding:1%;
        margin-left: 10%;
        margin-right: 10%;
        /* text-align: center; */

        /* border-radius: 8px; */
      }

      .historyBoldTextStyle {
        font-weight: bold;
      }

      .history-filter-grid-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        /* Three equal columns */
        gap: 8px;
        /* Adjust the gap as needed */
    }

    .history-filter-grid-item {
        text-align: center;
        flex: 2;
        /* Additional styling for each grid item if necessary */
    }


    .history-filter-grid-item-head {
        text-align: center;
        flex: 1;
        font-weight: bolder;
        margin-left: -1em;
        /* Additional styling for each grid item if necessary */
    }
    .Historypopup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 16em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .HistoryECGIntervalPopup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 15em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .HistoryECGIntervalPopup1 {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 17em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }
}

/* ----------- Nexus 6 and 6P ----------- */

/* Portrait and Landscape */
@media screen
  and (device-width: 360px)
  and (device-height: 592px)
  and (-webkit-device-pixel-ratio: 4) {
    .HistoryContainer {
        width: 100%;
        /* height: 46.5em; */
        margin-bottom: 3.5em;
        background-color: #e5e5e6;
        border-radius: 7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8.6em;
        bottom: 1em;
        overflow-y: auto;
        /* Enable vertical scrolling */
        max-height: calc(100vh);
        /* Set maximum height to fill the available space */
        min-height: 11em;
        overflow-x: hidden;
    }




    .profileDetailHeader {
        height: 2.6em;
        width: 100%;
        padding: 9px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-bottom: 3px;
        margin-top: 2px;
    }


    .profileDetailHeaderText {
        flex: auto;
        /* margin-left: 1.5em; */
        margin-left: 0em;
    }


    .historyHeader {
        padding: 5px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-top: 5px;
        font-size: 25px;
        width: 97%;
        height: 1.4em;
    }


    .history-rectangle {
        margin-left: 5.5%;
        /* margin-right: -15%; */
        width: 89.5%;
        height: 39%;
        background-color: white;
        /* margin-left: 0em; */
        /* margin-bottom: 2em; */
        margin-top: 0.5em;
        display: flex;
        flex-direction: column;
    }




    .history-filter-rectangle {
        width: 420px;
        height: 45px;
        padding: 2px;
        background-color: white;
        margin-left: 1.4em;
        margin-bottom: 0.5em;
        display: flex;
        flex-direction: column;
    }
    .FilterHeaderTitle {
        width: 90%;
        height: 5%;
        background-color: white;
        text-align: center;
        padding: 10px;
        font-size: 16px;
        margin-left: 1.5em;
        margin-top: 0.5em;
    }


    .filterModalBox{
        position: absolute;
        top: 27em;
        left: 50%;
        height: 27em;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 10px;
        border-radius: 8px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        width: 23em;
    }

    .FilterDropdown {
        text-align: center;
        margin-bottom: 0.5em;
        margin-top: 0.5em;
        /* margin-left: 0em; */
        width: 100%;
    }


    .FilterDropdown .select {
        width: 90%;
        text-align: center;
        padding: 10px;
        font-size: 16px;
    }


    .FilterDropdown .select .paragraphText {
        margin-top: 10px;
        font-weight: bold;
        font-size: 16px;
        color: #333;
    }


    .historyBackBtn {
        /* margin-left: 1.5em; */
        margin-left: 0em;
        background-color: #000080;
        color: white;
        border: none;
        padding: 3px;
        cursor: pointer;
        border-radius: 10px;
        font-weight: bold;
        display: flex;
        align-items: center;
    }






    .history-filter-top-content {
        display: flex;
        margin-right: -5.8em;
        margin-left: 1em;
    }




    .history-filter-top-content1 {
        display: flex;
        margin-right: -4em;
        margin-left: -1.6em;




    }




    .historyPopUpModal {
        position: absolute;
        top: 54%;
        left: 50%;
        height: 32em;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        width: 300px;
    }

    .HistoryECGContainer{
        margin-left: -0.2em;
    }

    .HistoryHeaderTxt {
        margin-right: 5em;
        text-align: center;
        font-size: 20px;
    }




    .vitalDetailsBorder {
        width: 100%;
        height: 2.3em;
        background: navy;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1px;
        margin-bottom: 1em;
    }




    .vitalDetailsModal {
        /* margin: 0; */
        color: white;
        font-size: 13px;
        font-weight: bold;
        display: flex;
        margin-left: 8em;
        /* align-items: center; */
        /* justify-content: center; */
    }


    .historyDateTime {
        height: 2em;
        padding: 7px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-bottom: 10px;
        margin-top: 1px;
    }






    .HistoryRecord-title {
        display: flex;
        font-size: 14px;
        text-align: center;
        margin-right: 25px;
        margin-left: 62px;
        margin-top: 3px;
    }


    .historyModelX {
        background: navy;
        color: white;
        border-color: white;
        font-weight: bold;
        padding: 3px 7px;
        border-radius: 14px;
        font-size: 14px;
        display: flex;
        margin-left: 6em;
    }


    .ECGContainer {
        display: block;
        /* You can adjust this based on the value of 'isModalOpen' */
        background-color: #efefef;
        height: 25.8em;
        /* width: 105%; */
        margin: auto;
        margin-right: -15px;
        /* margin-top: -15px; */
        /* margin-top: 4em; */
        /* margin-bottom: em; */
        /* This appears to be incomplete */
        margin-left: -15px;
        overflow: auto;
        scroll-margin-left: 0;
        scroll-margin-top: 0;
    }








    .HistoryImage-container,
    .HistoryHeader-container,
    .HistoryHeader-container1,
    .HistoryDetails-container,
    .HistoryRange-container,
    .HistoryRange-container1,
    .HistoryRange-container2,
    .HistoryTilte-contaniner {
        display: flex;
        align-items: center;
        justify-content: center;    }






    .HistoryImage1 {
        max-width: 33%;
    /* height: 10%; */
    margin-right:50%;
    margin-left: 50%;
    margin-top: 11px;
    }

    .HistorybloodPressureImage {
        border: #000;
        border-radius: 3px;
        max-width: 25%;
        height: 8%;
        margin-right: 50%;
        margin-left: 50%;
        margin-top: 17px;
    }


    .HistoryImage {
        max-width: 29%;
        height: 10%;
        margin-right: 50%;
        margin-left: 50%;
    }








    .HistoryHeader-title {
     /* display: flex; */
     margin: 0;
     font-size: 14px;
     text-align: center;
     /* margin-right: 25%; */
     /* margin-left: 25%; */
     margin-top: 8px;
    }


    .HistoryHeader-titleoxy {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        /* margin-right: 25%; */
        /* margin-left: 24%; */
        margin-top: 3px;
    }

    .HistoryHeader-titleBT {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        /* margin-right: 25%; */
        /* margin-left: 24%; */
        margin-top: 7px;
    }

    .HistoryHeader-title1 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 25%;
        margin-left: 25%;
        margin-top: 8px;
    }




    .HistoryHeader-title2 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 0%;
        margin-left: 0%;
        margin-top: 8px;
    }



    .HistoryDetails-container .HistoryValue {
        background-color: #ffb33a;
        color: white;
        font-size: 14px;
        text-align: center;
        padding: 5px;
        border-radius: 5px;
        margin-left: 0%;
        margin-right: 0%;
        margin-top: 5px;
        width: 95%;

    }




    .HistoryTilte-contaniner .HistoryTilte {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 20%;
        margin-right: 20%;
    }




    .HistoryTilte-contaniner .HistoryTilteT {
         text-align: center;
    font-size: 12px;
    margin-top: 5px;
    margin-left: 0%;
    margin-right: 0%;
    }




    .HistoryContainer-image {
        padding: 15px 10px;
        margin-top: 10px;
        padding-top: 5px;
    }




    .HistoryRange-container .HistoryRange {
        text-align: center;
        font-size: 13px;
        margin-top: 5px;
        margin-left: 0%;
        margin-right: 0%;
        font-weight: bold;
    }




    .HistoryRange-container1 .HistoryRange1 {
        text-align: center;
        font-size: 13px;
        margin-top: 5px;
        /* margin-left: 25%; */
        /* margin-right: 25%; */
        font-weight: bold;
    }




    .HistoryRange-container2 .HistoryRange2 {
        text-align: center;
        font-size: 13px;
        margin-top: 5px;
        /* margin-left: 25%; */
        /* margin-right: 25%; */
        margin-bottom: 20px;
        font-weight: bold;

    }







    .HistoryRange-container1 .HistoryRange3 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 77px;
        margin-bottom: 25px;
        font-weight: bold;
    }




    .HistoryRange-container1 .HistoryRange4 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 55px;
        margin-bottom: 25px;
        font-weight: bold;




    }




    .HistoryRange-container1 .HistoryRange5 {
         text-align: center;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 0%;
    margin-right: 0%;
    margin-bottom: 25px;
    font-weight: bold;
    }




    .HistoryRange-container1 .HistoryRange6 {
         text-align: center;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 0%;
    margin-right: 0%;
    margin-bottom: 25px;
    font-weight: bold;
    }

    .ibuttonHistory {
        display: flex;
        justify-content: flex-end;
    }

    .instructionButtonHistory {
        display: flex;
        position: static;
        font-weight: 800;
        height: 20px;
        width: 20px;
        color: #efefef;
        margin-top: -1.5em;
        margin-left: 9em;
        cursor: pointer;
        background-color: #000080;
        border: none;
        border-radius: 1em;
        /* background-image: url('aa.png'); */
    }


    .HistoryECGIntervelText {
        font-size: 18px;
        font-weight: bold;
        margin-top: 1em;
        margin-bottom: -2em;
        margin-left: 1em;
    }


    .HistoryECGIntervelText1 {
        font-size: 18px;
        font-weight: bold;
        margin-top: 0em;
        margin-left: 1em;
    }

    .detail-icon
    {
        display:flex;
        position:absolute;
        height:1.5em;
        margin-left:8.5em
    }
    .share-icon{
        display:flex;
        position:absolute;
        height:1.5em;
        margin-left:8.5em;
        margin-top: 2em;
    }
    .HistoryECGgrid-container{
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Two columns */
        grid-template-rows: repeat(2, 1fr);    /* Two rows */
        gap: 10px;

      }

      .Historybioheadergrid {
        width:100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        /* gap: 20px; */
      }

      .HistorybioheaderLeft-item,
      .HistorybioheaderRight-item {
        /* border: 1px solid #ddd; */
        padding:1%;
        margin-left: 10%;
        margin-right: 10%;
        /* text-align: center; */

        /* border-radius: 8px; */
      }

      .historyBoldTextStyle {
        font-weight: bold;
      }

      .history-filter-grid-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        /* Three equal columns */
        gap: 8px;
        /* Adjust the gap as needed */
    }

    .history-filter-grid-item {
        text-align: center;
        flex: 2;
        /* Additional styling for each grid item if necessary */
    }


    .history-filter-grid-item-head {
        text-align: center;
        flex: 1;
        font-weight: bolder;
        margin-left: -1em;
        /* Additional styling for each grid item if necessary */
    }

    .Historypopup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 16em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .HistoryECGIntervalPopup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 15em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .HistoryECGIntervalPopup1 {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 17em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }
}

/* ----------- Windows Phone ----------- */

/* Portrait and Landscape */
@media screen
  and (device-width: 480px)
  and (device-height: 800px) {
    .HistoryContainer {
        width: 100%;
        /* height: 46.5em; */
        margin-bottom: 3.5em;
        background-color: #e5e5e6;
        border-radius: 7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8.6em;
        bottom: 1em;
        overflow-y: auto;
        /* Enable vertical scrolling */
        max-height: calc(100vh);
        /* Set maximum height to fill the available space */
        min-height: 11em;
        overflow-x: hidden;
    }




    .profileDetailHeader {
        height: 2.6em;
        width: 100%;
        padding: 9px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-bottom: 3px;
        margin-top: 2px;
    }


    .profileDetailHeaderText {
        flex: auto;
        /* margin-left: 1.5em; */
        margin-left: 0em;
    }


    .historyHeader {
        padding: 5px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-top: 5px;
        font-size: 25px;
        width: 97%;
        height: 1.4em;
    }


    .history-rectangle {
        margin-left: 5.5%;
        /* margin-right: -15%; */
        width: 89.5%;
        height: 39%;
        background-color: white;
        /* margin-left: 0em; */
        /* margin-bottom: 2em; */
        margin-top: 0.5em;
        display: flex;
        flex-direction: column;
    }




    .history-filter-rectangle {
        width: 370px;
        height: 45px;
        padding: 2px;
        background-color: white;
        margin-left: 1.4em;
        margin-bottom: 0.5em;
        display: flex;
        flex-direction: column;
    }


    .FilterDropdown {
        text-align: center;
        margin-bottom: 0.5em;
        margin-top: 0.5em;
        /* margin-left: 0em; */
        width: 100%;
    }
.filterModelx{
    background: navy;
    color: white;
    border-color: white;
    font-weight: bold;
    /* padding: 1px 6px; */
    border-radius: 15px;
    font-size: 14px;
    display: flex;
    margin-left: 23em;
    display: flex;
    position: absolute;
    margin-top: -1.4em;
}
    .FilterDropdown .select {
        width: 90%;
        text-align: center;
        padding: 10px;
        font-size: 16px;
    }


    .FilterDropdown .select .paragraphText {
        margin-top: 10px;
        font-weight: bold;
        font-size: 16px;
        color: #333;
    }


    .historyBackBtn {
        /* margin-left: 1.5em; */
        margin-left: 0em;
        background-color: #000080;
        color: white;
        border: none;
        padding: 3px;
        cursor: pointer;
        border-radius: 10px;
        font-weight: bold;
        display: flex;
        align-items: center;
    }






    .history-filter-top-content {
        display: flex;
        margin-right: -5.8em;
        margin-left: 1em;
    }


    .history-filter-top-content1 {
        display: flex;
        margin-right: -4em;
        margin-left: -1.6em;




    }

    .historyPopUpModal {
        position: absolute;
        top: 54%;
        left: 50%;
        height: 32em;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
        width: 300px;
    }

    .HistoryECGContainer{
        margin-left: -0.2em;
    }

    .HistoryHeaderTxt {
        margin-right: 5em;
        text-align: center;
        font-size: 20px;
    }




    .vitalDetailsBorder {
        width: 100%;
        height: 2.3em;
        background: navy;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1px;
        margin-bottom: 1em;
    }




    .vitalDetailsModal {
        /* margin: 0; */
        color: white;
        font-size: 13px;
        font-weight: bold;
        display: flex;
        margin-left: 8em;
        /* align-items: center; */
        /* justify-content: center; */
    }


    .historyDateTime {
        height: 2em;
        padding: 7px;
        display: flex;
        background-color: navy;
        border: 1px solid #ccc;
        border-radius: 5px;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-bottom: 10px;
        margin-top: 1px;
    }






    .HistoryRecord-title {
        display: flex;
        font-size: 14px;
        text-align: center;
        margin-right: 25px;
        margin-left: 62px;
        margin-top: 3px;
    }


    .historyModelX {
        background: navy;
        color: white;
        border-color: white;
        font-weight: bold;
        padding: 3px 7px;
        border-radius: 14px;
        font-size: 14px;
        display: flex;
        margin-left: 6em;
    }


    .ECGContainer {

        display: block;
        /* You can adjust this based on the value of 'isModalOpen' */
        background-color: #efefef;
        height: 25.8em;
        /* width: 105%; */
        margin: auto;
        margin-right: -15px;
        /* margin-top: -15px; */
        /* margin-top: 4em; */
        /* margin-bottom: em; */
        /* This appears to be incomplete */
        margin-left: -15px;
        overflow: auto;
        scroll-margin-left: 0;
        scroll-margin-top: 0;
    }








    .HistoryImage-container,
    .HistoryHeader-container,
    .HistoryHeader-container1,
    .HistoryDetails-container,
    .HistoryRange-container,
    .HistoryRange-container1,
    .HistoryRange-container2,
    .HistoryTilte-contaniner {
        display: flex;
        align-items: center;
        justify-content: center;    }






    .HistoryImage1 {
        max-width: 33%;
        /* height: 10%; */
        margin-right:50%;
        margin-left: 50%;
        margin-top: 11px;
    }

    .HistorybloodPressureImage {
        border: #000;
        border-radius: 3px;
        max-width: 25%;
        height: 8%;
        margin-right: 50%;
        margin-left: 50%;
        margin-top: 17px;
    }


    .HistoryImage {
        max-width: 29%;
        height: 10%;
        margin-right: 50%;
        margin-left: 50%;
    }








    .HistoryHeader-title {
         /* display: flex; */
    margin: 0;
    font-size: 14px;
    text-align: center;
    /* margin-right: 25%; */
    /* margin-left: 25%; */
    margin-top: 8px;
    }
    .HistoryHeader-titleoxy {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        /* margin-right: 25%; */
        /* margin-left: 24%; */
        margin-top: 3px;
    }

    .HistoryHeader-titleBT {
        /* display: flex; */
        margin: 0;
        font-size: 14px;
        text-align: center;
        /* margin-right: 25%; */
        /* margin-left: 24%; */
        margin-top: 7px;
    }



    .HistoryHeader-title1 {
        margin: 0;
    font-size: 14px;
    text-align: center;
    margin-right: 25%;
    margin-left: 25%;
    margin-top: 8px;
    }




    .HistoryHeader-title2 {
        margin: 0;
        font-size: 14px;
        text-align: center;
        margin-right: 0%;
        margin-left: 0%;
        margin-top: 8px;
    }




  .HistoryDetails-container .HistoryValue {
    background-color: #ffb33a;
    color: white;
    font-size: 14px;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    margin-left: 0%;
    margin-right: 0%;
    margin-top: 5px;
    width: 95%;

    }




    .HistoryTilte-contaniner .HistoryTilte {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 20%;
        margin-right: 20%;
    }




    .HistoryTilte-contaniner .HistoryTilteT {
         text-align: center;
    font-size: 12px;
    margin-top: 5px;
    margin-left: 0%;
    margin-right: 0%;
    }




    .HistoryContainer-image {
        padding: 15px 10px;
        margin-top: 10px;
        padding-top: 5px;
    }




    .HistoryRange-container .HistoryRange {
        text-align: center;
        font-size: 13px;
        margin-top: 5px;
        margin-left: 0%;
        margin-right: 0%;
        font-weight: bold;
    }




    .HistoryRange-container1 .HistoryRange1 {
        text-align: center;
        font-size: 13px;
        margin-top: 5px;
        /* margin-left: 25%; */
        /* margin-right: 25%; */
        font-weight: bold;
    }




    .HistoryRange-container2 .HistoryRange2 {
        text-align: center;
        font-size: 13px;
        margin-top: 5px;
        /* margin-left: 25%; */
        /* margin-right: 25%; */
        margin-bottom: 20px;
        font-weight: bold;

    }







    .HistoryRange-container1 .HistoryRange3 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 77px;
        margin-bottom: 25px;
        font-weight: bold;
    }




    .HistoryRange-container1 .HistoryRange4 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 82px;
        margin-right: 55px;
        margin-bottom: 25px;
        font-weight: bold;




    }




    .HistoryRange-container1 .HistoryRange5 {
        text-align: center;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 0%;
    margin-right: 0%;
    margin-bottom: 25px;
    font-weight: bold;
    }




    .HistoryRange-container1 .HistoryRange6 {
        text-align: center;
        font-size: 14px;
        margin-top: 5px;
        margin-left: 0%;
        margin-right: 0%;
        margin-bottom: 25px;
        font-weight: bold;
    }

    .ibuttonHistory {
        display: flex;
        justify-content: flex-end;
    }

    .instructionButtonHistory {
        display: flex;
        position: static;
        font-weight: 800;
        height: 20px;
        width: 20px;
        color: #efefef;
        margin-top: -1.5em;
        margin-left: 9em;
        cursor: pointer;
        background-color: #000080;
        border: none;
        border-radius: 1em;
        /* background-image: url('aa.png'); */
    }


    .HistoryECGIntervelText {
        font-size: 18px;
        font-weight: bold;
        margin-top: 1em;
        margin-bottom: -2em;
        margin-left: 1em;
    }


    .HistoryECGIntervelText1 {
        font-size: 18px;
        font-weight: bold;
        margin-top: 0em;
        margin-left: 1em;
    }

    .detail-icon
    {
        display:flex;
        position:absolute;
        height:1.5em;
        margin-left:8.5em
    }

    .share-icon{
        display:flex;
        position:absolute;
        height:1.5em;
        margin-left:8.5em;
        margin-top: 2em;
    }

    .HistoryECGgrid-container{
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Two columns */
        grid-template-rows: repeat(2, 1fr);    /* Two rows */
        gap: 10px;

      }

      .Historybioheadergrid {
        width:100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        /* gap: 20px; */
      }

      .HistorybioheaderLeft-item,
      .HistorybioheaderRight-item {
        /* border: 1px solid #ddd; */
        padding:1%;
        margin-left: 10%;
        margin-right: 10%;
        /* text-align: center; */

        /* border-radius: 8px; */
      }

      .historyBoldTextStyle {
        font-weight: bold;
      }

      .history-filter-grid-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        /* Three equal columns */
        gap: 8px;
        /* Adjust the gap as needed */
    }

    .history-filter-grid-item {
        text-align: center;
        flex: 2;
        /* Additional styling for each grid item if necessary */
    }


    .history-filter-grid-item-head {
        text-align: center;
        flex: 1;
        font-weight: bolder;
        margin-left: -1em;
        /* Additional styling for each grid item if necessary */
    }

    .Historypopup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 16em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .HistoryECGIntervalPopup {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 15em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

    .HistoryECGIntervalPopup1 {
        border: black solid 10px;
        border-radius: 10px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        height: 17em;
        width: 15em;
        padding: 20px;
        border: 1px solid #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999;
    }

}

