.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  margin-top: 0%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f2f2f2;
}


.react-tel-input .flag-dropdown {
  bottom: 55px !important;
  height: 35px !important;
  /* top: 213px !important; */
}

.main-container {
  display: flex;
  flex-direction: column;
  max-height: 100vh;
}


.content {
  border-top: 1px solid black;
  background-image:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url('../public/backGroundImage.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
}



.spinner {
  border: 4px solid rgba(0, 0, 0, 0.3);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  /* Apply the spin animation */
}
.PhoneTemplate{
  max-height: 42em;
  background-size: cover;
  background-repeat:no-repeat;
  margin-top:4.8em;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media  screen and (max-width: 1366px) {
  .App {
    text-align: center;
  }

  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }


  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }

  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    margin-top: 0%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .App-link {
    color: #61dafb;
  }

  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
  body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f2f2f2;
  }


  .react-tel-input .flag-dropdown {
    bottom: 55px !important;
    height: 35px !important;
    /* top: 213px !important; */
  }

  .main-container {
    display: flex;
    flex-direction: column;
    max-height: 100vh;
  }


  .content {
    border-top: 1px solid black;
    background-image:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url('../public/backGroundImage.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
  }

  .loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 9999;
  }



  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.3);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
    /* Apply the spin animation */
  }
  .PhoneTemplate{
    max-height: 42em;
    background-size: cover;
    background-repeat:no-repeat;
    margin-top:1.8em;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

}

@media only screen
  and (min-device-width: 320px)
  and (max-device-width: 480px)
    and (orientation: portrait) {
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    margin-top: 0%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .App-link {
    color: #61dafb;
  }

  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f2f2f2;
  }


  .react-tel-input .flag-dropdown {
    bottom: 55px !important;
    height: 35px !important;
    /* top: 213px !important; */
  }

  .main-container {
    display: flex;
    flex-direction: column;
    max-height: 100vh;
  }


    .content {
      border-top: 1px solid black;
      background-image:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url('../public/backGroundImage.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      height: 100vh;
    }

  .loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 9999;
  }

  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.3);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
    /* Apply the spin animation */
  }

}

/* ----------- iPhone 4 and 4S ----------- */

/* Portrait and Landscape */
@media only screen
  and (min-device-width: 320px)
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .App-header {
      background-color: #282c34;
      min-height: 100vh;
      display: flex;
      margin-top: 0%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: calc(10px + 2vmin);
      color: white;
    }

    .App-link {
      color: #61dafb;
    }

    @keyframes App-logo-spin {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }

    body {
      font-family: Arial, sans-serif;
      margin: 0;
      padding: 0;
      background-color: #f2f2f2;
    }


    .react-tel-input .flag-dropdown {
      bottom: 55px !important;
      height: 35px !important;
      /* top: 213px !important; */
    }

    .main-container {
      display: flex;
      flex-direction: column;
      max-height: 100vh;
    }


    .content {
      border-top: 1px solid black;
      background-image:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url('../public/backGroundImage.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      height: 100vh;
    }

    .loading-spinner {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.7);
      z-index: 9999;
    }

    .spinner {
      border: 4px solid rgba(0, 0, 0, 0.3);
      border-top: 4px solid #3498db;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      animation: spin 2s linear infinite;
      /* Apply the spin animation */
    }

}

/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen
  and (min-device-width: 320px)
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .App-header {
      background-color: #282c34;
      min-height: 100vh;
      display: flex;
      margin-top: 0%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: calc(10px + 2vmin);
      color: white;
    }

    .App-link {
      color: #61dafb;
    }

    @keyframes App-logo-spin {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }

    body {
      font-family: Arial, sans-serif;
      margin: 0;
      padding: 0;
      background-color: #f2f2f2;
    }


    .react-tel-input .flag-dropdown {
      bottom: 55px !important;
      height: 35px !important;
      /* top: 213px !important; */
    }

    .main-container {
      display: flex;
      flex-direction: column;
      max-height: 100vh;
    }


    .content {
      border-top: 1px solid black;
      background-image:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url('../public/backGroundImage.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      height: 100vh;
    }

    .loading-spinner {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.7);
      z-index: 9999;
    }

    .spinner {
      border: 4px solid rgba(0, 0, 0, 0.3);
      border-top: 4px solid #3498db;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      animation: spin 2s linear infinite;
      /* Apply the spin animation */
    }

}


/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media only screen
  and (min-device-width: 375px)
  and (max-device-width: 667px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .App-header {
      background-color: #282c34;
      min-height: 100vh;
      display: flex;
      margin-top: 0%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: calc(10px + 2vmin);
      color: white;
    }

    .App-link {
      color: #61dafb;
    }

    @keyframes App-logo-spin {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }

    body {
      font-family: Arial, sans-serif;
      margin: 0;
      padding: 0;
      background-color: #f2f2f2;
    }


    .react-tel-input .flag-dropdown {
      bottom: 55px !important;
      height: 35px !important;
      /* top: 213px !important; */
    }

    .main-container {
      display: flex;
      flex-direction: column;
      max-height: 100vh;
    }


    .content {
      border-top: 1px solid black;
      background-image:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url('../public/backGroundImage.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      height: 100vh;
    }

    .loading-spinner {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.7);
      z-index: 9999;
    }

    .spinner {
      border: 4px solid rgba(0, 0, 0, 0.3);
      border-top: 4px solid #3498db;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      animation: spin 2s linear infinite;
      /* Apply the spin animation */
    }

}


/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait and Landscape */
@media only screen
  and (min-device-width: 414px)
  and (max-device-width: 736px)
  and (-webkit-min-device-pixel-ratio: 3) {
    .App-header {
      background-color: #282c34;
      min-height: 100vh;
      display: flex;
      margin-top: 0%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: calc(10px + 2vmin);
      color: white;
    }

    .App-link {
      color: #61dafb;
    }

    @keyframes App-logo-spin {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }

    body {
      font-family: Arial, sans-serif;
      margin: 0;
      padding: 0;
      background-color: #f2f2f2;
    }


    .react-tel-input .flag-dropdown {
      bottom: 55px !important;
      height: 35px !important;
      /* top: 213px !important; */
    }

    .main-container {
      display: flex;
      flex-direction: column;
      max-height: 100vh;
    }


    .content {
      border-top: 1px solid black;
    }

    .loading-spinner {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.7);
      z-index: 9999;
    }

    .spinner {
      border: 4px solid rgba(0, 0, 0, 0.3);
      border-top: 4px solid #3498db;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      animation: spin 2s linear infinite;
      /* Apply the spin animation */
    }

}


/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen
  and (min-device-width: 375px)
  and (max-device-width: 812px)
  and (-webkit-min-device-pixel-ratio: 3) {
    .App-header {
      background-color: #282c34;
      min-height: 100vh;
      display: flex;
      margin-top: 0%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: calc(10px + 2vmin);
      color: white;
    }

    .App-link {
      color: #61dafb;
    }

    @keyframes App-logo-spin {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }

    body {
      font-family: Arial, sans-serif;
      margin: 0;
      padding: 0;
      background-color: #f2f2f2;
    }


    .react-tel-input .flag-dropdown {
      bottom: 55px !important;
      height: 35px !important;
      /* top: 213px !important; */
    }

    .main-container {
      display: flex;
      flex-direction: column;
      max-height: 100vh;
    }


    .content {
      border-top: 1px solid black;
      background-image:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url('../public/backGroundImage.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      height: 100vh;
    }

    .loading-spinner {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.7);
      z-index: 9999;
    }

    .spinner {
      border: 4px solid rgba(0, 0, 0, 0.3);
      border-top: 4px solid #3498db;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      animation: spin 2s linear infinite;
      /* Apply the spin animation */
    }

}

/* ----------- Galaxy S3 ----------- */

/* Portrait and Landscape */
@media screen
  and (device-width: 360px)
  and (device-height: 640px)
  and (-webkit-device-pixel-ratio: 2) {
    .App-header {
      background-color: #282c34;
      min-height: 100vh;
      display: flex;
      margin-top: 0%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: calc(10px + 2vmin);
      color: white;
    }

    .App-link {
      color: #61dafb;
    }

    @keyframes App-logo-spin {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }

    body {
      font-family: Arial, sans-serif;
      margin: 0;
      padding: 0;
      background-color: #f2f2f2;
    }


    .react-tel-input .flag-dropdown {
      bottom: 55px !important;
      height: 35px !important;
      /* top: 213px !important; */
    }

    .main-container {
      display: flex;
      flex-direction: column;
      max-height: 100vh;
    }


    .content {
      border-top: 1px solid black;
      background-image:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url('../public/backGroundImage.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      height: 100vh;
    }

    .loading-spinner {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.7);
      z-index: 9999;
    }

    .spinner {
      border: 4px solid rgba(0, 0, 0, 0.3);
      border-top: 4px solid #3498db;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      animation: spin 2s linear infinite;
      /* Apply the spin animation */
    }

}

/* ----------- Galaxy S4, S5 and Note 3 ----------- */

/* Portrait and Landscape */
@media screen
  and (device-width: 320px)
  and (device-height: 640px)
  and (-webkit-device-pixel-ratio: 3) {
    .App-header {
      background-color: #282c34;
      min-height: 100vh;
      display: flex;
      margin-top: 0%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: calc(10px + 2vmin);
      color: white;
    }

    .App-link {
      color: #61dafb;
    }

    @keyframes App-logo-spin {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }

    body {
      font-family: Arial, sans-serif;
      margin: 0;
      padding: 0;
      background-color: #f2f2f2;
    }


    .react-tel-input .flag-dropdown {
      bottom: 55px !important;
      height: 35px !important;
      /* top: 213px !important; */
    }

    .main-container {
      display: flex;
      flex-direction: column;
      max-height: 100vh;
    }


    .content {
      border-top: 1px solid black;
      background-image:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url('../public/backGroundImage.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      height: 100vh;
    }

    .loading-spinner {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.7);
      z-index: 9999;
    }

    .spinner {
      border: 4px solid rgba(0, 0, 0, 0.3);
      border-top: 4px solid #3498db;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      animation: spin 2s linear infinite;
      /* Apply the spin animation */
    }

}

/* ----------- Galaxy S6 ----------- */

/* Portrait and Landscape */
@media screen
  and (device-width: 360px)
  and (device-height: 640px)
  and (-webkit-device-pixel-ratio: 4) {
    .App-header {
      background-color: #282c34;
      min-height: 100vh;
      display: flex;
      margin-top: 0%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: calc(10px + 2vmin);
      color: white;
    }

    .App-link {
      color: #61dafb;
    }

    @keyframes App-logo-spin {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }

    body {
      font-family: Arial, sans-serif;
      margin: 0;
      padding: 0;
      background-color: #f2f2f2;
    }


    .react-tel-input .flag-dropdown {
      bottom: 55px !important;
      height: 35px !important;
      /* top: 213px !important; */
    }

    .main-container {
      display: flex;
      flex-direction: column;
      max-height: 100vh;
    }


    .content {
      border-top: 1px solid black;
      background-image:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url('../public/backGroundImage.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      height: 100vh;
    }

    .loading-spinner {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.7);
      z-index: 9999;
    }

    .spinner {
      border: 4px solid rgba(0, 0, 0, 0.3);
      border-top: 4px solid #3498db;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      animation: spin 2s linear infinite;
      /* Apply the spin animation */
    }

}

/* ----------- HTC One ----------- */

/* Portrait and Landscape */
@media screen
  and (device-width: 360px)
  and (device-height: 640px)
  and (-webkit-device-pixel-ratio: 3) {
    .App-header {
      background-color: #282c34;
      min-height: 100vh;
      display: flex;
      margin-top: 0%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: calc(10px + 2vmin);
      color: white;
    }

    .App-link {
      color: #61dafb;
    }

    @keyframes App-logo-spin {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }

    body {
      font-family: Arial, sans-serif;
      margin: 0;
      padding: 0;
      background-color: #f2f2f2;
    }


    .react-tel-input .flag-dropdown {
      bottom: 55px !important;
      height: 35px !important;
      /* top: 213px !important; */
    }

    .main-container {
      display: flex;
      flex-direction: column;
      max-height: 100vh;
    }


    .content {
      border-top: 1px solid black;
      background-image:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url('../public/backGroundImage.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      height: 100vh;
    }

    .loading-spinner {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.7);
      z-index: 9999;
    }

    .spinner {
      border: 4px solid rgba(0, 0, 0, 0.3);
      border-top: 4px solid #3498db;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      animation: spin 2s linear infinite;
      /* Apply the spin animation */
    }

}

/* ----------- Google Pixel ----------- */

/* Portrait and Landscape */
@media screen
  and (device-width: 360px)
  and (device-height: 640px)
  and (-webkit-device-pixel-ratio: 3) {
    .App-header {
      background-color: #282c34;
      min-height: 100vh;
      display: flex;
      margin-top: 0%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: calc(10px + 2vmin);
      color: white;
    }

    .App-link {
      color: #61dafb;
    }

    @keyframes App-logo-spin {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }

    body {
      font-family: Arial, sans-serif;
      margin: 0;
      padding: 0;
      background-color: #f2f2f2;
    }


    .react-tel-input .flag-dropdown {
      bottom: 55px !important;
      height: 35px !important;
      /* top: 213px !important; */
    }

    .main-container {
      display: flex;
      flex-direction: column;
      max-height: 100vh;
    }


    .content {
      border-top: 1px solid black;
      background-image:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url('../public/backGroundImage.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      height: 100vh;
    }

    .loading-spinner {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.7);
      z-index: 9999;
    }

    .spinner {
      border: 4px solid rgba(0, 0, 0, 0.3);
      border-top: 4px solid #3498db;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      animation: spin 2s linear infinite;
      /* Apply the spin animation */
    }

}

/* ----------- Google Pixel XL ----------- */

/* Portrait and Landscape */
@media screen
  and (device-width: 360px)
  and (device-height: 640px)
  and (-webkit-device-pixel-ratio: 4) {
    .App-header {
      background-color: #282c34;
      min-height: 100vh;
      display: flex;
      margin-top: 0%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: calc(10px + 2vmin);
      color: white;
    }

    .App-link {
      color: #61dafb;
    }

    @keyframes App-logo-spin {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }

    body {
      font-family: Arial, sans-serif;
      margin: 0;
      padding: 0;
      background-color: #f2f2f2;
    }


    .react-tel-input .flag-dropdown {
      bottom: 55px !important;
      height: 35px !important;
      /* top: 213px !important; */
    }

    .main-container {
      display: flex;
      flex-direction: column;
      max-height: 100vh;
    }


    .content {
      border-top: 1px solid black;
      background-image:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url('../public/backGroundImage.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      height: 100vh;
    }

    .loading-spinner {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.7);
      z-index: 9999;
    }

    .spinner {
      border: 4px solid rgba(0, 0, 0, 0.3);
      border-top: 4px solid #3498db;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      animation: spin 2s linear infinite;
      /* Apply the spin animation */
    }

}

/* ----------- Nexus 4 ----------- */

/* Portrait and Landscape */
@media screen
  and (device-width: 384px)
  and (device-height: 592px)
  and (-webkit-device-pixel-ratio: 2) {
    .App-header {
      background-color: #282c34;
      min-height: 100vh;
      display: flex;
      margin-top: 0%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: calc(10px + 2vmin);
      color: white;
    }

    .App-link {
      color: #61dafb;
    }

    @keyframes App-logo-spin {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }

    body {
      font-family: Arial, sans-serif;
      margin: 0;
      padding: 0;
      background-color: #f2f2f2;
    }


    .react-tel-input .flag-dropdown {
      bottom: 55px !important;
      height: 35px !important;
      /* top: 213px !important; */
    }

    .main-container {
      display: flex;
      flex-direction: column;
      height: 100vh;
    }


    .content {
      border-top: 1px solid black;
      background-image:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url('../public/backGroundImage.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      height: 100vh;
    }

    .loading-spinner {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.7);
      z-index: 9999;
    }

    .spinner {
      border: 4px solid rgba(0, 0, 0, 0.3);
      border-top: 4px solid #3498db;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      animation: spin 2s linear infinite;
      /* Apply the spin animation */
    }

}

/* ----------- Nexus 5 ----------- */

/* Portrait and Landscape */
@media screen
  and (device-width: 360px)
  and (device-height: 592px)
  and (-webkit-device-pixel-ratio: 3) {
    .App-header {
      background-color: #282c34;
      min-height: 100vh;
      display: flex;
      margin-top: 0%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: calc(10px + 2vmin);
      color: white;
    }

    .App-link {
      color: #61dafb;
    }

    @keyframes App-logo-spin {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }

    body {
      font-family: Arial, sans-serif;
      margin: 0;
      padding: 0;
      background-color: #f2f2f2;
    }


    .react-tel-input .flag-dropdown {
      bottom: 55px !important;
      height: 35px !important;
      /* top: 213px !important; */
    }

    .main-container {
      display: flex;
      flex-direction: column;
      max-height: 100vh;
    }


    .content {
      border-top: 1px solid black;
      background-image:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url('../public/backGroundImage.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      height: 100vh;
    }

    .loading-spinner {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.7);
      z-index: 9999;
    }

    .spinner {
      border: 4px solid rgba(0, 0, 0, 0.3);
      border-top: 4px solid #3498db;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      animation: spin 2s linear infinite;
      /* Apply the spin animation */
    }

}

/* ----------- Nexus 6 and 6P ----------- */

/* Portrait and Landscape */
@media screen
  and (device-width: 360px)
  and (device-height: 592px)
  and (-webkit-device-pixel-ratio: 4) {
    .App-header {
      background-color: #282c34;
      min-height: 100vh;
      display: flex;
      margin-top: 0%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: calc(10px + 2vmin);
      color: white;
    }

    .App-link {
      color: #61dafb;
    }

    @keyframes App-logo-spin {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }

    body {
      font-family: Arial, sans-serif;
      margin: 0;
      padding: 0;
      background-color: #f2f2f2;
    }


    .react-tel-input .flag-dropdown {
      bottom: 55px !important;
      height: 35px !important;
      /* top: 213px !important; */
    }

    .main-container {
      display: flex;
      flex-direction: column;
      max-height: 100vh;
    }


    .content {
      border-top: 1px solid black;
      background-image:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url('../public/backGroundImage.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      height: 100vh;
    }

    .loading-spinner {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.7);
      z-index: 9999;
    }

    .spinner {
      border: 4px solid rgba(0, 0, 0, 0.3);
      border-top: 4px solid #3498db;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      animation: spin 2s linear infinite;
      /* Apply the spin animation */
    }

}

/* ----------- Windows Phone ----------- */

/* Portrait and Landscape */
@media screen
  and (device-width: 480px)
  and (device-height: 800px) {
    .App-header {
      background-color: #282c34;
      min-height: 100vh;
      display: flex;
      margin-top: 0%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: calc(10px + 2vmin);
      color: white;
    }

    .App-link {
      color: #61dafb;
    }

    @keyframes App-logo-spin {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(360deg);
      }
    }

    body {
      font-family: Arial, sans-serif;
      margin: 0;
      padding: 0;
      background-color: #f2f2f2;
    }


    .react-tel-input .flag-dropdown {
      bottom: 55px !important;
      height: 35px !important;
      /* top: 213px !important; */
    }

    .main-container {
      display: flex;
      flex-direction: column;
      max-height: 100vh;
    }

.content {
  border-top: 1px solid black;
  background-image:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url('../public/backGroundImage.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}

    .loading-spinner {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.7);
      z-index: 9999;
    }

    .spinner {
      border: 4px solid rgba(0, 0, 0, 0.3);
      border-top: 4px solid #3498db;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      animation: spin 2s linear infinite;
      /* Apply the spin animation */
    }

}
