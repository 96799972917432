 .takeVitalsContainer {
     width: 500px;
     /* height: 46.5em; */
     margin-bottom: 3.5em;
     background-color: #e5e5e6;
     border-radius: 7px;
     position: absolute;
     left: 50%;
     transform: translateX(-50%);
     top: 8.6em;
     bottom: 1em;
     overflow-y: auto;
     max-height: 83vh;
     overflow-x: hidden;
 }

 .takeVitals-Container {
     text-align: center;
 }


 .takeVitalsHeader {
     height: 1.4em;
     /* width: 100%; */
     padding: 9px;
     display: flex;
     background-color: navy;
     border: 1px solid #ccc;
     border-radius: 5px;
     justify-content: space-between;
     align-items: center;
     color: white;
     margin-bottom: 3px;
 }

 .instructionsBackBtn {
     background-color: #000080;
     color: white;
     border: none;
     padding: 3px;
     cursor: pointer;
     border-radius: 10px;
     font-weight: bold;
     display: flex;
     align-items: center;
 }


 .takeVitalsHeaderTxt {
     margin-right: 5.8em;
     text-align: center;
     font-size: 20px;
     font-weight: 600;
 }

 .takeVitalsImageStyle {
     display: flex;
     align-items: center;
     justify-content: center;
     margin-left: 50%;
     margin-right: 50%;
 }

 .instructionStyle {
     display: flex;
     /* align-items: center; */
     flex-direction: column;
     align-items: flex-start;
    width: 95%;
    margin-left: 0.2em;
    }

 .stepStyle {
     margin-left: 2.6em;
     display: flex;
     align-items: center;
     margin-bottom: 1.5em;
 }

 .stepNumberStyle {
     margin-left: 1em;
     min-width: 1.5em;
     font-size: 0.8em;
 }


 .startBtn {
     border-radius: 10px;
 }

 .takeVitalsfooter {
     position: fixed;
     bottom: 0;
     left: 0;
     width: 100%;
     height: 2.6em;
     background-color: white;
     display: flex;
     justify-content: space-between;
     padding: 14px 10px;
     box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
     /* Optional: Add a shadow to the footer */
 }


 @media only screen
  and (min-device-width: 320px)
  and (max-device-width: 480px)
 and (orientation: portrait) {

    .takeVitalsContainer {
        width: 500px;
        height: 50em;
        margin-bottom: 3.5em;
        background-color: #e5e5e6;
        border-radius: 7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8.6em;
        bottom: 1em;
        overflow-y: auto;
        /*max-height: 100vh;*/
        overflow-x: hidden;
    }
     .takeVitalsHeader {
         height: 1.4em;
         width: 100%;
         padding: 9px;
         display: flex;
         background-color: navy;
         border: 1px solid #ccc;
         border-radius: 5px;
         justify-content: space-between;
         align-items: center;
         color: white;
         margin-bottom: 3px;
     }


     .takeVitalsHeaderTxt {
         margin-right: 5em;
         text-align: center;
         font-size: 20px;
     }

     .instructionsBackBtn {
         margin-left: 4em;
         background-color: #000080;
         color: white;
         border: none;
         padding: 3px;
         cursor: pointer;
         border-radius: 10px;
         font-weight: bold;
         display: flex;
         align-items: center;
     }


     .stepStyle {
         margin-left: 4.8em;
         display: flex;
         align-items: center;
         justify-content: center;
         margin-bottom: 1.5em;
     }

     .stepNumberStyle {
         margin-left: 1em;
         min-width: 1.5em;
         font-size: 0.7em;
     }

     .startBtn {
         border-radius: 10px;
         margin-left: 3.8em;
     }
 }

 /* For mobile devices (portrait and landscape) */
 /* @media only screen and (max-width: 767px) {
     .takeVitalsContainer {
         width: 500px;

         margin-bottom: 3.5em;
         background-color: #e5e5e6;
         border-radius: 7px;
         position: absolute;
         left: 50%;
         transform: translateX(-50%);
         top: 8.6em;
         bottom: 1em;
         overflow-y: auto;
         max-height: 100vh;
         overflow-x: hidden;
     }

 } */



 /* For tablets and larger mobile devices (landscape) */
 @media only screen and (min-width: 768px) and (max-width: 1023px) {
     /* Your CSS styles for tablets and larger mobile devices */
 }


 /* ----------- iPhone 4 and 4S ----------- */

/* Portrait and Landscape */
@media only screen
and (min-device-width: 320px)
and (max-device-width: 480px)
and (-webkit-min-device-pixel-ratio: 2) {
    .takeVitalsContainer {
        width: 500px;
        height: 50em;
        margin-bottom: 3.5em;
        background-color: #e5e5e6;
        border-radius: 7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8.6em;
        bottom: 1em;
        overflow-y: auto;
        /*max-height: 100vh;*/
        overflow-x: hidden;
    }
     .takeVitalsHeader {
         height: 1.4em;
         width: 100%;
         padding: 9px;
         display: flex;
         background-color: navy;
         border: 1px solid #ccc;
         border-radius: 5px;
         justify-content: space-between;
         align-items: center;
         color: white;
         margin-bottom: 3px;
     }


     .takeVitalsHeaderTxt {
         margin-right: 5em;
         text-align: center;
         font-size: 20px;
     }

     .instructionsBackBtn {
         margin-left: 4em;
         background-color: #000080;
         color: white;
         border: none;
         padding: 3px;
         cursor: pointer;
         border-radius: 10px;
         font-weight: bold;
         display: flex;
         align-items: center;
     }


     .stepStyle {
         margin-left: 4.8em;
         display: flex;
         align-items: center;
         justify-content: center;
         margin-bottom: 1.5em;
     }

     .stepNumberStyle {
         margin-left: 1em;
         min-width: 1.5em;
         font-size: 0.7em;
     }

     .startBtn {
         border-radius: 10px;
         margin-left: 3.8em;
     }

}

/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen
and (min-device-width: 320px)
and (max-device-width: 568px)
and (-webkit-min-device-pixel-ratio: 2) {
    .takeVitalsContainer {
        width: 500px;
        /* height: 60em; */
        margin-bottom: 3.5em;
        background-color: #e5e5e6;
        border-radius: 7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8.6em;
        bottom: 1em;
        overflow-y: auto;
        max-height: 100vh;
        overflow-x: hidden;
    }
    .takeVitalsImageStyle {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 57%;
        margin-right: 50%;
    }
     .takeVitalsHeader {
         height: 1.4em;
         width: 100%;
         padding: 9px;
         display: flex;
         background-color: navy;
         border: 1px solid #ccc;
         border-radius: 5px;
         justify-content: space-between;
         align-items: center;
         color: white;
         margin-bottom: 3px;
     }


     .takeVitalsHeaderTxt {
         margin-right: 5em;
         text-align: center;
         font-size: 20px;
     }

     .instructionsBackBtn {
         margin-left: 4em;
         background-color: #000080;
         color: white;
         border: none;
         padding: 3px;
         cursor: pointer;
         border-radius: 10px;
         font-weight: bold;
         display: flex;
         align-items: center;
     }


     .stepStyle {
         margin-left: 4.8em;
         display: flex;
         align-items: center;
         justify-content: center;
         margin-bottom: 1.5em;
     }

     .stepNumberStyle {
         margin-left: 1em;
         min-width: 1.5em;
         font-size: 0.7em;
     }

     .startBtn {
         border-radius: 10px;
         margin-left: 3.8em;
     }


}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media only screen
and (min-device-width: 375px)
and (max-device-width: 667px)
and (-webkit-min-device-pixel-ratio: 2) {
    .takeVitalsContainer {
        width: 500px;
        /* height: 60em; */
        margin-bottom: 3.5em;
        background-color: #e5e5e6;
        border-radius: 7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8.6em;
        bottom: 1em;
        overflow-y: auto;
        max-height: 100vh;
        overflow-x: hidden;
    }
    .takeVitalsImageStyle {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 57%;
        margin-right: 50%;
    }
     .takeVitalsHeader {
         height: 1.4em;
         width: 100%;
         padding: 9px;
         display: flex;
         background-color: navy;
         border: 1px solid #ccc;
         border-radius: 5px;
         justify-content: space-between;
         align-items: center;
         color: white;
         margin-bottom: 3px;
     }


     .takeVitalsHeaderTxt {
         margin-right: 5em;
         text-align: center;
         font-size: 20px;
     }

     .instructionsBackBtn {
         margin-left: 4em;
         background-color: #000080;
         color: white;
         border: none;
         padding: 3px;
         cursor: pointer;
         border-radius: 10px;
         font-weight: bold;
         display: flex;
         align-items: center;
     }


     .stepStyle {
         margin-left: 4.8em;
         display: flex;
         align-items: center;
         justify-content: center;
         margin-bottom: 1.5em;
     }

     .stepNumberStyle {
         margin-left: 1em;
         min-width: 1.5em;
         font-size: 0.7em;
     }

     .startBtn {
         border-radius: 10px;
         margin-left: 3.8em;
     }

}


/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait and Landscape */
@media only screen
and (min-device-width: 414px)
and (max-device-width: 736px)
and (-webkit-min-device-pixel-ratio: 3) {
    .takeVitalsContainer {
        width: 500px;
        height: 50em;
        margin-bottom: 3.5em;
        background-color: #e5e5e6;
        border-radius: 7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8.6em;
        bottom: 1em;
        overflow-y: auto;
        /*max-height: 100vh;*/
        overflow-x: hidden;
    }
     .takeVitalsHeader {
         height: 1.4em;
         width: 100%;
         padding: 9px;
         display: flex;
         background-color: navy;
         border: 1px solid #ccc;
         border-radius: 5px;
         justify-content: space-between;
         align-items: center;
         color: white;
         margin-bottom: 3px;
     }


     .takeVitalsHeaderTxt {
         margin-right: 5em;
         text-align: center;
         font-size: 20px;
     }

     .instructionsBackBtn {
         margin-left: 4em;
         background-color: #000080;
         color: white;
         border: none;
         padding: 3px;
         cursor: pointer;
         border-radius: 10px;
         font-weight: bold;
         display: flex;
         align-items: center;
     }


     .stepStyle {
         margin-left: 4.8em;
         display: flex;
         align-items: center;
         justify-content: center;
         margin-bottom: 1.5em;
     }

     .stepNumberStyle {
         margin-left: 1em;
         min-width: 1.5em;
         font-size: 0.7em;
     }

     .startBtn {
         border-radius: 10px;
         margin-left: 3.8em;
     }

}


/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen
and (min-device-width: 375px)
and (max-device-width: 812px)
and (-webkit-min-device-pixel-ratio: 3) {
    .takeVitalsContainer {
        width: 500px;
        height: 50em;
        margin-bottom: 3.5em;
        background-color: #e5e5e6;
        border-radius: 7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8.6em;
        bottom: 1em;
        overflow-y: auto;
        /*max-height: 100vh;*/
        overflow-x: hidden;
    }
     .takeVitalsHeader {
         height: 1.4em;
         width: 100%;
         padding: 9px;
         display: flex;
         background-color: navy;
         border: 1px solid #ccc;
         border-radius: 5px;
         justify-content: space-between;
         align-items: center;
         color: white;
         margin-bottom: 3px;
     }


     .takeVitalsHeaderTxt {
         margin-right: 5em;
         text-align: center;
         font-size: 20px;
     }

     .instructionsBackBtn {
         margin-left: 4em;
         background-color: #000080;
         color: white;
         border: none;
         padding: 3px;
         cursor: pointer;
         border-radius: 10px;
         font-weight: bold;
         display: flex;
         align-items: center;
     }


     .stepStyle {
         margin-left: 4.8em;
         display: flex;
         align-items: center;
         justify-content: center;
         margin-bottom: 1.5em;
     }

     .stepNumberStyle {
         margin-left: 1em;
         min-width: 1.5em;
         font-size: 0.7em;
     }

     .startBtn {
         border-radius: 10px;
         margin-left: 3.8em;
     }

}

/* ----------- Galaxy S3 ----------- */

/* Portrait and Landscape */
@media screen
and (device-width: 360px)
and (device-height: 640px)
and (-webkit-device-pixel-ratio: 2) {
    .takeVitalsContainer {
        width: 500px;
        height: 50em;
        margin-bottom: 3.5em;
        background-color: #e5e5e6;
        border-radius: 7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8.6em;
        bottom: 1em;
        overflow-y: auto;
        /*max-height: 100vh;*/
        overflow-x: hidden;
    }
     .takeVitalsHeader {
         height: 1.4em;
         width: 100%;
         padding: 9px;
         display: flex;
         background-color: navy;
         border: 1px solid #ccc;
         border-radius: 5px;
         justify-content: space-between;
         align-items: center;
         color: white;
         margin-bottom: 3px;
     }


     .takeVitalsHeaderTxt {
         margin-right: 5em;
         text-align: center;
         font-size: 20px;
     }

     .instructionsBackBtn {
         margin-left: 4em;
         background-color: #000080;
         color: white;
         border: none;
         padding: 3px;
         cursor: pointer;
         border-radius: 10px;
         font-weight: bold;
         display: flex;
         align-items: center;
     }


     .stepStyle {
         margin-left: 4.8em;
         display: flex;
         align-items: center;
         justify-content: center;
         margin-bottom: 1.5em;
     }

     .stepNumberStyle {
         margin-left: 1em;
         min-width: 1.5em;
         font-size: 0.7em;
     }

     .startBtn {
         border-radius: 10px;
         margin-left: 3.8em;
     }

}

/* ----------- Galaxy S4, S5 and Note 3 ----------- */

/* Portrait and Landscape */
@media screen
and (device-width: 320px)
and (device-height: 640px)
and (-webkit-device-pixel-ratio: 3) {
    .takeVitalsContainer {
        width: 500px;
        height: 50em;
        margin-bottom: 3.5em;
        background-color: #e5e5e6;
        border-radius: 7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8.6em;
        bottom: 1em;
        overflow-y: auto;
        /*max-height: 100vh;*/
        overflow-x: hidden;
    }
     .takeVitalsHeader {
         height: 1.4em;
         width: 100%;
         padding: 9px;
         display: flex;
         background-color: navy;
         border: 1px solid #ccc;
         border-radius: 5px;
         justify-content: space-between;
         align-items: center;
         color: white;
         margin-bottom: 3px;
     }


     .takeVitalsHeaderTxt {
         margin-right: 5em;
         text-align: center;
         font-size: 20px;
     }

     .instructionsBackBtn {
         margin-left: 4em;
         background-color: #000080;
         color: white;
         border: none;
         padding: 3px;
         cursor: pointer;
         border-radius: 10px;
         font-weight: bold;
         display: flex;
         align-items: center;
     }


     .stepStyle {
         margin-left: 4.8em;
         display: flex;
         align-items: center;
         justify-content: center;
         margin-bottom: 1.5em;
     }

     .stepNumberStyle {
         margin-left: 1em;
         min-width: 1.5em;
         font-size: 0.7em;
     }

     .startBtn {
         border-radius: 10px;
         margin-left: 3.8em;
     }

}

/* ----------- Galaxy S6 ----------- */

/* Portrait and Landscape */
@media screen
and (device-width: 360px)
and (device-height: 640px)
and (-webkit-device-pixel-ratio: 4) {
    .takeVitalsContainer {
        width: 500px;
        height: 50em;
        margin-bottom: 3.5em;
        background-color: #e5e5e6;
        border-radius: 7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8.6em;
        bottom: 1em;
        overflow-y: auto;
        /*max-height: 100vh;*/
        overflow-x: hidden;
    }
     .takeVitalsHeader {
         height: 1.4em;
         width: 100%;
         padding: 9px;
         display: flex;
         background-color: navy;
         border: 1px solid #ccc;
         border-radius: 5px;
         justify-content: space-between;
         align-items: center;
         color: white;
         margin-bottom: 3px;
     }


     .takeVitalsHeaderTxt {
         margin-right: 5em;
         text-align: center;
         font-size: 20px;
     }

     .instructionsBackBtn {
         margin-left: 4em;
         background-color: #000080;
         color: white;
         border: none;
         padding: 3px;
         cursor: pointer;
         border-radius: 10px;
         font-weight: bold;
         display: flex;
         align-items: center;
     }


     .stepStyle {
         margin-left: 4.8em;
         display: flex;
         align-items: center;
         justify-content: center;
         margin-bottom: 1.5em;
     }

     .stepNumberStyle {
         margin-left: 1em;
         min-width: 1.5em;
         font-size: 0.7em;
     }

     .startBtn {
         border-radius: 10px;
         margin-left: 3.8em;
     }

}

/* ----------- HTC One ----------- */

/* Portrait and Landscape */
@media screen
and (device-width: 360px)
and (device-height: 640px)
and (-webkit-device-pixel-ratio: 3) {
    .takeVitalsContainer {
        width: 500px;
        height: 50em;
        margin-bottom: 3.5em;
        background-color: #e5e5e6;
        border-radius: 7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8.6em;
        bottom: 1em;
        overflow-y: auto;
        /*max-height: 100vh;*/
        overflow-x: hidden;
    }
     .takeVitalsHeader {
         height: 1.4em;
         width: 100%;
         padding: 9px;
         display: flex;
         background-color: navy;
         border: 1px solid #ccc;
         border-radius: 5px;
         justify-content: space-between;
         align-items: center;
         color: white;
         margin-bottom: 3px;
     }


     .takeVitalsHeaderTxt {
         margin-right: 5em;
         text-align: center;
         font-size: 20px;
     }

     .instructionsBackBtn {
         margin-left: 4em;
         background-color: #000080;
         color: white;
         border: none;
         padding: 3px;
         cursor: pointer;
         border-radius: 10px;
         font-weight: bold;
         display: flex;
         align-items: center;
     }


     .stepStyle {
         margin-left: 4.8em;
         display: flex;
         align-items: center;
         justify-content: center;
         margin-bottom: 1.5em;
     }

     .stepNumberStyle {
         margin-left: 1em;
         min-width: 1.5em;
         font-size: 0.7em;
     }

     .startBtn {
         border-radius: 10px;
         margin-left: 3.8em;
     }

}

/* ----------- Google Pixel ----------- */

/* Portrait and Landscape */
@media screen
and (device-width: 360px)
and (device-height: 640px)
and (-webkit-device-pixel-ratio: 3) {
    .takeVitalsContainer {
        width: 500px;
        height: 50em;
        margin-bottom: 3.5em;
        background-color: #e5e5e6;
        border-radius: 7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8.6em;
        bottom: 1em;
        overflow-y: auto;
        /*max-height: 100vh;*/
        overflow-x: hidden;
    }
     .takeVitalsHeader {
         height: 1.4em;
         width: 100%;
         padding: 9px;
         display: flex;
         background-color: navy;
         border: 1px solid #ccc;
         border-radius: 5px;
         justify-content: space-between;
         align-items: center;
         color: white;
         margin-bottom: 3px;
     }


     .takeVitalsHeaderTxt {
         margin-right: 5em;
         text-align: center;
         font-size: 20px;
     }

     .instructionsBackBtn {
         margin-left: 4em;
         background-color: #000080;
         color: white;
         border: none;
         padding: 3px;
         cursor: pointer;
         border-radius: 10px;
         font-weight: bold;
         display: flex;
         align-items: center;
     }


     .stepStyle {
         margin-left: 4.8em;
         display: flex;
         align-items: center;
         justify-content: center;
         margin-bottom: 1.5em;
     }

     .stepNumberStyle {
         margin-left: 1em;
         min-width: 1.5em;
         font-size: 0.7em;
     }

     .startBtn {
         border-radius: 10px;
         margin-left: 3.8em;
     }

}

/* ----------- Google Pixel XL ----------- */

/* Portrait and Landscape */
@media screen
and (device-width: 360px)
and (device-height: 640px)
and (-webkit-device-pixel-ratio: 4) {
    .takeVitalsContainer {
        width: 500px;
        height: 50em;
        margin-bottom: 3.5em;
        background-color: #e5e5e6;
        border-radius: 7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8.6em;
        bottom: 1em;
        overflow-y: auto;
        /*max-height: 100vh;*/
        overflow-x: hidden;
    }
     .takeVitalsHeader {
         height: 1.4em;
         width: 100%;
         padding: 9px;
         display: flex;
         background-color: navy;
         border: 1px solid #ccc;
         border-radius: 5px;
         justify-content: space-between;
         align-items: center;
         color: white;
         margin-bottom: 3px;
     }


     .takeVitalsHeaderTxt {
         margin-right: 5em;
         text-align: center;
         font-size: 20px;
     }

     .instructionsBackBtn {
         margin-left: 4em;
         background-color: #000080;
         color: white;
         border: none;
         padding: 3px;
         cursor: pointer;
         border-radius: 10px;
         font-weight: bold;
         display: flex;
         align-items: center;
     }


     .stepStyle {
         margin-left: 4.8em;
         display: flex;
         align-items: center;
         justify-content: center;
         margin-bottom: 1.5em;
     }

     .stepNumberStyle {
         margin-left: 1em;
         min-width: 1.5em;
         font-size: 0.7em;
     }

     .startBtn {
         border-radius: 10px;
         margin-left: 3.8em;
     }

}

/* ----------- Nexus 4 ----------- */

/* Portrait and Landscape */
@media screen
and (device-width: 384px)
and (device-height: 592px)
and (-webkit-device-pixel-ratio: 2) {
    .takeVitalsContainer {
        width: 500px;
        height: 50em;
        margin-bottom: 3.5em;
        background-color: #e5e5e6;
        border-radius: 7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8.6em;
        bottom: 1em;
        overflow-y: auto;
        /*max-height: 100vh;*/
        overflow-x: hidden;
    }
     .takeVitalsHeader {
         height: 1.4em;
         width: 100%;
         padding: 9px;
         display: flex;
         background-color: navy;
         border: 1px solid #ccc;
         border-radius: 5px;
         justify-content: space-between;
         align-items: center;
         color: white;
         margin-bottom: 3px;
     }


     .takeVitalsHeaderTxt {
         margin-right: 5em;
         text-align: center;
         font-size: 20px;
     }

     .instructionsBackBtn {
         margin-left: 4em;
         background-color: #000080;
         color: white;
         border: none;
         padding: 3px;
         cursor: pointer;
         border-radius: 10px;
         font-weight: bold;
         display: flex;
         align-items: center;
     }


     .stepStyle {
         margin-left: 4.8em;
         display: flex;
         align-items: center;
         justify-content: center;
         margin-bottom: 1.5em;
     }

     .stepNumberStyle {
         margin-left: 1em;
         min-width: 1.5em;
         font-size: 0.7em;
     }

     .startBtn {
         border-radius: 10px;
         margin-left: 3.8em;
     }

}

/* ----------- Nexus 5 ----------- */

/* Portrait and Landscape */
@media screen
and (device-width: 360px)
and (device-height: 592px)
and (-webkit-device-pixel-ratio: 3) {
    .takeVitalsContainer {
        width: 500px;
        height: 50em;
        margin-bottom: 3.5em;
        background-color: #e5e5e6;
        border-radius: 7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8.6em;
        bottom: 1em;
        overflow-y: auto;
        /*max-height: 100vh;*/
        overflow-x: hidden;
    }
     .takeVitalsHeader {
         height: 1.4em;
         width: 100%;
         padding: 9px;
         display: flex;
         background-color: navy;
         border: 1px solid #ccc;
         border-radius: 5px;
         justify-content: space-between;
         align-items: center;
         color: white;
         margin-bottom: 3px;
     }


     .takeVitalsHeaderTxt {
         margin-right: 5em;
         text-align: center;
         font-size: 20px;
     }

     .instructionsBackBtn {
         margin-left: 4em;
         background-color: #000080;
         color: white;
         border: none;
         padding: 3px;
         cursor: pointer;
         border-radius: 10px;
         font-weight: bold;
         display: flex;
         align-items: center;
     }


     .stepStyle {
         margin-left: 4.8em;
         display: flex;
         align-items: center;
         justify-content: center;
         margin-bottom: 1.5em;
     }

     .stepNumberStyle {
         margin-left: 1em;
         min-width: 1.5em;
         font-size: 0.7em;
     }

     .startBtn {
         border-radius: 10px;
         margin-left: 3.8em;
     }

}

/* ----------- Nexus 6 and 6P ----------- */

/* Portrait and Landscape */
@media screen
and (device-width: 360px)
and (device-height: 592px)
and (-webkit-device-pixel-ratio: 4) {
    .takeVitalsContainer {
        width: 500px;
        height: 50em;
        margin-bottom: 3.5em;
        background-color: #e5e5e6;
        border-radius: 7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8.6em;
        bottom: 1em;
        overflow-y: auto;
        /*max-height: 100vh;*/
        overflow-x: hidden;
    }
     .takeVitalsHeader {
         height: 1.4em;
         width: 100%;
         padding: 9px;
         display: flex;
         background-color: navy;
         border: 1px solid #ccc;
         border-radius: 5px;
         justify-content: space-between;
         align-items: center;
         color: white;
         margin-bottom: 3px;
     }


     .takeVitalsHeaderTxt {
         margin-right: 5em;
         text-align: center;
         font-size: 20px;
     }

     .instructionsBackBtn {
         margin-left: 4em;
         background-color: #000080;
         color: white;
         border: none;
         padding: 3px;
         cursor: pointer;
         border-radius: 10px;
         font-weight: bold;
         display: flex;
         align-items: center;
     }


     .stepStyle {
         margin-left: 4.8em;
         display: flex;
         align-items: center;
         justify-content: center;
         margin-bottom: 1.5em;
     }

     .stepNumberStyle {
         margin-left: 1em;
         min-width: 1.5em;
         font-size: 0.7em;
     }

     .startBtn {
         border-radius: 10px;
         margin-left: 3.8em;
     }

}

/* ----------- Windows Phone ----------- */

/* Portrait and Landscape */
@media screen
and (device-width: 480px)
and (device-height: 800px) {
    .takeVitalsContainer {
        width: 500px;
        height: 50em;
        margin-bottom: 3.5em;
        background-color: #e5e5e6;
        border-radius: 7px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8.6em;
        bottom: 1em;
        overflow-y: auto;
        /*max-height: 100vh;*/
        overflow-x: hidden;
    }
     .takeVitalsHeader {
         height: 1.4em;
         width: 100%;
         padding: 9px;
         display: flex;
         background-color: navy;
         border: 1px solid #ccc;
         border-radius: 5px;
         justify-content: space-between;
         align-items: center;
         color: white;
         margin-bottom: 3px;
     }


     .takeVitalsHeaderTxt {
         margin-right: 5em;
         text-align: center;
         font-size: 20px;
     }

     .instructionsBackBtn {
         margin-left: 4em;
         background-color: #000080;
         color: white;
         border: none;
         padding: 3px;
         cursor: pointer;
         border-radius: 10px;
         font-weight: bold;
         display: flex;
         align-items: center;
     }


     .stepStyle {
         margin-left: 4.8em;
         display: flex;
         align-items: center;
         justify-content: center;
         margin-bottom: 1.5em;
     }

     .stepNumberStyle {
         margin-left: 1em;
         min-width: 1.5em;
         font-size: 0.7em;
     }

     .startBtn {
         border-radius: 10px;
         margin-left: 3.8em;
     }

}
